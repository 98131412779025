import { IBlock } from "../../../framework/main/IBlock";
import { BlockComponent } from "../../../framework/main/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/main/Messages/MessageEnum";
import { runEngine } from "../../../framework/main/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  title: string;
  inputList: any;
  serviceArray: any;
  btnDisplay?: any;
  buttonName?: any;
  name?: any;
  formikHandelChange?: any;
  formikValue?: any;
  formikError?: any;
  maxFeilds?: any;
  touched?: any;
  goodToHave?: boolean;
  classes?: any;
  offeringText?: any;
  showCustomList?: any;
  showListHeader?: boolean;
  requiredIndex?: number;
}

interface S {
}

interface SS {
  id: any;
}

export default class ProblemStatementWithFormikController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    //
  }

  // web events


  styleCondition(data: any, condition1: any, condition2: any) {
    if (data) {
      return condition1;
    } else {
      return condition2;
    }
  }
}
