export const getCurrencySybmol = (code) => {
  switch (code) {
    case 'GBP':
      return '£'
    case 'USD':
      return '$'
    default:
      return code;
  }
}

export const formatCurrency = (amount) => {
  if (typeof amount === 'number') {
    return amount?.toFixed(2);
  } else {
    return amount
  }
}