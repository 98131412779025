import { IBlock } from "../../../framework/main/IBlock";
import { BlockComponent } from "../../../framework/main/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/main/Messages/MessageEnum";
import { runEngine } from "../../../framework/main/RunEngine";

import StorageProvider from '../../../framework/main/StorageProvider';
import { Message } from "../../../framework/main/Message";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  token?: string;
  open: boolean;
  onClose: () => void;
  generatedOutPutId: string;
  shareType: string;
}

interface S {
  fileType: string;
  email: string;
  emailList: string[];
  downloadUrl: string;
  error: boolean;
  emailError: string;
  fileTypeError: boolean;
  fileTypeErrorMsg: string;
}

interface SS {
  id: any;
}

export default class ShareFileModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  shareFilesID: string = "";
  token: string;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      fileType: '',
      email: '',
      emailList: [],
      downloadUrl: '',
      error: false,
      emailError: '',
      fileTypeError: false,
      fileTypeErrorMsg: ''
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    const token = await StorageProvider.getItem("user-token");
    this.token = token;
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      await this.handleFileUrl(requestCallId, message);
    }
  }
  async handleFileUrl(requestCallId: string, message: Message) {
    if (this.shareFilesID === requestCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson?.url) {
        this.setState({
          downloadUrl: responseJson.url
        });
      } else {
        const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorMessage);
      }
    }
  }
  handleFileType = (file: string) => {
    this.setState({
      fileType: file,
      fileTypeError: false,
      fileTypeErrorMsg: '',
    }, () => this.shareFIleAPi())
  }
  handleCancelFile = () => {
    this.setState({
      fileType: ''
    }, () => this.shareFIleAPi())
  }

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: event.target.value, error: false, emailError: '' });
  };

  handleEmailSubmit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if ((event.key === 'Enter' || event.key === ',') && this.state.email.trim() !== '') {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      if (!emailRegex.test(this.state.email)) {
        this.setState({ error: true, emailError: 'Enter Valid Email ID' })
      } else {
        this.setState({ error: false, emailError: '' })
        if (this.state.emailList.filter((e) => e === this.state.email.trim()).length > 0 && this.state.emailList.length > 0) {
          this.setState({ error: true, emailError: 'Email ID Already Exist' })
        } else {
          this.setState((prevState) => {
            const updatedEmailList = [...prevState.emailList, prevState.email.trim()];
            return {
              emailList: updatedEmailList,
              email: '',
            };
          });
          this.setState({ error: false, emailError: '' })
        }
        if (event.key === ',') {
          event.preventDefault();
        }
      }

    }
  };

  handleRemoveEmail = (email: string) => {
    this.setState((prevState) => ({
      emailList: prevState.emailList.filter((e) => e !== email),
    }));
  };

  handleShareMail = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (this.state.fileType === "") {
      this.setState({ fileTypeError: true, fileTypeErrorMsg: 'select file type' })
    } else if (!emailRegex.test(this.state.email) && this.state.email) {
      this.setState({ error: true, emailError: 'Enter Valid Email ID' })
    } else if (this.state.email === '' && this.state.emailList.length === 0) {
      this.setState({ error: true, emailError: 'Email Id should not be blank' })
    }
    else if (this.state.emailList.length === 0) {
      this.setState({ error: true, emailError: 'Please insert a comma at the end of the email and then click "Send to Email."' })
    }
    else {
      this.shareFIleAPi()
      this.handleCloseModal()
    }

  }

  shareFIleAPi = () => {
    const idParam = `id=${this.props.generatedOutPutId}`;
    const fileTypeParam = `file=${this.state.fileType}`
    const { generatedOutPutId }: any = this.props;
    const chat_session_id: string = generatedOutPutId;
    const type: string = this.props.shareType
    const emailParams = this.state.emailList.map(email => `email[]=${encodeURIComponent(email)}`).join('&');
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };
    this.shareFilesID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointShareFile(chat_session_id, type, idParam, fileTypeParam, emailParams)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleDownload = () => {
    if (this.state.fileType === '') {
      this.setState({ fileTypeError: true, fileTypeErrorMsg: 'select file type' })
    } else {
      this.setState({ fileTypeError: false, fileTypeErrorMsg: '' })
      const { downloadUrl } = this.state;
      if (downloadUrl) {
        const parts = downloadUrl.split('/');
        const filenameWithParams = parts[parts.length - 1];
        const filename = filenameWithParams.split('?')[0];
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', filename);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      this.setState({ downloadUrl: '' })
    }
  };
  handleCloseModal = () => {
    this.setState({
      email: '',
      error: false,
      emailError: '',
      fileType: '',
      fileTypeError: false,
      fileTypeErrorMsg: '',
      emailList: []
    })
    this.props.onClose();
  }
}
