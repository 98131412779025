import React from "react";

import {
  Box,
  Button,
  Container,
  Dialog,
  Typography,
  createStyles,
  withStyles
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

interface IPropsPopup {
  handleCloseUploadClosePop: () => void;
  open?: any;
  classes?: any;
  handleDeleteUploadPopup: () => void;
}

const UploadClosePopup = ({
  classes,
  open,
  handleCloseUploadClosePop,
  handleDeleteUploadPopup
}: IPropsPopup) => {
  return (
    <>
      <>
        <Dialog
          PaperProps={{
            style: { borderRadius: "20px", width: "600px", padding: "40px" }
          }}
          maxWidth="lg"
          open={open}
        >
          <div
            data-testid="closeModalBtn"
            className={classes.closeBtn}
            onClick={handleCloseUploadClosePop}
          >
            <Close />
          </div>
          <Container maxWidth={"sm"} classes={classes.mainContainer}>
            <Typography style={{ fontSize: '24px', fontWeight: '700', lineHeight: '33px', color: '#000000', textAlign: 'center' }}>
              Are you sure you want to close the uploading window and loose all the downloaded files?
            </Typography>
            <Box className={classes.btnContainer}>
              <Button
                fullWidth
                className={classes.canlBtn}
                onClick={handleCloseUploadClosePop}
              >
                Back
              </Button>
              <Button
                fullWidth
                onClick={handleDeleteUploadPopup}
                className={classes.delBtn}
              >
                Delete
              </Button>
            </Box>
          </Container>
        </Dialog>
      </>
    </>
  );
};

const styles = createStyles({
  mainContainer: {
    padding: "40px",
    display: "flex",
    flexDirection: "column",
    width: "600px"
  },
  closeBtn: {
    position: "absolute",
    right: "40px",
    top: "40px",
    cursor: "pointer"
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px"
  },
  canlBtn: {
    marginRight: "15px",
    color: "#000",
    border: "1px solid #CBD5E1",
    textTransform: "none" as "none",
    fontSize: "16px",
    fontWeight: 600
  },
  delBtn: {
    color: "#DC2626",
    border: "1px solid #DC2626",
    textTransform: "none" as "none",
    fontSize: "16px",
    fontWeight: 600
  }
});

export default withStyles(styles)(UploadClosePopup);
