import { api, apiCall } from "../api";
import { ApiResponse } from "../types";

const myLibraryAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getLibraryCampaigns: builder.query<any, any>({
      query: () => ({
        url: `/v1/library/campaigns`,
        method: "get"
      }),
      keepUnusedDataFor: 0
    }),
    createLibraryCampaigns: builder.mutation<any, any>({
      query: (body) => ({
        url: `/v1/library/campaigns`,
        method: "post",
        body
      })
    }),
    duplicateLibraryCampaigns: builder.mutation<any, any>({
      query: (body) => ({
        url: `/v1/library/campaigns/duplicate`,
        method: "put",
        body
      })
    }),
    deleteLibraryCampaigns: builder.mutation<any, any>({
      query: (body) => ({
        url: `/v1/library/campaigns`,
        method: "delete",
        body
      })
    }),
    duplicateLibraryEmails: builder.mutation<any, any>({
      query: (body) => ({
        url: `/v1/library/emails/duplicate`,
        method: "put",
        body
      })
    }),
    deleteLibraryEmails: builder.mutation<any, any>({
      query: (body) => ({
        url: `/v1/library/emails`,
        method: "delete",
        body
      })
    })
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getLibraryCampaigns = async (): Promise<ApiResponse> => {
  return apiCall(myLibraryAPI.endpoints.getLibraryCampaigns);
};

export const createLibraryCampaigns = async (body): Promise<ApiResponse> => {
  return apiCall(myLibraryAPI.endpoints.createLibraryCampaigns, body);
};

export const duplicateLibraryCampaigns = async (body): Promise<ApiResponse> => {
  return apiCall(myLibraryAPI.endpoints.duplicateLibraryCampaigns, body);
};

export const deleteLibraryCampaigns = async (body): Promise<ApiResponse> => {
  return apiCall(myLibraryAPI.endpoints.deleteLibraryCampaigns, body);
};

export const duplicateLibraryEmails = async (body): Promise<ApiResponse> => {
  return apiCall(myLibraryAPI.endpoints.duplicateLibraryEmails, body);
};

export const deleteLibraryEmails = async (body): Promise<ApiResponse> => {
  return apiCall(myLibraryAPI.endpoints.deleteLibraryEmails, body);
};
