import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  label: {
    display: 'flex',
    alignItems: 'center', 
    fontSize: '15px', 
    fontWeight: 500, 
    lineHeight: '19px', 
    color: '#0F172A', 
    marginBottom: '4px'
  }
}));