import React from "react";
import { useHistory } from "react-router-dom";
import moment from 'moment-timezone';
import { Box, IconButton, Typography } from "@material-ui/core";
import { trimTextByCharacters } from "../../../../helpers/text";
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar_icon.svg';
import { ReactComponent as ClockIcon } from '../../../../assets/icons/clock_icon.svg';
import { saveCampaignEmail, triggerEmailGenerate } from "../../../../redux/services/tactical-outreach";
import { enqueueSnackbar } from "notistack";
import { convertHTMLToText, convertMarkdownToHtml } from "../../helpers";

import { useStyles } from "./styles";
import { observableService } from "../../../../services/observable";

const SideBar = ({
  open,
  email,
  campaign,
  isSaveDisabled,
  setSideBarOpen,
  setEmail,
  setUnsavedDataModal,
  handleGetEmails,
  setLoading,
}) => {
  const history = useHistory();
  const classes = useStyles();

  const userTimezone = moment.tz.guess();

  const handleTriggerEmailGenerate = async () => {
    setLoading(true);

    const { data: email, error: emailError } = await triggerEmailGenerate({ campaignId: campaign.id });

    if (email && !emailError) {
      observableService.sendEvent('Decrease usage amount');

      const { data, error } = await saveCampaignEmail({ 
        email: { 
          ...email,
          content: convertMarkdownToHtml(email.content),
          campaignId: campaign.id
        } 
      });

      if (data && !error) {
        handleGetEmails(campaign.id, null);

        enqueueSnackbar(
          'Email created successfully',
          { variant: 'success' }
        )
      } else {
        enqueueSnackbar(
          'Failed to create email',
          { variant: 'error' }
        )
      }
    } else {
      if (emailError?.status === 402) {
        observableService.sendEvent('Show top up modal for TACTICAL_OUTREACH');
      } else {
        enqueueSnackbar(
          'Failed to request AI',
          { variant: 'error' }
        )
      }

      observableService.sendEvent('Increase usage amount');
    }

    setLoading(false);
  }

  return (
    <>
      <Box className={`${classes.sideBar} ${open ? classes.sideBarOpen : ''}`}>
        <Box className={classes.sideBarCloseButton}>
          <IconButton onClick={() => setSideBarOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box className={classes.sideBarContent}>
          {campaign.emails.map((item, index) => (
            <Box
              key={index}
              className={`${classes.sideBarItem} ${email.id === item.id ? classes.sideBarItemActive : ''}`}
              onClick={() => {
                if (!isSaveDisabled()) {
                  setUnsavedDataModal({ active: true, targetEmail: item })
                } else {
                  setEmail(item)
                }
              }}
            >
              <Typography noWrap style={{ fontSize: '14px', fontWeight: '600', lineHeight: '17px', color: '#334155' }}>
                {item.subject}
              </Typography>
              <Typography style={{ fontSize: '12px', fontWeight: '500', lineHeight: '14px', color: '#475569' }}>
                {trimTextByCharacters(convertHTMLToText(item.content), 50)}
              </Typography>
              <Box style={{ display: 'flex', alignItems: 'center', gap: '6px', }}>
                <Typography style={{ display: 'flex', alignItems: 'center', gap: '4px', marginRight: '4px', fontSize: '10px', fontWeight: '500', color: '#64748B' }}>
                  <CalendarIcon />
                  {moment.utc(item.updatedAt).tz(userTimezone).format('DD-MMM-YYYY')}
                </Typography>
                <Typography style={{ display: 'flex', alignItems: 'center', gap: '4px', fontSize: '10px', fontWeight: '500', color: '#64748B' }}>
                  <ClockIcon />
                  {moment.utc(item.updatedAt).tz(userTimezone).format('HH:mm')}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>

        <Typography
          style={{ padding: '12px 20px', marginTop: '20px', fontSize: '16px', fontWeight: '600', lineHeight: '19px', color: '#22507B', cursor: 'pointer' }}
          onClick={() => handleTriggerEmailGenerate()}
        >
          + New email
        </Typography>
      </Box>
    </>
  );
};

export default SideBar;
