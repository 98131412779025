import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Box } from '@material-ui/core';

import { useStyles } from './styles';

const OnboardingLayout = (props) => {

  useEffect(() => {

  }, [props.location]);

  return (
    <Box style={{ zIndex: 1 }}>
      {props.children}
    </Box>
  );
};

export default withRouter(OnboardingLayout);