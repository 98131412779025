import React, { useEffect, useState } from 'react';
import moment from "moment";
import { Modal, Box, IconButton, Typography, Button, CircularProgress as LoadingCircularProgress } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';
import { createTopUpOrder, getTopUps } from '../../../../../redux/services/wallet';
import { formatCurrency, getCurrencySybmol } from '../../../../../helpers/curency';
import { enqueueSnackbar } from 'notistack';
import { getCurrentSubscription } from '../../../../../redux/services/subscriptions';

import { useStyles } from './styles';

interface Props {
  open: boolean;
  type: 'ONBOARDING' | 'COPILOT' | 'TACTICAL_OUTREACH';
  limitReached: boolean;
  onClose: () => void;
  showPaymentModal: (option: any) => void;
}

const TopUpModal: React.FC<Props> = (props) => {
  const {
    open,
    type,
    limitReached,
    onClose,
    showPaymentModal,
  } = props;

  const [topUps, setTopUps] = useState<any>(null);
  const [currentSubscription, setCurrentSubscription] = useState<any>(null);

  const classes = useStyles();

  useEffect(() => {
    if (open) {
      handleGetTopUps(type);
      handleGetCurrentSubscription();
    }
  }, [open, type]);

  const handleGetTopUps = async (type) => {
    const { data, error } = await getTopUps(type);

    if (data && !error) {
      setTopUps(data);
    }
  }

  const handleCreateTopUpOrder = async (item) => {
    const { data, error } = await createTopUpOrder({ top_up_id: item.id });
    
    if (data && !error) {
      showPaymentModal({
        name: `Top up - ${data.amount} ${item.actionType === 'ONBOARDING' ? 'onboarding edits' : item.actionType === 'COPILOT' ? 'copilot messages' : 'generation of emails'}`,
        currency: data.currency,
        amount: data.amount,
        token: data.token
      })
    } else {
      enqueueSnackbar(
        'Failed to create order',
        { variant: 'error' }
      )
    }
  }

  const handleGetCurrentSubscription = async () => {
    const { data, error } = await getCurrentSubscription();

    if (data && !error) {
      setCurrentSubscription(data);
    }
  }

  return (
    <Modal open={open} onClose={onClose} className='MuiDialog-root'>
      <Box className={classes.modalBox}>
        <IconButton onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
        <Typography style={{ fontSize: '30px', fontWeight: '700', lineHeight: '32px', textAlign: 'center', }}>
          {limitReached
            ? `You are unable to ${props.type === 'ONBOARDING' ? 'edit onboarding' : props.type === 'COPILOT' ? 'send copilot message' : 'generate email'} until your limit resets on ${moment(currentSubscription?.validTill).format('DD-MMM-YYYY')}.`
            : `Buy more ${props.type === 'ONBOARDING' ? 'onboarding edits' : props.type === 'COPILOT' ? 'copilot messages' : 'generation of emails'}`
          }
        </Typography>
        <Box className={classes.content}>
          <Typography style={{ fontSize: '16px', fontWeight: '500', lineHeight: '19px', color: '#475569' }}>
            Choose to top up your limits
          </Typography>
          <Box className={classes.optionsWrapper}>
            {!topUps
              ? <LoadingCircularProgress 
                  color="inherit" 
                  style={{ margin: '0 auto' }}
                />
              : topUps.map((item, index) => (
                  <Box className={classes.optionItem} key={index}>
                    <Typography style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', gap: '4px', paddingBottom: '20px', borderBottom: '1px solid #CBD5E1', fontSize: '40px', fontWeight: '500', lineHeight: '40px', color: '#22507B' }}>
                      +{item.amount}
                      <Typography style={{ fontSize: '12px', fontWeight: '600', lineHeight: '20px', color: '#475569' }}>
                        {item.actionType === 'ONBOARDING'
                          ? 'onboardings'
                          : item.actionType === 'COPILOT'
                            ? 'messages'
                            : 'emails'
                        }
                      </Typography>
                    </Typography>
                    <Typography style={{ display: 'flex', justifyContent: 'center', gap: '4px', fontSize: '28px', fontWeight: '500', lineHeight: '28px' }}>
                      <Typography style={{ fontSize: '14px', fontWeight: '500', lineHeight: '14px' }}>
                        {getCurrencySybmol(item.currency)}
                      </Typography>
                      {formatCurrency(item.price)}
                    </Typography>
                    <Button
                      color='primary'
                      variant='contained'
                      style={{ marginTop: '20px' }}
                      onClick={() => handleCreateTopUpOrder(item)}
                    >
                      Choose
                    </Button>
                  </Box>
                ))
            }
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default TopUpModal;
