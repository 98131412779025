import { useState, useEffect, useRef } from 'react';

function useStateWithCallback(initialState) {
  const [state, setState] = useState(initialState);
  const callbackRef = useRef(null);
  const prevStateRef = useRef(initialState);

  const setStateWithCallback = (newState, callback) => {
    callbackRef.current = callback;
    prevStateRef.current = state;
    setState(newState);
  };

  useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current(prevStateRef.current, state);
      callbackRef.current = null;  // Reset callback after it's called
    }
  }, [state]);

  return [state, setStateWithCallback];
}

export default useStateWithCallback;
