import React, { CSSProperties } from "react";
import _ from 'lodash';
import {
  Grid,
  Button,
  Container,
  Backdrop,
  CircularProgress,
  IconButton
} from "@material-ui/core";
import * as Yup from "yup";
import { ReactComponent as BackButtonIcon } from '../../../assets/icons/back_button.svg';

const aboutYourApproachSchema = Yup.object().shape({
  solutionTitle1: Yup.string()
    .trim("This should not contain only blankspaces")
    .concat(maxWords(30, "Maximum 30 words allowed"))
    .required("This should not be blank."),
  solutionStatement1: Yup.string()
    .trim("This should not contain only blankspaces")
    .concat(maxWords(300, "Maximum 300 words allowed"))
    .required("This should not be blank."),
  solutionTitle2: Yup.string()
    .trim("This should not contain only blankspaces")
    .concat(maxWords(30, "Maximum 30 words allowed")),
  // .required("This should not be blank."),
  solutionStatement2: Yup.string()
    .trim("This should not contain only blankspaces")
    .concat(maxWords(300, "Maximum 300 words allowed")),
  // .required("This should not be blank."),
  solutionTitle3: Yup.string()
    .trim("This should not contain only blankspaces")
    .concat(maxWords(30, "Maximum 30 words allowed")),
  // .required("This should not be blank."),
  solutionStatement3: Yup.string()
    .trim("This should not contain only blankspaces")
    .concat(maxWords(300, "Maximum 300 words allowed")),
  // .required("This should not be blank."),
  stage1: Yup.string()
    .trim("This should not contain only blankspaces")
    .concat(maxWords(30, "Maximum 30 words allowed"))
    .required("This should not be blank."),
  stageDescription1: Yup.string()
    .trim("This should not contain only blankspaces")
    .concat(maxWords(150, "Maximum 150 words allowed"))
    .required("This should not be blank."),
  stage2: Yup.string()
    .trim("This should not contain only blankspaces")
    .concat(maxWords(30, "Maximum 30 words allowed")),
  // .required("This should not be blank."),
  stageDescription2: Yup.string()
    .trim("This should not contain only blankspaces")
    .concat(maxWords(150, "Maximum 150 words allowed")),
  // .required("This should not be blank."),
  stage3: Yup.string()
    .trim("This should not contain only blankspaces")
    .concat(maxWords(30, "Maximum 30 words allowed")),
  // .required("This should not be blank."),
  stageDescription3: Yup.string()
    .trim("This should not contain only blankspaces")
    .concat(maxWords(150, "Maximum 150 words allowed")),
  // .required("This should not be blank."),
  processSection: Yup.array().of(
    Yup.string()
      .trim("This should not contain only blankspaces")
      .max(640, "Maximum 640 characters allowed")
  ),
  compareSection: Yup.array().of(
    Yup.string()
      .trim("This should not contain only blankspaces")
      .max(1300, "Maximum 1300 characters allowed")
  ),
  processStep: Yup.array().of(
    Yup.string()
      .trim("This should not contain only blankspaces")
      .max(120, "Maximum 120 characters allowed")
  ),
  processDescription: Yup.array().of(
    Yup.string()
      .trim("This should not contain only blankspaces")
      .max(280, "Maximum 280 characters allowed")
  ),
  segmentOverview: Yup.string()
    .trim("This should not contain only blankspaces")
    .max(1200, "Maximum 1200 characters allowed"),
  segmentTitle1: Yup.string()
    .trim("This should not contain only blankspaces")
    .max(60, "Maximum 60 characters allowed"),
  segmentDescription1: Yup.string()
    .trim("This should not contain only blankspaces")
    .max(400, "Maximum 400 characters allowed"),
  segmentTitle2: Yup.string()
    .trim("This should not contain only blankspaces")
    .max(60, "Maximum 60 characters allowed"),
  segmentDescription2: Yup.string()
    .trim("This should not contain only blankspaces")
    .max(400, "Maximum 400 characters allowed"),
  segmentTitle3: Yup.string()
    .trim("This should not contain only blankspaces")
    .max(60, "Maximum 60 characters allowed"),
  segmentDescription3: Yup.string()
    .trim("This should not contain only blankspaces")
    .max(400, "Maximum 400 characters allowed"),
  background: Yup.string()
    .trim("This should not contain only blankspaces")
    .max(520, "Maximum 520 characters allowed"),
  problem: Yup.string()
    .trim("This should not contain only blankspaces")
    .max(400, "Maximum 400 characters allowed"),
  storySolution1: Yup.string()
    .trim("This should not contain only blankspaces")
    .max(400, "Maximum 400 characters allowed"),
  storySolution2: Yup.string()
    .trim("This should not contain only blankspaces")
    .max(400, "Maximum 400 characters allowed"),
  storySolution3: Yup.string()
    .trim("This should not contain only blankspaces")
    .max(400, "Maximum 400 characters allowed"),
  storyResult1: Yup.string()
    .trim("This should not contain only blankspaces")
    .max(400, "Maximum 400 characters allowed"),
  storyResult2: Yup.string()
    .trim("This should not contain only blankspaces")
    .max(400, "Maximum 400 characters allowed"),
  storyResult3: Yup.string()
    .trim("This should not contain only blankspaces")
    .max(400, "Maximum 400 characters allowed"),
  whyExist: Yup.array()
    .required("Min 10 and max 20 designations")
    .min(10, "Minimum 10 designations allowed")
    .max(20, "Maximum 20 designations allowed"),
});

import AboutYourApproachController, {
  initialValues,
  Props,
} from "./AboutYourApproachController";
import CompanyProgressHeaderWeb from "../../../components/main/CompanyProgressHeader.web";
import MustHave from "../../../components/main/MustHave"
import ProblemStatement from "./ProblemStatement.web"
import { accordup, accordupGreen, accordupYellow } from "./assets"
import { Form, Formik } from "formik";
import SuccessStories from "./SuccessStories.web";
import PrimaryQuestionWithFormik from "./PrimaryQuestionWithFormik.web";
import { modalInfoContent } from "../utils/data";
import ClearAllInformationDialog from "./ClearAllInformationDialog.web";
import SkipOnboardingConfirmationDialog from "../../../components/main/SkipOnboardingConfirmationDialog";
import { enqueueSnackbar } from "notistack";
import { maxWords } from "../../../helpers/yup";
import { getVisibleElementsByClassName } from "../../../helpers/other";

export default class Onboarding extends AboutYourApproachController {
  handleSearchDebounced: any;
  formRef: any;

  constructor(props: Props) {
    super(props);

    this.formRef = React.createRef();
    this.handleSearchDebounced = _.debounce(this.autoSave, 1500);
  }

  async componentDidMount() {
    super.componentDidMount();

    this.setState({
      setAutoSaveData: this.props.setAutoSaveData,
      formRef: this.formRef.current
    })

    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  async componentWillUnmount() {
    super.componentWillUnmount();

    this.autoSave();

    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };

  autoSave = async () => {
    // if (this.formRef && 'current' in this.formRef) {
    //   this.props.setAutoSaveData('approach', this.formRef.current?.values);
      
    //   if (!Object.keys(this.formRef.current?.errors)?.length && this.formRef.current?.dirty) {
    //     this.saveAndContinue(this.formRef.current.values, true);
    //   }
    // }
  };

  handleScrollToError = () => {
    setTimeout(() => {
      const elements = getVisibleElementsByClassName('Mui-error');

      if (elements.length) {
        elements[0].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
      }
    }, 500);
  }

  render() {
    const { alreadyFilledFormValues } = this.state;
    const haveInitData = Object.keys(alreadyFilledFormValues).length > 0
    const formikIntialValues = this.props.autoSavedValues?.approach
      ? this.props.autoSavedValues.approach
      : haveInitData
        ? alreadyFilledFormValues
        : initialValues;

    return (
      <>
        <>
          <Backdrop style={webStyle.backdropBg} open={this.state.loading}>
            <CircularProgress color="inherit" />
          </Backdrop>

          {!this.props.hideBackBtn && (
            <IconButton
              size="small"
              style={{
                position: 'absolute',
                left: '45px',
                top: '100px',
              }}
              onClick={() => {
                this.setState({
                  skipModalOpen: true
                })
              }}
            >
              <BackButtonIcon />
            </IconButton>
          )}

          <Grid container style={webStyle.servicesMainContainer}>
            <CompanyProgressHeaderWeb
              onConfirm={this.getDataFromAPI}
              primaryHeading="About your approach"
              secondaryHeading=" Next, provide info about your overarching solutions, processes and methodologies."
            />
            <Grid item style={webStyle.displayCol}>
              <Formik
                innerRef={this.formRef as any}
                initialValues={formikIntialValues}
                validationSchema={aboutYourApproachSchema}
                enableReinitialize
                isInitialValid={false}
                onSubmit={(values: any) => {
                  this.saveAndContinue(values);
                }}
              >
                {({ values, errors, touched, setFieldValue, handleChange, resetForm }) => {
                  return (
                    <Form 
                      translate={undefined}
                      onKeyDown={this.handleSearchDebounced}
                    >
                      <MustHave
                        name={'approachWhyExist'}
                        initData={haveInitData}
                        data-test-id='mustHaveTestId'
                        btnDisplay={false}
                        customlistTitle={"How do we do it? (semantic keywords)"}
                        addItem={this.addChipLabels}
                        textBoxDisplay={false}
                        iconName={accordup}
                        headerName={"MUST HAVE"}
                        formikValue={values}
                        labelsDisplay={true}
                        onChangeChip={(updated: string[]) => {
                          setFieldValue('whyExist', updated);
                          this.handleSearchDebounced();
                        }}
                        serviceArray={this.state.serviceArray1}
                        mustHaveExpanded={this.state.mustHaveExpanded}
                        handleExpand={this.handleExpand}
                        chipLabels={values.whyExist}
                        deleteChipByName={this.deleteChipByName}
                        chipError={this.checkValidation(!!touched.whyExist, errors.whyExist as string)}
                      />
                      {this.state.mustHaveExpanded &&
                        <>
                          <ProblemStatement
                            tooltipData={modalInfoContent.solutionStatement}
                            data-test-id='solution-section'
                            navigation={""}
                            id=""
                            title={"The Solution Statement(s)*"}
                            handleChange={(event: any, index: number) => {
                              setFieldValue(`solutionTitle${index + 1}`, event.target.value);
                            }}
                            handleChange1={(event: any, index: number) => {
                              setFieldValue(`solutionStatement${index + 1}`, event.target.value);
                            }}
                            inputList={this.state.offeringsInputList.map((item, index) => ({
                              ...item,
                              offering: values[`solutionTitle${index + 1}`],
                              description: values[`solutionStatement${index + 1}`],
                              offeringError: this.checkValidation(!!touched[`solutionTitle${index + 1}`], errors[`solutionTitle${index + 1}`]),
                              descriptionError: this.checkValidation(!!touched[`solutionStatement${index + 1}`], errors[`solutionStatement${index + 1}`])
                            }))} 
                            serviceArray={this.state.offeringsServiceArray}
                            name1={"offering"}
                            name2={"description"}
                            requiredIndex={0}
                          />
                          <div style={webStyle.spacing} />
                          <ProblemStatement
                            tooltipData={modalInfoContent.stageSection}
                            data-test-id='stage-section' handleChange={(event: any, index: number) => {
                              setFieldValue(`stage${index + 1}`, event.target.value);
                            }}
                            handleChange1={(event: any, index: number) => {
                              setFieldValue(`stageDescription${index + 1}`, event.target.value);
                            }}
                            inputList={this.state.approachInputList.map((item, index) => ({
                              ...item,
                              offering: values[`stage${index + 1}`],
                              description: values[`stageDescription${index + 1}`],
                              offeringError: this.checkValidation(!!touched[`stage${index + 1}`], errors[`stage${index + 1}`]),
                              descriptionError: this.checkValidation(!!touched[`stageDescription${index + 1}`], errors[`stageDescription${index + 1}`])
                            }))}
                            navigation={""}
                            id=""
                            title={"High-level Approach*"}
                            serviceArray={this.state.approachServiceArray}
                            requiredIndex={0}
                          />
                        </>
                      }
                      <MustHave btnDisplay={values.processStep.length < 10} buttonName="Add a step" data-test-id="shouldHaveTestId" customlistTitle={"The Process"} inputList={[]} textBoxDisplay={true}
                        iconName={accordupGreen} headerName={"SHOULD HAVE"} labelsDisplay={false}
                        addItem={() => {
                          this.addProcess()
                          setFieldValue('processStep', values.processStep.concat(['']) as string[])
                          setFieldValue('processDescription', values.processDescription.concat(['']) as string[])
                        }}
                        name={'theProcess'}
                        serviceArray={this.state.shouldHaveServiceArray} mustHaveExpanded={this.state.shouldHaveExpanded}
                        handleExpand={this.handleExpand1} chipLabels={[]} processText={true}
                        useFormik={false}
                        inputOfferingList={{
                          "showListHeader": true,
                          'inputList': this.state.theProcessInputList.map((item, index) => ({
                            ...item,
                            offering: values.processStep[index] as string[],
                            description: values.processDescription[index] as string[],
                            offeringError: this.checkValidation((touched as any).processStep?.[index], (errors as any).processStep?.[index]),
                            descriptionError: this.checkValidation((touched as any).processDescription?.[index], (errors as any).processDescription?.[index])
                          })),
                          "handleChange": (event: any, index: number) => this.handleFormikChange(setFieldValue, event.target.value, index, 'processStep', values.processStep),
                          "handleChange1": (event: any, index: number) => this.handleFormikChange(setFieldValue, event.target.value, index, 'processDescription', values.processDescription)
                        }} />
                      {this.state.shouldHaveExpanded &&
                        <>
                          <PrimaryQuestionWithFormik
                            touched={touched}
                            data-test-id='processSection'
                            addItem={this.handleAddObjectItemCompanyAcheivement}
                            handleChange={this.handleObjectChangeCompanyAcheivement}
                            navigation={""} id=""
                            title={"Process Testimonials"}
                            inputList={this.state.companyAchievementsInputList}
                            serviceArray={this.state.companyAchievementsServiceArr}
                            btnDisplay={true}
                            buttonName={"Add a testimonial"}
                            name={`processSection`}
                            formikHandelChange={handleChange}
                            formikValue={values}
                            formikError={errors}
                            setFieldValue={setFieldValue}
                            maxFeilds={3}
                          />
                          <SuccessStories
                            tooltipData={modalInfoContent.storiesSection}
                            data-test-id='storiesSection'
                            handleChange={(event: any, index: number) => {
                              setFieldValue(this.state.successStoriesInputList[index].name, event.target.value)
                            }}
                            onChangeDescription={(event: any, index: number) => setFieldValue(`storyResult${index + 1}`, event.target.value)}
                            onChangeTitle={(event: any, index: number) => setFieldValue(`storySolution${index + 1}`, event.target.value)}
                            buttonName="" 
                            secondTitle="Solution"
                            thirdTitle='Results' 
                            navigation={""} 
                            id="" 
                            title={"Success Stories"}
                            secondList={this.state.storySolutionInputList.map((item, index) => ({
                              ...item,
                              value: values[`storySolution${index + 1}`],
                              error: this.checkValidation(!!touched[`storySolution${index + 1}`], errors[`storySolution${index + 1}`]),
                            }))}
                            thirdList={this.state.storyResultInputList.map((item, index) => ({
                              ...item,
                              value: values[`storyResult${index + 1}`],
                              error: this.checkValidation(!!touched[`storyResult${index + 1}`], errors[`storyResult${index + 1}`]),
                            }))}
                            inputList={this.state.successStoriesInputList.map((item, index) => ({
                              ...item,
                              value: values[this.state.successStoriesInputList[index].name],
                              error: this.checkValidation(!!touched[this.state.successStoriesInputList[index].name], errors[this.state.successStoriesInputList[index].name]),
                            }))}
                            serviceArray={this.state.successStoriesServiceArr} btnDisplay={false}
                          />
                        </>
                      }
                      <MustHave
                        data-test-id='goodToHaveTestId'
                        btnDisplay={false}
                        customlistTitle={"Segment Expertise Overview"}
                        handleChange={() => { }}
                        inputList={this.state.segmentExpertiseInputList} textBoxDisplay={true} iconName={accordupYellow}
                        headerName={"GOOD TO HAVE"}
                        labelsDisplay={false} serviceArray={this.state.segmentExpertiseServiceArr}
                        mustHaveExpanded={this.state.goodToHaveExpanded}
                        handleExpand={this.handleExpand2} chipLabels={[]}
                        name={`segmentOverview`}
                        formikHandelChange={handleChange}
                        formikValue={values}
                        formikError={{ segmentOverview: [this.checkValidation(!!touched[`segmentOverview`], errors[`segmentOverview`])] }}
                        setFieldValue={setFieldValue} />
                      {this.state.goodToHaveExpanded &&
                        <>
                          <ProblemStatement
                            tooltipData={modalInfoContent.keySegmentSection}
                            handleChange={(event: any, index: number) => setFieldValue(`segmentTitle${index + 1}`, event.target.value)}
                            handleChange1={(event: any, index: number) => setFieldValue(`segmentDescription${index + 1}`, event.target.value)}
                            navigation={""} id="" data-test-id='segmentSection'
                            title={"Key Segment Differentiators"}
                            inputList={this.state.keySegmentInputList.map((item, index) => ({
                              ...item,
                              offering: values[`segmentTitle${index + 1}`],
                              description: values[`segmentDescription${index + 1}`],
                              offeringError: this.checkValidation(!!touched[`segmentTitle${index + 1}`], errors[`segmentTitle${index + 1}`]),
                              descriptionError: this.checkValidation(!!touched[`segmentDescription${index + 1}`], errors[`segmentDescription${index + 1}`])
                            }))}
                            serviceArray={this.state.keySegmentServiceArray}
                          />
                          <PrimaryQuestionWithFormik
                            handleChange={(event: any, index: number) => this.handleCommonObjectChange(event, index, 'highLevelComparisonInputList', 'value')}
                            navigation={""}
                            id=""
                            touched={touched}
                            data-test-id='compareSection'
                            title={"High-level comparison"}
                            inputList={this.state.highLevelComparisonInputList}
                            serviceArray={this.state.highLevelComparisonServiceArr}
                            btnDisplay={false} buttonName={""}
                            addItem={this.handleAddObjectItemCompanyAcheivement}
                            name={`compareSection`}
                            formikHandelChange={handleChange}
                            formikValue={values}
                            formikError={errors}
                            setFieldValue={setFieldValue}
                          />
                        </>
                      }
                      <Grid item style={webStyle.displayRow}>
                        <Button
                          data-test-id="clearBtn"
                          // type="reset"
                          variant="contained"
                          style={webStyle.clrBtn}
                          onClick={this.openSkipConfirmationDialog}
                        >
                          Skip onboarding
                        </Button>
                        <Button variant="contained"
                          data-test-id="clearBtn"
                          onClick={this.openClearAllInfoDilog}
                          style={webStyle.clrBtn}>
                          Clear all data
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          style={webStyle.saveBtn}
                          onClick={() => {
                            console.log('errors', errors);

                            this.handleScrollToError();
                          }}
                        >
                          Save & continue
                        </Button>
                      </Grid>
                    </Form>
                  )
                }
                }
              </Formik>
            </Grid>
          </Grid>
        </>

        <SkipOnboardingConfirmationDialog
          open={this.state.skipModalOpen}
          onClose={this.closeSkipConfirmationDialog}
          onYes={() => {
            this.saveAndContinue(this.formRef.current.values, true, true);
            this.closeSkipConfirmationDialog();
            this.redirectToPage('/home?afterSkipOnboarding=true');
          }}
          onNo={() => {
            this.closeSkipConfirmationDialog();
            this.redirectToPage('/home?afterSkipOnboarding=true');
          }}
        />

        <ClearAllInformationDialog
          open={this.state.clearAllInfoDialog}
          onClose={this.closeClearAllInfoDilog}
          onClear={() => {
            this.formRef.current.resetForm();
            this.clearAllInformation({...initialValues, whyExist: []});
            this.props.setAutoSaveData('approach', null);
            this.closeClearAllInfoDilog();
          }}
          onCancel={this.closeClearAllInfoDilog}
        />
      </>
    );
  }
}

const webStyle = {
  spacing: {
    marginTop: '16px'
  },
  backdropBg: {
    zIndex: 9999999999999,
    color: '#fff',
  },
  clrBtn: {
    height: '44px',
    fontWeight: 600,
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    background: "#FFF",
    width: "50%",
    textTransform: "none" as "none",
  },
  saveBtn: {
    height: '44px',
    borderRadius: "8px",
    background: "#57BE99",
    width: "50%",
    textTransform: "none" as "none",
    fontWeight: 600,
    boxSizing: 'border-box'
  } as CSSProperties,
  servicesMainContainer: {
    width: '810px',
    display: "flex",
    justifyContent: "center",
    flexDirection: "column" as "column",
    margin: '0px auto',
    padding: '0 0 80px 0'
  },
  displayRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
    marginTop: '20px'
  },
  displayCol: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "10px"
  },
  servicesContainer: {
    background: '#fff',
    borderRadius: '28px',
    padding: '32px 40px',
    height: 'max-content'
  },
  offeringBox: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap' as 'wrap',
    marginBottom: '2%'
  } as any,
}
