import * as Yup from 'yup';

export const emailValidationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const validationSchema = Yup.object({
  // emailInput: Yup.string()
  //   .test(
  //     'is-valid-emails',
  //     'One or more emails are invalid',
  //     value => value.split(',').every(email => email.trim() === '' || Yup.string().email().isValidSync(email.trim()))
  //   )
  //   .required('At least one email is required'),
  emails: Yup.array()
    .required('At least one email is required.'),
  permissions: Yup.array()
    .required('At least one permission is required.'),
  message: Yup.string()
    .max(1000, 'Max 1000 characters')
});
