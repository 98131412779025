import { IBlock } from "../../../framework/main/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/main/Messages/MessageEnum";
import { Message } from "../../../framework/main/Message";
import { BlockComponent } from "../../../framework/main/BlockComponent";

import { runEngine } from "../../../framework/main/RunEngine";

import { WithStyles } from "@material-ui/core";
import { parseErrors } from "../../utilities/main/Toast";
import StorageProvider from "../../../framework/main/StorageProvider";

export const configJSON = require("./config");


export interface Props extends WithStyles<any> {
  navigation: any;
  stripe: any;
  token?: string;
}

interface S {
  plans: any[];
  errors: any;
  loading: boolean
}

interface SS {
  navigation: any;
}

export default class SubscriptionController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiFetchPlansId: any;
  apiPostPlanId: any;
  apiCreateCheckOutSessionCallId: any;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      plans: [],
      errors: "",
      loading: false
    };

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.apiCreateCheckOutSessionCallId) {
        this.handelcreateCheckoutSessionAPIResponse(responseJson);
      }
      if (apiRequestCallId === this.apiFetchPlansId) {
        this.handelFetchPlanResponse(responseJson)
      }
      if (apiRequestCallId === this.apiPostPlanId) {
        if (responseJson) {
          if (!responseJson.errors) {
            this.props.navigation.navigate("BulkUploading");
          } else {
            const errors = parseErrors(responseJson);
            this.setState({ errors });
          }
        }
      }
    }
  }

  componentDidMount = async () => {
    super.componentDidMount();

    this.fetchPlans();
  };


  async fetchPlans() {
    this.setState({ loading: true });
    const token = await StorageProvider.getItem("user-token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiFetchPlansId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.plansEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  onBackButton = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "CompanyOffer"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  intiatePayment = async (planDetails: any) => {
    const { price, unique_key } = planDetails.attributes;
    if (unique_key === 'small_business') {
      await StorageProvider.setItem('small_business_data', JSON.stringify(planDetails));
      const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "DescribleCompanyPlan"
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
      return;
    }
    const currentHost = window.location.host;
    const successURL = `http://${currentHost}/PaymentStatus?status=true`;
    const cancelURL = `http://${currentHost}/PaymentStatus?status=false`;
    let payloadObj = {
      product_name: planDetails.attributes.name,
      planDetails: planDetails,
      unit_amount: parseInt(price) * 100,
      currency: "GBP",
      quantity: 1,
      success_url: successURL,
      cancel_url: cancelURL,
    };

    this.createCheckoutSession(payloadObj);
  };

  async createCheckoutSession(data: any) {
    const token = await StorageProvider.getItem("user-token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCreateCheckOutSessionCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.stripeCheckoutSessionAPIEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  async handelcreateCheckoutSessionAPIResponse(response: any) {
    window.localStorage.clear();

    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );

    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);

    // if (response?.id) {
    //   await this.props.stripe?.redirectToCheckout({
    //     sessionId:response.id,
    //   });
    //   window.localStorage.clear();
    // } else {
    //   if (Array.isArray(response?.errors) && response?.errors[0]?.token) {
    //     // todo: logout and clear cache
    //   }
    // }
  }

  handelFetchPlanResponse(response: any) {
    this.setState({ loading: false })
    if (response?.data) {
      this.setState({ plans: response.data.sort((a: any, b: any) => a.attributes.order - b.attributes.order) })
    }
  }
}
