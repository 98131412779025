import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  content: {
    width: '100%',
    maxWidth: '1080px',
    minWidth: '650px',
    height: '80%',
    padding: '32px 20px 20px 20px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
    margin: '0 auto'
  },
  editor: {
    position: 'relative',
    width: '100%',
    height: '90%',
    overflow: 'hidden',
    padding: '12px',
    border: '1px solid #94A3B8',
    borderRadius: '8px',
    '& .MuiFormControl-root': {
      height: '100% !important'
    },
    '& .MuiFormControlLabel-root': {
      height: '100% !important'
    },
    '& .MuiTextField-root': {
      height: '100% !important'
    },
    '& .MuiInputBase-multiline': {
      height: 'fit-content !important'
    }
  },
  editorWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  editorToolbar: {
    margin: '0px !important',
    padding: '12px !important',
    border: 'none !important',
    background: '#F8FAFC !important',
    borderRadius: '8px !important',
    '& .rdw-block-wrapper': {
      borderRight: '1px solid #CBD5E1',
      paddingRight: '12px',
      '& .rdw-dropdown-selectedtext': {
        color: '#475569',
        fontWeight: 'bold',
        '& .rdw-dropdown-carettoopen': {
          borderTopColor: '#475569'
        }
      }
    },
    '& .rdw-fontsize-wrapper': {
      borderRight: '1px solid #CBD5E1',
      padding: '0px 12px',
    },
    '& .rdw-remove-wrapper': {
      padding: '0px 12px',
    }
  },
  toolbarGroup: {
    borderRight: '1px solid #CBD5E1',
    padding: '0px 12px',
  },
  toolbarButton: {
    background: 'transparent !important',
    border: 'none !important',
    boxShadow: 'none !important',
    borderRadius: '6px !important',
    '&:hover': {
      background: '#CBD5E14D !important',
    },
    '&.rdw-option-active': {
      background: '#CBD5E1 !important',
    },
    '&.rdw-option-disabled': {
      background: 'transparent !important',
    }
  },
  editorTextarea: {
    // height: 'calc(100% - 100px) !important',
    height: '100%',
    '& .DraftEditor-root': {
      height: '95%',
      '& .DraftEditor-editorContainer': {
        height: '100%',
        '& .public-DraftStyleDefault-block': {
          margin: '0px !important'
        },
        '& h1': {
          margin: '0px !important',
          fontWeight: 'normal',
        },
        '& h2': {
          margin: '0px !important',
          fontWeight: 'normal',
        },
        '& h3': {
          margin: '0px !important',
          fontWeight: 'normal',
        },
        '& h4': {
          margin: '0px !important',
          fontWeight: 'normal',
        },
        '& h5': {
          margin: '0px !important',
          fontWeight: 'normal',
        },
        '& h6': {
          margin: '0px !important',
          fontWeight: 'normal',
        },
        '& ul': {
          margin: '0px !important',
        },
        '& ol': {
          margin: '0px !important',
        }
      }
    }
  },
  footer: {
    width: '100%',
    maxWidth: '1080px',
    minWidth: '650px',
    padding: '0px 20px 40px 20px',
    display: 'flex',
    rowGap: '20px',
    margin: '0 auto'
  },
  actions: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    padding: '8px 12px',
    gap: '12px',
    background: '#F8FAFC',
    borderRadius: '8px',
  },
  varticalSeparator: {
    width: '1px',
    height: '28px',
    background: '#CBD5E1',
  },
}));
