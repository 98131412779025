Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Documentation";
exports.labelBodyText = "Documentation Body";
exports.createBulkUploadMethod = "POST";
exports.deleteApiMethod = "DELETE";

exports.btnExampleTitle = "CLICK ME";
exports.rename = "Rename";
exports.delete = "Delete";
exports.document = "Upload Document";
exports.folder = "Add Folder";
exports.documents = "Documents";
exports.deleteFolder = "Delete Folder";
exports.addFolder = "Add Folder";
exports.cancelText = "Cancel";
exports.createText = "Create";
exports.renameText = "Rename";
exports.deleteText = "Delete";
exports.closeText = "CLOSE";
exports.renameFile = "RENAME";
exports.deleteFile = "DELETE";
exports.yesModal = "YES";
exports.noModal = "NO";
exports.file = "Do you want to delete the File ?";
exports.deletefolder = "Do you want to delete the Folder ?";
exports.folderDocument = "THIS FOLDER IS EMPTY !";
exports.documentation = "DATA NOT FOUND!";

exports.fileSizeErrorMsg = " is too large. Allowed maximum size is 25MB!";

exports.companyDocumentListApi = "list_files?search=";
exports.deleteCompanyDocumentListApi = "destroy_file/";
