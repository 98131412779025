import { api, apiCall } from "../api";
import { ApiResponse } from "../types";

const onboardingAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getOnboardingData: builder.query<any, void>({
      query: () => ({
        url: "/v1/onboarding/company_details",
        method: "get"
      }),
      transformResponse: (response: { data: any }) => response?.data?.attributes,
      keepUnusedDataFor: 0
    }),
    saveOnboardingData: builder.mutation<any, any>({
      query: (body) => ({
        url: "/v1/onboarding/company_details",
        method: "post",
        body: {
          data: {
            attributes: body
          }
        }
      }),
      transformResponse: (response: { data: any }) => response.data.attributes
    }),
    uploadOnboardingInformation: builder.mutation<any, any>({
      query: ({ file, type }) => {
        const formData = new FormData();
        formData.append("file", file);

        return {
          url: `/v1/onboarding/attachments?file_type=${type}`,
          method: "post",
          body: formData
        };
      }
    }),
    triggerAutofillOnboarding: builder.mutation<any, any>({
      query: (params) => ({
        url: "/v1/onboarding/intels",
        method: "post",
        params
      })
    }),
    getOnboardingCompleteness: builder.query<any, void>({
      query: () => ({
        url: "/v1/onboarding/company_details/completeness",
        method: "get"
      }),
      keepUnusedDataFor: 0
    })
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getOnboardingData = async (): Promise<ApiResponse> => {
  return apiCall(onboardingAPI.endpoints.getOnboardingData);
};

export const saveOnboardingData = async (body): Promise<ApiResponse> => {
  return apiCall(onboardingAPI.endpoints.saveOnboardingData, body);
};

export const uploadOnboardingInformation = async (
  body
): Promise<ApiResponse> => {
  return apiCall(onboardingAPI.endpoints.uploadOnboardingInformation, body);
};

export const triggerAutofillOnboarding = async (
  params
): Promise<ApiResponse> => {
  return apiCall(onboardingAPI.endpoints.triggerAutofillOnboarding, params);
};

export const getOnboardingCompleteness = async (): Promise<ApiResponse> => {
  return apiCall(onboardingAPI.endpoints.getOnboardingCompleteness);
};