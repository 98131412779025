import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import {
  Backdrop,
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import {
  deleteLibraryCampaigns,
  deleteLibraryEmails,
  duplicateLibraryCampaigns,
  duplicateLibraryEmails,
  getLibraryCampaigns
} from '../../redux/services/my-library';
import ConfirmationModal from '../../components/main/ConfirmationModal';
import { enqueueSnackbar } from 'notistack';
import CampaignTableRow from './components/CampaignTableRow';
import Filters from './components/Filters';

import { useStyles } from './styles';
import { updateCampaign } from '../../redux/services/tactical-outreach';
import { getOnboardingCompleteness } from '../../redux/services/onboarding';
import { getCurrentSubscription } from '../../redux/services/subscriptions';
import { getCurrentSubscriptionStatus } from '../../helpers/subscription';

interface Props {
  history: any;
}

const MyLibrary: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [campaigns, setCampaigns] = useState<any>(null);
  const [deleteSubject, setDeleteSubject] = useState<{ isCampaign: boolean, campaignId: number, emailId?: number } | null>(null);
  const [editSubject, setEditSubject] = useState<{ campaignId: number, value: string, field: string } | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isOnboardingCompleted, setIsOnboardingCompleted] = useState<boolean>(false);
  const [isSubscriptionActive, setIsSubscriptionActive] = useState<boolean>(false);
  const [showActionBlockedModal, setShowActionBlockedModal] = useState<boolean>(false);

  const classes = useStyles();

  useEffect(() => {
    setLoading(true);

    handleIsSubscriptionActive();
    handleIsOnboardingCompleted();
    handleGetLibraryCampaigns();
  }, []);

  const handleIsSubscriptionActive = async () => {
    const { data } = await getCurrentSubscription();

    if (getCurrentSubscriptionStatus(data) !== 'EXPIRED') {
      setIsSubscriptionActive(true);
    }
  }

  const handleIsOnboardingCompleted = async () => {
    const { data } = await getOnboardingCompleteness();

    if (data && Object.values(data).every(item => item)) {
      setIsOnboardingCompleted(true);
    }
  }

  const handleGetLibraryCampaigns = async () => {
    const { data, error } = await getLibraryCampaigns();

    if (!error && data && Array.isArray(data)) {
      const newCampaigns = [...data].reverse().map(item => ({
        ...item,
        expanded: false
      })).sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())

      setCampaigns(newCampaigns);
    }

    setLoading(false);
  };

  const handleExpandCampaign = (event: any, id: number, value?: boolean) => {
    if (
      typeof event.target.className === 'string' &&
      !event.target.className.includes('MuiIconButton') &&
      !event.target.className.includes('MuiInputBase')
    ) {
      setCampaigns(campaigns.map(item =>
        item.id === id
          ? { ...item, expanded: value || !item.expanded }
          : item
      ));
    }
  };

  const handleDelete = async () => {
    setLoading(true);

    if (deleteSubject.isCampaign) {
      const { error } = await deleteLibraryCampaigns({ campaignId: deleteSubject.campaignId });

      if (!error) {
        enqueueSnackbar(
          'Campaign deleted successfully',
          { variant: 'success' }
        );
      } else {
        enqueueSnackbar(
          'Campaign deletion failed',
          { variant: 'error' }
        );
      }

      handleGetLibraryCampaigns();
    } else {
      const { error } = await deleteLibraryEmails({ campaignId: deleteSubject.campaignId, emailId: deleteSubject.emailId });

      if (!error) {
        enqueueSnackbar(
          'Email deleted successfully',
          { variant: 'success' }
        );
      } else {
        enqueueSnackbar(
          'Email deletion failed',
          { variant: 'error' }
        );
      }

      handleGetLibraryCampaigns();
    }

    setDeleteSubject(null);
    setLoading(false);
  };

  const handleSearchChange = (event) => {
    if (!isOnboardingCompleted || !isSubscriptionActive) {
      setShowActionBlockedModal(true);
    } else {
      const { value } = event.target;

      setSearchTerm(value);
      debouncedSearch(value);
    }
  };

  const debouncedSearch = useMemo(() => _.debounce((query) => {
    if (query) {
      const filtered = campaigns.filter(item =>
        item.name.toLowerCase().includes(query.toLowerCase()) ||
        item.description?.toLowerCase()?.includes(query.toLowerCase())
      );
      setCampaigns(filtered);
    } else {
      handleGetLibraryCampaigns();
    }
  }, 300), [campaigns]);

  const handleSaveEdit = async () => {
    const { error } = await updateCampaign({
      body: {
        name: campaigns.find(item => item.id === editSubject.campaignId).name,
        description: campaigns.find(item => item.id === editSubject.campaignId).description,
        [editSubject.field]: editSubject.value
      },
      id: editSubject.campaignId
    });

    if (!error) {
      handleGetLibraryCampaigns();

      enqueueSnackbar(
        'Campaign edited successfully',
        { variant: 'success' }
      );
    } else {
      enqueueSnackbar(
        'Failed to edit',
        { variant: 'error' }
      );
    }

    setEditSubject(null);
  };

  const handleDuplicate = async ({ isCampaign, campaignId, emailId }) => {
    setLoading(true);

    if (isCampaign) {
      const { data, error } = await duplicateLibraryCampaigns({ campaignId });

      if (data && !error) {
        enqueueSnackbar(
          'Campaign duplicated successfully',
          { variant: 'success' }
        );

        handleGetLibraryCampaigns();
      } else {
        enqueueSnackbar(
          'Campaign duplication failed',
          { variant: 'error' }
        );

        setLoading(true);
      }
    } else {
      const { data, error } = await duplicateLibraryEmails({ campaignId, emailId });

      if (data && !error) {
        enqueueSnackbar(
          'Email duplicated successfully',
          { variant: 'success' }
        );

        handleGetLibraryCampaigns();
      } else {
        enqueueSnackbar(
          'Email duplication failed',
          { variant: 'error' }
        );

        setLoading(true);
      }
    }
  };

  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: 99999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={classes.container}>
        <Box className={classes.header}>
          <Typography
            style={{
              textAlign: 'center',
              fontSize: '48px',
              fontWeight: '600',
              lineHeight: '58px',
              color: '#000000'
            }}
          >
            My Library
          </Typography>
          <Typography
            style={{
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: '500',
              lineHeight: '19px',
              color: '#000000'
            }}
          >
            Your saved intelligence is conveniently stored in your <br />
            library, accessible whenever you need it.
          </Typography>
        </Box>

        <Filters
          campaigns={campaigns}
          searchTerm={searchTerm}
          isSubscriptionActive={isSubscriptionActive}
          isOnboardingCompleted={isOnboardingCompleted}
          handleSearchChange={handleSearchChange}
        />

        <Box className={classes.content}>
          {campaigns && campaigns.length > 0
            ? <TableContainer
                component={Paper}
                style={{
                  width: '100%',
                  minWidth: '1070px',
                  // maxWidth: '1080px',
                  boxShadow: 'none',
                  background: 'transparent',
                  margin: '0 auto',
                  // opacity: (!isOnboardingCompleted || !isSubscriptionActive) ? '0.5' : '1',
                  // pointerEvents: (!isOnboardingCompleted || !isSubscriptionActive) ? 'none' : 'all'
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          // width: '25%',
                          borderColor: 'transparent'
                        }}
                      />
                      <TableCell
                        align="left"
                        style={{
                          // width: '45%',
                          borderColor: 'transparent'
                        }}
                      />
                      <TableCell
                        align="center"
                        style={{
                          // width: '20%',
                          borderColor: 'transparent'
                        }}
                      />
                      <TableCell
                        align="right"
                        style={{
                          // width: '10%',
                          borderColor: 'transparent'
                        }}
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody
                    style={{
                      position: 'relative'
                    }}
                  >
                    {campaigns.map((item, index) => (
                      <React.Fragment key={index}>
                        <CampaignTableRow
                          item={item}
                          history={props.history}
                          editSubject={editSubject}
                          isSubscriptionActive={isSubscriptionActive}
                          isOnboardingCompleted={isOnboardingCompleted}
                          handleExpandCampaign={handleExpandCampaign}
                          setDeleteSubject={setDeleteSubject}
                          setEditSubject={setEditSubject}
                          handleSaveEdit={handleSaveEdit}
                          handleDuplicate={handleDuplicate}
                          setShowActionBlockedModal={setShowActionBlockedModal}
                        />
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            : <Box className={classes.notFound}>
                No campaigns
              </Box>
          }
        </Box>
      </Box>

      <ConfirmationModal
        open={!!deleteSubject}
        title={deleteSubject?.isCampaign
          ? 'Are you sure you want to delete campaign?'
          : 'Are you sure you want to delete email?'
        }
        description={deleteSubject?.isCampaign
          ? `Note that you'll delete this campaign forever and you will not be able to restore it.`
          : `Note that you'll delete this email forever and you will not be able to restore it.`
        }
        rejectBtnText={'Cancel'}
        confirmBtnText={'Delete'}
        onClose={() => setDeleteSubject(null)}
        onReject={() => setDeleteSubject(null)}
        onConfirm={() => {
          handleDelete();
          setDeleteSubject(null);
        }}
      />

      <ConfirmationModal
        open={showActionBlockedModal}
        title={
          !isSubscriptionActive
            ? 'To proceed, you have to be subscribed'
            : 'To proceed, you have to complete the Onboarding'
        }
        onClose={() => setShowActionBlockedModal(false)}
        reversedButtons
        confirmBtnText={
          !isSubscriptionActive
            ? 'Subscribe later'
            : 'Complete later'
        }
        rejectBtnText={
          !isSubscriptionActive
            ? 'Go to subscriptions'
            : 'Go to onboarding'
        }
        onConfirm={() => setShowActionBlockedModal(false)}
        onReject={() =>
          !isSubscriptionActive
            ? props.history.push('/onboarding')
            : props.history.push('/subscriptions')
        }
      />
    </>
  );
};

export default MyLibrary;
