export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backIcon = require("../assets/backIcon.png");

export const LogoImg = require("../assets/logo.svg").default;
export const pebbleLogo = require("../assets/pebbleLogo.png");
export const TransparentLogoImg = require("../assets/gtms_logo.svg").default;
export const leftRock = require("../assets/left-rock.svg").default;
export const rightRock = require("../assets/right-rock.svg").default;
export const backImg = require("../assets/button_back.svg").default;
export const infoIcon = require("../assets/info-icon.svg").default;
export const guidelineImg = require("../assets/guideline.svg").default;
export const bluePerson = require("../assets/bluePerson.svg").default;
export const quaterly = require("../assets/quaterly.svg").default;
export const bottomLogo = require("../assets/bottomLogo.png");
export const success = require("../assets/success.png");
export const rightBar = require("../assets/right_bar_icon.svg").default;
