import { IBlock } from "../../../framework/main/IBlock";
import { BlockComponent } from "../../../framework/main/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/main/Messages/MessageEnum";
import { runEngine } from "../../../framework/main/RunEngine";



export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  title: string;
  inputList: any;
  serviceArray: any;
  handleChange?: (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => void;
  handleChange1?: (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => void;
  handleChange3?: (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => void;
  handleChange2?: (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => void;
  processText?: boolean;
  goodToHave?: boolean;
  name1?: any;
  name2?: any;
  tooltipData?: string;
  classes?: any;
  offeringText?: any;
  showListHeader?: boolean;
  requiredIndex?: number;
}

interface S {
}

interface SS {
  id: any;
}

export default class ProblemStatementController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
  }

  // web events


  rowCount = () => {
    return this.props.processText ? 1 : 5;
  };
  styleCondition(e: any, condition1: any, condition2: any) {
    if (e) {
      return condition1;
    } else {
      return condition2;
    }
  }
}
