import React, { useEffect, useState } from 'react';
import moment from "moment";
import { Box, Button, Typography, CircularProgress as LoadingCircularProgress } from '@material-ui/core';
import { ReactComponent as BluePlusIcon } from '../../../../../assets/icons/blue_plus_icon.svg';
import PaymentsHistory from '../PlanAndBilling/components/PaymentsHistory';
import CircularProgress from '../../../CustomFields/CircularProgress';
import PaymentModal from '../../../LimitationWidget/components/PaymentModal';
import TopUpModal from '../../../LimitationWidget/components/TopUpModal';
import { getWallet } from '../../../../../redux/services/wallet';
import { getCurrentSubscription } from '../../../../../redux/services/subscriptions';

import { useStyles } from './styles';
import { observableService } from '../../../../../services/observable';

interface Props {
  onReloadData: () => void;
}

const PlanAndBilling: React.FC<Props> = (props) => {
  const [wallet, setWallet] = useState<any>(null);
  const [tab, setTab] = useState<string>('wallet');
  const [showPaymentModal, setShowPaymentModal] = useState<{ active: boolean, option: any }>({
    active: false,
    option: null
  });
  const [showTopUpModal, setShowTopUpModal] = useState<{ active: boolean, type: any }>({
    active: false,
    type: null
  });
  const [currentSubscription, setCurrentSubscription] = useState<any>(null);

  const classes = useStyles();

  useEffect(() => {
    handleGetWallet();
    handleGetCurrentSubscription();
  }, []);

  const handleGetWallet = async () => {
    const { data, error } = await getWallet();

    if (data && !error) {
      setWallet({
        ...data,
        limits: [
          {
            key: 'TACTICAL_OUTREACH',
            label: <>Generation <br /> Emails</>,
            limit: data.limits.TACTICAL_OUTREACH,
            current: data.actions.TACTICAL_OUTREACH,
          },
          {
            key: 'COPILOT',
            label: <>Copilot <br /> messages</>,
            limit: data.limits.COPILOT,
            current: data.actions.COPILOT,
          },
          {
            key: 'ONBOARDING',
            label: <>Onboarding <br /> edits</>,
            limit: data.limits.ONBOARDING,
            current: data.actions.ONBOARDING,
          },
        ]
      });
    }
  }

  const handleGetCurrentSubscription = async () => {
    const { data, error } = await getCurrentSubscription();

    if (data && !error) {
      setCurrentSubscription(data);
    }
  }

  return (
    <>
      <Box className={classes.container}>
        {(tab === 'wallet' || tab === 'currentPlan') && (
          <>
            <Typography style={{ marginBottom: '12px', fontSize: '30px', fontWeight: '700', lineHeight: '32px', textAlign: 'center' }}>
              Wallet
            </Typography>
            <Typography style={{ fontSize: '20px', fontWeight: '700', lineHeight: '24px' }}>
              Your balance
            </Typography>
            <Box className={classes.content}>
              {!wallet
                ? <Box
                    style={{
                      width: '100%',
                      height: '100%',
                      minHeight: '274px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <LoadingCircularProgress color="inherit" />
                  </Box>
                : <>
                    <Typography style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '16px', fontWeight: '600', lineHeight: '19px', color: '#D93855' }}>
                      GTM Professional
                      <Typography style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#64748B' }}>
                        |&nbsp;&nbsp;Next payment {moment(currentSubscription?.validTill).format('DD-MMM-YYYY')}
                      </Typography>
                    </Typography>
                    <Box className={classes.limitCardsWrapper}>
                      {wallet.limits.map((item, index) => (
                        <Box className={classes.limitCard} key={index}>
                          <CircularProgress
                            value={item.current}
                            totalValue={item.limit}
                            additionalText={item.label}
                          />
                          <Button
                            fullWidth
                            color='secondary'
                            variant='outlined'
                            startIcon={<BluePlusIcon />}
                            onClick={() => setShowTopUpModal({ active: true, type: item.key })}
                          >
                            Add
                          </Button>
                        </Box>
                      ))}
                    </Box>
                  </>
              }
            </Box>
            <Button
              fullWidth
              color='secondary'
              variant='outlined'
              onClick={() => setTab('history')}
            >
              View payments history
            </Button>
          </>
        )}
        {tab === 'history' && (
          <PaymentsHistory
            setTab={setTab}
          />
        )}
      </Box>

      <TopUpModal
        open={showTopUpModal.active}
        type={showTopUpModal.type}
        limitReached={false}
        onClose={() => setShowTopUpModal({ active: false, type: null })}
        showPaymentModal={(option) => {
          setShowPaymentModal({
            active: true,
            option
          })
        }}
      />

      <PaymentModal
        open={showPaymentModal.active}
        order={showPaymentModal.option}
        redirect={false}
        onClose={() => {
          setShowPaymentModal({
            active: false,
            option: null
          });

          observableService.sendEvent('Refetch usage amount');
          handleGetWallet();
        }}
      />
    </>
  );
};

export default PlanAndBilling;
