import React from "react";
import CustomTextArea from "../../../components/main/CustomTextArea.web";

import MissionStatementController, {
  Props,
  configJSON,
} from "./MissionStatementController";

export default class MissionStatement extends MissionStatementController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { 
      handleChange, 
      values, 
      errors, 
      name,
      requiredIndex,
      maxWords,
      maxCharacters
    } = this.props;

    return (
      <CustomTextArea
        data-testid="text-field"
        title={configJSON.missionStatement}
        maxCharacters={maxCharacters}
        maxWords={maxWords}
        onChange={(e: any) => handleChange(e)}
        placeholder={configJSON.missinPlaceholder}
        minRows={2}
        modalContent={configJSON.missionInfo}
        errors={errors}
        // value={this.props.textValue}
        value={values}
        name={name}
        required={requiredIndex === 0}
      />
    );
  }
}

