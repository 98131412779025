import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  widget: ({ open }: any) => ({
    minWidth: '87px',
    padding: '5px 8px 5px 5px',
    border: '1px solid #E2E8F0',
    background: open ? 'black' : 'white',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '20px',
    cursor: 'pointer',
    '&:hover': {
      background: 'black !important',
      '& p': {
        color: 'white !important'
      },
      '& $innerContent': {
        background: 'black !important',
      }
    }
  }),
  widgetOpen: {
    '& p': {
      color: 'white !important'
    }
  },
  widgetMenu: {
    '& .MuiPaper-root': {
      width: '212px',
      maxHeight: 'fit-content',
      borderRadius: '28px',
      padding: '28px',
      background: '#000000',
      boxShadow: '0px 8px 32px 0px #0000000F',
      border: 'none',
      '& .MuiList-root': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '16px',
      }
    }
  },
  addButton: {
    borderColor: '#FFE4A9'
  }
}));