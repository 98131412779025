import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  select: {
    height: '44px !important',
    border: 'none !important',
    background: 'transparent !important',
    '& .MuiSelect-root': {
      height: 'fit-content',
      padding: '0 18px 0 0 !important',
    },
    '& svg': {
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: 'translate(0, -65%)',
    },
    '&::before': {
      display: 'none'
    },
    '&::after': {
      display: 'none'
    },
  },
  menu: {
    '& .MuiMenu-paper': {
      border: '1px solid #CBD5E1',
      borderRadius: '8px',
      boxShadow: 'none',
      marginTop: '10px',
    },
    '& .MuiMenu-list': {
      padding: '0'
    }
  },
  item: {
    minHeight: '40px',
    padding: '10px 12px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    borderBottom: '1px solid #E2E8F0',
  }
}));