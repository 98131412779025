import React from 'react';

import Dropdown from '../../../../../../components/main/CustomFields/Dropdown/index';
import TextField from '../../../../../../components/main/CustomFields/TextField';

import { useStyles } from './styles';

interface Props {
  item: any;
  name: string;
  values: any;
  errors: any;
  touched: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldError: (field: string, message: string) => void;
}

const RenderNumberInput: React.FC<Props> = ({
  item,
  name,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldError,
}) => {
  const classes = useStyles();

  return (
    <TextField
      name={name}
      value={values[name]}
      placeholder={item.attributes.find(item => item.type === 'PLACEHOLDER_ATTRIBUTE').text || ""}
      type={'number'}
      error={touched[name] && errors[name]}
      onChange={(event) => setFieldValue(name, +event.target.value)}
    />
  );
};

export default RenderNumberInput;
