import React from 'react';

import { useStyles } from './styles';
import { Box, Button, Modal } from '@material-ui/core';
import { Clear, InfoOutlined } from '@material-ui/icons';

interface Props {
  open: boolean;
  message: string;
  buttonText?: string;
  onClose: () => void;
}

const TooltipModal: React.FC<Props> = ({
  open,
  message,
  buttonText = 'Thanks',
  onClose,
}) => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={onClose}
    >
      <Box className={classes.contentWrapper}>
        <Box className={classes.boxWrapper}>
          <Box className={classes.header}>
            <InfoOutlined className={classes.infoIconBig} />
            <Clear
              className={classes.closeIcon}
              onClick={onClose}
            />
          </Box>
          <Box className={classes.content}>
            <div
              dangerouslySetInnerHTML={{
                __html: `<div style='font-family:Montserrat,sans-serif !important;'>${message}</div>`
              }}
            />
          </Box>
          <Button
            fullWidth
            variant="contained"
            className={classes.button}
            onClick={onClose}
          >
            {buttonText}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TooltipModal;
