import React from 'react';

import { FormControl, FormControlLabel, FormHelperText, Typography, TextField as Input, Box, IconButton } from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';

import { useStyles } from './styles';

interface Props {
  name?: string;
  value: any;
  placeholder?: string;
  label?: string | React.ReactNode;
  required?: boolean;
  minRows?: number;
  maxRows?: number;
  type?: 'text' | 'search' | 'number' | 'textarea';
  maxLength?: number | undefined | null;
  error?: string;
  helperText?: string;
  autoFocus?: boolean;
  showClear?: boolean;
  className?: string;
  inputRef?: any;
  onChange: (event: any, value: any) => void;
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
  onKeyPress?: (event: any) => void;
  onSearchPress?: (value: any) => void;
}

const TextField: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <FormControl
      className={`${classes.wrapper} ${props.className || ''}`} 
      error={!!props.error} 
      variant="standard"
    >
      {props.label && (
        typeof props.label === 'string'
          ? <Typography className={classes.label}>
              {props.label} &nbsp;
              {props.required && (
                <Typography style={{ color: 'red' }}>
                  *
                </Typography>
              )}
            </Typography>
          : props.label
      )}
      <FormControlLabel 
        control={
          <Input
            inputRef={props.inputRef}
            variant="outlined"
            multiline={props.type === 'textarea'}
            minRows={props.minRows || 4}
            maxRows={props.maxRows || 8}
            placeholder={props.placeholder}
            type={props.type || 'text'}
            className={props.type === 'textarea' ? classes.textarea : classes.input}
            autoFocus={!!props.autoFocus}
          />
        }
        label={''}
        className={classes.controll} 
        name={props.name}
        value={props.value || ''}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        onKeyPress={props.onKeyPress}
      />
      {props.type === 'search'
        ? props.showClear && props.value.length
          ? <Box className={classes.inputAdornment}>
              <IconButton
                onClick={() => props.onChange({ target: { value: '' } }, '')}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          : <Box className={classes.inputAdornment}>
              <IconButton
                onClick={() => props.onSearchPress(props.value)}
              >
                <SearchIcon />
              </IconButton>
            </Box>
        : null
      }
      {(props.error?.length || props.helperText?.length || props.maxLength) && (
        <Box
          style={{ 
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <FormHelperText error={!!props.error?.length} style={{ maxWidth: '80%' }}>
            {props.error 
              ? props.error
              : props.helperText
            }
          </FormHelperText>
          {props.maxLength && typeof props.maxLength === 'number' && (
            <FormHelperText error={false}>
              {props.value?.length || 0}/{props.maxLength} characters
            </FormHelperText>
          )}
        </Box>
      )}
    </FormControl>
  );
};

export default TextField;
