import MessageEnum, {
  getName
} from '../../../framework/main/Messages/MessageEnum';
import { IBlock } from '../../../framework/main/IBlock';
import { runEngine } from '../../../framework/main/RunEngine';
import { Message } from '../../../framework/main/Message';
import { Block } from '../../../framework/main/Block';
import StorageProvider from '../StorageProvider';
import { enqueueSnackbar } from 'notistack';
import { clearStorages } from '../../../helpers/other';

const config = require('../config');

export default class RestApiClientBlock<Entity> extends Block {
  private props: any;

  private static instance: RestApiClientBlock<any>;

  private constructor() {
    super();
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIRequestMessage)
    ]);
  }

  static getInstance(): RestApiClientBlock<any> {
    if (!RestApiClientBlock.instance) {
      RestApiClientBlock.instance = new RestApiClientBlock();
    }
    return RestApiClientBlock.instance;
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIRequestMessage) === message.id) {
      const uniqueApiCallId = message.messageId;
      const {
        RestAPIRequestMethodMessage: method,
        RestAPIResponceEndPointMessage: endpoint,
        RestAPIRequestHeaderMessage: headers,
        RestAPIRequestBodyMessage: body,
        NavigationPropsMessage: props
      } = message.properties;
      this.props = props;
      this.makeApiCall(uniqueApiCallId, method, endpoint, headers, body);
    }
  }

  async makeApiCall(
    uniqueApiCallId: string,
    method: string,
    endpoint: string,
    headers: any,
    body: string
  ) {
    const fullURL =
      endpoint.indexOf('://') === -1
        ? config.baseURL + '/' + endpoint
        : endpoint;
    const apiResponseMessage = new Message(
      getName(MessageEnum.RestAPIResponceMessage)
    );
    apiResponseMessage.addData(
      getName(MessageEnum.RestAPIResponceDataMessage),
      uniqueApiCallId
    );

    try {
      let response: Response = new Response();
      if (headers && body) {
        response = await fetch(fullURL, {
          method: method.toUpperCase(),
          headers: headers.length ? JSON.parse(headers) : headers,
          body: body
        });
      } else if (headers) {
        response = await fetch(fullURL, {
          method: method.toUpperCase(),
          headers: headers.length ? JSON.parse(headers) : headers
        });
      } else {
        response = await fetch(fullURL, {
          method: method.toUpperCase()
        });
      }

      const responseJson = await response.json();

      if (!!responseJson.errors?.find((error: { token: string }) => error.token)) {
        // enqueueSnackbar(
        //   `Your session token has been expired. Please login again`,
        //   { variant: 'error' }
        // );

        await clearStorages();

        // window.location.href = '/';
      } else {
        apiResponseMessage.addData(
          getName(MessageEnum.RestAPIResponceSuccessMessage),
          responseJson
        );

        if (this.props) {
          apiResponseMessage.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
          );
        }
      }
    } catch (error) {
      if (!!error.errors?.find((error: { token: string }) => error.token)) {
        enqueueSnackbar(
          `Your session token has been expired. Please login again`,
          { variant: 'error' }
        );
        
        await clearStorages();

        window.location.href = '/';
      } else {
        apiResponseMessage.addData(
          getName(MessageEnum.RestAPIResponceErrorMessage),
          'An error has occuured. Please try again later.'
        );
      }
    }

    this.send(apiResponseMessage);
  }
}
