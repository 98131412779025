import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { useStyles } from './styles';

const CONTENT = [
  {
    title: 'Overview',
    content: 'Lorem ipsum dolor sit amet consectetur. Mauris odio leo mi risus elementum vivamus duis justo. Phasellus nunc sit in metus pulvinar risus pulvinar. Et ipsum imperdiet urna et. Enim bibendum dolor nunc eu sed enim eget. Purus aliquet vel sem morbi mauris. At molestie pellentesque auctor suspendisse. Diam nec nisl tempus ut id eu sapien lectus quam. Scelerisque aliquam vitae convallis eu. Viverra consequat convallis et integer scelerisque. Quisque id risus nulla ut. Diam vitae bibendum massa massa sed in.',
  },
  {
    title: 'Managing Your Information',
    content: 'Lorem ipsum dolor sit amet consectetur. Mauris odio leo mi risus elementum vivamus duis justo. Phasellus nunc sit in metus pulvinar risus pulvinar. Et ipsum imperdiet urna et. Enim bibendum dolor nunc eu sed enim eget. Purus aliquet vel sem morbi mauris. At molestie pellentesque auctor suspendisse. Diam nec nisl tempus ut id eu sapien lectus quam. Scelerisque aliquam vitae convallis eu. Viverra consequat convallis et integer scelerisque. Quisque id risus nulla ut. Diam vitae bibendum massa massa sed in.',
  },
  {
    title: 'Managing Your Information',
    content: 'Lorem ipsum dolor sit amet consectetur. Mauris odio leo mi risus elementum vivamus duis justo. Phasellus nunc sit in metus pulvinar risus pulvinar. Et ipsum imperdiet urna et. Enim bibendum dolor nunc eu sed enim eget. Purus aliquet vel sem morbi mauris. At molestie pellentesque auctor suspendisse. Diam nec nisl tempus ut id eu sapien lectus quam. Scelerisque aliquam vitae convallis eu. Viverra consequat convallis et integer scelerisque. Quisque id risus nulla ut. Diam vitae bibendum massa massa sed in.',
  },
  {
    title: 'Managing Your Information',
    content: 'Lorem ipsum dolor sit amet consectetur. Mauris odio leo mi risus elementum vivamus duis justo. Phasellus nunc sit in metus pulvinar risus pulvinar. Et ipsum imperdiet urna et. Enim bibendum dolor nunc eu sed enim eget. Purus aliquet vel sem morbi mauris. At molestie pellentesque auctor suspendisse. Diam nec nisl tempus ut id eu sapien lectus quam. Scelerisque aliquam vitae convallis eu. Viverra consequat convallis et integer scelerisque. Quisque id risus nulla ut. Diam vitae bibendum massa massa sed in.',
  },
  {
    title: 'Managing Your Information',
    content: 'Lorem ipsum dolor sit amet consectetur. Mauris odio leo mi risus elementum vivamus duis justo. Phasellus nunc sit in metus pulvinar risus pulvinar. Et ipsum imperdiet urna et. Enim bibendum dolor nunc eu sed enim eget. Purus aliquet vel sem morbi mauris. At molestie pellentesque auctor suspendisse. Diam nec nisl tempus ut id eu sapien lectus quam. Scelerisque aliquam vitae convallis eu. Viverra consequat convallis et integer scelerisque. Quisque id risus nulla ut. Diam vitae bibendum massa massa sed in.',
  },
  {
    title: 'Managing Your Information',
    content: 'Lorem ipsum dolor sit amet consectetur. Mauris odio leo mi risus elementum vivamus duis justo. Phasellus nunc sit in metus pulvinar risus pulvinar. Et ipsum imperdiet urna et. Enim bibendum dolor nunc eu sed enim eget. Purus aliquet vel sem morbi mauris. At molestie pellentesque auctor suspendisse. Diam nec nisl tempus ut id eu sapien lectus quam. Scelerisque aliquam vitae convallis eu. Viverra consequat convallis et integer scelerisque. Quisque id risus nulla ut. Diam vitae bibendum massa massa sed in.',
  },
  {
    title: 'Managing Your Information',
    content: 'Lorem ipsum dolor sit amet consectetur. Mauris odio leo mi risus elementum vivamus duis justo. Phasellus nunc sit in metus pulvinar risus pulvinar. Et ipsum imperdiet urna et. Enim bibendum dolor nunc eu sed enim eget. Purus aliquet vel sem morbi mauris. At molestie pellentesque auctor suspendisse. Diam nec nisl tempus ut id eu sapien lectus quam. Scelerisque aliquam vitae convallis eu. Viverra consequat convallis et integer scelerisque. Quisque id risus nulla ut. Diam vitae bibendum massa massa sed in.',
  },
  {
    title: 'Managing Your Information',
    content: 'Lorem ipsum dolor sit amet consectetur. Mauris odio leo mi risus elementum vivamus duis justo. Phasellus nunc sit in metus pulvinar risus pulvinar. Et ipsum imperdiet urna et. Enim bibendum dolor nunc eu sed enim eget. Purus aliquet vel sem morbi mauris. At molestie pellentesque auctor suspendisse. Diam nec nisl tempus ut id eu sapien lectus quam. Scelerisque aliquam vitae convallis eu. Viverra consequat convallis et integer scelerisque. Quisque id risus nulla ut. Diam vitae bibendum massa massa sed in.',
  },
  {
    title: 'Managing Your Information',
    content: 'Lorem ipsum dolor sit amet consectetur. Mauris odio leo mi risus elementum vivamus duis justo. Phasellus nunc sit in metus pulvinar risus pulvinar. Et ipsum imperdiet urna et. Enim bibendum dolor nunc eu sed enim eget. Purus aliquet vel sem morbi mauris. At molestie pellentesque auctor suspendisse. Diam nec nisl tempus ut id eu sapien lectus quam. Scelerisque aliquam vitae convallis eu. Viverra consequat convallis et integer scelerisque. Quisque id risus nulla ut. Diam vitae bibendum massa massa sed in.',
  },
  {
    title: 'Managing Your Information',
    content: 'Lorem ipsum dolor sit amet consectetur. Mauris odio leo mi risus elementum vivamus duis justo. Phasellus nunc sit in metus pulvinar risus pulvinar. Et ipsum imperdiet urna et. Enim bibendum dolor nunc eu sed enim eget. Purus aliquet vel sem morbi mauris. At molestie pellentesque auctor suspendisse. Diam nec nisl tempus ut id eu sapien lectus quam. Scelerisque aliquam vitae convallis eu. Viverra consequat convallis et integer scelerisque. Quisque id risus nulla ut. Diam vitae bibendum massa massa sed in.',
  },
]

interface Props {
  
}

const PrivacyPolicy: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.container}>
        <Typography style={{ textAlign: 'center', fontSize: '48px', fontWeight: '600', lineHeight: '58px' }}>
          Privacy Policy
        </Typography>
        <Typography style={{ textAlign: 'center', fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#475569' }}>
          Updated 25 May 2023
        </Typography>
        <Box className={classes.content}>
          {CONTENT.map((item, index) => (
            <React.Fragment key={index}>
              <Typography style={{ marginTop: '20px', fontSize: '16px', fontWeight: '600', lineHeight: '19px' }}>
                {item.title}
              </Typography>
              <Typography style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#475569' }}>
                {item.content}
              </Typography>
            </React.Fragment>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default PrivacyPolicy;
