import React from "react";

import { Box, Typography } from "@material-ui/core";
import { arrowWhite, slider1 } from "./assets";

import CarouselDisplayController, {
  Props,
  configJSON
} from "./CarouselDisplayController";

export default class CarouselDisplay extends CarouselDisplayController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <Box style={styles.container}>
          <Box style={styles.wrapper}>
            {this.state.imageList.map((image, index) => (
              <Box
                key={index}
                style={{
                  ...styles.slide,
                  ...(this.state.activeIdx === index ? styles.activeSlide : {})
                }}
              >
                <Box height={"100%"} position={"relative"}>
                  <picture>
                    <source srcSet={image.imgWeb} type="image/webp" />
                    <img 
                      style={styles.image} 
                      src={image.img}
                      loading="lazy"
                    />
                  </picture>
                  <Box
                    position={"absolute"}
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    bgcolor={"#00000033"}
                  />
                </Box>
                <Box style={styles.bottomSection}>
                  <Box style={styles.info}>
                    <Box style={styles.text}>
                      <Typography style={styles.title}>
                        {image.title}
                      </Typography>
                      <Typography style={styles.subTitle}>
                        {image.subTitle}
                      </Typography>
                    </Box>
                    <Box width={"109px"} />
                  </Box>
                </Box>
              </Box>
            ))}
            <Box style={styles.bottomSection}>
              <Box style={styles.info}>
                <Box style={styles.text} />
                <Box style={styles.buttonWrapper}>
                  <Box onClick={this.onClickPrevious} style={styles.button}>
                    <img src={arrowWhite} style={styles.arrowImg} />
                  </Box>
                  <Box
                    onClick={this.onClickNext}
                    style={{ ...styles.button, ...styles.marginLeft }}
                  >
                    <img
                      src={arrowWhite}
                      style={{ ...styles.arrowImg, ...styles.arrowRightImg }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box style={styles.dotWrapper}>
                {this.state.imageList.map((image, index) => (
                  <Box
                    key={index}
                    style={{
                      ...styles.dot,
                      ...(this.state.activeIdx === index
                        ? styles.dotActive
                        : {})
                    }}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  }
}

const styles: any = {
  container: {
    boxSizing: "border-box",
    "-moz-box-sizing": "border-box",
    "-webkit-box-sizing": "border-box",
    width: "100%",
    height: "100%"
  },
  wrapper: {
    position: "relative",
    margin: "auto",
    width: "100%",
    height: "100%"
  },
  slide: {
    width: "100%",
    height: "100%",
    position: "absolute",
    margin: "auto",
    opacity: 0,
    transition: " opacity 0.6s ease",
    "-webkit-transition": "opacity 0.6s ease",
    "-moz-transition": "opacity 0.6s ease",
    "-o-transition": "opacity 0.6s ease"
  },
  activeSlide: {
    opacity: 1
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover"
  },
  bottomSection: {
    position: "absolute",
    bottom: 40,
    left: 0,
    right: 0
  },
  dotWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  dot: {
    width: 12,
    height: 12,
    borderRadius: 6,
    background: "#CBD5E17A",
    transition: " opacity 0.6s ease",
    "-webkit-transition": "opacity 0.6s ease",
    "-moz-transition": "opacity 0.6s ease",
    "-o-transition": "opacity 0.6s ease",
    margin: "0 4px",
    boxShadow: "1px 1px 2px #00000030"
  },
  dotActive: {
    background: "#fff"
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "row"
  },
  button: {
    height: 48,
    width: 48,
    borderRadius: 24,
    border: "1px solid #fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer"
  },
  marginLeft: {
    marginLeft: "8px"
  },
  arrowImg: {
    width: 24,
    height: 24
  },
  arrowRightImg: {
    transform: "rotate(180deg)"
  },
  info: {
    display: "flex",
    padding: "0 40px",
    alignItems: "flex-end",
    marginBottom: "28px"
  },
  text: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    marginRight: "80px"
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 1.4,
    fontFamily: "Montserrat",
    color: "#fff"
  },
  subTitle: {
    fontFamily: "Montserrat",
    color: "#fff",
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.4
  }
};
