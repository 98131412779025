import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '670px',
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px',
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '32px',
    background: '#FFFFFF',
    boxSizing: 'border-box',
    outline: 'none !important'
  },
  closeButton: {
    position: 'absolute',
    right: '20px',
    top: '20px',
  },
  title: {
    width: '380px',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '33px',
    textAlign: 'center',
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '28px'
  },
  email: {
    '& .MuiInputBase-root': {
      border: '1px solid #94A3B8',
      borderRadius: '8px',
      padding: '10px 12px',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '21px',
      '& input': {
        padding: '0px',
        '&::placeholder': {
          color: '#475569'
        }
      },
      '& fieldset': {
        display: 'none'
      }
    }
  },
  select: {
    width: '100%',
    height: '44px',
    border: '1px solid #94A3B8',
    borderRadius: '8px',
    padding: '10px 12px 10px 12px',
    '& .MuiSelect-root': {
      padding: '0 18px 0 0'
    },
    '& svg': {
      position: 'absolute',
      right: '19px',
      top: '55%',
      transform: 'translate(0, -65%)',
    },
    '&::before': {
      display: 'none'
    },
    '&::after': {
      display: 'none'
    },
  },
  menu: {
    '& .MuiMenu-paper': {
      border: '1px solid #CBD5E1',
      borderRadius: '8px',
      boxShadow: 'none',
      marginTop: '10px',
    },
    '& .MuiMenu-list': {
      padding: '0'
    }
  },
  item: {
    minHeight: '40px',
    padding: '10px 12px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    borderBottom: '1px solid #E2E8F0',
  },
  textArea: {
    '& .MuiInputBase-multiline': {
      border: '1px solid #94A3B8',
      borderRadius: '8px',
      padding: '10px 12px',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '21px',
      '& textarea': {
        '&::placeholder': {
          color: '#475569'
        }
      },
      '& fieldset': {
        display: 'none'
      }
    }
  },
  inviteButton: {
    width: '100%',
    border: 'none',
    borderRadius: '8px',
    color: '#FFFFFF',
    textTransform: 'none',
    fontSize: '16px',
    lineHeight: '12px',
    fontWeight: 600,
    background: '#000000',
    padding: '13px',
    boxShadow: 'none',
    marginTop: '4px',
    '&:hover': {
      background: '#000000'
    }
  },
}));
