import React from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Box, IconButton, TableCell, TableRow, Typography } from '@material-ui/core';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete.svg';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/arrow_down_filled.svg';
import { ReactComponent as ArrowUpIcon } from '../../../../assets/icons/arrow_up_filled.svg';
import { ReactComponent as LatterIcon } from '../../../../assets/icons/latter.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit_dark.svg';
import { ReactComponent as DuplicateIcon } from '../../../../assets/icons/duplicate_icon.svg';
import { ReactComponent as BlueCheckmarkIcon } from '../../../../assets/icons/blue_checkmark.svg';
import { ReactComponent as DraftCampaignIcon } from '../../../../assets/icons/draft_campaign_icon.svg';
import TextField from '../../../../components/main/CustomFields/TextField';
import { trimTextByCharacters } from '../../../../helpers/text';

import { useStyles } from './styles';
import { enqueueSnackbar } from 'notistack';
import { convertHTMLToText } from '../../../TacticalOutreachEmail/helpers';

interface Props {
  item: any;
  history: any;
  editSubject: any;
  isSubscriptionActive: boolean;
  isOnboardingCompleted: boolean;
  handleExpandCampaign: (event: any, id: number, value?: boolean) => void;
  setDeleteSubject: (data: { isCampaign: boolean, campaignId: number, emailId?: number }) => void;
  setEditSubject: (data: { campaignId: number, value: string, field: string }) => void;
  handleSaveEdit: () => void;
  handleDuplicate: (data: { isCampaign: boolean, campaignId: number, emailId?: number }) => void;
  setShowActionBlockedModal: (open: boolean) => void;
}

const CampaignTableRow: React.FC<Props> = ({
  item,
  history,
  editSubject,
  isSubscriptionActive,
  isOnboardingCompleted,
  handleExpandCampaign,
  setDeleteSubject,
  setEditSubject,
  handleSaveEdit,
  handleDuplicate,
  setShowActionBlockedModal,
}) => {
  const classes = useStyles({ item });

  const userTimezone = moment.tz.guess();

  return (
    <>
      <TableRow
        className={classes.tableRow}
        onClick={(event) => {
          if (!item.emails.length) {
            if (!isOnboardingCompleted || !isSubscriptionActive) {
              setShowActionBlockedModal(true);
            } else {
              history.push(`/tactical-outreach/builder?campaign_id=${item.id}`)
            }
          } else {
            handleExpandCampaign(event, item.id)
          }
        }}
      >
        <TableCell
          align="left"
          className={classes.tableCellMain}
        >
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <Box
              className={classes.iconBox}
              style={{
                background: !item.emails.length ? '#FEF3C7' : item.expanded ? 'white' : 'black'
              }}
            >
              {!!item.emails.length
                ? <>
                  <Typography className={classes.iconText}>
                    {item.emails.length}
                  </Typography>
                  {item.expanded
                    ? <ArrowUpIcon className={classes.arrowIcon} />
                    : <ArrowDownIcon className={classes.arrowIcon} />
                  }
                </>
                : <DraftCampaignIcon />
              }
            </Box>
            <Box className={classes.contentBox}>
              {editSubject?.campaignId === item.id && editSubject?.field === 'name'
                ? <Box
                    className={classes.expandedTableCellMain}
                    onClick={(event) => event.stopPropagation()}
                    style={{
                      width: '75%'
                    }}
                  >
                    <TextField
                      value={editSubject.value}
                      placeholder={'Name'}
                      autoFocus
                      // error={editSubject.value.trim().length > 50 ? 'Max 50 characters' : ''}
                      onChange={(event) => {
                        setEditSubject({ ...editSubject, value: event.target.value })
                      }}
                    />
                    <IconButton
                      size='small'
                      onClick={(event) => {
                        event.stopPropagation();

                        if (editSubject.value.trim().length > 50) {
                          enqueueSnackbar(
                            'Max 50 characters',
                            { variant: 'error' }
                          )
                        } else {
                          if (!isOnboardingCompleted || !isSubscriptionActive) {
                            setShowActionBlockedModal(true);
                          } else {
                            handleSaveEdit();
                          }
                        }
                      }}
                    >
                      <BlueCheckmarkIcon />
                    </IconButton>
                  </Box>
                : <Box className={classes.expandedTableCellMain}>
                    {!item.emails.length && (
                      <Typography style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#D97706' }}>
                        [draft]
                      </Typography>
                    )}
                    <Typography
                      noWrap
                      className={classes.contentTitle}
                      style={{
                        width: !!item.emails.length ? '300px' : '250px',
                        marginLeft: !!item.emails.length ? '0px' : '-10px'
                      }}
                    >
                      {/* {trimTextByCharacters(item.name, item.emails.length ? 50 : 20)} */}
                      {item.name}
                    </Typography>
                    <IconButton
                      className={classes.contentTitleIcon}
                      onClick={(event) => {
                        event.stopPropagation();

                        setEditSubject({ campaignId: item.id, value: item.name, field: 'name' })
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Box>
              }
              <Typography className={classes.contentDate}>
                Created: {moment.utc(item.createdAt).tz(userTimezone).format('DD-MMM-YYYY HH:mm')}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell
          align="left"
          className={classes.tableCell}
        // style={{
        //   display: 'flex',
        //   maxHeight: 'fit-content',
        //   minHeight: 'fit-content',
        // }}
        >
          <Typography noWrap className={classes.descriptionText}>
            {editSubject?.campaignId === item.id && editSubject?.field === 'description'
              ? <Box
                  className={classes.expandedTableCellMain}
                  onClick={(event) => event.stopPropagation()}
                  style={{
                    width: '75%'
                  }}
                >
                  <TextField
                    value={editSubject.value}
                    placeholder={'Description'}
                    autoFocus
                    // error={editSubject.value.trim().length > 300 ? 'Max 300 characters' : ''}
                    onChange={(event) => {
                      setEditSubject({ ...editSubject, value: event.target.value })
                    }}
                  />
                  <IconButton
                    size='small'
                    onClick={(event) => {
                      event.stopPropagation();

                      if (editSubject.value.trim().length > 300) {
                        enqueueSnackbar(
                          'Max 300 characters',
                          { variant: 'error' }
                        )
                      } else {
                        if (!isOnboardingCompleted || !isSubscriptionActive) {
                          setShowActionBlockedModal(true);
                        } else {
                          handleSaveEdit();
                        }
                      }
                    }}
                  >
                    <BlueCheckmarkIcon />
                  </IconButton>
                </Box>
              : <Box className={classes.expandedTableCellMain}>
                  <Typography className={classes.contentDescription}>
                    {trimTextByCharacters(item.description, 180)}
                  </Typography>

                  <IconButton
                    className={classes.contentDescriptionEdit}
                    onClick={(event) => {
                      event.stopPropagation();

                      setEditSubject({ campaignId: item.id, value: item.description, field: 'description' })
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
            }
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          className={classes.tableCell}
          style={{
            // width: '20%',
          }}
        >
          <Typography
            noWrap
            style={{
              width: 'fit-content',
              fontSize: '12px',
              fontWeight: '500',
              lineHeight: '18px',
              padding: '4px 16px',
              borderRadius: '20px',
              color: item.category === 'MARKETING' ? '#57BE99' : item.category === 'SALES' ? '#D93855' : '#3A84C9',
              border: `1px solid ${item.category === 'MARKETING' ? '#57BE99' : item.category === 'SALES' ? '#D93855' : '#3A84C9'}`,
            }}
          >
            {item.type.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}
          </Typography>
        </TableCell>
        <TableCell
          align="right"
          className={classes.tableCell}
          style={{
            paddingRight: '8px'
            // width: '10%',
          }}
        >
          <Box className={classes.optionsBox}>
            {!!item.emails.length && (
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();

                  if (!isOnboardingCompleted || !isSubscriptionActive) {
                    setShowActionBlockedModal(true);
                  } else {
                    handleDuplicate({ isCampaign: true, campaignId: item.id })
                  }
                }}
              >
                <DuplicateIcon />
              </IconButton>
            )}
            <IconButton
              onClick={(event) => {
                event.stopPropagation();

                if (!isOnboardingCompleted || !isSubscriptionActive) {
                  setShowActionBlockedModal(true);
                } else {
                  setDeleteSubject({ isCampaign: true, campaignId: item.id })
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      {item.expanded && (
        !!item.emails.length ? (
          item.emails.map((emailItem, index) => (
            <TableRow
              key={emailItem.id + index}
              className={classes.expandedTableRow}
            >
              <TableCell
                align="left"
                className={classes.expandedTableCell}
              >
                <Box className={classes.expandedTableCellMain}>
                  <Box />
                  <LatterIcon className={classes.latterIcon} />
                  <Box className={classes.emailContentBox}>
                    <Typography noWrap className={classes.emailContentTitle}>
                      {/* {trimTextByCharacters(emailItem.subject, 30)} */}
                      {emailItem.subject}
                    </Typography>
                    <Typography className={classes.emailContentDate}>
                      Created: {moment.utc(emailItem.createdAt).tz(userTimezone).format('DD-MMM-YYYY HH:mm')}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell
                align="left"
                className={classes.expandedTableCell}
                style={{
                  // width: '45%',
                }}
              >
                <Typography className={classes.emailContentText}>
                  {trimTextByCharacters(convertHTMLToText(emailItem.content), 120)}
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                className={classes.expandedTableCell}
                style={{
                  // width: '20%',
                }}
              />
              <TableCell
                align="right"
                className={classes.expandedTableCell}
                style={{
                  // width: '10%',
                }}
              >
                <Box className={classes.optionsBox}>
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation();

                      if (!isOnboardingCompleted || !isSubscriptionActive) {
                        setShowActionBlockedModal(true);
                      } else {
                        history.push(`/tactical-outreach/email?campaign_id=${emailItem.campaignId}&email_id=${emailItem.id}`)
                      }
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      event.stopPropagation();

                      if (!isOnboardingCompleted || !isSubscriptionActive) {
                        setShowActionBlockedModal(true);
                      } else {
                        handleDuplicate({ isCampaign: false, campaignId: emailItem.campaignId, emailId: emailItem.id })
                      }
                    }}
                  >
                    <DuplicateIcon />
                  </IconButton>
                  <IconButton
                    disabled={item.emails.length <= 1}
                    onClick={() => {
                      event.stopPropagation();

                      if (!isOnboardingCompleted || !isSubscriptionActive) {
                        setShowActionBlockedModal(true);
                      } else {
                        setDeleteSubject({ isCampaign: false, campaignId: emailItem.campaignId, emailId: emailItem.id })
                      }
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <Box className={classes.noEmailsBox}>
            <Typography className={classes.noEmailsText}>
              No emails
            </Typography>
          </Box>
        )
      )}
    </>
  );
};

export default CampaignTableRow;
