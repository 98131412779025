import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    '&.MuiFormControl-root': {
      width: '100%',
      margin: '4px 0px',
      background: 'transparent'
    }
  },
  controll: {
    '&.MuiFormControlLabel-root': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      columnGap: '8px',
      margin: '0px',
      padding: '0px',
      cursor: 'default',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '15px',
      fontWeight: '500',
      lineHeight: '20px',
      color: '#0F172A'
    },
    '& .MuiInput-formControl': {
      width: '100%',
      minWidth: '100%',
      height: '44px',
      borderRadius: '8px',
      padding: '0px',
    }
  },
  slider: {
    width: '100%',
    minWidth: '100%',
    height: 'fit-content',
    borderRadius: '8px',
    padding: '0px',
    margin: '20px 0px 0px 0px',
    '& .MuiSlider-rail': {
      backgroundColor: '#3A84C9',
      opacity: '20%'
    },
    '& .MuiSlider-markLabel': {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '12px',
      top: '-25px',
      transform: 'translate(0px, 0px)',
      color: 'rgba(0, 0, 0, 0.87) !important',
      '&:nth-last-child(2)': {
        transform: 'translate(-100%, 0px) !important',
      }
    },
    '& .MuiSlider-mark': {
      width: '4px',
      height: '4px',
    },
    '& .MuiSlider-thumb': {
      // width: '18px',
      // height: '18px',
      color: '#3A84C9'
    }
  }
}));