import React from "react";
import { Grid, Box, Typography, Divider, Backdrop, CircularProgress } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Toast from "../../utilities/main/Toast";
import Layout from "../../../components/main/Layout";

import ChooseYourPathController, {
  Props,
  configJSON,
} from "./ChooseYourPathController";

export default class ChooseYourPath extends ChooseYourPathController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      !!this.state.pathDetails.length
        ? <Layout
            carousel={"hide"}
            poweredByLogo={false}
            poweredByText={true}
            backgroundImage={true}
            hideBackBtn
            back={this.handleBack}
          >
            <>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={webStyle.mainContainer}
              >
                <Grid item xs={12}>
                  <Box style={webStyle.contentBox}>
                    <Typography style={webStyle.choosePath}>
                      {configJSON.choosePath}
                    </Typography>
                    <Typography align="center" style={webStyle.pathDetails}>
                      {configJSON.pathDetails}
                    </Typography>
                  </Box>
                  <Grid container justifyContent="center" alignItems="center">
                    {this.state.pathDetails.map((data, index) => {
                      const borderColor = this.generateBorderColor(index);
                      const fontColor = this.generateFontColor(index);
                      return (
                        <Grid
                          item
                          xs={12}
                          style={{
                            ...webStyle.quickFitContainer,
                            borderColor: borderColor,
                          }}
                          key={data.id}
                          component="button"
                          onClick={() => this.handlePath(data.id)}
                          data-testid={"choose_path_" + data.id}
                        >
                          <Box style={webStyle.quickFixBox}>
                            <Typography
                              style={{ ...webStyle.quickFitText, color: fontColor }}
                            >
                              {data.attributes.path_name}
                            </Typography>
                            <Box>
                              <Typography
                                style={{ ...webStyle.minCount, color: fontColor }}
                              >
                                {data.attributes.duration}
                                <span style={webStyle.minSpan}>
                                  {configJSON.min}
                                </span>
                              </Typography>
                              <Typography style={{ ...webStyle.completeText }}>
                                {configJSON.toComplete}
                              </Typography>
                            </Box>
                          </Box>
                          <Divider
                            orientation="vertical"
                            style={{ border: "none", backgroundColor: borderColor }}
                          />
                          <Box>
                            <Typography style={webStyle.customTool}>
                              {JSON.parse(data.attributes.path_description).title}:
                            </Typography>
                            {JSON.parse(
                              data.attributes.path_description
                            ).description.map((item: string, index: number) => (
                              <Box style={webStyle.listContainer} key={index}>
                                <FiberManualRecordIcon
                                  fontSize="small"
                                  style={webStyle.listIcon}
                                />
                                <Typography style={webStyle.listItem}>
                                  {item}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </>
          </Layout>
        : <Backdrop
            style={{ color: '#fff', zIndex: 9999 }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
    );
  }
}

const webStyle = {
  choosePath: {
    fontSize: "2.5rem",
    fontWeight: 600,
    textAlign: "center" as "center",
    fontFamily: "Montserrat",
  },
  pathDetails: {
    width: '75%',
    fontSize: "1.12rem",
    fontWeight: 500,
    color: "#475569",
    textAlign: "center" as "center",
    fontFamily: "Montserrat",
    lineHeight: "27px",
    margin: '0 auto'
  },
  quickFitContainer: {
    display: "flex",
    border: "1px solid",
    padding: "19px 20px",
    gap: "20px",
    borderRadius: "15px",
    background: "#FFFFFFC9",
    cursor: "pointer",
    textAlign: "left" as "left",
    maxWidth: "440px",
    height: "260px",
  },
  quickFixBox: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "space-between",
    height: "-webkit-fill-available",
  },
  quickFitText: {
    fontSize: "1.25rem",
    fontWeight: 600,
    background:
      "linear-gradient(234.7deg, #A68B3D 46.6%, #E2C33C 53.98%, #A68B3D 60.72%, #E2C33C 67.45%, #A68B3D 76.99%), linear-gradient(80.21deg, #261302 49.3%, #261302 56.65%, #E2C33C 61.32%, #261302 67.06%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
    width: "90%",
  },
  minCount: {
    fontSize: "2.25rem",
    background:
      "linear-gradient(234.7deg, #A68B3D 46.6%, #E2C33C 53.98%, #A68B3D 60.72%, #E2C33C 67.45%, #A68B3D 76.99%), linear-gradient(80.21deg, #261302 49.3%, #261302 56.65%, #E2C33C 61.32%, #261302 67.06%)",
    backgroundClip: "text",
    WebkitBackgroundClip: "text",
  },
  minSpan: {
    fontSize: "0.8rem",
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#000",
    marginLeft: "5px",
  },
  completeText: {
    fontSize: "0.8rem",
    fontWeight: 500,
    color: "#000",
  },
  customTool: {
    fontSize: "0.875rem",
    fontWeight: 500,
    color: "#000",
    marginBottom: "5px",
  },
  listContainer: {
    padding: "0",
    display: "flex",
    alignItems: "first baseline",
    gap: "8px",
    marginBottom: "5px",
    width: "132px",
  },
  listItem: {
    fontFamily: "Montserrat",
    fontSize: "0.75rem",
    fontWeight: 500,
    color: "#475569",
  },
  listIcon: {
    fontSize: "0.4rem",
    color: "#475569",
  },
  contentBox: {
    textAlign: "center" as "center",
    padding: "0 7% 4%",
  },
  mainContainer: {
    margin: "auto",
    textAlign: "center" as "center",
  },
};
