import React from 'react';
import { CustomContentProps, SnackbarContent, closeSnackbar } from 'notistack';
import { Box, IconButton, Typography } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';

import { useStyles } from './styles';

const Error = React.forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
  const { 
    id, 
    message 
  } = props;

  const classes = useStyles();

  return (
    <SnackbarContent ref={ref} role="alert">
      <Box className={classes.container}>
        <Typography className={classes.message}>
          {message}
        </Typography>
        <IconButton 
          className={classes.button}
          onClick={() => closeSnackbar(id)} 
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </SnackbarContent>
  );
});

Error.displayName = 'Error';

export default Error;
