import { Message } from "../../../framework/main/Message";
import { BlockComponent } from "../../../framework/main/BlockComponent";
import { runEngine } from "../../../framework/main/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/main/Messages/MessageEnum";

export const configJSON = require("./config");
import StorageProvider from "../../../framework/main/StorageProvider";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { WithStyles } from "@material-ui/core/styles/withStyles";

export interface Props extends WithStyles<any> {
  navigation?: any;
  id?: string;
  email?: string;
  token?: string;
}

export interface S {
  enablePasswordField: boolean;
  email: string | null;
  isAgreed: boolean;

  companyDes: any[];
  marketList: any[];
  errors: any;
  isLoad: boolean;
}

export interface SS {
  id: any;
}

export default class DescribleCompanyPlanController extends BlockComponent<
  Props,
  S,
  SS
> {
  createAccountId: string = "";
  createCompanyAccountId: string = "";
  fetchDescMsgId: string = "";
  fetchCompanyMarketTargetMsgId: string = "";
  token: string;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      isAgreed: false,
      errors: "",
      isLoad: false,
      enablePasswordField: true,
      companyDes: [{ id: "", name: "Choose variant" }],
      marketList: [{ id: "", name: "Choose variant" }],
      email: ""
    };
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.fetchDescMsgId:
            this.handleCompDescAPI(responseJson);
            break;
          case this.fetchCompanyMarketTargetMsgId:
            this.handleMarketList(responseJson);
            break;
          case this.createCompanyAccountId:
            this.handleCreating(responseJson);
            break;
        }
      }
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    
    const token = await StorageProvider.getItem("user-token");
    this.token = token;

    if (!this.token) {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationWelcomeScreenMessage)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
      return;
    }

    const email = window.localStorage.getItem("sign-up-email");
    this.setState({ email });
    this.getCompanyDescription();
    this.getMargetTarget();
  }

  handleCreating = (responseJson: any) => {
    if (!responseJson.errors) {
      const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "TellBitMoreBusiness"
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    } else {
      //Check Error Response
      const errors = this.parseApiErrorResponse(responseJson);
      this.setState({ errors });
    }
  };

  onCompanyBack = async () => {
    const changeSubscriptionStatus = await StorageProvider.getItem(
      "change_subscription_status"
    );
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );

    let page = "";

    switch (changeSubscriptionStatus) {
      case "changing":
        page = "LandingPage";
        break;

      case "upgrading":
        // page = "SubscriptionExploreUpgrade";
        page = "Subscriptions";
        break;

      default:
        page = "Subscription";
        break;
    }

    await StorageProvider.removeItem("new_plan");
    await StorageProvider.removeItem("change_subscription_status");

    message.addData(getName(MessageEnum.NavigationTargetMessage), page);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  handleCompDescAPI = (responseJson: any) => {
    if (responseJson.data) {
      let newArr: any[] = [];
      responseJson.data.forEach((companyDes: any) => {
        newArr.push(companyDes);
      });
      this.setState({ companyDes: [...this.state.companyDes, ...newArr] });
    }
  };

  async getCompanyDescription() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createDescribesCompanyEndPoint
    );
    this.fetchDescMsgId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleMarketList = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({
        marketList: [...this.state.marketList, ...responseJson]
      });
    } else {
      //Check Error Response
      const errors = this.parseApiErrorResponse(responseJson);
      this.setState({ errors });
    }
  };

  getMargetTarget = async () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.fetchCompanyMarketTargetMsgId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.marketListUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createCompanyAccount = async (values: any) => {
    if (!values.describes || !values.marketTarget || !values.reach) {
      return false;
    }
    const { describes, marketTarget, reach } = values;
    const body = {
      small_business_plan: {
        describes_company_id: describes,
        market_id: marketTarget,
        contact_number: reach
      }
    };
    const token = await StorageProvider.getItem("user-token");
    this.setState({ isLoad: true });

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitBusinessPlan
    );

    this.createCompanyAccountId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
}
