import { runEngine } from "../../../framework/main/RunEngine";
import { BlockComponent } from "../../../framework/main/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/main/Messages/MessageEnum";
import { Message } from "../../../framework/main/Message";
import { IBlock } from "../../../framework/main/IBlock";

import { parseErrors } from "../../utilities/main/Toast";
import { WithStyles } from "@material-ui/core";

export const configJSON = require("./config");

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import StorageProvider from "../../../framework/main/StorageProvider";
import { getAuth, Auth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { clearStorages } from "../../../helpers/other";
import { enqueueSnackbar } from "notistack";
import { getProfile, loginUser } from "../../../redux/services/profile";
import { getCurrentSubscription } from "../../../redux/services/subscriptions";

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
}

interface S {
  loading: boolean;
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  errors: any;
  message: string;
  privacy: any;
  terms: any;
}

interface SS {
  id: any;
}

export default class EmailLoginController extends BlockComponent<Props, S, SS> {
  validationApiCallId: string = "";
  labelTitle: string = "";
  apiEmailLoginCallId: string = "";
  apiGoogleLoginCallId: string = "";
  getPrivacyMessageId: string = "";
  getTermsMessageId: string = "";
  apiMyProfileMessageId: string = "";
  token: string;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.redirectToSignUpPage = this.redirectToSignUpPage.bind(this);
    this.handleForgotPasswordClick = this.handleForgotPasswordClick.bind(this);

    this.state = {
      loading: false,
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      errors: { password: "", email: "" },
      message: "",
      privacy: {
        isModalVisible: false,
        loading: false,
        data: [],
      },
      terms: {
        isModalVisible: false,
        loading: false,
        data: [],
      },
    };

    this.labelTitle = configJSON.labelTitle;

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    // const token = await StorageProvider.getItem("user-token");
    // this.token = token;

    // if (this.token) {
    //   this.props.navigation.navigate("LandingPage");
    // }
  }

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
    },
    secureTextEntry: true,
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.apiEmailLoginCallId) {
        this.handelEmailLoginCallAPIResponse(responseJson);
      }
      if (apiRequestCallId === this.apiGoogleLoginCallId) {
        this.handelGoogleLoginAPIResponse(responseJson);
      }
      if (apiRequestCallId === this.apiMyProfileMessageId) {
        this.redirectToLandingPage(responseJson);
      }
    }
  }

  async handelEmailLoginCallAPIResponse(responseJson: any) {
    if (responseJson?.error || !responseJson?.meta?.token) {
      responseJson.type === "email"
        ? this.setState({ errors: { email: responseJson.error } })
        : responseJson.type === "password"
          ? this.setState({ errors: { password: responseJson.error } })
          : !responseJson?.meta?.token
            ? enqueueSnackbar(
                'Authentication error',
                { variant: 'error' }
              )
            : enqueueSnackbar(
                responseJson?.error || 'Unexpected error occurred',
                { variant: 'error' }
              )
    } else {
      await StorageProvider.setItem("user-data", JSON.stringify(responseJson.data));
      await StorageProvider.setItem("user-token", responseJson.meta.token);
      await StorageProvider.setItem("user-refresh-token", responseJson.meta.refresh_token);

      this.getMyProfile(responseJson.meta.token);
    }

    this.setState({
      loading: false
    })
  }

  async handelGoogleLoginAPIResponse(responseJson: any) {
    if(!responseJson?.data?.attributes?.is_account_existed){
      await StorageProvider.setItem('user-email',responseJson?.meta?.email);
      await StorageProvider.setItem('user-token',responseJson?.meta?.token);
      const message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'CreateAccount'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      this.send(message);
    }
      else if (responseJson?.meta?.token) {
          await StorageProvider.setItem("user-data", JSON.stringify(responseJson.data));
          await StorageProvider.setItem("user-token", responseJson.meta.token);
          await StorageProvider.setItem("user-refresh-token", responseJson.meta.refresh_token);
          this.redirectToLandingPage(responseJson);
        } else {
          const errors = parseErrors(responseJson);

          enqueueSnackbar(
            errors,
            { variant: 'error' }
          );
        }
      
  }

  redirectToSignUpPage() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      // "ChooseOption"
      'SignUpEmail'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  async redirectToLandingPage(responseJson: any) {
    if (responseJson.errors) {
      await clearStorages();

      if (responseJson.errors === 'Profile not present') {
        // this.setState({ errors: { password: 'Incorrect password' } });
        enqueueSnackbar(
          'Profile not present',
          { variant: 'error' }
        )
      } else {
        // this.setState({ errors: responseJson.errors });
        enqueueSnackbar(
          responseJson.errors,
          { variant: 'error' }
        )
      }
    } else {
      await StorageProvider.setItem('isEdit', 'true');

      const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "LandingPage"
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }

    this.setState({
      loading: false
    })
  }

  handleForgotPasswordClick() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  //Google Login
  initiateGoogleLogin = async () => {
    try {
      const auth: Auth = getAuth();
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider) as any;
      this.doGoogleLogin(result.user.accessToken, result.user.email);
    } catch (error) {
      const errorMessage = (error as any).message;
      this.setState({ errors: errorMessage });
    }
  };

  doGoogleLogin = async (token: string, email: string) => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const httpsBody = {
      data: {
        type: "social_account",
        attributes: {
          // email: email,
          unique_auth_id: token,
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGoogleLoginCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.googleLoginAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpsBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  forgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
  }

  async doLogIn(emailId: string, pwd: string): Promise<boolean> {
    if (emailId === null || emailId?.length === 0) {
      // this.showAlert("Error", configJSON.errorEmailNotValid);
      enqueueSnackbar(
        configJSON.errorEmailNotValid,
        { variant: 'error' }
      )
      return false;
    }

    if (pwd === null || pwd?.length === 0) {
      // this.showAlert("Error", configJSON.errorPasswordNotValid);
      enqueueSnackbar(
        configJSON.errorPasswordNotValid,
        { variant: 'error' }
      )
      return false;
    }

    this.setState({
      loading: true
    })

    const body = {
      type: "email_account",
      attributes: {
        email: emailId,
        password: pwd,
      },
    };

    const { data, error } = await loginUser({ data: body });

    this.handelEmailLoginCallAPIResponse(data || error);

    this.setState({
      loading: false
    })
  };

  getMyProfile = async (token: string) => {
    this.setState({
      loading: true
    })

    const { data: profile, error: profileError } = await getProfile();
    const { data: subscription, error: subscriptionError } = await getCurrentSubscription();

    if (profile && !profileError) {
      if (!profile.attributes.account.activated) {
        this.props.navigation.navigate('/verify-signup-code');
      } else if (!profile.attributes.account.full_name?.trim()?.length) {
        this.props.navigation.navigate('/create-account');
      } else if (!profile.attributes.job_role) {
        this.props.navigation.navigate('/about-yourself');
      } else if (!subscription && !subscriptionError) {
        this.props.navigation.navigate('/subscriptions');
      } else {
        this.redirectToLandingPage(profile || profileError);
      }
    } else {
      enqueueSnackbar(
        'Failed to get profile data. Try to login again or contact our support',
        { variant: 'error' }
      )
      
      await clearStorages();

      this.props.navigation.navigate('/');
    }
  };
}
