Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.impactPricing = "Does not impact pricing";
exports.choosePath = "Gold in, gold out";
exports.pathDetails =
  "Please fill out our science-based, onboarding framework to train your dedicated Pebbles Copilot, supercharging the efficacy of your GTM workflow across all pebbles. The more you invest in the framework, the more knowledgeable your Pebbles Copilot becomes.";
exports.quickFit = "Quick Fit";
exports.min = "min";
exports.toComplete = "to complete";
exports.apiEndPointGetPath = "account_block/account_paths";
exports.apiEndPointAddPath = "/add_path_to_account";
exports.putMethodType = "PUT";
