import { apiCall } from "./../api";
import { api } from "../api";
import { ApiResponse } from "../types";

const tacticalOutreachAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllCampaigns: builder.query<any, any>({
      query: (id) => ({
        url: `/v1/tactical_outreach/campaign_types?categoryId=${id}`,
        method: "get"
      }),
      keepUnusedDataFor: 0
    }),
    getCampaignTemplate: builder.query<any, any>({
      query: (id) => ({
        url: `/v1/tactical_outreach/campaign_templates?typeId=${id}`,
        method: "get"
      }),
      keepUnusedDataFor: 0
    }),
    saveCampaignTemplate: builder.mutation<any, any>({
      query: (body) => ({
        url: `/v1/tactical_outreach/campaigns`,
        method: "post",
        body
      })
    }),
    saveCampaignParameters: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `/v1/tactical_outreach/answers/${id}`,
        method: "put",
        body
      })
    }),
    getCampaignParameters: builder.mutation<any, any>({
      query: (id) => ({
        url: `/v1/tactical_outreach/answers/${id}`,
        method: "get",
        keepUnusedDataFor: 0
      })
    }),
    triggerEmailGenerate: builder.mutation<any, any>({
      query: (body) => ({
        url: `/v1/tactical_outreach/emails/generate`,
        method: "post",
        body
      })
    }),
    getCampaignEmails: builder.query<any, any>({
      query: (id) => ({
        url: `/v1/tactical_outreach/emails?campaignId=${id}`,
        method: "get"
      }),
      keepUnusedDataFor: 0
    }),
    updateCampaignEmail: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/v1/tactical_outreach/emails/${id}`,
        method: "put",
        body
      })
    }),
    saveCampaignEmail: builder.mutation<any, any>({
      query: (body) => ({
        url: `/v1/tactical_outreach/emails`,
        method: "post",
        body
      })
    }),
    getCampaignById: builder.query<any, any>({
      query: (id) => ({
        url: `/v1/tactical_outreach/campaigns/${id}`,
        method: "get"
      }),
      keepUnusedDataFor: 0
    }),
    updateCampaign: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/v1/tactical_outreach/campaigns/${id}`,
        method: "put",
        body
      })
    })
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getAllCampaigns = async (id): Promise<ApiResponse> => {
  return apiCall(tacticalOutreachAPI.endpoints.getAllCampaigns, id);
};

export const getCampaignTemplate = async (id): Promise<ApiResponse> => {
  return apiCall(tacticalOutreachAPI.endpoints.getCampaignTemplate, id);
};

export const saveCampaignTemplate = async (body): Promise<ApiResponse> => {
  return apiCall(tacticalOutreachAPI.endpoints.saveCampaignTemplate, body);
};

export const saveCampaignParameters = async ({
  id,
  body
}): Promise<ApiResponse> => {
  return apiCall(tacticalOutreachAPI.endpoints.saveCampaignParameters, {
    id,
    body
  });
};

export const getCampaignParameters = async (id): Promise<ApiResponse> => {
  return apiCall(tacticalOutreachAPI.endpoints.getCampaignParameters, id);
};

export const triggerEmailGenerate = async (body): Promise<ApiResponse> => {
  return apiCall(tacticalOutreachAPI.endpoints.triggerEmailGenerate, body);
};

export const getCampaignEmails = async (id): Promise<ApiResponse> => {
  return apiCall(tacticalOutreachAPI.endpoints.getCampaignEmails, id);
};

export const updateCampaignEmail = async ({ body, id }): Promise<ApiResponse> => {
  return apiCall(tacticalOutreachAPI.endpoints.updateCampaignEmail, { body, id });
};

export const saveCampaignEmail = async (body): Promise<ApiResponse> => {
  return apiCall(tacticalOutreachAPI.endpoints.saveCampaignEmail, body);
};

export const getCampaignById = async (id): Promise<ApiResponse> => {
  return apiCall(tacticalOutreachAPI.endpoints.getCampaignById, id);
};

export const updateCampaign = async ({ body, id }): Promise<ApiResponse> => {
  return apiCall(tacticalOutreachAPI.endpoints.updateCampaign, { body, id });
};
