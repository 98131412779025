export const welcomeSlider = require("../assets/welcomeBG.png");
export const arrowWhite = require("../assets/arrow-white.png");

export const slider1 = require("../assets/1img.png");
export const slider2 = require("../assets/2img.png");
export const slider3 = require("../assets/3img.png");
export const slider4 = require("../assets/4img.png");
export const slider5 = require("../assets/5img.png");
export const slider6 = require("../assets/6img.png");
export const slider7 = require("../assets/7img.png");

export const slider1Web = require("../assets/1img.webp");
export const slider2Web = require("../assets/2img.webp");
export const slider3Web = require("../assets/3img.webp");
export const slider4Web = require("../assets/4img.webp");
export const slider5Web = require("../assets/5img.webp");
export const slider6Web = require("../assets/6img.webp");
export const slider7Web = require("../assets/7img.webp");
