import React from 'react';

import TextField from '../../../../../../components/main/CustomFields/TextField';
import { Typography } from '@material-ui/core';
import { findElementByName } from '../../../../helpers';

import { useStyles } from './styles';

interface Props {
  item: any;
  name: string;
  values: any;
  errors: any;
  touched: any;
  template: any;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldError: (field: string, message: string) => void;
}

const RenderTextArea: React.FC<Props> = ({
  item,
  name,
  values,
  errors,
  touched,
  template,
  setFieldValue,
  setFieldError,
}) => {
  const classes = useStyles();

  const minLength = item.attributes.find(item => item.type === 'MIN_LENGTH_ATTRIBUTE')?.value || 0;
  const maxLength = item.attributes.find(item => item.type === 'MAX_LENGTH_ATTRIBUTE')?.value;
  const placeholder = item.attributes.find(item => item.type === 'PLACEHOLDER_ATTRIBUTE')?.text;
  const label = item.attributes.find(item => item.type === 'TITLE_ATTRIBUTE')?.text;
  const isOptional = item.attributes.find(item => item.type === 'OPTIONAL_ATTRIBUTE');

  return (
    <>
      {label && (
        <Typography className={classes.label}>
          {label}
          &nbsp;
          {!!minLength && (
            <Typography style={{ fontSize: '12px', fontWeight: '400', lineHeight: '14px', color: '#475569' }}>
              (min {minLength} characters)
              &nbsp;&nbsp;
            </Typography>
          )}
          {isOptional
            ? <Typography style={{ fontSize: '12px', fontWeight: '400', lineHeight: '14px', color: '#475569' }}>
                (optional)
              </Typography>
            : <Typography style={{ fontSize: '16px', fontWeight: '500', lineHeight: '19px', color: '#DC2626' }}>
                *
              </Typography>
          }
        </Typography>
      )}
      <TextField
        name={name}
        value={values[name]}
        placeholder={placeholder || ""}
        type={'textarea'}
        maxLength={maxLength}
        error={touched[name] && errors[name]}
        onChange={(event) => setFieldValue(name, event.target.value)}
      />
    </>
  );
};

export default RenderTextArea;
