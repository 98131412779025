import React, { useEffect, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import { Backdrop, Box, Button, CircularProgress, FormControlLabel, IconButton, Switch, Typography } from "@material-ui/core";
import { ReactComponent as EditIcon } from '../../assets/icons/edit_dark.svg';
import { ReactComponent as LibraryBooksIcon } from '../../assets/icons/library_books.svg';
import { ReactComponent as DuplicateIcon } from '../../assets/icons/duplicate_icon.svg';
import { ReactComponent as ReloadIcon } from '../../assets/icons/reload_icon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete_red.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar_icon.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/clock_icon.svg';
import { ReactComponent as BlueCheckmarkIcon } from '../../assets/icons/blue_checkmark.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as SideBarOpenIcon } from '../../assets/icons/side_bar_open_icon.svg';
import TextField from "../../components/main/CustomFields/TextField";
import { getUrlAndParams, removeUrlParams } from "../../helpers/other";
import { getCampaignById, getCampaignEmails, updateCampaign, updateCampaignEmail } from "../../redux/services/tactical-outreach";
import ConfirmationModal from "../../components/main/ConfirmationModal";
import { enqueueSnackbar } from "notistack";
import { trimTextByCharacters } from "../../helpers/text";
import SideBar from "./components/SideBar";
import ContentEditor from "./components/ContentEditor";
import { convertHTMLToState, convertStateToHTML } from "./helpers";

import { useStyles } from "./styles";
import LimitationWidget from "../../components/main/LimitationWidget";

const TacticalOutreachEmail = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [campaign, setCampaign] = useState<any>(null);
  const [email, setEmail] = useState<any>(null);
  const [unsavedDataModal, setUnsavedDataModal] = useState<{ active: boolean, targetEmail: number }>({
    active: false,
    targetEmail: null,
  });
  const [editSubject, setEditSubject] = useState<{ value: string, field: string } | null>(null);
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(true);
  const [leavingModal, setLeavingModal] = useState<{ active: boolean, nextLocation: string | null }>({
    active: false,
    nextLocation: null
  });
  const [editorState, setEditorState] = useState<any>(null);

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (email?.content) {
      setEditorState(
        convertHTMLToState(email.content)
      );
    }
  }, [email?.content]);

  useEffect(() => {
    setLoading(true);

    const { params } = getUrlAndParams();
    const campaign_id = params.get('campaign_id');
    const email_id = params.get('email_id');

    if (!campaign_id || campaign_id === 'null') {
      enqueueSnackbar(
        'Campaign not found',
        { variant: 'error' }
      )

      history.push('/tactical-outreach');
    } else {
      handleGetEmails(+campaign_id, +email_id);
    }

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };

  const handleGetEmails = async (campaignId, emailId) => {
    const { data: campaign, error: campaignError } = await getCampaignById(campaignId);
    const { data: emails, error: emailsError } = await getCampaignEmails(campaignId);

    if (emails && campaign && !campaignError && !emailsError) {
      setCampaign({
        ...campaign,
        emails: [...emails].reverse()
      })

      if (emailId) {
        const targetEmail = emails.find(item => item.id === emailId);

        if (targetEmail) {
          setEmail(targetEmail)
        } else {
          setEmail(emails[emails.length - 1])
        }
      } else {
        setEmail(emails[emails.length - 1])
      }
    }

    setLoading(false);
  }

  const handleSaveEmail = async () => {
    const { data, error } = await updateCampaignEmail({ 
      body: { 
        ...email,
        content: convertStateToHTML(editorState)
      }, 
      id: email.id 
    });

    if (data && !error) {
      handleGetEmails(data.campaignId, data.id);

      enqueueSnackbar(
        'Changes saved successfully',
        { variant: 'success' }
      )
    } else {
      enqueueSnackbar(
        'Failed to save changes',
        { variant: 'error' }
      )
    }
  }

  const handleSaveEdit = async () => {
    if (
      (editSubject.field === 'name' && editSubject.value.trim().length > 50) ||
      (editSubject.field === 'description' && editSubject.value.trim().length > 300)
    ) {
      enqueueSnackbar(
        `Max ${editSubject.field === 'name' ? '50' : '300'} characters`,
        { variant: 'error' }
      )
    } else {
      const { error } = await updateCampaign({
        body: {
          name: campaign.name,
          description: campaign.description,
          [editSubject.field]: editSubject.value
        },
        id: campaign.id
      });
  
      if (!error) {
        handleGetEmails(campaign.id, email.id);
  
        enqueueSnackbar(
          'Campaign edited successfully',
          { variant: 'success' }
        )
      } else {
        enqueueSnackbar(
          'Failed to edit',
          { variant: 'error' }
        )
      }
  
      setEditSubject(null);
    }
  }

  const isSaveDisabled = () => {
    if (campaign && email) {
      const originalEmail = {
        subject: campaign.emails.find(item => item.id === email.id).subject,
        content: campaign.emails.find(item => item.id === email.id).content,
      }
      const currentEmail = {
        subject: email.subject,
        content: convertStateToHTML(editorState),
      }
  
      return JSON.stringify(originalEmail) === JSON.stringify(currentEmail);
    } else {
      return true;
    }
  }

  const handleBlockedNavigation = (nextLocation) => {
    setLeavingModal({
      active: true,
      nextLocation: nextLocation.pathname + nextLocation.search
    })

    return false;
  };

  return (
    <>
      <Prompt
        when={!isSaveDisabled() && !loading}
        message={(location) => handleBlockedNavigation(location)}
      />

      <Backdrop
        style={{ color: '#fff', zIndex: 9999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={classes.wrapper}>
        <Box className={classes.container}>
          {campaign && email && (
            <Box
              className={classes.header}
              style={{
                minHeight: editSubject ? '100px' : 'fit-content'
              }}
            >
              <Box className={classes.nameBlock}>
                {editSubject?.field === 'name'
                  ? <Box style={{ width: '82%', display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <TextField
                        value={editSubject.value}
                        placeholder={'Name'}
                        onChange={(event) => {
                          setEditSubject({ ...editSubject, value: event.target.value })
                        }}
                      />
                      <IconButton 
                        size='small' 
                        onClick={handleSaveEdit}
                      >
                        <BlueCheckmarkIcon />
                      </IconButton>
                    </Box>
                  : <Box style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <Typography noWrap style={{ fontSize: '16px', fontWeight: '600', lineHeight: '19px' }}>
                        {trimTextByCharacters(campaign.name, 50)}
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={() => setEditSubject({ field: 'name', value: campaign.name })}
                      >
                        <EditIcon />
                      </IconButton>
                    </Box>
                }
                {editSubject?.field === 'description'
                  ? <Box style={{ width: '82%', display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <TextField
                        value={editSubject.value}
                        placeholder={'Name'}
                        onChange={(event) => {
                          setEditSubject({ ...editSubject, value: event.target.value })
                        }}
                      />
                      <IconButton 
                        size='small' 
                        onClick={handleSaveEdit}
                      >
                        <BlueCheckmarkIcon />
                      </IconButton>
                    </Box>
                  : <Box style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <Typography noWrap style={{ fontSize: '12px', fontWeight: '600', lineHeight: '14px', color: '#475569' }}>
                        {trimTextByCharacters(campaign.description, 50)}
                      </Typography>
                      <IconButton
                        size='small'
                        onClick={() => setEditSubject({ field: 'description', value: campaign.description })}
                      >
                        <EditIcon />
                      </IconButton>
                    </Box>
                }
              </Box>
              {campaign.type && (
                <Typography
                  className={classes.tag}
                  style={{
                    color: campaign.category === 'MARKETING' ? '#57BE99' : campaign.category === 'SALES' ? '#D93855' : '#3A84C9',
                    borderColor: campaign.category === 'MARKETING' ? '#57BE99' : campaign.category === 'SALES' ? '#D93855' : '#3A84C9',
                  }}
                >
                  {campaign.type.toLowerCase()?.replace(/_/g, ' ')?.replace(/\b\w/g, char => char.toUpperCase())}
                </Typography>
              )}
              <Box style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<EditIcon />}
                  onClick={() => history.push(`/tactical-outreach/builder?campaign_id=${campaign.id}&target_email_id=${email.id}`)}
                >
                  Edit parameters
                </Button>
                {/* &nbsp;
                <LimitationWidget
                  type='TACTICAL_OUTREACH'
                /> */}
                {!sideBarOpen && (
                  <IconButton onClick={() => setSideBarOpen(true)}>
                    <SideBarOpenIcon />
                  </IconButton>
                )}
              </Box>
            </Box>
          )}

          {campaign && email && (
            <ContentEditor 
              campaign={campaign}
              email={email}
              editorState={editorState}
              setEmail={setEmail}
              isSaveDisabled={isSaveDisabled}
              handleSaveEmail={handleSaveEmail}
              handleGetEmails={handleGetEmails}
              setEditorState={setEditorState}
            />
          )}
        </Box>
        {campaign && email && (
          <SideBar 
            open={sideBarOpen}
            email={email}
            campaign={campaign}
            isSaveDisabled={isSaveDisabled}
            setSideBarOpen={setSideBarOpen}
            setEmail={setEmail}
            setUnsavedDataModal={setUnsavedDataModal}
            handleGetEmails={handleGetEmails}
            setLoading={setLoading}
          />
        )}
      </Box>

      <ConfirmationModal
        open={unsavedDataModal.active}
        title={"You have unsaved data!"}
        description={"Do you want to save latest changes?"}
        onClose={() => setUnsavedDataModal({ active: false, targetEmail: null })}
        rejectBtnText={"No"}
        confirmBtnText={"Yes"}
        onReject={() => {
          setEmail(unsavedDataModal.targetEmail)
          setUnsavedDataModal({ active: false, targetEmail: null })
        }}
        onConfirm={async () => {
          await handleSaveEmail()
          setEmail(unsavedDataModal.targetEmail)
          setUnsavedDataModal({ active: false, targetEmail: null })
        }}
      />

      <ConfirmationModal
        open={leavingModal.active}
        title={"Would you like to save your email before leaving?"}
        onClose={() => setLeavingModal({
          active: false,
          nextLocation: null
        })}
        reversedButtons
        rejectBtnText={"Yes"}
        confirmBtnText={"No"}
        onReject={() => {
          setLoading(true);
          handleSaveEmail();
          setLeavingModal({
            ...leavingModal,
            active: false,
          });

          setTimeout(() => {
            history.push(leavingModal.nextLocation);
          }, 500);
        }}
        onConfirm={() => {
          setLoading(true);
          setLeavingModal({
            active: false,
            nextLocation: null
          });

          setTimeout(() => {
            history.push(leavingModal.nextLocation);
          }, 500);
        }}
      />
    </>
  );
};

export default TacticalOutreachEmail;
