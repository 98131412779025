import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  infoIconBig: {
    height: "24px",
    width: "24px",
    color: "#3A84C9"
  },
  boxWrapper: {
    background: "#fff",
    borderRadius: "20px",
    padding: "40px",
    width: "100%",
    maxWidth: "520px",
    display: "flex",
    flexDirection: "column"
  },
  modal: {
    width: 'fit-content',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: '0 auto'
  },
  contentWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    padding: "20px"
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  closeIcon: {
    width: "24px",
    height: "24px",
    cursor: "pointer",
    color: "#475569"
  },
  content: {
    display: "flex",
    flex: 1,
    margin: "28px 0"
  },
  button: {
    height: "44px",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: 1.2,
    background: "#000",
    textTransform: "none",
    color: "white",
    "&:hover": {
      background: "#000"
    }
  }
}));