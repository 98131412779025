import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px'
  },
  loader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 9
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px'
  },
  cardFields: {
    height: '48px',
    padding: '10px 12px',
    border: '1px solid #94A3B8',
    borderRadius: '8px',
  },
  footer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '8px'
  },
  contryAutocomplete: {
    '& .MuiAutocomplete-endAdornment': {
      marginRight: '10px',
    },
    '& .MuiInputBase-root': {
      paddingLeft: '0px',
      paddingTop: '0px',
      paddingBottom: '0px',
      border: '1px solid #94A3B8 !important',
      borderRadius: '8px 8px 0px 0px !important',
      '& input': {
        color: '#000000',
        height: '44px',
        overflow: 'hidden',
        fontSize: '14px',
        boxSizing: 'border-box',
        fontWeight: 400,
        lineHeight: '21px',
        padding: '18.5px 14px !important'
      },
      '&::before': {
        display: 'none'
      },
      '&::after': {
        display: 'none'
      },
    }
  },
  zipField: {
    margin: '0px !important',
    '& .MuiInputBase-root': {
      borderRadius: '0px 0px 8px 8px !important',
      borderTop: 'none !important',
    }
  },
}));