import { api, apiCall } from "../api";
import { ApiResponse } from "../types";

type Response = {
  attributes: {
    account: {
      account_path_id: number;
      account_type: string;
      activated: boolean;
      age: null | string;
      app_language_id: null | string | number;
      campaign_mails_count: number;
      company_name: string;
      country_code: null | string | number;
      created_at: string;
      date_of_birth: null | string | number;
      description: string;
      device_id: null | string | number;
      email: string;
      first_name: null | string | number;
      full_name: string;
      full_phone_number: null | string | number;
      gender: null | string | number;
      id: number;
      is_blacklisted: boolean;
      last_name: null | string;
      last_visit_at: null | string;
      password_digest: string;
      phone_number: null | string | number;
      platform: null | string;
      reset_password_token: null | string;
      reset_password_token_sent_at: null | string;
      role: null | string;
      role_id: null | string | number;
      status: string;
      stripe_id: null | string | number;
      stripe_subscription_date: null | string;
      stripe_subscription_id: null | string | number;
      suspend_until: null | string | number;
      unique_auth_id: null | string | number;
      updated_at: string;
      user_name: null | string | number;
      user_path_id: null | string | number;
      user_type: null | string | number;
      uuid: string;
      is_social_login?: boolean;
    };
    company_industry: null | string;
    country_name: null | string;
    cover: null | string;
    gtm_team_size: null | string;
    id: number;
    job_role: null | string;
    logo: null | string;
    profile_photo: null | string;
    subscription_and_limit: null | {
      campaign_mails_limit: null | string | number;
    };
    web_link: null | string;
  };
  id: string;
  type: string;
};

const profileAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getProfile: builder.query<Response, void>({
      query: () => ({
        url: "/bx_block_profile/my_profile",
        method: "get"
      }),
      transformResponse: (response: { data: Response }) => response.data,
      // providesTags: ["Profile"]
    }),
    updateProfile: builder.query<any, any>({
      query: ({ body, id }) => ({
        url: `/bx_block_profile/profiles/${id}`,
        method: "PATCH",
        body
      }),
    }),
    deleteProfile: builder.query<any, any>({
      query: (email) => ({
        url: `/account_block/accounts/:id?email=${email}`,
        method: "DELETE",
      }),
    }),
    loginUser: builder.query<any, any>({
      query: (body) => ({
        url: `/bx_block_login/logins`,
        method: "POST",
        body
      }),
    }),
  }),
  overrideExisting: true
});

export const { 
  useGetProfileQuery
} = profileAPI;

// Create a function to call the API without using the hook
export const getProfile = async (): Promise<ApiResponse> => {
  return apiCall(profileAPI.endpoints.getProfile);
};

export const updateProfile = async ({ body, id }): Promise<ApiResponse> => {
  return apiCall(profileAPI.endpoints.updateProfile, { body, id });
};

export const deleteProfile = async (email): Promise<ApiResponse> => {
  return apiCall(profileAPI.endpoints.deleteProfile, email);
};

export const loginUser = async (body): Promise<ApiResponse> => {
  return apiCall(profileAPI.endpoints.loginUser, body);
};