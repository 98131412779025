import React from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  Paper,
  Link,
} from "@material-ui/core";
import Layout from "../../../components/main/Layout";
import ChooseyourOptionsController, {
  Props,
} from "./ChooseyourOptionsController.web";
import { personIcon, personsIcon } from "./assets";
import CarouselDisplay from "../../../blocks/carouseldisplay/main/CarouselDisplay.web";

export default class ChooseyourOptions extends ChooseyourOptionsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <Container style={pageStyles.containerStyle}>
        <Paper style={pageStyles.paperStyle}>
          <Box>
            <Typography
              align="center"
              style={pageStyles.typographyStyle1}
              variant="h6"
            >
              Choose your option
            </Typography>
            <Typography
              align="center"
              style={pageStyles.typographyStyle2}
              variant="h6"
            >
              Already have an account?{" "}
              <Link
                style={pageStyles.link}
                data-test-id="loginBtn"
                onClick={this.goToLogin}
              >
                Log in
              </Link>
            </Typography>
          </Box>
          <Box style={pageStyles.buttonStyle}>
            <Button
              style={{ ...pageStyles.buttonPersonal, textTransform: "none" }}
              onClick={this.goToSignUpToMe}
              data-test-id="signUpProfessionalbtnDataTestId"
            >
              <img
                src={personIcon}
                alt="personsIcon"
                height="24px"
                width="24px"
              />{" "}
              Sign up for me
            </Button>
            <Button
              style={{
                ...pageStyles.buttonPersonal,
                textTransform: "none",
                marginLeft: "8px",
              }}
              color="primary"
              onClick={this.goForMyCompany}
            >
              <img
                src={personsIcon}
                alt="personsIcon"
                height="24px"
                width="24px"
              />{" "}
              For My Company
            </Button>
          </Box>
        </Paper>
      </Container>
    );
  }
}

const pageStyles = {
  containerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paperStyle: {
    padding: "40px",
    maxWidth: "440px",
    width: "100%",
    height: "221px",
    borderRadius: "28px",
    background:
      "linear-gradient(151.76deg, rgba(255, 255, 255, 0) -34.68%, rgba(255, 255, 255, 0.8) 113.6%)",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
  },
  typographyStyle1: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "30px",
    lineHeight: "32px",
  },
  typographyStyle2: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
    color: "rgba(71, 85, 105, 1)",
    marginTop: "12px",
    lineHeight: 1.5,
  },
  buttonStyle: {
    width: "100%",
    marginTop: "32px",
    display: "flex",
  },
  buttonPersonal: {
    color: "black",
    backgroundColor: "white",
    height: "56px",
    flex: 1,
    cursor: "pointer",
    borderRadius: "8px",
    border: " 1px solid rgba(203, 213, 225, 1)",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: 1.2,
  },
  link: {
    cursor: "pointer",
    color: "#3A84C9",
    fontWeight: 700,
  },
};
