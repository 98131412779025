import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    // height: "100vh",
    background: "linear-gradient(150deg, #F9F9F9 0%, #EBE2CF 100%)",
    // overflowY: "scroll",
    // overflowX: "hidden"
    overflow: 'hidden'
  },
  header: {
    width: '100%',
    maxHeight: '70px',
    minHeight: '70px',
    // background: '#F2EDE2CC',
    padding: '20px 40px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerSticky: {
    position: 'sticky',
    top: 0,
    width: '100%',
    maxHeight: '70px',
    minHeight: '70px',
    // background: '#F2EDE2CC',
    padding: '20px 40px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 1299
  },
  logo: {
    mixBlendMode: "multiply", //for removing background white from image
    height: "25px",
    width: "125px",
    // cursor: 'pointer',
    "@media (min-width: 600px)": {
      height: "40px",
      width: "200px"
    }
  },
  leftSection: {
    position: "relative",
    display: "flex",
    height: "100vh",
    flexDirection: "column",
    alignItems: "flex-start",
    "@media (min-width: 600px)": {
      width: "50%",
      // flex: ".5",
      display: "flex",
      height: "100vh"
    }
  },
  leftSection2: {
    flex: "1",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "@media (min-width: 600px)": {
      display: "flex"
    }
  },
  leftContent: {
    display: "flex",
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  leftContent2: {
    width: "100%",
    height: "fit-content",
    display: "flex",
    marginTop: "50px",
    // flex:1,
    "@media (min-width: 600px)": {
      marginTop: 0,
      justifyContent: "center",
      alignItems: "center"
    }
  },
  iconBack: {
    zIndex: 1299,
    width: "48px",
    height: "48px",
    alignSelf: "flex-start",
    boxSizing: "border-box",
    position: 'absolute',
    left: '40px',
    top: '85px',
  },
  content: {
    height: 'calc(100vh - 70px)',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 1
  },
  content2: {
    height: 'calc(100vh - 155px)',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flex: 1
  },
  scrollContainer: {
    width: '100%',
    padding: '0',
    overflowY: 'auto',
    zIndex: 1,
  },
  rightSection: {
    display: "none",
    position: "relative",
    "@media (min-width: 600px)": {
      flex: ".5",
      display: "flex"
    }
  },
  img: {
    height: "100%",
    position: "absolute",
    left: "-34px",
    right: 0,
    width: "calc(100% + 34px)"
  },
  poweredText: {
    color: "#000",
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "120%"
  },
  poweredBox: {
    marginTop: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    position: "relative",
    paddingBottom: "28px"
  },
  canImg: {
    marginLeft: "8px",
    width: "100px",
    height: "17px"
  },
  tempImageWrapper: {
    boxSizing: "border-box",
    "-moz-box-sizing": "border-box",
    "-webkit-box-sizing": "border-box",
    width: "100%",
    height: "100%"
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover"
  },
  poweredByLogo: {
    display: "none"
  },
  back: {
    width: 48,
    height: 48,
    cursor: "pointer",

    "@media (max-width: 767.98px)": {
      width: 24,
      height: 24
    }
  },
  carouselWrapper: {
    position: "fixed",
    top: 0,
    right: 0,
    width: "50%",
    bottom: 0
  },
  wrapper: {
    width: '810px',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0px",
    // position: 'sticky',
    // top: '20px',
    // zIndex: 999999999999,
  },
  breadcrumbItem: {
    cursor: 'pointer',
    '&:hover $inActiveTitle': {
      color: '#000',
    },
  },
  flexCenter: {
    display: "flex",
    alignItems: "center"
  },
  btn1: {
    height: '32px',
    width: '32px',
    backgroundColor: '#000',
    color: '#FFF',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn2: {
    height: '32px',
    width: '32px',
    backgroundColor: '#94A3B8',
    color: '#FFF',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  activeTitle: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: '16px',
    color: '#000',
  },
  progressStepTitle: {
    marginLeft: "5px",
  },
  inActiveTitle: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: '16px',
    color: '#94A3B8',
  },
  bar1: {
    height: '4px',
    width: '80px',
    background: '#000',
    marginRight: '5px',
    borderRadius: '20px',
  },
  bar2: {
    height: '4px',
    width: '80px',
    background: '#94A3B8',
    marginRight: '5px',
    borderRadius: '20px',
  },
  bar2Transparent: {
    height: '4px',
    width: '80px',
    border: '1px solid #94A3B8',
    marginRight: '5px',
    borderRadius: '20px',
  },
  barWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: "10px"
  },
  autoFillChipWrapper: {
    position: 'absolute',
    top: '70px',
    left: 0,
    width: '100%',
    height: '104px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'visible',
    zIndex: 9,
  },
  autoFillChipWrapper2: {
    zIndex: -1,
  },
  autoFillChip: {
    opacity: 0,
    width: '810px',
    borderRadius: '8px',
  },
  autoFillChipAnimation: {
    transition: 'all .3s ease-out',
  },
  autoFillChipHide: {
    opacity: 0,
    transform: 'translate(-8px, -104px)',
    boxShadow: '0px 0px 0px 0px #00000000',
  },
  autoFillChipShow: {
    opacity: 1,
    transform: 'translate(-8px, 0px)',
    boxShadow: '0px 24px 64px 0px #00000026',
  }
}));