export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const close = require("../assets/close.svg").default;
export const copy = require("../assets/copy.svg").default;
export const thinkigRobo = require("../assets/thinkigRobo.svg").default;
export const answerReadyRobo = require("../assets/answerReadyRobo.svg").default;
export const selectedBookmark = require("../assets/selectedBookmark.svg")
  .default;
export const bookmsrkGrey = require("../assets/bookmsrkGrey.svg").default;
export const like = require("../assets/like.svg").default;
export const dislike = require("../assets/dislike.svg").default;
export const bookmark = require("../assets/bookmark.svg").default;
export const send = require("../assets/send.svg").default;
export const pdf = require("../assets/pdf.svg").default;
export const word = require("../assets/word.svg").default;
export const atSign = require("../assets/atSign.svg").default;
export const download = require("../assets/download.svg").default;
export const maximize = require("../assets/maximize.svg").default;
export const plusIcon = require("../assets/plusIcon.svg").default;
export const share = require("../assets/share.svg").default;
export const questionIcon = require("../assets/questionIcon.svg").default;
export const d1 = require("../assets/d1.svg").default;
export const retry = require("../assets/retry.svg").default;
export const robo1 = require("../assets/robo1.svg").default;
export const openInNew = require("../assets/openInNew.svg").default;
export const swapCalls = require("../assets/swapCalls.svg").default;
export const bookmark2 = require("../assets/bookmark2.svg").default;
export const delete2 = require("../assets/delete2.svg").default;
export const rightBar = require("../assets/rightBar.svg").default;
export const chatBubble = require("../assets/chatBubble.svg").default;
export const searchIcon = require("../assets/searchIcon.png");
export const conversation = require("../assets/conversation.svg").default;
export const calender = require("../assets/calender.svg").default;
export const clock = require("../assets/clock.svg").default;
export const selectBtn = require("../assets/selectBtn.svg").default;
export const workflowDelete = require("../assets/workflowDelete.svg").default;
export const checkedIcon = require("../assets/checkbox_checked.svg").default;
export const checkIcon = require("../assets/checkbox.svg").default;
export const bookmarkRed = require("../assets/bookmarkRed.svg").default;
export const chatBlue = require("../assets/chatBlue.svg").default;
export const openInNewBlue = require("../assets/openInNewBlue.svg").default;
export const historyTime = require("../assets/historyTime.svg").default;
export const arrowDown = require("../assets/arrowDown.svg").default;
export const arrowUp = require("../assets/arrowUp.svg").default;
export const historyTimeGreen = require("../assets/historyTimeGreen.svg")
  .default;
export const historyTimeBlue = require("../assets/historyTimeBlue.svg").default;
export const historyTimeRed = require("../assets/historyTimeRed.svg").default;
export const emailGreen = require("../assets/emailGreen.svg").default;
export const liked = require("../assets/liked.svg").default;
export const disliked = require("../assets/disliked.svg").default;
