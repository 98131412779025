import React, { CSSProperties } from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
  Backdrop,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import CompanyIntelController, {
  Props
} from "./CompanyIntelController";
import { Edit, InsertDriveFile } from "@material-ui/icons";
import { deleteIcon, searchIcon, uploadIcon, viewcircle_ } from "./assets";
import UploadCompanyDocument from "./UploadCompanyDocument.web";
import CompanyIntelDeletePopup from "../../../components/main/CompanyIntelDeletePopup";
import UploadClosePopup from "../../../components/main/UploadClosePopup";
import { convertFromBytes } from "../../../helpers/file";
import ConfirmationModal from "../../../components/main/ConfirmationModal";

export default class CompanyIntel extends CompanyIntelController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <Backdrop
          style={{ color: '#fff', zIndex: 9999 }}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Container style={webStyle.mainContainer}>
          <Box style={webStyle.mainBox}>
            <Box style={webStyle.uploadContainer}>
              <Typography variant="h1">
                Company intel
              </Typography>
              <Box style={webStyle.tabContainer}>
                <Button
                  style={this.state.isAboutCompany
                    ? webStyle.activeAction
                    : webStyle.activeButton
                  }
                  onClick={this.handleActiveTab1}
                >
                  About your company
                </Button>
                <Button
                  style={!this.state.isAboutCompany
                    ? webStyle.activeAction
                    : webStyle.activeButton
                  }
                  onClick={this.handleActiveTab2}
                >
                  Target audience
                </Button>
              </Box>
            </Box>
            <Box style={webStyle.uploadContainer}>
              <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', gap: '8px' }}>
                <Button 
                  style={webStyle.CompanyIntelButton} 
                  onClick={this.handelIsOpenUpload}
                >
                  Upload new
                  <img src={uploadIcon} alt="" style={webStyle.iconBtn} />
                </Button>
                <Button 
                  style={webStyle.CompanyIntelButton} 
                  onClick={this.handleOnboarding}
                >
                  Edit onboarding
                  <Edit style={webStyle.iconBtn} />
                </Button>
              </Box>
              <Box style={{ width: '100%' }}>
                <TextField 
                  type="search"
                  placeholder="Search documents"
                  variant="outlined"
                  value={this.state.serchFile}
                  onChange={(e) => {
                    if (this.state.isSubscriptionActive) {
                      this.handelSearchTextCompanyIntel(e.target.value)
                    } else {
                      this.setState({
                        showActionBlockedModal: true
                      })
                    }
                  }}
                  style={{ width: '100%' }}
                  InputProps={{
                    style: webStyle.textCompanyInput,
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => this.getDocumentList(this.state.serchFile, this.state.isAboutCompany)}
                      >
                        <img 
                          src={searchIcon} 
                          alt="search" 
                          style={{ cursor: 'pointer' }} 
                        />
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box style={webStyle.listMainContainer as CSSProperties}>
            {!this.state.loading && this.state.fetching && (
              <Box 
                style={{ 
                  width: '100%', 
                  height: '50vh', 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  position: 'absolute',
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
            {!this.state.loading && !this.state.fetching && !Object.entries(this.state.isAboutCompany ? this.state.companyDoc : this.state.targetDoc).length && (
              <Box 
                style={{ 
                  width: '100%', 
                  height: '50vh', 
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center',
                  position: 'absolute',
                }}
              >
                <Typography
                  style={{ 
                    fontSize: '16px',
                    fontWeight: '600',
                    lineHeight: '19px'
                  }}
                >
                  No data
                  {/* {this.state.serchFile.length
                    ? 'No results'
                    : 'No data'
                  } */}
                </Typography>
              </Box>
            )}
            {Object.entries(this.state.isAboutCompany ? this.state.companyDoc : this.state.targetDoc).map(([key, items]: any, index: number) => (
              <Box key={index} style={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
                <Typography style={{ fontSize: '16px', fontWeight: '600', lineHeight: '19px' }}>
                  {key}
                </Typography>
                {items.map((item: any, index: any) => {
                  return (
                    <>
                      <Box key={index + item.filename} style={webStyle.listContainer}>
                        <Box style={{ width: '50%', display: 'flex' }}>
                          <Box style={{ display: "flex", }}>
                            <InsertDriveFile
                              style={{
                                width: "40px",
                                marginRight: '4px',
                                height: "40px",
                                color: this.getColor(item.filename),
                              }}
                            />
                          </Box>
                          <Box style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Typography noWrap style={{ maxWidth: '90%', fontSize: '16px', fontWeight: '600', lineHeight: '19px' }}>
                              {item.filename}
                            </Typography>
                            <Box style={{ display: 'flex', alignItems: 'center', columnGap: '8px', marginTop: '4px' }}>
                              <Box style={webStyle.spanText}>
                                {item.contentType.toUpperCase()} • {convertFromBytes(item.attachmentSize)}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box style={{ width: '40%', display: 'flex', justifyContent: 'flex-start' }}>
                          <Typography style={webStyle.fileTags}>{item.informationTypeDescription}</Typography>
                        </Box>
                        <IconButton>
                          <img 
                            src={deleteIcon} 
                            alt="remove" 
                            style={webStyle.iconBtn2} 
                            onClick={this.handleOpenDeletePop.bind(this, item.id)} 
                          />
                        </IconButton>
                      </Box>
                    </>
                  )
                })}
              </Box>
            ))}
            {/* {this.state.visibleItems < this.state.targetDoc?.length &&
              <Button style={webStyle.viewButton} onClick={this.handleViewMoreTarget}>View More</Button>
            } */}
          </Box>
        </Container>

        <ConfirmationModal
          open={this.state.showActionBlockedModal}
          title={'To proceed, you have to be subscribed'}
          onClose={() => this.setState({ showActionBlockedModal: false })}
          reversedButtons
          confirmBtnText={'Subscribe later'}
          rejectBtnText={'Go to subscriptions'}
          onConfirm={() => this.setState({ showActionBlockedModal: false })}
          onReject={() => this.props.navigation.navigate('/subscriptions')}
        />

        <UploadCompanyDocument
          isOpen={this.state.isOpen}
          handleOpenUploadClosePop={this.handleOpenUploadClosePop}
          uploadMultipleCompanyFile={this.uploadMultipleCompanyFile}
          isAboutCompany={this.state.isAboutCompany}
          toggleInfoDialog={this.toggleInfoDialog}
          onChangeUploadFile={this.onChangeUploadFile}
          docFiles={this.state.docFiles}
          fileTypeTarget={this.state.fileTypeTarget}
          fileTypeCompany={this.state.fileTypeCompany}
          isImports={this.state.isImports}
          handleChangeFileInfomation={this.handleChangeFileInfomation}
          getColor={this.getColor}
          removeFileWeb={this.removeFileWeb}
          error={this.state.error}
          infoDialog={this.state.infoDialog}
        />

        <CompanyIntelDeletePopup
          open={this.state.isOpenDeletePop}
          handleCloseDeletePop={this.handleCloseDeletePop}
          deleteCompanyDocList={this.deleteCompanyDocList}
        />

        <UploadClosePopup
          open={this.state.isUploadClosePop}
          handleCloseUploadClosePop={this.handleCloseUploadClosePop}
          handleDeleteUploadPopup={this.handleDeleteUploadPopup}
        />
      </>
    );
  }
}

const webStyle = {
  mainContainer: {
    width: '100%',
    maxWidth: '100%',
    padding: '60px',
  },
  mainBox: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: '60px',
  },
  uploadContainer: {
    width: '50%',
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: "20px",
  },
  tabContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    border: "1px solid #CBD5E1",
    borderRadius: "80px",
  },
  CompanyIntelButton: {
    width: '100%',
    minHeight: "44px",
    padding: '10px 16px',
    borderRadius: '8px',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#000',
    textTransform: 'none' as 'none',
    border: '1px solid #CBD5E1',
  },
  textCompanyInput: {
    // maxWidth: "490px",
    width: "100%",
    borderRadius: '8px',
    border: '1px solid  #94A3B8',
    padding: '8px 14px',
    alignItems: 'center',
    height: '40px'
  },
  listMainContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '40px',
    paddingTop: '40px',
    position: 'relative'
  },
  listContainer: {
    padding: '16px 0px',
    alignItems: 'center',
    borderBottom: '1px solid #CBD5E1',
    display: 'flex',
    justifyContent: 'space-between'
  },
  iconBtn: {
    width: "20px",
    height: "20px",
    marginLeft: '8px'
  },
  iconBtn2: {
    width: "24px",
    height: "24px",
    cursor: 'pointer'
  },
  spanText: {
    color: '#475569',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '14px',
    marginRight: '3px',
    textTransform: "uppercase" as "uppercase"
  },
  imgicon: {
    width: "24px",
    height: "32px",
    marginRight: "7px"
  },
  activeButton: {
    textTransform: 'none' as 'none',
    padding: '8px 26px',
    alignItems: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '150%',
    borderRadius: '80px',
    color: 'black'
  },
  activeAction: {
    textTransform: 'none' as 'none',
    padding: '8px 26px',
    alignItems: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '150%',
    borderRadius: '80px',
    background: '#000',
    color: '#FFF',
  },
  viewCricle: {
    width: "3px",
    height: "3px",
    marginRight: "3px"
  },
  viewButton: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#000",
    textDecorationLine: "underline",
    textTransform: "capitalize" as "capitalize",
    marginTop: "15px"
  },
  fileTags: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '21px',
    color: '#22507B',
    padding: '6px 16px',
    background: '#E2E8F0',
    borderRadius: '26px',
  }
};

