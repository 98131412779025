import { runEngine } from "../../../framework/main/RunEngine";
import { Block } from "../../../framework/main/Block";
import { IBlock } from "../../../framework/main/IBlock";
import { Message } from "../../../framework/main/Message";
import MessageEnum from "../../../framework/main/Messages/MessageEnum";
import { getName } from "../../../framework/main/Messages/MessageEnum";
import NavigationMessage from "../../../framework/main/Messages/NavigationMessage";
import { v4 as uuidv4 } from 'uuid';

export default class OtpToNewPasswordAdapter {
  send: (message: Message) => void;
  context: any;

  constructor(context) {
    this.context = context;

    const blockId = uuidv4();
    this.send = (message) => runEngine.sendMessage(blockId, message);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.NavigationNewPasswordMessage),
    ]);
  }
  convert(from: Message): Message {
    const to = new Message(getName(MessageEnum.NavigationMessage));

    to.addData(getName(MessageEnum.NavigationTargetMessage), "NewPassword");
    to.addData(
      getName(MessageEnum.NavigationPropsMessage),
      {
        ... from.getData(getName(MessageEnum.NavigationPropsMessage)),
        ...this.context
      }
    );

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      from.getData(getName(MessageEnum.AuthTokenDataMessage))
    );

    to.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    return to;
  }
  receive(from: string, message: Message): void {
    this.send(this.convert(message));
  }
}
