import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography
} from "@material-ui/core";
import {
  WithStyles,
  createStyles,
  withStyles
} from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import React from "react";

interface Props extends WithStyles<any> {
  open: boolean;

  onClose(): void;
  onClear(): void;
  onCancel(): void;
}

const styles = () =>
  createStyles({
    content: {
      position: "relative",
      padding: "40px !important"
    },

    closeIcon: {
      position: "absolute",
      right: 32,
      top: 30
    },

    description: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "34px",
      letterSpacing: "-0.005em",
      textAlign: "center"
    },

    subDescription: {
      marginTop: 12,
      fontSize: 16,
      fontWeight: 600,
      lineHeight: "19px",
      textAlign: "center",
      color: "#475569"
    },

    highlight: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "34px",
      letterSpacing: "-0.005em",
      textAlign: "center",
      color: "#DC2626"
    },

    action: {
      marginTop: 32,
      display: "flex",
      alignItems: "center",
      gap: 8
    },

    deleteButton: {
      color: "#FFFFFF",
      border: "1px solid #DC2626",
      backgroundColor: "#DC2626",
      padding: "10px 16px",
      borderRadius: 8,
      cursor: "pointer",
      flex: 1,
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "19.2px"
    },

    backButton: {
      backgroundColor: "#FFFFFF",
      border: "1px solid #CBD5E1",
      color: "#000000",
      padding: "10px 16px",
      borderRadius: 8,
      cursor: "pointer",
      flex: 1,
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "19.2px"
    }
  });

export class ClearAllInformationDialog extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, open, onClose, onClear, onCancel } = this.props;

    return (
      <>
        <Dialog
          open={open}
          className={classes.dialog}
          PaperProps={{
            style: { borderRadius: 20, maxWidth: 848, width: "100%" }
          }}
        >
          <DialogContent className={classes.content}>
            <IconButton className={classes.closeIcon} onClick={onClose}>
              <Close />
            </IconButton>

            <Typography component="p" className={classes.description}>
              Are you sure you want to <br />{" "}
              <Typography component="span" className={classes.highlight}>
                clear
              </Typography>{" "}
              all the information ?
            </Typography>

            <Typography component="p" className={classes.subDescription}>
              This action cannot be undone.
              <br />
              All of your data will be deleted permanently.
            </Typography>

            <Box className={classes.action}>
              <button
                className={classes.backButton}
                onClick={onCancel}
                data-test-id="cancel-btn"
              >
                Cancel
              </button>
              <button
                data-test-id="clear-info-btn"
                className={classes.deleteButton}
                onClick={onClear}
              >
                Clear all info
              </button>
            </Box>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(ClearAllInformationDialog);
