import React, { FC, createContext } from 'react';
import { SnackbarProvider } from 'notistack';
import Success from '../components/main/Notifications/Success';
import Error from '../components/main/Notifications/Error';
import Info from '../components/main/Notifications/Info';

export const notificationContext = createContext({});

export const NotificationProvider = ({ children }) => {
  return (
    <SnackbarProvider
      Components={{
        success: Success,
        error: Error,
        info: Info,
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      // preventDuplicate
    >
      {children}
    </SnackbarProvider>
  );
};
