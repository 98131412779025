export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const threeDots = require("../assets/threedots.png");
export const folder = require("../assets/folder.png");
export const file = require("../assets/file.png");
export const backButton = require("../assets/Backbtn.png");
export const folderThreeDot = require("../assets/folderThreeDots.png");
export const deleteIcon = require("../assets/button_delete.svg").default;
export const uploadIcon = require("../assets/upload.svg").default;
export const searchIcon = require("../assets/searchIcon.png");
export const viewcircle_ = require("../assets/viewcircle_.png");
