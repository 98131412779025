import React from "react";

import {
  Box,
  Button,
  Container,
  Dialog,
  Typography,
  createStyles,
  withStyles
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

interface IPropsPopup {
  handleCloseDeletePop: () => void;
  open?: any;
  classes?: any;
  deleteCompanyDocList: () => void;
}

const CompanyIntelDeletePopup = ({
  classes,
  open,
  handleCloseDeletePop,
  deleteCompanyDocList,
}: IPropsPopup) => {
  return (
    <Dialog
      PaperProps={{
        style: { borderRadius: "20px", width: "670px", padding: "40px" }
      }}
      maxWidth="lg"
      open={open}
    >
      <Box
        data-testid="closeModalBtn"
        className={classes.closeBtn}
        onClick={handleCloseDeletePop}
      >
        <Close />
      </Box>
      <Container maxWidth={"sm"} classes={classes.mainContainer}>
        <Typography
          style={{ fontSize: '24px', fontWeight: '700', lineHeight: '33px', textAlign: 'center' }}
        >
          Are you sure you want to
        </Typography>
        <Typography 
          style={{ fontSize: '24px', fontWeight: '700', lineHeight: '33px', textAlign: 'center' }}
        >
          delete this company intel document?
        </Typography>
        <Box className={classes.btnContainer}>
          <Button
            fullWidth
            className={classes.canlBtn}
            onClick={handleCloseDeletePop}
          >
            Cancel
          </Button>
          <Button
            fullWidth
            onClick={e => {
              e.preventDefault();
              deleteCompanyDocList();
              // handleCloseDeletePop();
            }}
            className={classes.delBtn}
          >
            Delete
          </Button>
        </Box>
      </Container>
    </Dialog>
  );
};

const styles = createStyles({
  mainContainer: {
    padding: "40px",
    display: "flex",
    flexDirection: "column",
    width: "500px"
  },
  closeBtn: {
    position: "absolute",
    right: "40px",
    top: "40px",
    cursor: "pointer"
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "32px"
  },
  canlBtn: {
    marginRight: "15px",
    color: "#000",
    border: "1px solid #CBD5E1",
    textTransform: "none" as "none",
    fontSize: "16px",
    fontWeight: 600
  },
  delBtn: {
    color: "#DC2626",
    border: "1px solid #DC2626",
    textTransform: "none" as "none",
    fontSize: "16px",
    fontWeight: 600
  }
});

export default withStyles(styles)(CompanyIntelDeletePopup);
