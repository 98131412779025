import { Component } from "react";

import { AppContext } from './context';

import NavigationBlock from "../framework/main/Blocks/NavigationBlock";
import SingletonFactory from "../framework/main/SingletonFactory";

import AlertBlock from "../blocks/alert/main/AlertBlock";
import HomeScreenAdapter from "../blocks/adapters/main/HomeScreenAdapter";
import InfoPageAdapter from "../blocks/adapters/main/InfoPageAdapter";
import AlertPageWebAdapter from "../blocks/adapters/main/AlertPageWebAdapter";
import PrivacyPolicyAdapter from "../blocks/adapters/main/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../blocks/adapters/main/TermsAndConditionAdapter";
import SplashScreenAdapter from "../blocks/adapters/main/SplashScreenAdapter";
import SocialMediaLogInAdapter from "../blocks/adapters/main/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../blocks/adapters/main/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../blocks/adapters/main/EmailAccountSignUpAdapter";
import EmailSignUpAdapter from "../blocks/adapters/main/EmailSignupAdapter";
import EmailSignupOtpAdapter from "../blocks/adapters/main/EmailSignupOtpAdapter";
import AccountAboutAdapter from "../blocks/adapters/main/AccountAboutAdapter";
import AccountCreationAdapter from "../blocks/adapters/main/AccountCreationAdapter";
import ForgotPasswordAdapter from "../blocks/adapters/main/ForgotPasswordAdapter";
import EmailSuccessAdapter from "../blocks/adapters/main/EmailSuccessAdapter";
import MobilePhoneToOTPAdapter from "../blocks/adapters/main/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../blocks/adapters/main/OtpToNewPasswordAdapter";
import MobilePhoneLogInAdapter from "../blocks/adapters/main/MobilePhoneLogInAdapter";
import MobilePhoneToAdditionalDetailsAdapter from "../blocks/adapters/main/MobilePhoneToAdditionalDetailsAdapter";
import ResetSuccessAdapter from "../blocks/adapters/main/ResetSuccessAdapter";
import WelcomeScreenAdapter from "../blocks/adapters/main/WelcomeScreenAdapter";
import VerifySignUpCodeScreenAdapter from "../blocks/adapters/main/VerifySignUpCodeScreenAdapter";
import LetsGetStartedAdapter from "../blocks/adapters/main/LetsGetStartedApapter";
import PasswordRecoverySuccessAdapter from "../blocks/adapters/main/PasswordRecoverySuccessAdapter";
import PasswordRecoveryAdapter from "../blocks/adapters/main/PasswordRecoveryAdapter";


class InstanceProvider extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    new SocialMediaLogInAdapter(this.context);
    new EmailAccountLogInAdapter(this.context);
    new EmailAccountSignUpAdapter(this.context);
    new EmailSignupOtpAdapter(this.context);
    new AccountAboutAdapter(this.context);
    new AccountCreationAdapter(this.context);
    new ForgotPasswordAdapter(this.context);
    new MobilePhoneToOTPAdapter(this.context);
    new OtpToNewPasswordAdapter(this.context);
    new MobilePhoneLogInAdapter(this.context);
    new MobilePhoneToAdditionalDetailsAdapter(this.context);
    new ResetSuccessAdapter(this.context);
    new WelcomeScreenAdapter(this.context);
    new VerifySignUpCodeScreenAdapter(this.context);
    new LetsGetStartedAdapter(this.context);
    new PasswordRecoverySuccessAdapter(this.context);
    new PasswordRecoveryAdapter(this.context);
    new PrivacyPolicyAdapter(this.context);
    new TermsAndConditionAdapter(this.context);
    new SplashScreenAdapter(this.context);
    new EmailSuccessAdapter(this.context);
    new EmailSignUpAdapter(this.context);
    new AlertBlock(this.context);
    new NavigationBlock(this.context);
    new HomeScreenAdapter(this.context);
    new InfoPageAdapter(this.context);
    new AlertPageWebAdapter(this.context);

    SingletonFactory.getRestBlockInstance();
    SingletonFactory.getSessionBlockInstance();
    SingletonFactory.getUserManagerInstance();
  }

  render() {
    return this.props.children
  }
}

InstanceProvider.contextType = AppContext;

export { InstanceProvider };
