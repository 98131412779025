import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  currentPlan: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    borderRadius: '20px',
    background: '#F8FAFC',
    gap: '5px'
  },
  currentPlanBenefits: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginTop: '10px',
  },
  benefitsLeftSidebar: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  navigationButtons: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '28px',
    paddingTop: '28px',
    marginTop: '12px',
    borderTop: '1px solid #CBD5E1'
  },
  footerOtherOptions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
}));