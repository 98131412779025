import React from "react";
import Layout from "../../../components/main/Layout";
import ForgotPwdController, { Props } from "./ForgotPwdController";
import * as Yup from "yup";
import {
  Container,
  Box,
  Button,
  Typography,
  Paper,
  createStyles
} from "@material-ui/core";
import CarouselDisplay from "../../../blocks/carouseldisplay/main/CarouselDisplay.web";

export default class PasswordRecovery extends ForgotPwdController {
  constructor(props: Props) {
    super(props);
  }

  validationSchema = Yup.object({
    email: Yup.string()
      .email("Email address is not valid.")
      .required("Email address is required.")
  });

  render() {
    const { countdown } = this.state;
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;
    return (
      <Container style={PwdRecoveryPageStyles.FormContainerStyle}>
        <Paper style={PwdRecoveryPageStyles.forgotPwdForm}>
          <Box>
            <Typography
              align="center"
              style={PwdRecoveryPageStyles.PwdRecoveryTitle}
              variant="h6"
            >
              Password recovery
            </Typography>
            <Typography
              align="center"
              style={PwdRecoveryPageStyles.subHeadingtypographyStyle1}
              variant="h6"
            >
              Check your email
            </Typography>
            <Typography
              align="center"
              style={PwdRecoveryPageStyles.PwdRecoverySubtitle}
              variant="h6"
            >
              We have sent a password reset link to{" "}
              {window.localStorage.getItem("sign-up-email")}
            </Typography>
          </Box>

          <Box>
            <Button
              type="submit"
              data-test-id="forgotPasswordBtnDataTestId"
              style={
                countdown > 0
                  ? PwdRecoveryPageStyles.disableBtn
                  : PwdRecoveryPageStyles.submitBtn
              }
              onClick={() => {
                const email =
                  window.localStorage.getItem("sign-up-email") || "";
                this.resendPasswordApi(email);
              }}
              fullWidth
              disabled={countdown > 0}
            >
              Resend
            </Button>
            {countdown > 0 && (
              <Box>
                <Typography
                  align="center"
                  style={PwdRecoveryPageStyles.PwdRecoverySubtitle}
                  variant="h6"
                >
                  Will be active after {this.zeroPad(minutes)}:
                  {this.zeroPad(seconds)}
                </Typography>
              </Box>
            )}
          </Box>
        </Paper>
      </Container>
    );
  }
}

const PwdRecoveryPageStyles: any = createStyles({
  FormContainerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  paperStyle: {
    padding: "20px",
    borderRadius: "28px",
    height: "273px",
    background:
      "linear-gradient(151.76deg, rgba(255, 255, 255, 0) -34.68%, rgba(255, 255, 255, 0.8) 113.6%)"
  },
  PwdRecoveryTitle: {
    margin: "10px  0px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "30px"
  },
  PwdRecoverySubtitle: {
    // margin: '16px  0px',
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
    color: "rgba(71, 85, 105, 1)"
  },
  forgotPwdForm: {
    maxWidth: "520px",
    width: "100%",
    borderRadius: "28px",
    padding: "40px",
    background:
      "linear-gradient(144.88deg, rgba(255, 255, 255, 0.52) -7.94%, rgba(255, 255, 255, 0.79) 102.73%)",
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column"
  },
  inputFeildText: {
    fontWeight: 700
  },

  submitBtn: {
    margin: "25px 0px ",
    color: "white",
    backgroundColor: "black",
    height: "44px",
    marginRight: "5px",
    cursor: "pointer",
    borderRadius: "8px",
    border: " 1px solid rgba(203, 213, 225, 1)",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "14px"
  },
  disableBtn: {
    margin: "25px 0px ",
    color: "rgba(71, 85, 105, 1)",
    backgroundColor: "rgba(226, 232, 240, 1)",
    height: "44px",
    marginRight: "5px",
    cursor: "pointer",
    borderRadius: "8px",
    border: " 1px solid rgba(203, 213, 225, 1)",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "14px"
  },
  link: {
    cursor: "pointer",
    color: "#3A84C9"
  },
  subHeadingtypographyStyle1: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "16px"
  },
  inputFeilds: {
    margin: "15px 0px"
  }
});
