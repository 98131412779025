import { IBlock } from "../../../framework/main/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/main/Messages/MessageEnum";
import { Message } from "../../../framework/main/Message";
import { BlockComponent } from "../../../framework/main/BlockComponent";
import { runEngine } from "../../../framework/main/RunEngine";
import { v4 as uuidv4 } from "uuid";
import { WithStyles } from "@material-ui/core";
import StorageProvider from "../../../framework/main/StorageProvider";
import { deleteCompanyIntelData, getCompanyIntelAttachments, uploadCompanyIntelInformation } from "../../../redux/services/company-intel";
import { enqueueSnackbar } from "notistack";
import { getFileTypesData } from "../../../redux/services/common";
import moment from 'moment';
import { getCurrentSubscription } from "../../../redux/services/subscriptions";
import { getCurrentSubscriptionStatus } from "../../../helpers/subscription";

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation?: any;
  id?: string;
  isOpen: boolean;
  isAboutCompany: boolean;
  handleOpenUploadClosePop: () => void;
  isImports: boolean;
  docFiles: any;
  onChangeUploadFile: any;
  uploadMultipleCompanyFile: any;
  handleChangeFileInfomation: any;
  removeFileWeb: any;
  getColor: any;
  toggleInfoDialog: any;
  fileTypeTarget: any;
  fileTypeCompany: any;
  error: string;
  infoDialog: boolean;
  token?: string;
}

interface WebFile extends File {
  id: string;
  information_type: number | string;
}

interface S {
  errors: any;
  docFiles: WebFile[];
  loading: boolean;
  fetching: boolean;
  isOpen: boolean;
  highlightInfo: boolean;
  infoDialog: boolean;
  error: string;
  isAboutCompany: boolean;
  isOpenDeletePop: boolean;
  isUploadClosePop: boolean;
  companyDoc: any;
  targetDoc: any;
  listID: number;
  serchFile: string;
  visibleItems: number;
  fileTypeCompany: Array<{
    name: string;
    id: number;
  }>;
  fileTypeTarget: Array<{
    name: string;
    id: number;
  }>;
  showMessage: string;
  isImports: boolean;
  isSubscriptionActive: boolean;
  showActionBlockedModal: boolean;
}

interface SS {
  id: any;
}

export default class CompanyIntelController extends BlockComponent<Props, S, SS> {
  fetchDocumentList: string = "";
  deleteDocumentList: string = "";
  fetchCompanyFileTypeList: string = "";
  fetchTargetFileTypeList: string = "";
  maxFileSize = 26214400;
  token: string = "";
  timer: any = null;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      errors: "",
      loading: false,
      fetching: false,
      isOpen: false,
      highlightInfo: false,
      infoDialog: false,
      docFiles: [],
      error: "",
      isAboutCompany: true,
      isOpenDeletePop: false,
      isUploadClosePop: false,
      companyDoc: {},
      targetDoc: {},
      listID: 0,
      fileTypeCompany: [],
      fileTypeTarget: [],
      serchFile: '',
      visibleItems: 10,
      showMessage: '',
      isImports: false,
      isSubscriptionActive: false,
      showActionBlockedModal: false,
    };

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount = async () => {
    super.componentDidMount();

    const token = await StorageProvider.getItem("user-token");
    this.token = token;

    this.getDocumentList(this.state.serchFile, this.state.isAboutCompany);
    this.getCompanyFileTypeLists();
    this.getTargetFileTypeLists();
    this.handleIsSubscriptionActive();
  };

  handleIsSubscriptionActive = async () => {
    const { data } = await getCurrentSubscription();

    if (getCurrentSubscriptionStatus(data) !== 'EXPIRED') {
      this.setState({
        isSubscriptionActive: true
      })
    }
  }

  handleOnboarding = async () => {
    if (this.state.isSubscriptionActive) {
      await StorageProvider.setItem("isEdit", 'true');

      const message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'Onboarding'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      this.send(message);
    } else {
      this.setState({
        showActionBlockedModal: true
      })
    }
  }

  handleActiveTab1 = () => {
    this.setState({
      isAboutCompany: true,
      serchFile: '',
      companyDoc: {},
      targetDoc: {},
    });

    this.getDocumentList('', true);
  }

  handleActiveTab2 = () => {
    this.setState({
      isAboutCompany: false,
      serchFile: '',
      companyDoc: {},
      targetDoc: {},
    });

    this.getDocumentList('', false);
  }

  handleError() {
    this.setState({
      error: "An error occurred, please try again",
    });

    setTimeout(() => {
      this.setState({
        error: "",
      });
    }, 2000);
  }

  handelIsOpenUpload = async () => {
    if (this.state.isSubscriptionActive) {
      await StorageProvider.setItem("isEdit", 'true');

      this.setState({
        isOpen: !this.state.isOpen,
        docFiles: []
      })
    } else {
      this.setState({
        showActionBlockedModal: true
      })
    }
  }

  handelIsButtonDisable = () => {
    this.setState({
      isImports: !this.state.isImports,
      loading: !this.state.loading
    })
  }

  handleFinishUploading = async () => {
    this.setState({
      loading: true
    })

    this.handleDeleteUploadPopup();
    this.handleOpenUploadClosePop();
    this.getDocumentList(this.state.serchFile, this.state.isAboutCompany);
  };

  toggleHighlightInfo = () => {
    this.setState({
      highlightInfo: !this.state.highlightInfo,
    });
  };

  toggleInfoDialog = () => {
    this.setState({
      infoDialog: !this.state.infoDialog,
    });
  };

  handleOpenDeletePop = (id?: any) => {
    this.setState({ isOpenDeletePop: true, listID: id })
  }

  handleCloseDeletePop = () => {
    this.setState({ isOpenDeletePop: false })
  }

  handleOpenUploadClosePop = () => {
    this.setState({
      isOpen: false,
      // docFiles: [],
    });

    // if (this.state.docFiles.length === 0) {
    //   this.setState({
    //     isOpen: false,
    //   })
    // } else {
    //   this.setState({ isUploadClosePop: true })
    // }
  }

  handleCloseUploadClosePop = () => {
    this.setState({ isUploadClosePop: false })
  }

  handleDeleteUploadPopup = () => {
    this.setState({
      docFiles: [],
      isOpen: false,
      isUploadClosePop: false,
    })
  }

  // handleViewMoreCompany = () => {
  //   this.setState({
  //     visibleItems: this.state.companyDoc?.length
  //   })
  // };

  // handleViewMoreTarget = () => {
  //   this.setState({
  //     visibleItems: this.state.targetDoc?.length
  //   })
  // };

  closeToasts = () => {
    this.setState({
      showMessage: "",
    });
  };

  onChangeUploadFile = (files?: FileList, erroneous?: { file: File, message: string }[]) => {
    this.setState({
      error: ''
    });

    if (files.length > 10 || this.state.docFiles.length >= 10) {
      this.setState({
        error: 'You can upload 10 documents max'
      });
    } else {
      let tempFiles: WebFile[] = [];

      Array.from(files).forEach((_file) => {
        const willAddfiles: any = _file;
  
        willAddfiles.information_type = 0;
        tempFiles.push(willAddfiles as unknown as WebFile);
      });
  
      this.setState({
        docFiles: [...this.state.docFiles, ...tempFiles],
      });
  
      if (erroneous.length) {
        let errors = '';
  
        erroneous.forEach(item => {
          errors += `${item.message} \n`
        });
  
        this.setState({
          error: errors
        });
      }
    }

    setTimeout(() => {
      this.setState({
        error: ''
      });
    }, 5000);
  };

  getColor = (name: string) => {
    if (name.includes(".pdf")) {
      return "#E04E4E";
    }

    if (name.includes(".docx")) {
      return "#3166ED";
    }

    return "#00B84A";
  };

  removeFileWeb = (fileId: string) => {
    this.setState({
      docFiles: this.state.docFiles.filter((_file) => _file.id !== fileId),
    });
  };

  uploadMultipleCompanyFile = () => {
    this.handleOpenUploadClosePop();
    this.handleCloseDeletePop();
    this.handleCloseUploadClosePop();

    this.setState({
      loading: true
    });

    const uploadFiles = this.state.docFiles;

    if (!uploadFiles.length) {
      const message = "Please attach file.";

      this.setState({
        error: message,
      });

      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);

      // enqueueSnackbar(
      //   message,
      //   { variant: 'error' }
      // );

      return;
    }

    const promises = uploadFiles.map(async file => {
      return await uploadCompanyIntelInformation({
        file,
        type: this.state.isAboutCompany
          ? this.state.fileTypeCompany.find(item => item.id === file.information_type).name
          : this.state.fileTypeTarget.find(item => item.id === file.information_type).name
      });
    });

    Promise.allSettled(promises).then(res => {
      const erroneous = res.filter((item: any) => 'error' in item.value);
      const successful = res.filter((item: any) => 'data' in item.value && !('error' in item.value));

      if (successful.length) {
        enqueueSnackbar(
          `${successful.length} company intel document(s) uploaded successfully`,
          { variant: 'success' }
        );
      }

      if (erroneous.length) {
        enqueueSnackbar(
          `${erroneous.length} company intel document(s) failed in the uploading process`,
          { variant: 'error' }
        );
      }

      this.getDocumentList(this.state.serchFile, this.state.isAboutCompany);
    });
  };

  getDocumentList = async (search?: string, isAboutCompany?: boolean) => {
    this.setState({
      fetching: true,
    });

    const { data, error } = await getCompanyIntelAttachments({
      fileName: search,
      fileTypeDescription: isAboutCompany ? 'COMPANY' : 'AUDIENCE'
    });

    if (!error && data && Array.isArray(data)) {
      const today = moment().startOf('day');
      const yesterday = moment().subtract(1, 'days').startOf('day');

      const groupedData = Object.fromEntries(
        Object.entries(
          data.reduce((acc, item) => {
            const updatedAt = moment(item.updatedAt);
            let key;

            if (item.updatedAt) {
              if (updatedAt.isSame(today, 'd')) {
                key = 'Today';
              } else if (updatedAt.isSame(yesterday, 'd')) {
                key = 'Yesterday';
              } else {
                key = updatedAt.format('DD MMMM YYYY');
              }
            } else {
              key = 'Unknown';
            }

            if (!acc[key]) {
              acc[key] = [];
            }

            acc[key].push(item);
            return acc;
          }, {})
        ).reverse()
      );

      this.setState({
        companyDoc: groupedData,
        targetDoc: groupedData,
        loading: false,
        fetching: false,
        docFiles: []
      })
    } else {
      this.setState({
        companyDoc: {},
        targetDoc: {},
        loading: false,
        fetching: false,
        docFiles: []
      })
    }
  }

  deleteCompanyDocList = async () => {
    this.handleOpenUploadClosePop();
    this.handleCloseDeletePop();
    this.handleCloseUploadClosePop();

    this.setState({
      loading: true
    });

    const { data } = await deleteCompanyIntelData(this.state.listID);

    if (data && 'error' in data) {
      enqueueSnackbar(
        `Document deletion failed`,
        { variant: 'error' }
      );
    } else {
      enqueueSnackbar(
        `Document deleted successfully`,
        { variant: 'success' }
      );
    }

    this.getDocumentList(this.state.serchFile, this.state.isAboutCompany);
  }


  getCompanyFileTypeLists = async () => {
    const { data } = await getFileTypesData({ file_type: "About your company" });

    if (Array.isArray(data)) {
      const fileTypeCompany = data.map((type: any) => ({
        id: type.id,
        name: type.description,
      }));

      fileTypeCompany.unshift({
        id: 0,
        name: "Choose file type",
      });

      this.setState({
        fileTypeCompany,
      });
    }
  }

  getTargetFileTypeLists = async () => {
    const { data } = await getFileTypesData({ file_type: "Target audience" });

    if (Array.isArray(data)) {
      const fileTypeTarget = data.map((type: any) => ({
        id: type.id,
        name: type.description,
      }));

      fileTypeTarget.unshift({
        id: 0,
        name: "Choose file type",
      });

      this.setState({
        fileTypeTarget,
      });
    }
  }

  handleChangeFileInfomation = (fileId: string, value: number) => {
    const index = this.state.docFiles.findIndex((file) => file.id === fileId);
    if (index < 0) {
      return;
    }
    const newWebFiles = [...this.state.docFiles];

    newWebFiles[index].information_type = value;

    this.setState({
      docFiles: newWebFiles,
    });

  };

  handelSearchTextCompanyIntel(value: string) {
    this.setState({
      serchFile: value,
    });

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.getDocumentList(value, this.state.isAboutCompany)
    }, 500);
  }

  skipNow = async () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Onboarding"
    );
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );

    this.send(message);
  };
}
