import { IBlock } from "../../../framework/main/IBlock";
import { BlockComponent } from "../../../framework/main/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/main/Messages/MessageEnum";
import { runEngine } from "../../../framework/main/RunEngine";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  title: string;
  inputList?: any;
  serviceArray?: any;
  btnDisplay?: boolean;
  buttonName: string;
  addItem?: any;
  handleChange?: any;
  tooltipData?: string;
  touched?: any;
  values?: any;
  errors?: any;
  onChangeTitle?: (event: any, index: number) => void;
  onChangeDescription?: (event: any, index: number) => void;
  secondList?: any[];
  thirdList?: any[];
  secondTitle: string;
  thirdTitle: string;
}

interface S {
}

interface SS {
  id: any;
}

export default class SuccessStoriesController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    //
  }

}
