import React from 'react';
import { Modal, Box, IconButton, Typography, Button } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';
import PaymentMethod from '../../../SettingsModal/components/PlanAndBilling/components/PaymentMethod';
import { formatCurrency, getCurrencySybmol } from '../../../../../helpers/curency';

import { useStyles } from './styles';

interface Props {
  open: boolean;
  order: any;
  redirect?: boolean;
  onClose: () => void;
}

const PaymentModal: React.FC<Props> = (props) => {
  const {
    open,
    order,
    redirect = true,
    onClose,
  } = props;

  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose} className='MuiDialog-root'>
      <Box className={classes.modalBox}>
        <IconButton onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
        <Typography style={{ width: '100%', fontSize: '30px', fontWeight: '700', lineHeight: '32px', textAlign: 'left' }}>
          {order?.name}
          <Typography style={{ fontSize: '24px', fontWeight: '500', lineHeight: '32px', color: '#64748B' }}>
            {getCurrencySybmol(order?.currency)} {formatCurrency(order?.amount)}
          </Typography>
        </Typography>
        <PaymentMethod
          order={order}
          redirect={redirect}
          onClose={onClose}
        />
      </Box>
    </Modal>
  );
};

export default PaymentModal;
