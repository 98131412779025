import * as Yup from "yup";

export const generateValidationRules = (
  name,
  element,
  parentElement,
  ignoreRequired = false
) => {
  const shape = {};

  // set basic validation and type
  switch (element.type) {
    case "TEXT_INPUT":
    case "TEXT_AREA":
    case "DROP_DOWN":
    case "SWITCH_SELECTOR":
    case "GROUPED_SWITCH_SELECTOR":
    case "OPT_SLIDER_SELECTOR":
      shape[name] = Yup.string();
      break;
    case "INT_NUMBER_INPUT":
      shape[name] = Yup.number().nullable();
      break;
    case "MULTI_SELECTOR":
      shape[name] = Yup.array().of(Yup.string());
      break;
    case "FILE_UPLOAD":
    case "BOX_SWITCH_SELECTOR":
      shape[name] = Yup.object();
      break;
    case "URL_INPUT":
      shape[name] = Yup.string().matches(
        /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}\/?.*$/,
        "Enter a valid URL"
      );
      break;
    default:
      break;
  }

  // set field is required
  if (!ignoreRequired) {
    if (
      !(
        parentElement.attributes.find((item) => item.type === "OPTIONAL_ATTRIBUTE") || 
        element.attributes.find((item) => item.type === "OPTIONAL_ATTRIBUTE")
      )
    ) {
      shape[name] = shape[name].required("This field is required");
    }
  }

  // handle other validation types
  element.attributes.forEach((attr) => {
    switch (attr.type) {
      case "MIN_LENGTH_ATTRIBUTE":
        shape[name] = shape[name].min(
          attr.value,
          `Min ${attr.value} ${attr.value > 1 ? "characters" : "character"}`
        );
        break;
      case "MAX_LENGTH_ATTRIBUTE":
        shape[name] = shape[name].max(
          attr.value,
          `Max ${attr.value} ${attr.value > 1 ? "characters" : "character"}`
        );
        break;
      case "MIN_VALUE_ATTRIBUTE":
        shape[name] = shape[name].min(attr.value, `Min ${attr.value}`);
        break;
      case "MAX_VALUE_ATTRIBUTE":
        shape[name] = shape[name].max(attr.value, `Max ${attr.value}`);
        break;
      case "MAX_OPTIONS_ATTRIBUTE":
        if (shape[name].type === "array") {
          shape[name] = shape[name].max(
            attr.value,
            `You can choose max ${attr.value} ${
              attr.value > 1 ? "options" : "option"
            }`
          );
        }
        break;
      default:
        break;
    }
  });

  return shape[name];
};

export const findReferencedSubSection = (template, targetName) => {
  const result = [];

  template.sections.forEach((section) => {
    section.subSections.forEach((subSection) => {
      subSection.attributes.map((attribute) => {
        if (attribute.elementReference) {
          const refElemName = Object.values(attribute.elementReference).join(
            "-"
          );
  
          if (targetName === refElemName) {
            result.push({
              ...attribute,
              name: `${section.id}-${subSection.id}`
            });
          }
        }
      });
    });
  });

  return result;
};

export const findReferencedElement = (template, targetName) => {
  const result = [];

  template.sections.forEach((section) => {
    section.subSections.forEach((subSection) => {
      subSection.elements.forEach((element) => {
        element.attributes.forEach((attribute) => {
          if (attribute.elementReference) {
            const refElemName = Object.values(attribute.elementReference).join(
              "-"
            );

            if (targetName === refElemName) {
              result.push({
                ...attribute,
                name: `${section.id}-${subSection.id}-${element.id}`
              });
            }
          }
        });
      });
    });
  });

  return result;
};

export const findElementByName = (template, name) => {
  const ids = name.split('-');

  const traverse = (currentLevel, index) => {
    if (!Array.isArray(currentLevel)) return null;

    const id = ids[index];
    const match = currentLevel.find(item => item.id === parseInt(id));

    if (!match) return null;
    if (index === ids.length - 1) return match;

    if (match.subSections) return traverse(match.subSections, index + 1);
    if (match.elements) return traverse(match.elements, index + 1);
    if (match.groups) return traverse(match.groups.flatMap(group => group.options), index + 1);
    if (match.attributes) return traverse(match.attributes.flatMap(attr => attr.element ? [attr.element] : []), index + 1);

    return null;
  };

  return traverse(template.sections, 0);
};

export const findElementsWithReference = (template, referenceName, targetTypes) => {
  const result = [];
  
  // Helper function to check if the elementReference matches the referenceName
  function matchesReference(elementReference, referenceName) {
    // Convert the referenceName into a format that can be compared
    const refString = `${elementReference.sectionId}-${elementReference.subsectionId}-${elementReference.elementId}`;
    
    // Check if the refString starts with the referenceName
    return refString.startsWith(referenceName) && (refString === referenceName || refString.startsWith(`${referenceName}-`));
  }

  // Function to remove duplicates from an array of objects
  function removeDuplicates(arr) {
    // Create a map to track unique elements
    const seen = new Map();
    return arr.filter(item => {
        // Create a unique key based on the criteria
        const key = `${item.type}-${item.id}`;
        // Check if the key is already in the map
        if (seen.has(key)) {
            return false;
        }
        // Otherwise, add the key to the map and include the item in the result
        seen.set(key, true);
        return true;
    });
  }
  
  template.sections.forEach(section => {
    section.subSections.forEach(subSection => {
      subSection.elements.forEach(element => {
        if (element.attributes.filter(item => item.elementReference && matchesReference(item.elementReference, referenceName) && targetTypes.includes(item.type))?.length) {
          result.push(element);
        }
      });
    });
  });

  return removeDuplicates(result);
}

export const generateInitialValue = (item) => {
  let initialValue = null;

  switch (item.type) {
    case 'string':
      initialValue = item.nullable ? null : ''
      break;
    case 'number':
      initialValue = item.nullable ? null : 0
      break;
    case 'array':
      initialValue = item.nullable ? null : []
      break;
    case 'object':
      initialValue = item.nullable ? null : {}
      break;
    default:
      break;
  }

  return initialValue;
}

export const isFieldNotEmpty = (value) => {
  return (
    value !== null &&
    value !== undefined &&
    ((typeof value === "number" && value > 0) ||
      (typeof value === "string" && value.trim().length > 0) ||
      (typeof value === "object" &&
        !Array.isArray(value) &&
        Object.keys(value).length > 0) ||
      (Array.isArray(value) && value.length > 0))
  );
};

export const handleMaxElements = (
  section,
  subSection,
  element,
  values,
  value,
  validationSchema,
  setFieldError,
  setValidationSchema,
  validateForm
) => {
  const targetName = `${section.id}-${subSection.id}-${element.id}`;
  const parentName = `${section.id}-${subSection.id}`;
  const maxElementsAttribute = subSection.attributes.find(item => item.type === 'MAX_ELEMENTS_ATTRIBUTE');
  
  if (maxElementsAttribute?.value === 1) {
    const restElement = subSection.elements.find(elem => elem.id !== element.id)
    const restElementName = Object.keys(values).find(item => item.startsWith(parentName) && item !== targetName);
    const newValidationSchema = { ...validationSchema };

    delete newValidationSchema[restElementName];

    newValidationSchema[restElementName] = generateValidationRules(restElementName, restElement, subSection, isFieldNotEmpty(value));
    setValidationSchema(newValidationSchema);

    // setTimeout uses to set the validateForm function into another task stack
    setTimeout(() => {
      validateForm();
    }, 500);
  }
}

export const handleHideSubSections = (
  name,
  template,
  subSection,
  values,
  value,
  validationSchema,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  setValidationSchema,
) => {
  const attributes = findReferencedSubSection(template, name).filter(item => item.option === (value || values[name]) && item.type === 'HIDE_UNDER_CONDITION_ATTRIBUTE');

  const updatedValidationSchema = { ...validationSchema };

  attributes.forEach(item => {
    const subSectionElements = Object.keys(updatedValidationSchema).filter(key => key.startsWith(item.name) && key !== item.name);

    subSectionElements.forEach(item => {
      updatedValidationSchema[item] = generateValidationRules(item, findElementByName(template, item), subSection, isFieldNotEmpty(value));
  
      Object.entries(Yup.object().shape(validationSchema).describe().fields).forEach(([key, value]) => {
        if (key === item) {
          setFieldValue(item, generateInitialValue({ ...value, nullable: !!validationSchema[key]?._nullable }));
        }
      });
      setFieldError(item, '');
      setFieldTouched(item, false);
    })
  })

  if (attributes.length) {
    setValidationSchema(updatedValidationSchema);
  }
}