import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    // Override default styles here
    '&.MuiAccordion-root': {
      border: 'none',
      boxShadow: 'none',
      background: 'transparent',
      margin: '0px !important',
    },
    '&.Mui-expanded': {
      marginBottom: '30px !important',
    }
  },
  accordionSummary: {
    // Custom styles for AccordionSummary
    '&.MuiAccordionSummary-root': {
      widht: '100%',
      minHeight: '48px',
      margin: '0px',
      padding: '14px 40px',
      background: '#000000',
      border: '1px solid #94A3B8',
      borderRadius: '28px',
      color: 'white',
      transition: 'border-radius .5s .4s',
    },
    '& .MuiAccordionSummary-content': {
      margin: '0px !important',
      padding: '0px !important',
      color: 'white !important',
      fontWeight: '700 !important',
      fontSize: '14px !important',
      lineHeight: '17px !important',
      textTransform: 'uppercase !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '&.Mui-expanded': {
      borderRadius: '28px 28px 0px 0px',
      transition: 'none',
    }
  },
  accordionSummaryFilled: {
    '& .MuiIconButton-edgeEnd': {
      '& .MuiIconButton-label': {
        '& svg': {
          '& g': {
            '& path': {
              fill: 'black !important'
            }
          }
        }
      }
    }
  },
  accordionDetails: {
    // Custom styles for AccordionDetails
    '&.MuiAccordionDetails-root': {
      width: '100%',
      padding: '32px 40px',
      background: '#FFFFFF',
      borderRadius: '0px 0px 28px 28px',
      border: '1px solid #94A3B8'
    },
  },
  accordionDetails2: {
    // Custom styles for AccordionDetails
    '&.MuiAccordionDetails-root': {
      width: '100%',
      padding: '32px 40px',
      background: '#FFFFFF',
      borderRadius: '28px',
      border: '1px solid #94A3B8',
      marginTop: '12px'
    },
  },
  subSectionsGroup: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '12px',
    margin: '12px 0px 0px 0px',
  },
  subSection: {
    width: '100%',
    padding: '32px 40px',
    background: '#FFFFFF',
    borderRadius: '28px',
    border: '1px solid #94A3B8',
    '& > div': {
      padding: '0px !important',
      margin: '0px !important'
    }
  },
  subSectionTitleWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  subSectionTitle: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  subSectionRules: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  subSectionContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '15px'
  }
}));