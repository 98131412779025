import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  historyContainer: {
    maxHeight: '300px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginBottom: '16px',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  historyItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px',
    background: '#F8FAFC',
    borderRadius: '20px'
  }
}));