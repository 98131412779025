import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: 'fit-content !important',
    flex: 'unset !important',
    '&.MuiFormControl-root': {
      width: '100%',
      margin: '4px 0px',
      background: 'transparent'
    }
  },
  controll: {
    '&.MuiFormControlLabel-root': {
      width: 'fit-content',
      display: 'flex',
      alignItems: 'center',
      columnGap: '8px',
      padding: '0px',
      margin: '0px',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '15px',
      fontWeight: '500',
      lineHeight: '20px',
      color: '#0F172A',
      display: 'flex',
      alignItems: 'center'
    }
  },
  radio: {
    padding: '0px',
  },
}));