import { IBlock } from "../../../framework/main/IBlock";
import { BlockComponent } from "../../../framework/main/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/main/Messages/MessageEnum";
import { runEngine } from "../../../framework/main/RunEngine";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  title: string;
  inputList?: any;
  serviceArray?: any;
  btnDisplay?: boolean;
  buttonName: string;
  addItem?: any;
  handleChange?: any;
  onAddNewItem?: () => void;
  touched?: any;
  values?: any;
  errors?: any;
  name?: string;
  formikHandelChange?: any;
  formikValue?: any;
  formikError?: any;
  setFieldValue?: any;
  maxFeilds?: any;
  tooltipData?: any;
  requiredIndex?: number;
}

interface S {
}

interface SS {
  id: any;
}

export default class PrimaryQuestionController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
    ];

    this.state = {

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
  }
}
