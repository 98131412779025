import React from "react";
import {
  Box,
  Container,
  IconButton,
  Snackbar,
  Typography,
} from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";
import HistoryHeader from "../../../components/main/HistoryHeader.web";
import HistoryTableWithAccodian from "../../../components/main/HistoryTableWithAccodian.web";
import CommanDeletePopup from "../../../components/main/CommanDeletePopup";

const copilotTableHeader = [
  "Conversation",
  "Intelligence",
  "Timestamp",
  "Options",
];
const outreachTableHeader = [
  "Title & Number",
  "Tag & Preview",
  "Timestamp",
  "Options",
];

import HistoryController, { Props, configJSON } from "./HistoryController.web.";

export class History extends HistoryController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, id, navigation } = this.props;
    const {
      selectFeildEnable,
      selectedFeilds,
      rightDrawerEnable,
      activeTab,
      outreachData,
      selectedHistoryId,
    } = this.state;
    return (
      <>
        <CommanDeletePopup
          open={this.state.delete}
          handleCloseDeletePopUp={this.handleDeleteModal}
          workFlowName={this.state.DeleteName}
          handelDeleteData={this.handelDeleteData}
          text={"History?"}
          data-testid="delete-popup"
          isHistory={this.state.isHistory}
          isLibrary={false}
        />
        <CommanDeletePopup
          open={this.state.deleteAll}
          handleCloseDeletePopUp={this.handleDeleteAllModal}
          handelDeleteData={this.DeleteAllAPICall}
          text={"History?"}
          data-testid="deleteAll-popup"
          isHistory={this.state.isHistory}
          isLibrary={false}
        />
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={this.state.saveChart}
          autoHideDuration={2000}
          data-testid="snackbar"
          onClose={this.handleSanckbarClose}
          message={this.state.savedMessage}
        />
        <Container className={classes.mainWorkFlowContainerr}>
          {/* <Box className={classes.sideBarIcon}>
            <IconButton
              data-test-id="rightBarBtnDataTetId"
              onClick={() => this.setState({ rightDrawerEnable: true })}
            >
              <img src={rightBar} alt="rightBarSide" />
            </IconButton>
          </Box> */}
          <Box>
            <Typography className={classes.pageHeading1}>
              {configJSON.history}
            </Typography>
          </Box>

          <Box className={classes.tableHeaderWrapper}>
            <HistoryHeader
              data-test-id="HistoryHeader"
              selectedFeilds={selectedFeilds}
              dataLength={
                activeTab === 1
                  ? this.state.copilotData.length
                  : outreachData.length
              }
              handelSelectAllWorkFLow={this.handelSelectAllWorkFLow}
              handelSelectFeildBtn={this.handelSelectFeildBtn}
              selectFeildEnable={selectFeildEnable}
              removeSelction={() =>
                this.setState({
                  selectFeildEnable: false,
                  selectedFeilds: [],
                  selectedSubId: [],
                })
              }
              activeTab={activeTab}
              handelTabChange={(obj: any) => {
                this.setState(obj);
              }}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                this.handleSearch(event)
              }
              searchValue={this.state.searchValue}
              onDeleteAll={this.handleDeleteAllModal}
              selectedSubId={this.state.selectedSubId}
              isHideSelectHistory={this.isHideSelectHistory}
            />

            <HistoryTableWithAccodian
              data-test-id="HistoryTableWithAccodian"
              data={activeTab == 1 ? this.state.copilotData : outreachData}
              handelFeildCheckBoxClick={this.handelFeildCheckBoxClick}
              selectFeildEnable={selectFeildEnable}
              selectedFeilds={selectedFeilds}
              selectedHistoryId={selectedHistoryId}
              handelSelctedHistory={(obj: any) => {
                this.setState(obj);
              }}
              tableHeader={
                activeTab == 1 ? copilotTableHeader : outreachTableHeader
              }
              activeTab={activeTab}
              onClick={(id: number) => this.handleChatHistory(id)}
              onDelete={(title: string, id: number, type: string) =>
                this.handleOpenDelete(title, id, type)
              }
              onSave={(
                title: string,
                id: number,
                type: string,
                save: boolean
              ) => this.handleSave(title, id, type, save)}
              onOpenMail={(
                id: number,
                campType: string,
                param_group_id: number
              ) => this.handleEmailCreation(id, campType, param_group_id)}
              openEmailOutreach={(
                id: number,
                campType: string,
                param_group_id: number,
                outreachId: string | number
              ) =>
                this.openEmailOutreach(id, campType, param_group_id, outreachId)
              }
              handelCheckSubId={(
                event: React.ChangeEvent<HTMLInputElement>,
                id: string
              ) => this.handelCheckSubId(event, id)}
              selectedSubId={this.state.selectedSubId}
            />
          </Box>
        </Container>
      </>
    );
  }
}

const styles = createStyles({
  mainWorkFlowContainerr: {
    position: "relative",
    height: "100vh",
    padding: "16px 20px 16px 20px",
    maxWidth: "100%",
  },
  pageHeading1: {
    fontFamily: "Montserrat",
    fontSize: "48px",
    fontWeight: 600,
    textAlign: "center",
  },
  sideBarIcon: {
    position: "absolute",
    right: "10px",
    top: "10px",
    zIndex: 4200,
  },
  tableHeaderWrapper: {
    margin: "0px 50px",
  },
});
export default withStyles(styles)(History);
