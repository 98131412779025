import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  tableRow: {
    background: (props: any) => props.item.expanded ? '#F1F5F9' : 'transparent',
    cursor: 'pointer',
    // '& $contentTitle': {
    //   '& svg': {
    //     opacity: 0,
    //     transition: 'all .3s'
    //   }
    // },
    // '& $contentDescription': {
    //   '& svg': {
    //     opacity: 0,
    //     transition: 'all .3s'
    //   }
    // },
    '& $contentTitleIcon': {
      opacity: 0,
      transition: 'all .3s'
    },
    '& $contentDescriptionEdit': {
      opacity: 0,
      transition: 'all .3s'
    },
    '&:hover': {
      background: '#F1F5F9',
      // '& $contentTitle': {
      //   '& svg': {
      //     opacity: 1
      //   }
      // },
      // '& $contentDescription': {
      //   '& svg': {
      //     opacity: 1
      //   }
      // },
      '& $contentTitleIcon': {
        opacity: 1
      },
      '& $contentDescriptionEdit': {
        opacity: 1
      }
    }
  },
  tableCellMain: {
    // width: '25%',
    minHeight: '83px',
    maxHeight: '83px',
    paddingLeft: '8px'
  },
  tableCell: {
    width: '100%',
    minHeight: '83px',
    maxHeight: '83px',
  },
  iconBox: {
    width: 'fit-content',
    minWidth: '36px',
    height: '36px',
    borderRadius: '8px',
    padding: '6px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: (props: any) => props.item.expanded ? 'white' : 'black',
    gap: '6px',
  },
  iconText: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    color: (props: any) => props.item.expanded ? 'black' : 'white',
  },
  arrowIcon: {
    minWidth: '8px',
    minHeight: '8px',
    color: (props: any) => props.item.expanded ? 'black' : 'white',
  },
  contentBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    // gap: '4px',
  },
  contentTitle: {
    // display: 'flex',
    // alignItems: 'center',
    // gap: '8px',
    // maxWidth: '250px',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19px',
  },
  contentTitleIcon: {
    opacity: 0
  },
  contentDescription: {
    // alignItems: 'center',
    // gap: '8px',
    // maxWidth: '400px',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14px',
    color: '#64748B',
    textWrap: 'pretty',
  },
  contentDescriptionEdit: {
    opacity: 0,
  },
  contentDate: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14px',
    color: '#64748B'
  },
  descriptionText: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '14px',
    color: '#475569',
  },
  expandedTableRow: {
    background: '#F8FAFC',
  },
  expandedTableCell: {
    // width: '25%',
    minHeight: '64px',
    maxHeight: '64px',
    paddingLeft: '20px',
  },
  expandedTableCellMain: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  latterIcon: {
    minWidth: '20px',
    minHeight: '16px',
    // color: (props: any) => props.value.color,
    color: 'black'
  },
  emailContentBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  emailContentTitle: {
    // display: 'flex',
    // alignItems: 'center',
    // gap: '16px',
    maxWidth: '300px',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '17px',
  },
  emailContentDate: {
    color: '#64748B',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14px',
  },
  emailContentText: {
    color: '#64748B',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '14px',
  },
  emailTag: {
    width: 'fit-content',
    padding: '4px 16px',
    borderRadius: '20px',
    border: '1px solid black',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
    color: 'black'
  },
  optionsBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  noEmailsBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 0px',
  },
  noEmailsText: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, 0px)',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '14px',
    color: '#475569',
  },
}));
