import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  importFileSectionWrapper: {
    display: "flex",
    marginTop: 32,
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  importFileInput: {
    width: "100%",
  },
  importDropfileWrapper: {
    width: "100%",
    height: "136px",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 20,
    border: "1px solid #CBD5E1",
    background: 'transparent',
    cursor: "pointer",
    '&:hover': {
      border: "2px dashed #3A84C9",
      background: '#3A84C90D',
    }
  },
  importUploadIcon: {
    fontSize: 24,
    color: "#3A84C9",
  },
  importUploadTitle: {
    marginTop: 8,
    fontWeight: 500,
    lineHeight: "22px",
    display: "flex",
    fontSize: 16,
  },
  importChooseFile: {
    color: "#3A84C9",
    marginLeft: 4,
    textDecorationLine: "underline",
    fontWeight: 600,
  },
  importInput: {
    display: "none",
  },
  importFileNoted: {
    width: "100%",
    padding: "0 6px",
    marginTop: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  errorMessage: {
    color: "red",
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "22px",
    whiteSpace: "pre-line"
  },
  importNoted: {
    color: "#8C8C8C",
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "22px",
  },
}));
