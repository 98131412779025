import { Message } from "../../../framework/main/Message";
import { BlockComponent } from "../../../framework/main/BlockComponent";
import { runEngine } from "../../../framework/main/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/main/Messages/MessageEnum";

import { WithStyles } from "@material-ui/core";
import StorageProvider from "../../../framework/main/StorageProvider";
export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation?: any;
  id?: string;
}

export interface S {
  loading: boolean;
}

export interface SS {
  id: any;
}

export default class WelcomePageController extends BlockComponent<
  Props,
  S,
  SS
> {
  token: string;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage)
    ];

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      loading: true
    };

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // runEngine.debugLog("Message Recived", message);
  }

  async componentDidMount() {
    super.componentDidMount();

    const token = await StorageProvider.getItem("user-token");
    this.token = token;

    this.setState({
      loading: false
    })
  }

  goToLogin = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  goToChooseYourOption = () => {
    this.props.navigation.navigate(
      // "ChooseOption"
      'SignUpEmail'
    );
  };

}
