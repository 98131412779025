import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    // minHeight: '116px',
    padding: '12px 190px 12px 180px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
    background: '#F1F5F9',
    borderBottom: '1px solid #94A3B8',
    position: 'sticky',
    top: 0,
    zIndex: 9,
    [theme.breakpoints.down(1280)]: {
      padding: '12px 90px 12px 80px',
    },
  },
  headerBreadCrumbs: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4.5px 0px'
  },
  headerProgressBar: {
    width: '100%',
    minHeight: '44px',
    maxHeight: '44px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 20px',
    background: '#FFFFFF',
    borderRadius: '8px',
    columnGap: '20px',
  },
  headerProgressBarLeftBox: {
    width: '30%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    '& svg': {
      minWidth: '26px',
      minHeight: '26px'
    }
  },
  headerProgressBarRightBox: {
    width: '70%',
    display: 'flex',
    alignItems: 'center',
    gap: '2px'
  },
  templateNameAndDescription: {
    display: 'flex',
    flexDirection: 'column',
    padding: '26px 190px 12px 180px',
    gap: '8px',
    [theme.breakpoints.down(1280)]: {
      padding: '26px 90px 12px 80px',
    },
  },
  templateName: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
    '& svg': {
      opacity: 0,
      transition: 'all .3s',
    },
    '&:hover': {
      '& svg': {
        opacity: 1
      },
    }
  },
  templateDescription: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
    '& svg': {
      opacity: 0,
      transition: 'all .3s',
    },
    '&:hover': {
      '& svg': {
        opacity: 1
      }
    }
  },
}));