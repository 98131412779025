export const offeringsServiceArrayData = ['Min 1 problem statement', 'Title length max 30 words', 'Description length max 350 words'];
export const offeringsInputListData = [
    {
        offeringPlaceholder: "Exclusive GTM Expertise",
        descriptionPlaceholder: "A concerning number of startups and scale-ups crash, not due to lack of innovation but GTM inadequacies - more specifically 85% (CBI Insights, 2022) of inadequacies can be attributed to a poor go-to-market approach. The top-tier GTM expertise, pivotal for unlocking potential, remains exclusive to tech giants and elite consultancies.",        
        offering: '',
        description: '',
        offeringTitle: 'Problem Statement',
        descriptionTitle: 'Description',
        wordsLength: 30,
        wordsLengthDesc: 350
    },
    {
        offeringPlaceholder: "Growth Stagnation Trap",
        descriptionPlaceholder: "As maturating companies grow, they often squander resources on unproductive tasks, misallocate time and money, and grapple with ineffective GTM strategies, leading to nearly 72% (SalesForce, 2022) of sales professionals missing their annual quotas. Such inefficiencies directly hamper accelerated, high-margin growth",        
        offering: '',
        description: '',
        offeringTitle: 'Problem Statement',
        descriptionTitle: 'Description',
        wordsLength: 30,
        wordsLengthDesc: 350
    },
    {
        offeringPlaceholder: "The Slow Giant",
        descriptionPlaceholder: "Companies leveraging digital innovation in their go-to-market approach outpace peers with 30% (McKinsey, 2022) higher acquisition speed. Meanwhile, large entities slow in adopting advanced GTM tech risk reduced growth, margins, and employee morale. Agile competitors capitalise on these gaps, chipping away at their market dominance.,",
        offering: '',
        description: '',
        offeringTitle: 'Problem Statement',
        descriptionTitle: 'Description',
        wordsLength: 30,
        wordsLengthDesc: 350

    },
];
export const companyValueServiceArrayData = ["Title: max 160 characters each",
    " Body: max 520 characters each"];
export const companyValueInputListData = [
    {
        offeringPlaceholder: "A strong moral compass",
        descriptionPlaceholder: "We are honest with each other and with our Clients. We say what we mean; we keep our promises.",
        offering: '',
        description: '',
        offeringTitle: 'Value',
        descriptionTitle: '',
        charLength: 160,
        charLengthDesc: 520
    },
    {
        offeringPlaceholder: "Consistency and honesty",
        descriptionPlaceholder: "Excellence is a habit. A critical element of success is consistency. We practise what we preach.",
        offering: '',
        description: '',
        offeringTitle: 'Value',
        descriptionTitle: '',
        charLength: 160,
        charLengthDesc: 520
    },
    {
        offeringPlaceholder: "Speed, quality & passion",
        descriptionPlaceholder: "We are a young, tight-knit and nimble organisation that is able to make changes fast and be reactive to market changes, and client needs.",
        offering: '',
        description: '',
        offeringTitle: 'Value',
        descriptionTitle: '',
        charLength: 160,
        charLengthDesc: 520

    },
];
export const vissionStatementInputListData =[
    {
        label: "",
        placeholder: "We champion ambitious leaders with our science-led go-to-market strategies, fuelling success from early-stage, scale to expansion.",
        value: "",
        name: "",
        charLength: 600

    },
];
export const companyEvaluationInputListData = [
    {
        label: "",
        placeholder: "For 10 years, we've meticulously blended research and real-world application to refine GTM approach. Taking insights from blue-chip giants, prominent business schools, and elite startup accelerators, we've also incorporated findings from over 150 projects and insights from 12 industries.",
        value: "",
        name: "",
        charLength: 1200

    },
];
export const companyEvaluationServiceArrData = ["Max 1200 characters"];
export const companyHeadquarterInputListData = [
    {
        label: "",
        placeholder: "Headquartered in London, we are Europe’s science-driven Marketing Strategy House that has easy access to top-qualified talent.",
        value: "",
        name: "",
        charLength: 600

    },
];
export const companyHeadQuarterServiceArrData = ["Max 600 characters"];
export const primaryUtilityServiceArrData =  ["Max 640 characters "];
export const mqlServiceArrData = ["Max 800 characters "];
export const primaryUtilityInputListData = [
    {
        label: "",
        placeholder: "Penetrate key markets & achieve accelerated, sustained growth",
        value: "",
        name: "",
        charLength: 640

    },
];
export const mqlStatementInputListData = [
    {
        label: "",
        placeholder: "We are an enterprise-grade Marketing Strategy House that is specialised in taking small and medium-sized enterprises to market through our science-led marketing strategies, market intel, and marketing automation.",
        value: "",
        name: "",
        charLength: 800

    },
];
export const contributionInputListData = [
    {
        label: "",
        placeholder: "We commit to using part of our profits to support children in Ukrainian orphanages by supplying them with educational toys and musical instruments, aiding the development of their spatial reasoning, numeracy skills, and literacy skills.",
        value: "",
        name: "",
        charLength: 800

    },
];
export const companyAchievementsInputListData = [
    {
        label: "Achievement",
        placeholder: "Published in Europe’s marketing bestseller book by Oxford University Press in 2022",
        value: "",
        name: "achievement1",
        charLength: 480

    },
];
export const companyAchievementsServiceArrData = ["Title ",
    "Company",
    "Industry",
    "Technology",
    "Max 3 achievements",
    "Max 160 characters each"];
export const companyFactsServiceArrData = ["Title ",
    "Company",
    "Industry",
    "Technology",
    "Max 5 company facts ",
    "Max 200 characters each"];
export const companyFactsInputListData = [
    {
        label: "Fact",
        placeholder: "12 industries of combined knowledge",
        value: "",
        name: "fact1",
        charLength: 200
    },
];
export const primaryQuestionInputListData = [
    {
        label: "Question",
        placeholder: "Feeling anxious about the growth of your company?",
        value: "",
        name: "question1",
        wordsLength: 50
    },
    {
        label: "Question",
        placeholder: "In need of a growth partner that you can truly rely on?",
        value: "",
        name: "question2",
        wordsLength: 50
    },
    {
        label: "Question",
        placeholder: "Worrying about the efficacy of your marketing spend?",
        value: "",
        name: "question3",
        wordsLength: 50
    }
];
export const primaryQuestionServiceArrData = ['Min 1 question', ' Max 50 words each'];
export const shouldHaveServiceArrayData = ["Max 10 steps", "Title length max 30 characters", "Description length max 70 characters"];
export const companyTestimonialArrayData = ["Name", "Title", "Company","Industry","Technology","Max 3 testimonials","Max 160 characters each"];
export const serviceArrayData = ['Max 3 questions', 'Max 50 characters each'];
export const serviceArray1Data = ['Min 10 and max 20 designations', 'Max 3 words for each designation'];
export const companyTestimonialsInputData = [
    {
        label: "Testimonial",
        placeholder: " Michael Kert | Chairman | SIF Ventures | Venture Capital | SaaS Pebbles Ai has been invaluable and indispensable in our efforts to build one of the world's first early-stage Web3 VC. He has gone above and beyond and displayed a great sense of support and loyalty toward our team.",
        value: "",
        name: "Testimonial",
        charLength: 480
    },
]
export const inputListData = [
    {
        label: "Step",
        placeholder: "Mission Brief: gather and review client intelligence.",
        value: "",
        name: "step1",
        charLength: 30
    },
    {
        label: "Step",
        placeholder: "Marketing Strategy: produce GTMS™ Source of Truth marketing strategy.",
        value: "",
        name: "step2",
        charLength: 70

    },
];
export const chipLabelsData = ["Democratise B2B success"];


// The Comapny Offer 

export const offeringsProductArray = ['Min 1 product & feature', 'Offering length max 30 words', 'Description max length 300 words'];
export const offeringsServicesArray = ['Min 1 service', 'Title max length 30 words', 'Description max length 120 words'];
export const productTestimonial = ['Name', 'Title', 'Company', 'Industry', 'Technology', 'Max 3 testimonials', 'Max 160 characters each'];
export const performanceAnalyticsServiceArr = ['Max 5 performance analytics', 'Max 320 characters each'];
export const shouldHaveServiceArray = ["Full name", "Max 3 key team members", "Title length max 120 characters each", "Description length max 1200 characters each"]
export const serviceArray = ['Max 3 questions', 'Max 50 characters each'];
export const serviceArray1 = ['Min 10 and max 20 designations', 'Max 3 words for each designation'];
export const goodToHaveServiceArray = ['Max 6 packages', 'Package name max 120 characters', 'Description length max 600 characters'];
export const productTestimonialInputList = [
    {
        label: "Testimonial",
        placeholder: "Paul Walker | CEO | Kumoco | B2B | SaaS | Cloud Tech Our GTM strategy and execution capabilities in conjunction with our execution support has pushed our incoming marketing qualified leads by 444%, reduced our marketing execution cost by 76%, and landed our first demo for an Enterprise client in month 6",
        value: "",
        name: "",
        charLength: 480
    },
];

export const performanceAnalyticsQuestionList = [
    {
        label: "Analytics",
        placeholder: "100% of our B2B / B2B2C Clients witnessed accelerated growth.",
        value: "",
        name: "analytics1",
        charLength: 320
    },
    {
        label: "Analytics",
        placeholder: "340% increase in incoming Marketing Qualified Leads (MQLs).",
        value: "",
        name: "analytics2",
        charLength: 320
    },
    {
        label: "Analytics",
        placeholder: "57% annual reduction in overall marketing and sales-related execution costs.",
        value: "",
        name: "analytics3",
        charLength: 320
    }
];
export const inputList = [
    {
        label: "Step",
        placeholder: "Mission Brief: gather and review client intelligence.",
        value: "",
        name: "step1",
        charLength: 30
    },
    {
        label: "Step",
        placeholder: "Marketing Strategy: produce GTMS™ Source of Truth marketing strategy.",
        value: "",
        name: "step2",
        charLength: 70

    },
];
export const chipLabels = ["All-round GTM platform"];
export const offeringsProductsInputList = [
    {
        offeringPlaceholder: "Pebbles AI Platform",
        descriptionPlaceholder: "An AI-native platform that supercharges your productivity, propels your business growth, and ensures your long-term success.",
        offering: '',
        description: '',
        offeringTitle: 'Offering',
        descriptionTitle: 'Description',
        wordsLength: 30,
        wordsLengthDesc: 300
    },
    {
        offeringPlaceholder: "The GTMS™ Model",
        descriptionPlaceholder: "A bespoke marketing strategy model that depicts a strategic go to market roadmap to business success.",
        offering: '',
        description: '',
        offeringTitle: 'Offering',
        descriptionTitle: 'Description',
        wordsLength: 30,
        wordsLengthDesc: 300
    },
    {
        offeringPlaceholder: "GTM Intelligence Report",
        descriptionPlaceholder: "A market intel report that depicts your correct market trajectory across various industry segments, geolocations, and persona clusters.",
        offering: '',
        description: '',
        offeringTitle: 'Offering',
        descriptionTitle: 'Description',
        wordsLength: 30,
        wordsLengthDesc: 300
    },
];
export const offeringsTeamInputList = [
    {
        offeringPlaceholder: "Emin Can Turan | Founder",
        descriptionPlaceholder: "Can is an award-winning and published Marketing Strategist and Go to Market Specialist. Formerly at Walmart, Cisco and Google in Marketing Strategy, Data & Insight, and Leadership Advisory. He is widely known to be the GTM expert in London. Can studied business management, strategy and marketing at Cranfield School of Management and Oxford University.",
        offering: '',
        description: '',
        offeringTitle: 'Name & Title',
        descriptionTitle: 'Description',
        charLength: 120,
        charLengthDesc: 1200
    },
    {
        offeringPlaceholder: "Vincent Thurow | Co-Founder",
        descriptionPlaceholder: "Vincent has over a decade of Investment Banking experience, specialising in the Technology sector. Previously, he was a Director at UBS in London, where he concentrated on the EMEA software landscape. In this role, Vincent provided strategic and financial. His expertise spans public and private M&A deals (sell-side & buy-side), initial public offerings (IPOs) and financing projects.",
        offering: '',
        description: '',
        offeringTitle:'Name & Title',
        descriptionTitle: 'Description',
        charLength: 120,
        charLengthDesc: 1200
    },
    {
        offeringPlaceholder: "Mincent Frurow | Founder",
        descriptionPlaceholder: "Vincent has over a decade of Investment Banking experience, specialising in the Technology sector. Previously, he was a Director at UBS in London, where he concentrated on the EMEA software landscape. In this role, Vincent provided strategic and financial. His expertise spans public and private M&A deals (sell-side & buy-side), initial public offerings (IPOs) and financing projects.",
        offering: '',
        description: '',
        offeringTitle: 'Name & Title',
        descriptionTitle: 'Description',
        charLength: 120,
        charLengthDesc: 1200

    },
];
export const offeringsServicesInputList = [
    {
        offeringPlaceholder: "GTM Training & Support",
        descriptionPlaceholder: "We go the extra mile to teach you the process of going to market from funnel management to sales best-practices.",
        offering: '',
        description: '',
        offeringTitle: 'Offering',
        descriptionTitle: 'Description',
        wordsLength: 30,
        wordsLengthDesc: 120
    },
    {
        offeringPlaceholder: "Funnel Support",
        descriptionPlaceholder: "We help you run a smooth marketing-sales funnel through helpful tools, sales training, and ongoing leadership support.",
        offering: '',
        description: '',
        offeringTitle: 'Offering',
        descriptionTitle: 'Description',
        wordsLength: 30,
        wordsLengthDesc: 120
    },
    {
        offeringPlaceholder: "Performance Analytics",
        descriptionPlaceholder: "We provide insightful execution reports that look in detail across the performance of your marketing-sales funnel.",
        offering: '',
        description: '',
        offeringTitle: 'Offering',
        descriptionTitle: 'Description',
        wordsLength: 30,
        wordsLengthDesc: 120

    },
];

export const modalInfoContent = {
    primaryFearQuestion1: "",
    primaryFearQuestion2: "",
    primaryFearQuestion3: "",
    offering1: "",
    description1: "",
    offering2: "",
    description2: "",
    offering3: "",
    description3: "",
    keySegmentSection:`This section underscores how your company disrupts the norm, emphasising key propositions related to the USPs that challenge traditional competitors. By spotlighting these fundamental differences, it showcases the innovative edge and visionary approach your brand brings to the table, setting you apart from incumbent players.`,
    storiesSection:`This section showcases a real-life situation where a customer faced challenges in a particular domain and then sought to use your solutions to overcome those hurdles. By breaking the story down into the background, problem, solution, and results, it paints a clear picture of the journey from adversity to success. This format allows readers to empathise with the problem, understand the actions taken, and see the outcomes.`,
    stageSection:`This section succinctly captures the primary stages of collaboration, illuminating the specific value each phase brings to the table. By laying out the roadmap, it offers clients clarity on post-contractual actions and the resultant benefits they can anticipate. Essentially, it demystifies the "so what?" for clients, spotlighting the significance of each engagement facet.`,
    solutionStatement:`This section presents the company's specific response to the aforementioned identified problems, and how the product and service alleviates these primary problems, as a whole. The statement encapsulates the essence of the company's primary value proposition, ensuring clarity for stakeholders on the solutions and benefits offered. Best written from macro level to micro level.`,
    theProcess:`This section details a systematic progression, breaking down the high-level steps a client will experience. Each step is methodically designed to cultivate a specific aspect of the client's journey, ensuring thoroughness and alignment at every stage. From initial intelligence gathering to tactical deployment, the process is meticulously crafted for clarity, efficiency, and precision.`,
    approachWhyExist:`These are pivotal words or short phrases that encapsulate the methodologies, practices, or processes the company employs. Incorporating these terms consistently into communications ensures that stakeholders understand the unique and effective ways in which the company functions.`,
    compareSection:`This section boldly contrasts your company's strengths against competitors, without diminishing them. While recognising other approaches, it accentuates how your distinct methodology, grounded in empirical evidence, offers superior value. It's a diplomatic differentiation, emphasising the company’s unique advantages and forward-thinking perspective.`,
    segmentOverview:`This section showcases a concise summary of the company's experience across diverse industries in the segments and by size. By specifying the industries and the range of enterprises the company serves, it demonstrates versatility and expertise, assuring potential clients that the company can cater to varied needs and challenges.`,
    processSection:`This section highlights select commendations from clients or users, shedding light on their experiences and satisfaction levels with the company's processes. Each testimonial serves as an individual affirmation of the efficiency, transparency, and effectiveness of the company's workflow, underlining its capacity to consistently deliver exceptional results.`,
    missionStatement: `Ask yourself, what’s wrong with the world and how do you intend to fix it? A Company Mission Statement is a concise declaration of a company's main purpose and guiding principles. It answe,rs the question, "Why do we exist?" It helps both employees and customers understand what the company stands for and its main objectives.`,
    usp: "The USP is a clear, concise statement that differentiates your company from competitors by spotlighting a unique benefit or feature. It succinctly conveys why customers across target segments should choose your product or service over others in the market.",
    companyAchivements:"This section celebrates the company's most distinguished achievements, be it in the form of awards, acknowledgements in reputed publications, or other significant recognition. These achievements vouch for the company's excellence, innovation, and commitment to industry standards.",
    keyCompanyFacts: "This section showcases concise and essential data points about the company, providing a snapshot of its breadth of experience, skilled workforce, enduring market presence, leadership calibre, and global reach. Such key facts offer a quick, yet comprehensive insight into the company's strengths and capabilities.",
    companyEvalutionTime:"This section illustrates the company's history through significant milestones, highlighting its adaptability, longevity, and expertise over time. By showcasing the blend of influences from diverse prestigious sources and years of dedication, it underpins the unique positioning and credibility of the company in the marketplace.",
    companyHeadquarters: "This section highlights the company's primary operational base, underscoring its regional significance. Indicating a specific headquarters location can elevate the company's image, implying a dominant presence through advantages such as skilled talent, cost-effective labour, or cultural assets.",
    mqlStatement:"This section covers the Marketing Qualified Lead statement, which is a public-facing description of the company's target industry segment(s). It clearly defines who the company serves and its specialised focus. By being transparent and specific, it allows potential clients to self-identify their alignment with the company's services, ensuring that only those truly suited will engage, thereby saving valuable sales resources.",
    contributeToSocity:"This section highlights the company's dedication to positive social change, indicating its responsibility and moral integrity. By aligning profits with purpose, it shows prospective clients and partners that the company not only thrives economically but also takes tangible steps to make a meaningful difference in society.",
    primaryUtilityHook:"The Primary Utility Hook is a bold and concise sentence that showcases the primary value of your product or service to across your target segments. Crafted at a macro level, it is broad enough to resonate with all industry segments, emphasising its universal appeal.",
    companyTestimonial:"This section features examples of positive feedback from satisfied clients, offering a glimpse into the quality of experience they had while partnering with the company. Each testimonial serves as a standalone testament to the company's capacity to meet and exceed client expectations, showcasing its commitment and proficiency.",
    visionStatement:"Ask yourself, what do you envision the world will be after you fixed it? The Vision Statement paints a vivid picture of the world once your company has achieved its overarching goals. It showcases the lasting impact and transformative change your company seeks to bring about, serving as a beacon to guide and inspire.",
    whyExist:"These are pivotal words or short phrases that encapsulate the very reason for the company's existence. By weaving these terms into written and verbal communication, the company ensures a consistent and clear articulation of its core purpose and underlying values.",
    companyValue:"This section outlines the company values, the core principles that guide an organisation's actions and decisions. These values not only shape the company's culture and character but also enhance its reputation, foster trust among stakeholders, and drive sustainable success in the market.",
    problemStatement:"This section outlines the three primary challenges or issues that your target audience faces across different industry segments. By categorising the problem into three distinct areas, you offer a comprehensive view, ensuring stakeholders understand the multifaceted nature of what your product or service aims to solve. Bolster each problem statement using quantifiable data, supported by research from trusted publications.",
    productAndFeature:"In this section, the products or features are showcased along with the corresponding value each one offers to the customer. Think of it as pairing a tool with its purpose. The goal is to link each product or feature clearly with its real-world advantage, making it easier for potential users to understand why it's vital for them.",
    offeringServices:"In this section, essential services are presented alongside the distinct benefits they provide to clients. Envision it as matching a service with its outcome. The objective here is to associate each service directly with its tangible benefits, simplifying the decision-making process for prospective clients by highlighting its significance.",
    productTestimonials:"This section presents select positive feedback from users, offering insights into their experiences and satisfaction levels with the product. Each testimonial stands as an individual endorsement of the product's efficacy, usability, and value, reflecting its ability to meet and often surpass user expectations.",
    performanceAnalystics:"This section presents a detailed analysis of the product's performance metrics, highlighting its efficiency, reliability, and functionality. Each analytical data point underscores the product's capabilities and alignment with benchmarks. The figures reveal its efficacy and value to customers, reflecting real-world usage of our products, services, and features.",
    modularProducts:"This section lists out individual products and services that clients can opt for, without necessarily investing in a full package. It's akin to an à la carte menu in a restaurant. Each item or service is stand-alone, allowing clients to mix and match based on their specific needs. This approach caters to diverse customer requirements, ensuring they don't pay for things they don't need.",
    corporatePartners:"This section lists businesses and organisations that the company has a partnership with. Your association with these partners often indicates a shared mission. The partnerships must offer tangible benefits to your customers, such as special rates or complementary services. When listing them, briefly mention what they do or offer.",
    primaryFearQuestion:"The Primary Fear Questions tie in with the Problem Statements. They highlight three universal fears (emotions) that your company can address across industry segments. By resonating deeply, they make the audience pause and reflect on potential risks, prompting them to consider the potential negative consequences of their current path. They also underscore your company's deep understanding of their concerns.",
    offershouldHave:"In this section, the team is showcased along with their expertise and the credibility they bring to the table. By detailing their professional accomplishments, background, and recognition, you offer a human touch to the business. For each member, include their title, key achievements, past affiliations, and educational credentials.",
    offergoodToHave:"This section lays out the distinct service packages your company offers, targeting different customer needs. Each package should be described succinctly, focusing on its primary purpose and audience. This gives a clear idea of who each package is designed for. Transparency is key for potential clients to assess their investment.",
    offer_why_do_we_exist: "These are pivotal words or short phrases that encapsulate the primary activities, products, or services the company delivers. Integrating these terms consistently into communications ensures that stakeholders have a clear and concise understanding of the company's main solutions, features and competencies.",
}

export const offeringsgoodToHaveInputList = [
    {
        offeringPlaceholder: "Early-Stage Package",
        pricePlaceholder: "49.000",
        descriptionPlaceholder: "For young companies that are looking to fundraise and build the foundations of the company.",
        offering: '',
        price: '',
        currency:'null',
        description: '',
        offeringTitle: 'Name',
        priceTitle: 'Price & Currency',
        descriptionTitle: 'Description',
        charLength: 120,
        charLengthDesc: 600
    },
    {
        offeringPlaceholder: "Scale Package",
        pricePlaceholder: "49.000",
        descriptionPlaceholder: "For scale-stage companies that are looking for sustained growth with limited internal resources.",
        offering: '',
        price: '',
        currency:'null',
        description: '',
        offeringTitle: 'Name',
        priceTitle: 'Price & Currency',
        descriptionTitle: 'Description',
        charLength: 120,
        charLengthDesc: 600
    },
    {
        offeringPlaceholder: "Expansion Package",
        pricePlaceholder: "49.000",
        descriptionPlaceholder: "For maturing organisations with big sales teams that are looking to expand internationally or into different markets.",
        offering: '',
        price: '',
        currency:'null',
        description: '',
        offeringTitle: 'Name',
        priceTitle: 'Price & Currency',
        descriptionTitle: 'Description',
        charLength: 120,
        charLengthDesc: 600
    },
];

export const offeringsModularProductsServiceArray = ["Max 3 modular products & services", "Package name max 120 characters", "Description length max 600 characters"];
export const offeringsModularProductsInputList = [
    {
        namePlaceholder: "Competitor Analysis",
        descriptionPlaceholder: "Understand your competitor landscape in your market.",
        pricePlaceholder: "49.000",
        name: '',
        description: '',
        price: '',
        currency:'null',
        nameTitle: 'Name',
        descriptionTitle: 'Description',
        priceTitle: 'Price & Currency',
        charLength: 120,
        charLengthDesc: 600
    },
    {
        namePlaceholder: "Investor Pitch Send Deck",
        descriptionPlaceholder: "Entice investors and raise capital.",
        pricePlaceholder: "49.000",
        name: '',
        description: '',
        price: '',
        currency:'null',
        nameTitle: 'Name',
        descriptionTitle: 'Description',
        priceTitle: 'Price & Currency',
        charLength: 120,
        charLengthDesc: 600
    },
    {
        namePlaceholder: "Adv. Financial Model",
        descriptionPlaceholder: "Plan your financial strategy.",
        pricePlaceholder: "49.000",
        name: '',
        description: '',
        price: '',
        currency:'null',
        nameTitle: 'Name',
        descriptionTitle: 'Description',
        priceTitle: 'Price & Currency',
        charLength: 120,
        charLengthDesc: 600
    },
];
export const offeringsCorporatePartnersServiceArray = ["Max 3 corporate partners", "Name length max 120 characters", "Description length max 200 characters", "Benefit length max 200 characters"];
export const offeringsCorporatePartnersInputList = [
    {
        orgNamePlaceholder: "Entrepreneurs Collective",
        descriptionPlaceholder: "A founders & investors community",
        benefitPlaceholder: "Free AWS credits & unlimited tickets to events in London.",
        orgName: '',
        description: '',
        benefit: '',
        orgNameTitle: 'Org Name',
        descriptionTitle: 'Description',
        benefitTitle: 'Benefit',
        charLength: 120,
        charLengthDesc: 200,
        charLengthBenefit: 200
    },
    {
        orgNamePlaceholder: "Flying Agency",
        descriptionPlaceholder: "A product design & brand agency.",
        benefitPlaceholder: "Up to 30% off on all UX/UI and design projects.",
        orgName: '',
        description: '',
        benefit: '',
        orgNameTitle: 'Org Name',
        descriptionTitle: 'Description',
        benefitTitle: 'Benefit',
        charLength: 120,
        charLengthDesc: 200,
        charLengthBenefit: 200
    },
    {
        orgNamePlaceholder: "Clover Dynamics",
        descriptionPlaceholder: "An agile software development company.",
        benefitPlaceholder: "Up to 25% off on all software projects above £20k.",
        orgName: '',
        description: '',
        benefit: '',
        orgNameTitle: 'Org Name',
        descriptionTitle: 'Description',
        benefitTitle: 'Benefit',
        charLength: 120,
        charLengthDesc: 200,
        charLengthBenefit: 200
    },
]
export const offeringsBenefits = [
    {
        benefitsPlaceholder: "We provide our customers with exceptional rates with our corporate partners. Businesses that share a common passion for advancing small and medium-sized enterprises through deep subject matter expertise.",
        benefits: '',
        benefitsTitle: 'Benefits',
        charLength: 200,
    }
];