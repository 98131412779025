import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  modalBox: {
    width: '850px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px',
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '40px',
    background: '#FFFFFF',
    boxSizing: 'border-box',
  },
  closeButton: {
    position: 'absolute',
    right: '20px',
    top: '20px',
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  optionsWrapper: {
    width: '100%',
    minHeight: '235px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  optionItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    border: '1px solid #CBD5E1',
    borderRadius: '20px',
    gap: '20px',
  }
}));
