import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  content: {
    position: "relative",
    padding: "40px !important"
  },

  closeIcon: {
    position: "absolute",
    right: 32,
    top: 30
  },

  description: {
    fontSize: 30,
    fontWeight: 700,
    lineHeight: "36px",
    textAlign: "center"
  },

  subDescription: {
    marginTop: 16,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "19px",
    textAlign: "center",
    color: "#475569"
  },

  highlight: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "34px",
    letterSpacing: "-0.005em",
    textAlign: "center",
    color: "#DC2626"
  },

  action: {
    width: '100%',
    marginTop: 32,
    display: "flex",
    alignItems: "center",
    gap: 8
  },

  deleteButton: {
    color: "#FFFFFF",
    border: "1px solid #DC2626",
    backgroundColor: "#DC2626",
    padding: "10px 16px",
    borderRadius: 8,
    cursor: "pointer",
    flex: 1,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "19.2px"
  },

  backButton: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #CBD5E1",
    color: "#000000",
    padding: "10px 16px",
    borderRadius: 8,
    cursor: "pointer",
    flex: 1,
    fontWeight: 600,
    fontSize: 16,
    lineHeight: "19.2px"
  }
}));
