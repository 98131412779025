export const aboutCompanyOnboradingData = (data) => {
  const mustHaveSectionList = data.filter((item: any) => item.details_type === 'must' && item.object_type === 'company');
  const shouldHaveSectionList = data.filter((item: any) => item.details_type === 'should' && item.object_type === 'company');
  const goodToHaveSectionList = data.filter((item: any) => item.details_type === 'good' && item.object_type === 'company');
  
  const mustHaveSection = !!mustHaveSectionList.length ? {
    whyExist: mustHaveSectionList[mustHaveSectionList.length - 1].why_keywords,
    missionStatement: mustHaveSectionList[mustHaveSectionList.length - 1].mission_statement,
    usp: mustHaveSectionList[mustHaveSectionList.length - 1].usp,
    primaryFearQuestion1: mustHaveSectionList[mustHaveSectionList.length - 1].primary_fear_questions_attributes[0].question_text,
    primaryFearQuestion2: mustHaveSectionList[mustHaveSectionList.length - 1].primary_fear_questions_attributes[1].question_text,
    primaryFearQuestion3: mustHaveSectionList[mustHaveSectionList.length - 1].primary_fear_questions_attributes[2].question_text,
    offering1: mustHaveSectionList[mustHaveSectionList.length - 1].problem_statements_attributes[0].title,
    description1: mustHaveSectionList[mustHaveSectionList.length - 1].problem_statements_attributes[0].description,
    offering2: mustHaveSectionList[mustHaveSectionList.length - 1].problem_statements_attributes[1].title,
    description2: mustHaveSectionList[mustHaveSectionList.length - 1].problem_statements_attributes[1].description,
    offering3: mustHaveSectionList[mustHaveSectionList.length - 1].problem_statements_attributes[2].title,
    description3: mustHaveSectionList[mustHaveSectionList.length - 1].problem_statements_attributes[2].description,
  } : {}

  const shouldHaveSection = !!shouldHaveSectionList.length ? {
    companyEvalutionTime: [shouldHaveSectionList[shouldHaveSectionList.length - 1].evolution_timeline],
    companyHeadquarters: [shouldHaveSectionList[shouldHaveSectionList.length - 1].headquarters],
    companyAchivements: shouldHaveSectionList[shouldHaveSectionList.length - 1].company_achievements_attributes.map((item: { achievement: string }) => item.achievement),
    companyTestimonial: shouldHaveSectionList[shouldHaveSectionList.length - 1].company_testimonials_attributes.map((item: { testimonial: string }) => item.testimonial),
    keyCompanyFacts: shouldHaveSectionList[shouldHaveSectionList.length - 1].company_facts_attributes.map((item: { fact: string }) => item.fact)
  } : {}

  const goodHaveSection = !!goodToHaveSectionList.length ? {
    visionStatement: goodToHaveSectionList[goodToHaveSectionList.length - 1].vision_statements_attributes.map((item: { vision: string }) => item.vision),
    companyValue1: goodToHaveSectionList[goodToHaveSectionList.length - 1].company_values_attributes[0].company_value,
    companyValue2: goodToHaveSectionList[goodToHaveSectionList.length - 1].company_values_attributes[1].company_value,
    companyValue3: goodToHaveSectionList[goodToHaveSectionList.length - 1].company_values_attributes[2].company_value,
    companyDescription1: goodToHaveSectionList[goodToHaveSectionList.length - 1].company_values_attributes[0].description || '',
    companyDescription2: goodToHaveSectionList[goodToHaveSectionList.length - 1].company_values_attributes[1].description || '',
    companyDescription3: goodToHaveSectionList[goodToHaveSectionList.length - 1].company_values_attributes[2].description || '',
    primaryUtilityHook: goodToHaveSectionList[goodToHaveSectionList.length - 1].primary_utility_hooks_attributes.map((item: { utility_hook: string }) => item.utility_hook),
    mqlStatement: goodToHaveSectionList[goodToHaveSectionList.length - 1].mql_statements_attributes.map((item: { mql: string }) => item.mql),
    contributeToSocity: goodToHaveSectionList[goodToHaveSectionList.length - 1].contributation_to_societies_attributes.map((item: { contributation: string }) => item.contributation),
  } : {}

  return {
    mustHaveSection,
    shouldHaveSection,
    goodHaveSection,
    isMustHaveCompleted: checkForCompleted(mustHaveSection),
    isShouldHaveCompleted: checkForCompleted(shouldHaveSection),
    isGoodHaveCompleted: checkForCompleted(goodHaveSection),
  }
}

export const yourApproachOnboradingData = (data) => {
  const mustHaveSectionList = data.filter((item: any) => item.details_type === 'must' && item.object_type === 'approach');
  const shouldHaveSectionList = data.filter((item: any) => item.details_type === 'should' && item.object_type === 'approach');
  const goodToHaveSectionList = data.filter((item: any) => item.details_type === 'good' && item.object_type === 'approach');

  const mustHaveSection = !!mustHaveSectionList.length ? {
    whyExist: mustHaveSectionList[mustHaveSectionList.length - 1].how_keywords,
    solutionTitle1: mustHaveSectionList[mustHaveSectionList.length - 1].solution_statement[0].title,
    solutionStatement1: mustHaveSectionList[mustHaveSectionList.length - 1].solution_statement[0].description,
    solutionTitle2: mustHaveSectionList[mustHaveSectionList.length - 1].solution_statement[1].title,
    solutionStatement2: mustHaveSectionList[mustHaveSectionList.length - 1].solution_statement[1].description,
    solutionTitle3: mustHaveSectionList[mustHaveSectionList.length - 1].solution_statement[2].title,
    solutionStatement3: mustHaveSectionList[mustHaveSectionList.length - 1].solution_statement[2].description,
    stage1: mustHaveSectionList[mustHaveSectionList.length - 1].high_level_approach[0].title,
    stageDescription1: mustHaveSectionList[mustHaveSectionList.length - 1].high_level_approach[0].description,
    stage2: mustHaveSectionList[mustHaveSectionList.length - 1].high_level_approach[1].title,
    stageDescription2: mustHaveSectionList[mustHaveSectionList.length - 1].high_level_approach[1].description,
    stage3: mustHaveSectionList[mustHaveSectionList.length - 1].high_level_approach[2].title,
    stageDescription3: mustHaveSectionList[mustHaveSectionList.length - 1].high_level_approach[2].description,
  } : {}

  const shouldHaveSection = !!shouldHaveSectionList.length ? {
    processStep: shouldHaveSectionList[shouldHaveSectionList.length - 1].process.map((item: any) => item.title),
    processDescription: shouldHaveSectionList[shouldHaveSectionList.length - 1].process.map((item: any) => item.description),
    processSection: shouldHaveSectionList[shouldHaveSectionList.length - 1].process_testimonials_attributes.map((item: any) => item.testimonial),
    background: shouldHaveSectionList[shouldHaveSectionList.length - 1].success_stories[0].background,
    problem: shouldHaveSectionList[shouldHaveSectionList.length - 1].success_stories[1].problem,
    storySolution1: shouldHaveSectionList[shouldHaveSectionList.length - 1].solution[0].point,
    storySolution2: shouldHaveSectionList[shouldHaveSectionList.length - 1].solution[1].point,
    storySolution3: shouldHaveSectionList[shouldHaveSectionList.length - 1].solution[2].point,
    storyResult1: shouldHaveSectionList[shouldHaveSectionList.length - 1].results[0].point,
    storyResult2: shouldHaveSectionList[shouldHaveSectionList.length - 1].results[1].point,
    storyResult3: shouldHaveSectionList[shouldHaveSectionList.length - 1].results[2].point,
  } : {}

  const goodHaveSection = !!goodToHaveSectionList.length ? {
    segmentOverview: goodToHaveSectionList[goodToHaveSectionList.length - 1].segment_expertise_overiew[0],
    segmentTitle1: goodToHaveSectionList[goodToHaveSectionList.length - 1].key_segment_differentiators[0].title,
    segmentTitle2: goodToHaveSectionList[goodToHaveSectionList.length - 1].key_segment_differentiators[1].title,
    segmentTitle3: goodToHaveSectionList[goodToHaveSectionList.length - 1].key_segment_differentiators[2].title,
    segmentDescription1: goodToHaveSectionList[goodToHaveSectionList.length - 1].key_segment_differentiators[0].description,
    segmentDescription2: goodToHaveSectionList[goodToHaveSectionList.length - 1].key_segment_differentiators[1].description,
    segmentDescription3: goodToHaveSectionList[goodToHaveSectionList.length - 1].key_segment_differentiators[2].description,
    compareSection: goodToHaveSectionList[goodToHaveSectionList.length - 1].high_level_comparison,
  } : {}

  return {
    mustHaveSection,
    shouldHaveSection,
    goodHaveSection,
    isMustHaveCompleted: checkForCompleted(mustHaveSection),
    isShouldHaveCompleted: checkForCompleted(shouldHaveSection),
    isGoodHaveCompleted: checkForCompleted(goodHaveSection),
  }
}

export const offeringsOnboradingData = (data) => {
  const mustHaveSectionData = data.filter((item: any) => item.details_type === 'must' && item.object_type === 'offerings');
  const shouldHaveSectionData = data.filter((item: any) => item.details_type === 'should' && item.object_type === 'offerings');
  const goodToHaveSectionData = data.filter((item: any) => item.details_type === 'good' && item.object_type === 'offerings');

  const mustHaveSection = !!mustHaveSectionData.length ? {
    whyExist: mustHaveSectionData[mustHaveSectionData.length - 1].what_keywords,
    productAndFeature: mustHaveSectionData[mustHaveSectionData.length - 1].product_features_attributes,
    offeringServices: mustHaveSectionData[mustHaveSectionData.length - 1].offering_services_attributes,
  } : {}

  const shouldHaveSection = !!shouldHaveSectionData.length ? {
    theTeam: shouldHaveSectionData[shouldHaveSectionData.length - 1].the_team,
    productTestimonials: shouldHaveSectionData[shouldHaveSectionData.length - 1].product_testimonials,
    performanceAnalystics: shouldHaveSectionData[shouldHaveSectionData.length - 1].performance_analystics,
  } : {}

  const goodHaveSection = !!goodToHaveSectionData.length ? {
    corporatePartners: [goodToHaveSectionData[goodToHaveSectionData.length - 1].corporate_partners],
    modularProducts: goodToHaveSectionData[goodToHaveSectionData.length - 1].modular_products,
    packagesAndPricing: goodToHaveSectionData[goodToHaveSectionData.length - 1].packages_and_pricing,
  } : {}

  return {
    mustHaveSection,
    shouldHaveSection,
    goodHaveSection,
    isMustHaveCompleted: checkForCompleted(mustHaveSection),
    isShouldHaveCompleted: checkForCompleted(shouldHaveSection),
    isGoodHaveCompleted: checkForCompleted(goodHaveSection),
  }
}

export function checkForCompleted(data) {
  if (!Object.keys(data).length) {
    return false;
  } else {
    const { details_type, object_type, ...restData } = data;

    const checkValue = (value) => {
      if (Array.isArray(value)) {
          return value.every(checkValue);
      } else if (typeof value === 'object' && value !== null) {
          return checkForCompleted(value);
      } else {
          return value !== "";
      }
    };

    return Object.values(restData).every(checkValue);
  }
}

export function checkPartiallyFilled(data) {
  if (!Object.keys(data).length) {
    return false;
  } else {
    const checkValue = (value) => {
      if (Array.isArray(value)) {
            return value.some(checkValue);
        } else if (typeof value === 'object' && value !== null) {
            return checkPartiallyFilled(value);
        } else {
            return value !== "";
        }
    };

    return Object.values(data).some(checkValue);
  }
};