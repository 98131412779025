import StorageProvider from "../framework/main/StorageProvider";

export const isLocalhost = () => {
  return (
    location.hostname === "localhost" || 
    location.hostname === "127.0.0.1" || 
    location.hostname === ""
  )
}

export const clearStorages = async () => {
  await StorageProvider.clear();
  window.localStorage.clear();
  window.sessionStorage.clear();

  // to clear all cookies for the current domain
  document.cookie.split(";").forEach(cookie => (
    document.cookie = cookie.split("=")[0] + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/"
  ));
};

export const getUrlAndParams = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  return {
    url,
    params
  };
};

export const removeUrlParams = (hardRemove = false) => {
  if (!isLocalhost() || hardRemove) {
    const url = new URL(window.location.href);

    url.search = '';
  
    window.history.replaceState({}, document.title, url.href);
  }
};

export const getVisibleElementsByClassName = (className: string): HTMLElement[] => {
  // Get all elements by class name
  const elements = document.getElementsByClassName(className);
  const visibleElements = [];

  // Check visibility of each element
  for (let i = 0; i < elements.length; i++) {
      const element = elements[i] as HTMLElement;
      const style = window.getComputedStyle(element);
      const isVisible = element.offsetWidth > 0 && element.offsetHeight > 0 && style.display !== 'none' && style.visibility !== 'hidden';
      
      if (isVisible) {
          visibleElements.push(element);
      }
  }

  return visibleElements;
}

export const jsonToArray = (jsonString: { [key: string]: string }) => {
  const jsonObject = jsonString;
  const idNamePairs = Object.values(jsonObject).map((key, index) => ({ id: index, name: key }));

  return idNamePairs;
}
