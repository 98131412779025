import { BlockComponent } from "../../../framework/main/BlockComponent";
import { runEngine } from "../../../framework/main/RunEngine";
import { Message } from "../../../framework/main/Message";

import { WithStyles } from "@material-ui/core";

export const configJSON = require("./config");
import MessageEnum, {
  getName,
} from "../../../framework/main/Messages/MessageEnum";

import { parseErrors } from "../../utilities/main/Toast";
import StorageProvider from '../../../framework/main/StorageProvider';
import { getAuth, Auth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { SIGN_UP_STEPS, STEPS_TYPE } from "../../../utils/enums";
import { clearStorages } from "../../../helpers/other";
import { getProfile } from "../../../redux/services/profile";

export interface Props extends WithStyles<any> {
  navigation?: any;
  id?: string;
  email?: string;
  token?: string;
}

export interface S {
  email: string;
  loading: boolean;
  isAgreed: boolean;
  errors: any;
  privacyPolicyLink: any,
  termAndConditionLink: any,
}

export interface SS {
  id: any;
}

export default class SignUpController extends BlockComponent<Props, S, SS> {
  referralCode: string | null = ""
  token: string;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      email: '',
      loading: false,
      isAgreed: true,
      errors: "",
      privacyPolicyLink: null,
      termAndConditionLink: null,
    };
  }

  async componentDidMount() {
    super.componentDidMount();

    const currentURL = window.location.href;

    const token = await StorageProvider.getItem("user-token");
    this.token = token;

    if (token) {
      const { data, error } = await getProfile();

      if (data && !error) {
        this.setState({
          email: data.attributes.user_email
        })

        // this.resendOtp(data.attributes.user_email);
      }
    }

    this.getTncAndPrivacyPolicyLinks()

    const url = new URL(currentURL)

    if (url.pathname === '/signup') {
      this.getReferralCode()
    }
  }

  getReferralCode = async () => {
    const currentURL = new URL(window.location.href)
    const query = currentURL.searchParams

    const code = query.get("referral_code") ?? await StorageProvider.getItem("referral_code")
    if (code) { this.referralCode = code }
    let url = `${currentURL.origin}${currentURL.pathname}`

    if (this.referralCode) { url = `${url}?referral_code=${this.referralCode}` }
    window.history.replaceState({ path: url }, "", url);
    await StorageProvider.removeItem("referral_code");
  }

  saveReferralCode = async () => {
    if (!this.referralCode) {
      return
    }
    await StorageProvider.setItem("referral_code", this.referralCode);
  }

  createAccountApiCallId: any;
  apiEmailSignupCallId: string = "";
  socialLoginMessageId: string = "";
  getPrivacyPolicyMessageId: string = "";
  getTermAndConditionMessageId: string = "";
  apiOtpVerificationCallId: string = "";
  resendOtpApiCallId: string = "";
  tncAndPrivacyPolicyApiCallId: string = "";

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson1 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (message.properties.RestAPIResponceDataMessage && responseJson1) {
        this.handleAPISignupResponse(apiRequestCallId, responseJson1);
        this.handleAPIOtpVerificationResponse(apiRequestCallId, responseJson1);
        this.handelResendOtp(apiRequestCallId, responseJson1);
        this.handelTncAndPrivacyResponse(apiRequestCallId, responseJson1);
        this.handleGoogleSignup(apiRequestCallId, responseJson1);
      }
    }
  }

  handleGoogleSignup = async (apiRequestCallId: string, responseJson1: any) => {
    if (apiRequestCallId === this.socialLoginMessageId) {
      if (!!responseJson1.data) {
        if (responseJson1.data.attributes.is_account_existed) {
          this.setState({ errors: "Google email already exists." });
          return;
        }
        await StorageProvider.setItem('user-token', responseJson1.meta.token);
        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(
          getName(MessageEnum.NavigationTargetMessage),
          'CreateAccount'
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        this.send(message);
      } else {
        const errors = parseErrors(responseJson1);
        this.setState({ errors });
      }

      this.setState({ 
        loading: false 
      });
    }
  }
  //Google Login
  socialGoogleLogin = async () => {
    try {
      const auth: Auth = getAuth();
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider) as any;
      this.doLogin(result.user.accessToken, result.user.email)
    } catch (error) {
      const errorMessage = (error as any).message;
      this.setState({ errors: errorMessage });
    }
  };

  doLogin = async (token: string, email: string) => {
    await StorageProvider.setItem('user-email', email);

    this.setState({
      loading: true
    });

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const httpsBody: any = {
      data: {
        type: "social_account",
        attributes: {
          unique_auth_id: token,
        },
      },
    };

    if (this.referralCode) {
      httpsBody.data.attributes.referral_code = this.referralCode
    }

    await StorageProvider.setItem('user-email', email);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.socialLoginMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.socialLoginAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpsBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  onClose = () => {
    this.setState({ errors: "" });
  };

  goToLogin = async () => {
    await clearStorages();

    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  handleCheck = () => {
    this.setState({ isAgreed: !this.state.isAgreed });
  };

  onBackChooseOption = async () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      // 'ChooseOption'
      'SignUpEmail'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);

    await this.saveReferralCode()
  }

  onBackSignUp = async () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'SignUpEmail'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  async doSignup(email: string): Promise<boolean> {
    if (!email?.trim()?.length) {
      return false;
    }

    this.setState({ 
      loading: true 
    });

    const body = {
      email,
      "account_type": "professional"
    };

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const dataBody = {
      type: "email_account",
      attribute: body,
    };

    const httpsBody = {
      data: dataBody,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailSignupCallId = requestMessage.messageId;

    let endPoint = configJSON.signupApiEndPoint

    if (this.referralCode) {
      endPoint = `${configJSON.signupApiEndPoint}?referral_code=${this.referralCode}`
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpsBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  async resendOtp(targetEmail) {
    const email = targetEmail || window.localStorage.getItem("sign-up-email");

    this.setState({
      loading: true
    });

    const body = {
      email,
    };

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const dataBody = {
      type: "email_account",
      attribute: body,
    };

    const httpsBody = {
      data: dataBody,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resendOtpApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendOTPApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpsBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  async getTncAndPrivacyPolicyLinks() {
    this.setState({
      loading: true
    });

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.tncAndPrivacyPolicyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPrivacyPolicyApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  // Handel API Responses
  async handleSubmitVerificationCode(values: any) {
    if (values === null || values === undefined) {
      return false;
    }

    this.setState({
      loading: true
    });

    const body = {
      otp: values.signupCode,
    };

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiOtpVerificationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiOtpVerificationApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleAPISignupResponse(apiRequestCallId: string, responseJson1: any) {
    if (apiRequestCallId === this.apiEmailSignupCallId) {
      if (!!responseJson1.data) {
        this.handleSuccessfulSignupResponse(responseJson1);
      } else {
        const errors = parseErrors(responseJson1);
        this.setState({ errors });
      }

      this.setState({ 
        loading: false 
      });
    }
  }

  async handleSuccessfulSignupResponse(responseJson1: any) {
    if (responseJson1?.data?.attributes && responseJson1?.meta?.token) {
      window.localStorage.setItem("sign-up-email", responseJson1.data.attributes.email);
      window.localStorage.setItem(STEPS_TYPE.SIGN_UP, SIGN_UP_STEPS.VERIFY_EMAIL);
      await StorageProvider.setItem("user-token", responseJson1.meta.token);
      await StorageProvider.setItem("user-refresh-token", responseJson1.meta.refresh_token);

      const msg: Message = new Message(
        getName(MessageEnum.VerifySignUpCodeScreenMessage)
      );

      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

      this.send(msg);
    }
  }

  async handleAPIOtpVerificationResponse(
    apiRequestCallId: string,
    responseJson1: any
  ) {
    if (apiRequestCallId === this.apiOtpVerificationCallId) {
      if (responseJson1.meta) {
        if (responseJson1.meta.token) {
          window.localStorage.setItem(STEPS_TYPE.SIGN_UP, SIGN_UP_STEPS.ADD_BASIC_INFO);
          await StorageProvider.setItem("user-token", responseJson1.meta.token);
          await StorageProvider.setItem("user-refresh-token", responseJson1.meta.refresh_token);

          this.props.navigation.navigate("CreateAccount");
        }
        this.setState({ errors: { email: responseJson1.meta.message } });
      } else {
        const errors = parseErrors(responseJson1);
        this.setState({ errors: { signupCode: errors } });
      }

      this.setState({
        loading: false
      });
    }
  }

  async handelResendOtp(apiRequestCallId: string, responseJson1: any) {
    if (apiRequestCallId === this.resendOtpApiCallId) {
      if (responseJson1.meta) {
        if (responseJson1.meta.token) {
          await StorageProvider.setItem("user-token", responseJson1.meta.token);
          await StorageProvider.setItem("user-refresh-token", responseJson1.meta.refresh_token);
        }
      } else {
        const errors = parseErrors(responseJson1);
        this.setState({ errors: { signupCode: errors } });
      }

      this.setState({
        loading: false
      });
    }
  }

  handelTncAndPrivacyResponse(apiRequestCallId: string, responseJson1: any) {
    if (apiRequestCallId === this.tncAndPrivacyPolicyApiCallId) {
      if (responseJson1.data) {
        this.setState({
          privacyPolicyLink: responseJson1?.data?.privacy_policy?.link,
          termAndConditionLink: responseJson1?.data?.terms_and_condition?.link,
        })
      }

      this.setState({
        loading: false
      });
    }
  }

}
