import { IBlock } from "../../../framework/main/IBlock";
import MessageEnum, {
  getName
} from "../../../framework/main/Messages/MessageEnum";
import { Message } from "../../../framework/main/Message";
import { BlockComponent } from "../../../framework/main/BlockComponent";

import { runEngine } from "../../../framework/main/RunEngine";

import { WithStyles } from "@material-ui/core";

import { parseErrors } from "../../utilities/main/Toast";
import StorageProvider from "../../../framework/main/StorageProvider";
import { enqueueSnackbar } from "notistack";

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation: any;
}

interface S {
  enablePasswordField: boolean;
  enablePasswordField1: boolean;
  isCapital: boolean;
  isLower: boolean;
  isNumeric: boolean;
  isLengthy: boolean;
  email: string | null;
  isLoad: boolean;
  btnConfirmPasswordShowHide: boolean;
  countdown: number;
}

interface SS {
  navigation: any;
}

export default class ForgotPwdController extends BlockComponent<Props, S, SS> {
  forgotPasswordId: any;
  resetPasswordId: any;
  resendPasswordId: any;
  interval: any;
  token: string;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      enablePasswordField1: true,
      isCapital: false,
      isLower: false,
      isNumeric: false,
      isLengthy: false,
      email: null,
      isLoad: false,
      enablePasswordField: false,
      btnConfirmPasswordShowHide: false,
      countdown: 120
    };
    this.receive = this.receive.bind(this);
    this.goToLogin = this.goToLogin.bind(this);
    this.onBackButtonNavigation = this.onBackButtonNavigation.bind(this);
    this.navigateToPasswordRecovery = this.navigateToPasswordRecovery.bind(
      this
    );
    this.navigateToPasswordRecovery = this.navigateToPasswordRecovery.bind(
      this
    );

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    const token = await StorageProvider.getItem("user-token");
    this.token = token;

    const targetEmail = window.localStorage.getItem("sign-up-email");
    const url = new window.URL(window.location.href);

    if (url.pathname !== '/forgot-password' && !targetEmail) {
      this.props.navigation.navigate('/');

      return;
    }

    this.startCounter();

    // const url = new window.URL(window.location.href);
    // this.setState({ 
    //   email: url.searchParams.get("email"),
    // });
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.forgotPasswordId) {
        this.handelforgotPasswordAPICallId(responseJson);
      }
      if (apiRequestCallId == this.resetPasswordId) {
        this.handelresetPasswordAPICallId(responseJson);
      }
      if (apiRequestCallId === this.resendPasswordId) {
        this.handelresendPasswordAPICallId(responseJson);
      }
    }
  }

  forgotPasswordApi(email: string) {
    const header = {};
    this.setState({ isLoad: true });

    const formData = new FormData();
    formData.append("email", email);
    window.localStorage.setItem("sign-up-email", email);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.forgotPasswordId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  resendPasswordApi(email: string) {
    const header = {};
    this.setState({ isLoad: true });

    const formData = new FormData();
    formData.append("email", email);
    window.localStorage.setItem("sign-up-email", email);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resendPasswordId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleClickShowPassword(type: number) {
    if (type === 1) {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
    } else {
      this.setState({ enablePasswordField1: !this.state.enablePasswordField1 });
    }
  }

  resetPasswordApi(password: string, confirmPassword: string) {
    const url = new window.URL(window.location.href);

    const header = {
      token: this.token
    };
    this.setState({ isLoad: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resetPasswordId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.resetPasswordEndpoint}?token=${url.searchParams.get("token")}&email=${
        url.searchParams.get("email")
      }`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const formData = new FormData();
    formData.append("new_password", password);
    formData.append("password_confirmation", confirmPassword);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  startCounter() {
    clearInterval(this.interval);
    this.setState({ countdown: 120 });
    this.interval = setInterval(() => {
      this.setState(
        (prevState) => ({
          countdown: prevState.countdown > 0 ? prevState.countdown - 1 : 0
        }),
        () => {
          if (this.state.countdown === 0) {
            clearInterval(this.interval);
          }
        }
      );
    }, 1000);
  }

  onBackButtonNavigation() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  navigateToPasswordRecoverySuccess() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPsswordRecoverySuccessMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  navigateToPasswordRecovery() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPsswordRecoveryMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  
  zeroPad(value: any) {
    return value < 10 ? `0${value}` : value;
  }

  handelforgotPasswordAPICallId(responseJson: any) {
    this.setState({ isLoad: false });
    this.startCounter();
    if (responseJson && responseJson.success) {
      this.navigateToPasswordRecovery();
    } else {
      const errors = parseErrors(responseJson);

      enqueueSnackbar(
        errors,
        { variant: 'error' }
      )
    }
  }
  handelresetPasswordAPICallId(responseJson: any) {
    this.setState({ isLoad: false });
    if (responseJson && responseJson.meta) {
      this.navigateToPasswordRecoverySuccess();
    } else {
      const errors = parseErrors(responseJson);
      
      enqueueSnackbar(
        errors,
        { variant: 'error' }
      )
    }
  }
  handelresendPasswordAPICallId(responseJson: any) {
    this.setState({ isLoad: false });
    if (responseJson && responseJson.success) {
      this.startCounter();
    }
  }
}
