import React from 'react';
import { PersistGate as PersistGateProvider } from 'redux-persist/integration/react';
import { persistor } from '../redux/store';

export function PersistProvider({ children }) {
  return (
    <PersistGateProvider loading={children} persistor={persistor}>
      {children}
    </PersistGateProvider>
  );
}
