import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { api } from "./api";
import exampleReducer from "./features/example";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";

// Persist configuration
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["example"]
};

// Combine all reducers
const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  example: exampleReducer
});

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      }
    }).concat(api.middleware as any),
  devTools: process.env.REACT_APP_ENVIRONMENT === "development"
});

// Create persistor
export const persistor = persistStore(store);

// Define RootState type including the persist state
export type RootState = ReturnType<typeof rootReducer> & {
  _persist: {
    version: number;
    rehydrated: boolean;
  };
};

// Define AppDispatch type
export type AppDispatch = typeof store.dispatch;
