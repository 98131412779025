import React from "react";
import _ from 'lodash';
import {
  Grid,
  Button,
  Container,
  Backdrop,
  CircularProgress,
  IconButton
} from "@material-ui/core";
import * as Yup from "yup";
import CompanyProgressHeaderWeb from "../../../components/main/CompanyProgressHeader.web";
import MustHave from "../../../components/main/MustHave";
// import ProblemStatement from "./ProblemStatement.web";
import { accordup, accordupGreen, accordupYellow } from "./assets";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import OfferTheCompanyController, {
  initialOfferValues,
  Props
} from "./OfferTheCompanyController";
import { Form, Formik } from "formik";
import CorporatePartners from "./CorporatePartners.web";
import ModularProducts from "./ModularProducts.web";
import { modalInfoContent } from "../utils/data";
import PrimaryQuestionWithFormik from "./PrimaryQuestionWithFormik.web";
import { ProblemStatementWithFormik } from "./ProblemStatementWithFormik.web";
import ClearAllInformationDialog from "./ClearAllInformationDialog.web";
import SkipOnboardingConfirmationDialog from "../../../components/main/SkipOnboardingConfirmationDialog";
import { enqueueSnackbar } from "notistack";
import { maxWords } from "../../../helpers/yup";
import { getVisibleElementsByClassName } from "../../../helpers/other";
import { ReactComponent as BackButtonIcon } from '../../../assets/icons/back_button.svg';

const offeringsSchema = Yup.object().shape({
  performanceAnalystics: Yup.array().of(
    Yup.string()
      .matches(/^[^\s]/, "Cannot start with spaces")
      .max(320, "Maximum 320 characters allowed")
  ),
  productTestimonials: Yup.array().of(
    Yup.string()
      .matches(/^[^\s]/, "Cannot start with spaces")
      .max(480, "Maximum 480 characters allowed")
  ),
  corporatePartners: Yup.array().of(
    Yup.object().shape({
      benifit: Yup.string()
        .matches(/^[^\s]/, "Cannot start with spaces")
        .max(200, "Maximum 200 characters allowed"),
      partner: Yup.array().of(
        Yup.object().shape({
          name: Yup.string()
            .matches(/^[^\s]/, "Cannot start with spaces")
            .max(120, "Maximum 120 characters allowed"),
          description: Yup.string()
            .matches(/^[^\s]/, "Cannot start with spaces")
            .max(200, "Maximum 200 characters allowed"),
          benifit: Yup.string()
            .matches(/^[^\s]/, "Cannot start with spaces")
            .max(200, "Maximum 200 characters allowed")
        })
      )
    })
  ),
  productAndFeature: Yup.array()
    .of(
      Yup.object().shape({
        title: Yup.string()
          .matches(/^[^\s]/, "Cannot start with spaces")
          .concat(maxWords(30, "Maximum 150 words allowed")),
        description: Yup.string()
          .matches(/^[^\s]/, "Cannot start with spaces")
          .concat(maxWords(300, "Maximum 150 words allowed")),
      })
    )
    .test('first-item-required', null, function (value) {
      if (!value || value.length === 0) return false;
      const firstItem = value[0];
      const errors = {};

      if (!firstItem?.title?.length) {
        errors['title'] = 'This should not be blank.';
      }
      if (!firstItem?.description?.length) {
        errors['description'] = 'This should not be blank.';
      }

      if (Object.keys(errors).length > 0) {
        return this.createError({
          path: 'productAndFeature[0]',
          message: errors as string,
        });
      }

      return true;
    }),
  offeringServices: Yup.array().of(
    Yup.object().shape({
      title: Yup.string()
        .matches(/^[^\s]/, "Cannot start with spaces")
        .concat(maxWords(30, "Maximum 30 words allowed")),
      description: Yup.string()
        .matches(/^[^\s]/, "Cannot start with spaces")
        .concat(maxWords(130, "Maximum 120 words allowed")),
    })
  ),
  theTeam: Yup.array().of(
    Yup.object().shape({
      full_name: Yup.string()
        .matches(/^[^\s]/, "Cannot start with spaces")
        .max(120, "Maximum 120 characters allowed"),
      bio: Yup.string()
        .matches(/^[^\s]/, "Cannot start with spaces")
        .max(1200, "Maximum 1200 characters allowed")
    })
  ),
  modularProducts: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .matches(/^[^\s]/, "Cannot start with spaces")
        .max(120, "Maximum 120 characters allowed"),
      description: Yup.string()
        .matches(/^[^\s]/, "Cannot start with spaces")
        .max(600, "Maximum 600 characters allowed"),
      price: Yup.number()
        .typeError("Must be a number")
        .test(
          "decimal-places",
          "Must have up to 3 decimal places",
          (data) => (
            (data?.toString().split('.')[1] || []).length <= 3
          )
        ),
      currency: Yup.string()
    })
  ),
  packagesAndPricing: Yup.array().of(
    Yup.object().shape({
      offering: Yup.string()
        .matches(/^[^\s]/, "Cannot start with spaces")
        .max(120, "Maximum 120 characters allowed"),
      description: Yup.string()
        .matches(/^[^\s]/, "Cannot start with spaces")
        .max(600, "Maximum 600 characters allowed"),
      price: Yup.number()
        .typeError("Must be a number")
        .test(
          "decimal-places",
          "Must have up to 3 decimal places",
          (data) => (
            (data?.toString().split('.')[1] || []).length <= 3
          )
        ),
      currency: Yup.string()
    })
  ),
  whyExist: Yup.array()
    .required("Min 10 and max 20 designations")
    .min(10, "Minimum 10 designations allowed")
    .max(20, "Maximum 20 designations allowed"),
});

type OfferFormValuesType = {
  whyExist: string[];
  what_is_it: string;
  performanceAnalystics: any;
  productTestimonials: any;
  corporatePartners: any;
  modularProducts: any;
  packagesAndPricing: any;
  theTeam: any;
  [key: string]: any;
};

export default class Offering extends OfferTheCompanyController {
  handleSearchDebounced: any;
  formRef: any;

  constructor(props: Props) {
    super(props);

    this.setState({
      setAutoSaveData: this.props.setAutoSaveData
    })

    this.formRef = React.createRef();
    this.handleSearchDebounced = _.debounce(this.autoSave, 1500);
  }

  async componentDidMount() {
    super.componentDidMount();

    this.setState({
      setAutoSaveData: this.props.setAutoSaveData,
      formRef: this.formRef.current
    })

    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  async componentWillUnmount() {
    super.componentWillUnmount();

    this.autoSave();

    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };

  autoSave = async () => {
    // if (this.formRef && 'current' in this.formRef) {
    //   this.props.setAutoSaveData('offerings', this.formRef.current?.values);

    //   if (!Object.keys(this.formRef.current?.errors)?.length && this.formRef.current?.dirty) {
    //     this.saveAndContinueButton(this.formRef.current.values, true);
    //   }
    // }
  };

  handleScrollToError = () => {
    setTimeout(() => {
      const elements = getVisibleElementsByClassName('Mui-error');

      if (elements.length) {
        elements[0].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
      }
    }, 500);
  }

  render() {
    const { alreadyFilledFormValues } = this.state;
    const haveInitData = Object.keys(alreadyFilledFormValues).length > 0;
    const formikIntialValues = this.props.autoSavedValues?.offerings
      ? this.props.autoSavedValues.offerings
      : haveInitData
        ? alreadyFilledFormValues
        : initialOfferValues;

    return (
      <>
        <>
          <Backdrop style={webStyle.backdropBg} open={this.state.loading}>
            <CircularProgress color="inherit" />
          </Backdrop>

          {!this.props.hideBackBtn && (
            <IconButton
              size="small"
              style={{
                position: 'absolute',
                left: '45px',
                top: '100px',
              }}
              onClick={() => {
                this.setState({
                  skipModalOpen: true
                })
              }}
            >
              <BackButtonIcon />
            </IconButton>
          )}

          <Grid container style={webStyle.servicesMainContainer}>
            <CompanyProgressHeaderWeb
              onConfirm={this.getAPIData}
              primaryHeading={this.state.progressHeaderPrimaryHeading}
              secondaryHeading={this.state.progressHeaderSecondaryHeading}
            />
            <Grid item style={webStyle.displayCol}>
              <Formik
                innerRef={this.formRef as any}
                initialValues={formikIntialValues}
                validationSchema={offeringsSchema}
                enableReinitialize
                isInitialValid={false}
                onSubmit={(values: any) => {
                  this.saveAndContinueButton(values);
                }}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  setFieldValue,
                  resetForm,
                  touched,
                }) => (
                  <Form 
                    translate={undefined}
                    onKeyDown={this.handleSearchDebounced}
                  >
                    <MustHave
                      btnDisplay={false}
                      data-test-id="offermustHaveTestId"
                      handleChange={this.handleChange}
                      addItem={this.addChipLabels}
                      customlistTitle={"What is it? (semantic keywords)"}
                      inputList={[]}
                      textBoxDisplay={false}
                      headerName={"MUST HAVE"}
                      iconName={accordup}
                      serviceArray={this.state.serviceArray1}
                      labelsDisplay={true}
                      formikValue={values}
                      formikTouched={touched}
                      mustHaveExpanded={this.state.mustHaveExpanded}
                      chipLabels={values.whyExist}
                      handleExpand={this.handleExpand}
                      deleteChipByName={this.deleteChipByName}
                      onChangeChip={(updated: string[]) => {
                        setFieldValue("whyExist", updated);
                        this.handleSearchDebounced();
                      }}
                      chipError={this.checkValidation(
                        !!touched.whyExist,
                        errors.whyExist as string
                      )}
                      name={"whyExist"}
                    />
                    {this.state.mustHaveExpanded && (
                      <>
                        <ProblemStatementWithFormik
                          data-test-id={"problemStatementTestId2"}
                          navigation={""}
                          id=""
                          title={"Products and Features*"}
                          inputList={this.state.offeringsProductsInputList}
                          serviceArray={this.state.offeringsProductArray}
                          btnDisplay={true}
                          buttonName={"Add products"}
                          name={`productAndFeature`}
                          formikHandelChange={handleChange}
                          formikValue={values}
                          formikError={errors}
                          touched={touched}
                          maxFeilds={15}
                          requiredIndex={0}
                        />
                        <ProblemStatementWithFormik
                          data-test-id={"problemStatementTestId"}
                          navigation={""}
                          id=""
                          title={"Services*"}
                          inputList={this.state.offeringsServicesInputList}
                          serviceArray={this.state.offeringsServicesArray}
                          btnDisplay={true}
                          buttonName={"Add services"}
                          name={`offeringServices`}
                          formikHandelChange={handleChange}
                          formikValue={values}
                          formikError={errors}
                          touched={touched}
                          maxFeilds={15}
                          requiredIndex={0}
                        />
                      </>
                    )}

                    <MustHave
                      btnDisplay={false}
                      data-test-id="offershouldHaveTestId"
                      customlistTitle={"The Team"}
                      inputList={[]}
                      textBoxDisplay={true}
                      iconName={accordupGreen}
                      headerName={"SHOULD HAVE"}
                      labelsDisplay={false}
                      serviceArray={this.state.shouldHaveServiceArray}
                      mustHaveExpanded={this.state.shouldHaveExpanded}
                      handleExpand={this.handleExpand1}
                      chipLabels={[]}
                      deleteChipByName={this.deleteChipByName}
                      offeringText={true}
                      formikHandelChange={handleChange}
                      formikValue={values}
                      formikError={errors}
                      formikTouched={touched}
                      inputOfferingList={{
                        showListHeader: true,
                        inputList: this.state.offeringsTeamInputList,
                        name: "theTeam"
                      }}
                      name={"offershouldHave"}
                    />
                    {this.state.shouldHaveExpanded && (
                      <>
                        <PrimaryQuestionWithFormik
                          data-test-id={"PrimaryQuestion5"}
                          navigation={""}
                          id=""
                          title={"Product Testimonials"}
                          inputList={this.state.productTestimonialInputList}
                          serviceArray={this.state.productTestimonial}
                          btnDisplay={true}
                          buttonName={"Add a Testimonial"}
                          name={`productTestimonials`}
                          formikHandelChange={handleChange}
                          formikValue={values}
                          formikError={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          maxFeilds={3}
                        />
                        <PrimaryQuestionWithFormik
                          data-test-id={"PrimaryQuestion5"}
                          navigation={""}
                          id=""
                          title={"Performance Analytics"}
                          inputList={
                            this.state.performanceAnalyticsQuestionList
                          }
                          serviceArray={
                            this.state.performanceAnalyticsServiceArr
                          }
                          btnDisplay={true}
                          buttonName={"Add"}
                          name={`performanceAnalystics`}
                          formikHandelChange={handleChange}
                          formikValue={values}
                          formikError={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          maxFeilds={5}
                        />
                      </>
                    )}

                    <MustHave
                      btnDisplay={true}
                      buttonName={"Add packages"}
                      maxFeilds={6}
                      showBtnFromMustHave={false}
                      data-test-id="offergoodToHaveTestId"
                      customlistTitle={"Packages & Pricing"}
                      inputList={[]}
                      textBoxDisplay={true}
                      iconName={accordupYellow}
                      headerName={"GOOD TO HAVE"}
                      labelsDisplay={false}
                      serviceArray={this.state.goodToHaveServiceArray}
                      mustHaveExpanded={this.state.goodToHaveExpanded}
                      handleExpand={this.handleExpand2}
                      chipLabels={[]}
                      deleteChipByName={this.deleteChipByName}
                      offeringText={true}
                      formikHandelChange={handleChange}
                      formikValue={values}
                      formikError={errors}
                      formikTouched={touched}
                      inputOfferingList={{
                        showListHeader: true,
                        inputList: this.state.offeringsgoodToHaveInputList,
                        handleChange: (e: any, index: number) => {
                          setFieldValue(
                            `offering${index + 1}`,
                            e.target.value
                          );
                          this.handleCommonObjectChange(
                            e,
                            index,
                            "offeringsgoodToHaveInputList",
                            "offering"
                          );
                        },
                        handleChange1: (event: any, index: number) => {
                          setFieldValue(
                            `description${index + 1}`,
                            event.target.value
                          );
                          this.handleCommonObjectChange(
                            event,
                            index,
                            "offeringsgoodToHaveInputList",
                            "description"
                          );
                        },
                        handleChange2: (event: any, index: number) => {
                          setFieldValue(
                            `price${index + 1}`,
                            event.target.value
                          );
                          this.handleCommonObjectChange(
                            event,
                            index,
                            "offeringsgoodToHaveInputList",
                            "price"
                          );
                        },
                        handleChange3: (e: any, index: number) => {
                          setFieldValue(
                            `currency${index + 1}`,
                            e.target.value
                          );
                          this.handleCommonObjectChange(
                            e,
                            index,
                            "offeringsgoodToHaveInputList",
                            "currency"
                          );
                        },
                        goodToHave: true,
                        name: "packagesAndPricing"
                      }}
                      name={"offergoodToHave"}
                    />
                    {this.state.goodToHaveExpanded && (
                      <>
                        <ModularProducts
                          data-test-id="modularProducts"
                          title={"Modular Products & Services"}
                          id=""
                          navigation={""}
                          inputList={
                            this.state.offeringsModularProductsInputList
                          }
                          serviceArray={
                            this.state.offeringsModularProductsServiceArray
                          }
                          name="modularProducts"
                          formikHandelChange={(e) => {
                            handleChange(e);
                            this.handleSearchDebounced();
                          }}
                          formikValue={values.modularProducts}
                          formikError={errors.modularProducts}
                          tootipData={modalInfoContent.modularProducts}
                        />
                        <CorporatePartners
                          data-test-id="corporatePartners"
                          title={"Corporate Partners"}
                          id=""
                          navigation={""}
                          inputList={
                            this.state.offeringsCorporatePartnersInputList
                          }
                          inputList2={this.state.offeringsBenefits}
                          serviceArray={
                            this.state.offeringsCorporatePartnersServiceArray
                          }
                          name="corporatePartners"
                          formikHandelChange={handleChange}
                          formikValue={values}
                          formikError={errors}
                          tootipData={modalInfoContent.corporatePartners}
                        />
                      </>
                    )}

                    <Grid item style={webStyle.displayRow}>
                      <Button
                        variant="contained"
                        style={webStyle.clrBtn}
                        onClick={this.openSkipConfirmationDialog}
                      >
                        Skip onboarding
                      </Button>
                      <Button
                        variant="contained"
                        style={webStyle.clrBtn}
                        onClick={this.openClearAllInfoDilog}
                      >
                        Clear all data
                      </Button>
                      <Button
                        variant="contained"
                        type="submit"
                        style={webStyle.saveBtn}
                        onClick={() => {
                          console.log('errors', errors);

                          this.handleScrollToError();
                        }}
                      >
                        Save & continue
                      </Button>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </>

        <SkipOnboardingConfirmationDialog
          open={this.state.skipModalOpen}
          onClose={this.closeSkipConfirmationDialog}
          onYes={() => {
            this.saveAndContinueButton(this.formRef.current.values, true, true);
            this.closeSkipConfirmationDialog();
            this.redirectToPage('/home?afterSkipOnboarding=true');
          }}
          onNo={() => {
            this.closeSkipConfirmationDialog();
            this.redirectToPage('/home?afterSkipOnboarding=true');
          }}
        />

        <ClearAllInformationDialog
          open={this.state.clearAllInfoDialog}
          onClose={this.closeClearAllInfoDilog}
          onClear={() => {
            this.formRef.current.resetForm();
            this.clearAllInformation({...initialOfferValues, whyExist: []});
            this.props.setAutoSaveData('offerings', null);
            this.closeClearAllInfoDilog();
          }}
          onCancel={this.closeClearAllInfoDilog}
        />
      </>
    );
  }
}

const webStyle = {
  saveBtn: {
    height: '44px',
    borderRadius: "8px",
    background: "#57BE99",
    width: "50%",
    textTransform: "none",
    fontWeight: 600,
    boxSizing: 'border-box'
  } as CSSProperties,
  displayRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
    marginTop: '20px'
  },
  clrBtn: {
    height: '44px',
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    background: "#FFF",
    width: "50%",
    textTransform: "none",
    fontWeight: 600
  } as CSSProperties,
  servicesMainContainer: {
    width: '810px',
    display: "flex",
    justifyContent: "center",
    flexDirection: "column" as "column",
    margin: '0px auto',
    padding: '0 0 80px 0'
  },
  servicesContainer: {
    background: "#fff",
    borderRadius: "28px",
    padding: "32px 40px",
    height: "max-content"
  },
  offeringBox: {
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap" as "wrap",
    marginBottom: "2%"
  } as any,
  displayCol: {
    display: "flex",
    flexDirection: "column",
    gap: "10px"
  } as CSSProperties,
  backdropBg: {
    zIndex: 9999999999999,
    color: "#fff"
  }
};
