import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { routeMap } from '../helpers/routes';
import PlatformLayout from '../layouts/PlatformLayout';
import CarouselLayout from '../layouts/CarouselLayout';
import OnboardingLayout from '../layouts/OnboardingLayout';
import { LAYOUTS } from '../utils/enums';
import { Box } from '@material-ui/core';
import { matchRoute } from '../helpers/routes';

const LayoutsProvider = ({ children, location }) => {
  const [layout, setLayout] = useState(null);

  useEffect(() => {
    const currentPath = location.pathname;
    const matchedRoute = Object.values(routeMap).find(item => matchRoute(currentPath, item.path));

    setLayout(matchedRoute?.layout || null);
  }, [location.pathname]);

  switch (layout) {
    case LAYOUTS.CAROUSEL_LAYOUT:
      return (
        <CarouselLayout>
          {children}
        </CarouselLayout>
      );
    case LAYOUTS.ONBOARDING_LAYOUT:
      return (
        <OnboardingLayout>
          {children}
        </OnboardingLayout>
      );
    case LAYOUTS.PLATFORM_LAYOUT:
      return (
        <PlatformLayout>
          {children}
        </PlatformLayout>
      );
    default:
      return (
        <Box style={{ zIndex: 1 }}>
          {children}
        </Box>
      );
  }
};

export const LayoutProvider = withRouter(LayoutsProvider);