Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint = "account/accounts";

exports.socialLoginAPIEndPoint = "login/login";

exports.marketListUrl = "bx_block_custom_user_subs/markets";
exports.submitBusinessPlan = "bx_block_custom_user_subs/small_business_plans";
exports.submitBusinessMoreInfo =
  "bx_block_custom_user_subs/small_business_plans/tell_us_bit_more";
exports.stripeCheckoutSessionAPIEndpoint =
  "bx_block_stripe_integration/payments/create_checkout_session";

exports.apiMethodTypeAddDetail = "POST";
exports.apiGetGoldPlanInfo = "bx_block_profile/company_detail/compnay_details";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";

exports.formErrorMessage = "Please enter details";

exports.signupApiEndPoint = "account_block/accounts";

exports.validateApiEndPoint = "otp_confirmation";

exports.signUpAboutEndPoint = "bx_block_profile/profiles";

exports.industriesEndPoint = "bx_block_profile/industries";
exports.generateDataEndpoint = "bx_builder_chain/parse_document";

exports.roleListEndPoint = "/roles";
exports.companySizeEndPoint = "bx_block_profile/company_sizes";
exports.gtmChallengesEndPoint = "bx_block_profile/gtm_challenges";

exports.createAccountEndPoint = "account_block/accounts/update_account";

exports.countryEndPoint = "bx_block_profile/industries/country";

exports.createDescriptionEndPoint = "/create_description";

exports.createDescribesCompanyEndPoint = "/describes_company";
exports.buidlerChainAPI = "bx_builder_chain/upload_gold_flow_to_builder_chain";

exports.companytellUsEndPoint = "bx_block_profile/profiles";

exports.companytHearAboutEndPoint = "bx_block_profile/industries/hear_about_us";

exports.validationApiMethodType = "GET";
exports.postApiMethodType = "POST";
exports.googleLoginAPIEndPoint = "account_block/accounts";
exports.getPrivacyPolicyApiEndPoint =
  "bx_block_terms_and_conditions/privacy_policies";
exports.getTermAndConditionApiEndPoint =
  "bx_block_terms_and_conditions/terms_and_conditions";
exports.apiOtpVerificationApiEndPoint = "/otp_confirmation";
exports.resendOTPApiEndPoint = "account_block/accounts/resent_signup_otp";
exports.musthaveEndPoint = "bx_block_profile/company_detail";

exports.webTitle = "What is your company website?";
exports.sizeTitle = "What is the size of your GTM team?";
exports.industryTitle = "What Industry are you in?";
exports.roleTitle = "What is your role?";
exports.aboutSelfTitle = "Tell us about yourself";
exports.aboutSelfSubTitle =
  "This lets us send tailored updates like new pebble launches.";
exports.serviceModal =
  "In this section, essential services are presented alongside the distinct benefits they provide to clients. Envision it as matching a service with its outcome. The objective here is to associate each service directly with its tangible benefits, simplifying the decision-making process for prospective clients by highlighting its significance.";
exports.offeringLabel = "Offering";
exports.ProblemLabel = "Problem Statement ";
exports.offerPlaceholder = "GTM Training & Support";
exports.ProblemPlaceholder = "Exclusive GTM Expertise";
exports.prbDescriptionPlaceholder =
  "As maturating companies grow, they often squander resources on unproductive tasks, misallocate time and money, and grapple with ineffective GTM strategies, leading to nearly 72% (SalesForce, 2022) of sales professionals missing their annual quotas. Such inefficiencies directly hamper accelerated, high-margin growth";
exports.descriptionLabel = "Description";
exports.descriptionPlaceholder =
  "We go the extra mile to teach you the process of going to market from funnel management to sales best-practices.";
exports.missionStatement = "Mission Statement";
// exports.maxCharacters = "Max 600 characters";
// exports.maxWords = "Max 150 words";
// exports.characterLimit = "/600 characters";
// exports.wordsLimit = "/150 words";
exports.missinPlaceholder =
  "We champion ambitious leaders with our science-led go-to-market strategies, fuelling success from early-stage, scale to expansion.";
exports.uniqueSelling = "Unique Selling Proposition (USP)";
exports.uniqueMaxCharacters = "Max 800 characters";
exports.uniqueCharacterLimit = "/800 characters";
exports.uniquePlaceholder =
  "We persistently book superior results for our clients through our innovative go-to-market methodologies (GTMS™), developed for small and mid-sized companies and executed by world-class teams.";
exports.missionInfo =
  "Ask yourself, what's wrong with the world and how do you intend to fix it? A Company Mission Statement is a concise declaration of a company's main purpose and guiding principles. It answers the question, 'Why do we exist?' It helps both employees and customers understand what the company stands for and its main objectives.";
exports.uniqueSellInfo =
  "The USP is a clear, concise statement that differentiates your company from competitors by spotlighting a unique benefit or feature. It succinctly conveys why customers across target segments should choose your product or service over others in the market.";
exports.addService = "Add Services";
exports.productFeature = "Products & Features";
exports.productModal =
  "In this section, the products or features are showcased along with the corresponding value each one offers to the customer. Think of it as pairing a tool with its purpose. The goal is to link each product or feature clearly with its real-world advantage, making it easier for potential users to understand why it's vital for them.";
exports.addProduct = "Add products";
