import React from "react";

import {
  Grid,
  Box,
  Typography,
  // TextareaAutosize,
  TextField
} from "@material-ui/core";

import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import InfoIcon from "./InfoIcon.web";

interface IProps {
  title: string;
  value: string;
  onChange: any;
  maxCharacters?: number;
  maxWords?: number;
  placeholder: string;
  minRows: number;
  modalContent: string;
  errors: any;
  name: any;
  required?: boolean;
}

export default class CustomTextArea extends React.Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    return (
      <>
        <Grid container style={webStyle.missionMainContainer}>
          <Grid item xs={12} style={webStyle.missionContainer}>
            <Box style={webStyle.infoBox}>
              <Typography style={webStyle.missionStatement}>
                {this.props.title}{this.props.required ? '*' : ''}
              </Typography>
              <InfoIcon htmlContent={this.props.modalContent} />
            </Box>
            <Box style={webStyle.maxCharacterBox}>
              <FiberManualRecordIcon style={webStyle.listIcon} />
              {this.props.maxWords
                ? <Typography style={webStyle.maxCharacter}>
                    Max {this.props.maxWords} words
                  </Typography>
                : this.props.maxCharacters
                  ? <Typography style={webStyle.maxCharacter}>
                      Max {this.props.maxCharacters} characters
                    </Typography>
                  : null
              }
            </Box>
            <TextField
              variant="outlined"
              multiline
              maxRows={12}
              id="outlined-multiline-static"
              placeholder={this.props.placeholder}
              minRows={1}
              value={this.props.value}
              InputProps={{
                style: {
                  ...webStyle.inputProps,
                  width: "100%",
                  fontStyle: this.props.value ? "normal" : "italic",
                  color: this.props.value ? "#000" : "#94A3B8"
                }
              }}
              style={{ ...webStyle.inputProps1, width: "100%" }}
              error={!!this.props.errors}
              name={this.props.name}
              onChange={e => this.props.onChange(e)}
            />
            {/* <TextareaAutosize
                            data-testid="text-field"
                            id="outlined-multiline-static"
                            placeholder={this.props.placeholder}
                            minRows={this.props.minRows}
                            value={this.props.value}
                            style={{
                                ...webStyle.inputProps,
                                border: this.props.errors && '1px solid red',
                            }}
                            onChange={(e) => this.props.onChange(e)}
                        /> */}
            <Typography style={webStyle.errorValidate}>
              {this.props.errors && this.props.errors}
            </Typography>
            {this.props.maxWords
              ? <Typography style={webStyle.characterLimit}>
                  {this.props?.value?.trim()?.split(' ')?.filter(item => item?.trim()?.length)?.length || 0}/{this.props.maxWords} words
                </Typography>
              : this.props.maxCharacters
                ? <Typography style={webStyle.characterLimit}>
                    {this.props?.value?.length || 0}/{this.props.maxCharacters} characters
                  </Typography>
                : null
            }
          </Grid>
        </Grid>
      </>
    );
  }
}

const webStyle = {
  inputProps: {
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontWeight: 400,
    lineHeight: "21px",
    padding: "10px"
  },
  inputProps1: {
    // padding: '10px',
  },
  missionMainContainer: {
    // background:'#EEE7D9',
    // height:'100vh',
    padding: "2% 0 0 0"
  },
  missionContainer: {
    background: "#fff",
    borderRadius: "28px",
    padding: "32px 40px",
    height: "max-content"
  },
  infoBox: {
    display: "flex",
    gap: "10px",
    alignItems: "center"
  },
  missionStatement: {
    fontWeight: 600
  },
  infoIcon: {
    fontSize: "1rem",
    color: "#3A84C9",
    cursor: "pointer"
  },
  listIcon: {
    fontSize: "0.5rem",
    color: "#475569"
  },
  maxCharacter: {
    fontSize: "0.87rem",
    color: "#475569"
  },
  maxCharacterBox: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    padding: "1% 1% 2.5%"
  },
  characterLimit: {
    textAlign: "end" as "end",
    fontSize: "0.75rem",
    fontWeight: 500,
    color: "#475569",
    paddingTop: "5px"
  },
  errorValidate: {
    fontFamily: "Montserrat",
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.2,
    color: "#DC2626",
    marginTop: "2px"
  }
};
