import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as RouterProvider } from 'react-router-dom';

ReactDOM.render(
  <RouterProvider>
    <App />
  </RouterProvider>, 
  document.getElementById("root")
);
