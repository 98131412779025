import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  modalBox: {
    width: '670px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '20px',
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    background: '#FFFFFF',
    boxSizing: 'border-box',
  },
  closeButton: {
    position: 'absolute',
    right: '20px',
    top: '20px',
  },
  title: {
    width: '380px',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '33px',
    textAlign: 'center',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: '8px',
    marginTop: '12px',
  },
}));