import { pathToRegexp } from "path-to-regexp";

// import InfoPage from "../blocks/info-page/main/InfoPageBlock";
import Cfdataintegrationviapromptengineering4 from "../blocks/cfdataintegrationviapromptengineering4/main/Cfdataintegrationviapromptengineering4";
// import Chatgpt3 from "../blocks/chatgpt3/main/Chatgpt3";
// import PhoneNumberInput from "../blocks/mobile-account-registration/main/PhoneNumberInput";
// import AdditionalDetailForm from "../blocks/mobile-account-registration/main/AdditionalDetailForm";
// import Emailnotifications2 from "../blocks/emailnotifications2/main/Emailnotifications2";
// import Cfauditinganalytics3 from "../blocks/cfauditinganalytics3/main/Cfauditinganalytics3";
// import OTPInputAuth from "../blocks/otp-input-confirmation/main/OTPInputAuth";
// import Documentstorage from "../blocks/documentstorage/main/Documentstorage";
// import Adminconsole2 from "../blocks/adminconsole2/main/Adminconsole2";
// import Chatbot4 from "../blocks/chatbot4/main/Chatbot4";
// import Customisableusersubscriptions from "../blocks/customisableusersubscriptions/main/Customisableusersubscriptions";
import SubscriptionDetails from "../blocks/customisableusersubscriptions/main/SubscriptionDetails";
// import ApiIntegration from "../blocks/apiintegration/main/ApiIntegration";
// import CountryCodeSelector from "../blocks/country-code-selector/main/CountryCodeSelector";
import TacticalOutreach from "../blocks/catalogue/main/TacticalOutreach";
// import Settings2 from "../blocks/settings2/main/Settings2";
// import SocialMediaAccountLoginScreen from "../blocks/social-media-account-login/main/SocialMediaAccountLoginScreen";
// import SocialMediaAccountRegistrationScreen from "../blocks/social-media-account-registration/main/SocialMediaAccountRegistrationScreen";
import EmailLoginBlock from "../blocks/email-account-login/main/EmailLoginBlock.web";
import WelcomePage from "../blocks/email-account-login/main/WelcomePage.web";
import ChooseyourOptions from "../blocks/email-account-login/main/ChooseyourOptions.web";
// import Cffinetuningimplementationofchatgpt3 from "../blocks/cffinetuningimplementationofchatgpt3/main/Cffinetuningimplementationofchatgpt3";
// import ForgotPwd from "../blocks/forgot-password/main/ForgotPwd";
// import Success from "../blocks/forgot-password/main/Success";
// import ResetSuccess from "../blocks/forgot-password/main/ResetSuccess";
// import ResetPwd from "../blocks/forgot-password/main/ResetPwd";
// import ForgotPasswordOTP from "../blocks/forgot-password/main/ForgotPasswordOTP";
import NewPassword from "../blocks/forgot-password/main/NewPassword.web";
// import Customform4 from "../blocks/customform4/main/Customform4";
// import StripePayments from "../blocks/stripepayments/main/StripePayments";
// import ImportExportData from "../blocks/importexportdata/main/ImportExportData";
// import TermsConditions from "../blocks/termsconditions/main/TermsConditions";
// import TermsConditionsDetail from "../blocks/termsconditions/main/TermsConditionsDetail";
// import TermsConditionsUsers from "../blocks/termsconditions/main/TermsConditionsUsers";
import BulkUploading from "../blocks/bulkuploading/main/BulkUploadingCompanyInfo.web";
import BulkUploadingImport from "../blocks/bulkuploading/main/BulkUploadingCompanyImport.web";
// import Cfchatgpttemplates from "../blocks/cfchatgpttemplates/main/Cfchatgpttemplates";
import LandingPage from "../blocks/landingpage/main/LandingPage.web";
// import EmailAccountRegistration from "../blocks/email-account-registration/main/EmailAccountRegistration";
import SignUpEmail from "../blocks/email-account-registration/main/SignUpEmail.web";
import About from "../blocks/email-account-registration/main/About.web";
import CompanyAbout from "../blocks/email-account-registration/main/CompanyAbout.web";
import TellBitMoreBusiness from "../blocks/email-account-registration/main/TellBitMoreBusiness.web";
import Subscription from "../blocks/customisableusersubscriptions/main/Subscription.web";
import BeforeWeStart from "../blocks/customisableusersubscriptions/main/BeforeWeStart.web";
// import Categoriessubcategories from "../blocks/categoriessubcategories/main/Categoriessubcategories";
import SignUp from "../blocks/email-account-registration/main/SignUp.web";
import LetsGetStarted from "../blocks/email-account-registration/main/LetsGetStarted.web";
import AccountDetail from "../blocks/email-account-registration/main/AccountDetail.web";
import CompanyDetail from "../blocks/email-account-registration/main/CompanyDetail.web";
import DescribleCompanyPlan from "../blocks/email-account-registration/main/DescribleCompanyPlan.web";
// import Profile from "../blocks/customisableuserprofiles/main/ProfileModal.web";
import ForgotPassword from "../blocks/forgot-password/main/ForgotPassword.web";
import PasswordRecovery from "../blocks/forgot-password/main/PasswordRecovery.web";
// import SignUpProfessional from "../blocks/email-account-registration/main/signUpProfessional.web";
import VerifySignUpCode from "../blocks/email-account-registration/main/VerifySignUpCode.web";
import PasswordRecoverySuccess from "../blocks/forgot-password/main/PasswordRecoverySuccess.web";
import CompanySuccess from "../blocks/email-account-registration/main/CompanySuccess.web";
import ChooseYourPath from "../blocks/choose-your-path/main/ChooseYourPath.web";
// import Services from "../blocks/email-account-registration/main/Services.web";
// import PrimaryQuestion from "../blocks/email-account-registration/main/PrimaryQuestion.web";
// import MissionStatement from "../blocks/email-account-registration/main/MissionStatement.web";
// import UniqueSelling from "../blocks/email-account-registration/main/UniqueSelling.web";
// import ProblemStatement from "../blocks/email-account-registration/main/ProblemStatement.web";
// import ProductAndFeature from "../blocks/email-account-registration/main/ProductsAndFeature.web";
import OnboardingLayout from "../blocks/email-account-registration/main/OnboardingLayout.web";
// import AboutTheCompany from "../blocks/email-account-registration/main/AboutTheCompany.web";
// import OfferTheCompany from "../blocks/email-account-registration/main/OfferTheCompany.web";
// import AboutYourApproach from "../blocks/email-account-registration/main/AboutYourApproach.web";
// import PaymentStatus from "../blocks/info-page/main/PaymentStatus.web";
import ChatHistory from "../blocks/chatbot4/main/ChatHistory.web";
import CompanyIntel from "../blocks/documentation/main/ComapnyIntel.web";
import Myworkflow from "../blocks/chatbot4/main/Myworkflow.web";
import SubscriptionExploreUpgrade from "../blocks/customisableusersubscriptions/main/SubscriptionExploreUpgrade.web";
import EmailCreation from "../blocks/multipageforms/main/EmailCreation.web";
import History from "../blocks/multipageforms/main/History.web";
// import MyLibrary from "../blocks/cfdataintegrationviapromptengineering4/main/MyLibrary.web";
import ShareAndEarn from "../blocks/referrals/main/ShareAndEarn.web";
// import CompanyProgressHeader from "../components/main/CompanyProgressHeader.web";
import UserManagement from "../pages/UserManagement";
import TacticalOutreachBuilder from "../pages/TacticalOutreachBuilder";
import TacticalOutreachEmail from "../pages/TacticalOutreachEmail";
import TacticalOutreachList from "../pages/TacticalOutreachList";
import MyLibrary from "../pages/MyLibrary";
import Copilot from "../pages/Copilot";

import { LAYOUTS } from "../utils/enums";
import Subscriptions from "../pages/Subscriptions";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsAndConditions from "../pages/TermsAndConditions";

export const routeMap = {
  TacticalOutreach: {
    component: TacticalOutreachList,
    path: "/tactical-outreach",
    layout: LAYOUTS.PLATFORM_LAYOUT,
    exact: true,
    private: true
  },
  TacticalOutreachBuilder: {
    component: TacticalOutreachBuilder,
    path: "/tactical-outreach/builder",
    layout: LAYOUTS.PLATFORM_LAYOUT,
    exact: false,
    private: true
  },
  TacticalOutreachEmail: {
    component: TacticalOutreachEmail,
    path: "/tactical-outreach/email",
    layout: LAYOUTS.PLATFORM_LAYOUT,
    exact: false,
    private: true
  },
  EmailAccountLoginBlock: {
    component: EmailLoginBlock,
    path: "/login",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: false
  },
  NewPassword: {
    component: NewPassword,
    path: "/new-password",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: false
  },
  Subscription: {
    component: Subscription,
    path: "/plans",
    layout: LAYOUTS.ONBOARDING_LAYOUT,
    private: true
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/upload-company-info",
    private: true
  },
  BulkUploadingImport: {
    component: BulkUploadingImport,
    path: "/additional-upload-company-info",
    private: true
  },
  LandingPage: {
    component: LandingPage,
    path: "/home",
    layout: LAYOUTS.PLATFORM_LAYOUT,
    private: true
  },
  SignUpEmail: {
    component: SignUpEmail,
    path: "/signup",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: false
  },
  LetsGetStarted: {
    component: LetsGetStarted,
    path: "/lets-get-started",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: false
  },
  EmailAccountSignup: {
    component: SignUp,
    path: "/verify-code",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: false
  },
  CreateAccount: {
    component: AccountDetail,
    path: "/create-account",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: true
  },
  About: {
    component: About,
    path: "/about-yourself",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: true
  },
  ChooseOption: {
    component: ChooseyourOptions,
    path: "/choose-option",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: true
  },
  BeforeWeStart: {
    component: BeforeWeStart,
    path: "/before-start",
    private: true
  },
  CompanyDetail: {
    component: CompanyDetail,
    path: "/company-describe",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: true
  },
  DescribleCompanyPlan: {
    component: DescribleCompanyPlan,
    path: "/describle-business-plan",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: true
  },
  CompanyAbout: {
    component: CompanyAbout,
    path: "/company-about",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: true
  },
  CompanySuccess: {
    component: CompanySuccess,
    path: "/company-success",
    private: true
  },
  TellBitMoreBusiness: {
    component: TellBitMoreBusiness,
    path: "/tell-more-business",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: true
  },
  Onboarding: {
    component: OnboardingLayout,
    path: "/onboarding",
    exact: true,
    layout: LAYOUTS.ONBOARDING_LAYOUT,
    private: true
  },
  OnboardingLayout: {
    component: OnboardingLayout,
    path: "/onboarding/:step",
    layout: LAYOUTS.ONBOARDING_LAYOUT,
    private: true
  },
  "/": {
    component: WelcomePage,
    path: "/",
    exact: true,
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: false
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/forgot-password",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: false
  },
  PasswordRecovery: {
    component: PasswordRecovery,
    path: "/password-recovery",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: false
  },
  PasswordRecoverySuccess: {
    component: PasswordRecoverySuccess,
    path: "/password-recovery-success",
    private: false
  },
  VerifySignUpCode: {
    component: VerifySignUpCode,
    path: "/verify-signup-code",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: true
  },
  ChooseYourPath: {
    component: ChooseYourPath,
    path: "/choose-your-path",
    layout: LAYOUTS.ONBOARDING_LAYOUT,
    private: true
  },
  ChatHistory: {
    component: Copilot,
    path: "/copilot",
    layout: LAYOUTS.PLATFORM_LAYOUT,
    private: true
  },
  ChatHistoryId: {
    component: Copilot,
    path: "/chat/:id",
    layout: LAYOUTS.PLATFORM_LAYOUT,
    private: true
  },
  CompanyIntel: {
    component: CompanyIntel,
    path: "/company-intel",
    layout: LAYOUTS.PLATFORM_LAYOUT,
    private: true
  },
  ChooseYourPlan: {
    component: Subscriptions,
    path: "/choose-your-plan",
    layout: LAYOUTS.ONBOARDING_LAYOUT,
    private: true
  },
  Subscriptions: {
    component: Subscriptions,
    path: "/subscriptions",
    layout: LAYOUTS.ONBOARDING_LAYOUT,
    private: true
  },
  EmailCreation: {
    component: EmailCreation,
    path: "/email-creation",
    layout: LAYOUTS.PLATFORM_LAYOUT,
    private: true
  },
  History: {
    component: History,
    path: "/my-history",
    layout: LAYOUTS.PLATFORM_LAYOUT,
    private: true
  },
  MyLibrary: {
    component: MyLibrary,
    path: "/my-library",
    layout: LAYOUTS.PLATFORM_LAYOUT,
    private: true
  },
  UserManagement: {
    component: UserManagement,
    path: "/user-management",
    layout: LAYOUTS.PLATFORM_LAYOUT,
    private: true
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: "/privacy-policy",
    private: false
  },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: "/terms-and-conditions",
    private: false
  }
};

// todo: move function below to another folder
export const matchRoute = (pathname, path) => {
  try {
    const keys = [];
    const regexp = pathToRegexp(path, keys);
    return regexp.test(pathname);
  } catch (error) {
    console.error(`Error matching route with path "${path}":`, error);
    return false;
  }
};
