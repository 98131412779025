import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Backdrop, Badge, Box, Button, CircularProgress, IconButton, Typography, styled } from '@material-ui/core';
import { getProfile, updateProfile } from '../../../../../redux/services/profile';
import * as Yup from 'yup';
import TextField from '../../../CustomFields/TextField';
import { Form, Formik } from 'formik';
import Dropdown from '../../../CustomFields/Dropdown/index';
import { ReactComponent as ChangeProfilePhotoIcon } from '../../../../../assets/icons/change_profile_photo_icon.svg';
import { getIndustries, getJobRoles } from '../../../../../redux/services/common';
import { enqueueSnackbar } from 'notistack';
import ConfirmationModal from '../../../ConfirmationModal';
import { useHistory } from 'react-router-dom';
import { clearStorages } from '../../../../../helpers/other';

import { useStyles } from './styles';

interface Props {
  onClose: () => void;
  onReloadData: () => void;
}

const Profile: React.FC<Props> = ({
  onClose,
  onReloadData
}) => {
  const [jobRoles, setJobRoles] = useState<any>(null);
  const [industries, setIndustries] = useState<any>(null);
  const [profile, setProfile] = useState<any>(null);
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const history = useHistory();
  const classes = useStyles();
  const formRef = useRef<any>(null);

  useEffect(() => {
    handleGetProfile();
    handleGetJobRoles();
    handleGetIndustries();
  }, []);

  const handleGetProfile = async () => {
    onReloadData();

    const { data, error } = await getProfile();

    if (data && !error) {
      setProfile(data);
    }
  }

  const handleGetJobRoles = async () => {
    const { data, error } = await getJobRoles();

    if (data?.data?.roles && !error) {
      setJobRoles(data.data.roles.map(item => ({ value: item.id, label: item.name })));
    }
  }

  const handleGetIndustries = async () => {
    const { data, error } = await getIndustries();

    if (data?.data && !error) {
      setIndustries(data.data.map(item => ({ value: item.id, label: item.industry_name })));
    }
  }

  const getInitialValues = () => {
    return {
      profile_photo: {
        file: null,
        url: profile.attributes.profile_photo
      },
      company_name: profile.attributes.account.company_name,
      role_id: profile.attributes.job_role,
      industry_id: profile.attributes.company_industry || '-',
      logo: {
        file: null,
        url: profile.attributes.logo,
      },
    }
  }

  const getValidationSchema = () => {
    return Yup.object().shape({
      profile_photo: Yup.object(),
      company_name: Yup.string()
        .matches(/^[a-zA-Z0-9][a-zA-Z0-9\s\-']*$/, "Invalid company name.")
        .max(1000, 'Max 1000 symbols')
        .required("This field is required"),
      role_id: Yup.string().required('This field is required'),
      industry_id: Yup.string().required('This field is required'),
      logo: Yup.object(),
    })
  }

  const handleChangeAvatar = async (event, fieldName) => {
    const validExtensions = ['image/jpeg', 'image/png'];
    const maxSize = 25 * 1024 * 1024; // 25MB in bytes

    const file = event.target.files && event.target.files[0];

    if (file && file.size <= maxSize && validExtensions.includes(file.type)) {
      const reader = new FileReader();

      reader.onload = function (e) {
        formRef.current.setFieldValue(fieldName, {
          url: e.target.result,
          file
        })
      }

      reader.readAsDataURL(file);
    } else if (file) {
      enqueueSnackbar(
        file.size > maxSize
          ? 'Max size is 25MB'
          : !validExtensions.includes(file.type)
            ? 'Unsupported type'
            : 'Something went',
        { variant: 'error' }
      )
    }
  }

  const handleSaveChanges = async (values, formikHelpers) => {
    setLoading(true);

    const body = { ...values };
    delete body['profile_photo'];
    delete body['logo'];

    if (values['profile_photo'].file) {
      body.profile_photo = values.profile_photo.file
    }

    if (values['logo'].file) {
      body.logo = values.logo.file
    }

    body['industry_id'] = industries.find(item => item.label === body['industry_id']).value
    body['role_id'] = jobRoles.find(item => item.label === body['role_id']).value

    const formData = new FormData();

    Object.entries(body).forEach(([key, value]: [key: any, value: any]) => {
      formData.append(`${key}`, value)
    })

    const { data, error } = await updateProfile({ body: formData, id: profile.id });

    if (data && !error) {
      enqueueSnackbar(
        'Profile updated successfully',
        { variant: 'success' }
      )

      formikHelpers.setValues({
        ...values,
        logo: {
          file: null,
          url: data.data.attributes.logo
        },
        profile_photo: {
          file: null,
          url: data.data.attributes.profile_photo
        },
      })

      handleGetProfile();
    } else {
      enqueueSnackbar(
        'Failed to update profile',
        { variant: 'error' }
      )
    }

    setLoading(false);
  }

  const handleLogout = async () => {
    await clearStorages();

    history.push('/');
  }

  const getIsSaveDisabled = (values) => {
    const isDisabled = JSON.stringify(getInitialValues()) === JSON.stringify(values);

    return isDisabled;
  }

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: 9999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {!profile
        ? <Box style={{ display: 'flex', alignItems: 'center', width: '100%', minHeight: '500px' }}>
            <CircularProgress
              color="inherit"
              style={{ margin: '0 auto' }}
            />
          </Box>
        : <>
            <Formik
              innerRef={formRef}
              initialValues={getInitialValues()}
              validationSchema={getValidationSchema()}
              validateOnChange={true}
              validateOnBlur={false}
              isInitialValid={false}
              onSubmit={(values: any, formikHelpers: any) => handleSaveChanges(values, formikHelpers)}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                submitForm,
                validateField,
                validateForm,
                resetForm,
                setFieldValue,
                setFieldError,
                setFieldTouched
              }) => (
                <Form
                  translate={undefined}
                  style={{ width: '100%' }}
                >
                  <Box className={classes.container}>
                    <Box className={classes.avatarBlock}>
                      <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                          <IconButton
                            component='label'
                            size='small'
                            tabIndex={-1}
                            role={undefined}
                          // onClick={() => { }}
                          >
                            <ChangeProfilePhotoIcon />
                            <VisuallyHiddenInput
                              type="file"
                              accept=".png,.jpeg,.jpg"
                              onChange={(event) => handleChangeAvatar(event, 'profile_photo')}
                            />
                          </IconButton>
                        }
                      >
                        <Avatar
                          src={values['profile_photo'].url}
                          alt={profile.attributes.account.full_name}
                          style={{
                            width: '100px',
                            height: '100px',
                            border: '1px solid #E2E8F0'
                          }}
                        />
                      </Badge>
                      <Typography style={{ fontSize: '18px', fontWeight: '700', lineHeight: '22px' }}>
                        {profile.attributes.account.full_name}
                      </Typography>
                    </Box>
                    <Box className={classes.inputsBlock}>
                      <TextField
                        name={'company_name'}
                        value={values['company_name']}
                        placeholder={'Enter company name'}
                        label={'Company Name'}
                        error={(touched['company_name'] && errors['company_name']) as string}
                        onChange={(event) => setFieldValue('company_name', event.target.value)}
                      />
                      <Dropdown
                        name={'role_id'}
                        value={values['role_id']}
                        label={'Job role'}
                        options={jobRoles}
                        error={(touched['role_id'] && errors['role_id']) as string}
                        onChange={(event) => setFieldValue('role_id', jobRoles.find(item => item.value === event.target.value).label)}
                      />
                      <Box className={classes.companyIndustry}>
                        <Dropdown
                          name={'industry_id'}
                          value={values['industry_id']}
                          label={'Company Industry'}
                          options={industries}
                          error={(touched['industry_id'] && errors['industry_id']) as string}
                          onChange={(event) => setFieldValue('industry_id', industries.find(item => item.value === event.target.value).label)}
                        />
                        <Button
                          component={'label'}
                          color={'secondary'}
                          variant={'outlined'}
                          style={{ margin: '4px 0px' }}
                          tabIndex={-1}
                          role={undefined}
                          startIcon={
                            values['logo'].url
                              ? <img
                                src={values['logo'].url}
                                style={{ height: '20px' }}
                              />
                              : null
                          }
                        >
                          Upload Logo
                          <VisuallyHiddenInput
                            type="file"
                            accept=".png,.jpeg,.jpg"
                            onChange={(event) => handleChangeAvatar(event, 'logo')}
                          />
                        </Button>
                      </Box>
                    </Box>
                    <Box className={classes.buttonsBlock}>
                      <Button
                        color={'secondary'}
                        variant={'outlined'}
                        className={classes.logOut}
                        onClick={() => setShowLogoutModal(true)}
                      >
                        Log out
                      </Button>
                      <Box style={{ width: '60%', display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <Button
                          fullWidth
                          color={'secondary'}
                          variant={'outlined'}
                          onClick={onClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          fullWidth
                          color={'primary'}
                          variant={'contained'}
                          type={'submit'}
                          disabled={isSubmitting || getIsSaveDisabled(values)}
                        >
                          Save
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </>
      }

      <ConfirmationModal
        open={showLogoutModal}
        title={"Are you sure you want to log out from your account?"}
        onClose={() => setShowLogoutModal(false)}
        rejectBtnText={"Cancel"}
        confirmBtnText={"Log out"}
        onReject={() => setShowLogoutModal(false)}
        onConfirm={handleLogout}
      />
    </>
  );
};

export default Profile;
