import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import PaymentsHistory from './components/PaymentsHistory';
import CurrentPlan from './components/CurrentPlan';
import PaymentMethod from './components/PaymentMethod';

import { useStyles } from './styles';

interface Props {
  onReloadData: () => void;
}

const PlanAndBilling: React.FC<Props> = (props) => {
  const [order, setOrder] = useState<any>(null);
  const [tab, setTab] = useState<string>('currentPlan');

  const classes = useStyles();

  return (
    <>
      <Box className={classes.container}>
        {tab === 'currentPlan' && (
          <CurrentPlan
            setTab={setTab}
            setOrder={setOrder}
          />
        )}
        {tab === 'history' && (
          <PaymentsHistory
            setTab={setTab}
          />
        )}
        {tab === 'paymentMethod' && (
          <PaymentMethod
            order={order}
            onClose={() => {
              props.onReloadData();
              setTab('currentPlan');
            }}
          />
        )}
      </Box>
    </>
  );
};

export default PlanAndBilling;
