import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from "@material-ui/core";
import { Close, InfoOutlined } from "@material-ui/icons";
import React from "react";

import {
  WithStyles,
  createStyles,
  withStyles
} from "@material-ui/core/styles";

export interface Props extends WithStyles<any> {
  open: boolean;
  onSubmit(): void;
  onClose(): void;
}

const styles = () =>
  createStyles({
    dialog: {}
  });

const contents = [
  "A brief history of the company",
  "The company's mission, vision and USP",
  "Core company values",
  "Significant milestones and achievements",
  "Detailed descriptions of products/services",
  "Benefits of product/service features",
  "Brief biographies of key team members",
  "Previous marketing and sales materials",
  "Briefs on news stories featuring the company",
  "Testimonials from customers",
  "Narratives of customer success stories",
  "Key performance metrics",
  "Endorsements from satisfied customers",
  "Findings from competitive analysis",
  "Market insights specific to your company",
  "Insights from your customers' markets"
];

export class UploadingCompanyInfoDialog extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { open, classes } = this.props;

    return (
      <>
        <Dialog
          scroll="paper"
          PaperProps={{ style: { borderRadius: 20 } }}
          open={open}
          className={classes.dialog}
        >
          <DialogTitle className={classes.title1}>
            <InfoOutlined className={classes.icon1} />
            <IconButton
              className={classes.closeButton1}
              onClick={this.props.onClose}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.content1}>
            <Box className={classes.topContent1}>
              <Typography className={classes.description1}>
                Our proprietary engine, Pebbles Copilot, is able to generate
                unparalleled intelligence, guidance, and content tailored to
                each specific goal. Though to maximise its capabilities, it's
                crucial to provide it with up to date information about your
                company.
              </Typography>
            </Box>
            <Box className={classes.list1}>
              <Typography className={classes.listTitle1}>
                Some topics you might consider uploading:
              </Typography>
              <ul className={classes.listContent1}>
                {contents.map((item, index) => (
                  <li className={classes.listItem1} key={index}>
                    {item}
                  </li>
                ))}
              </ul>
            </Box>
          </DialogContent>
          <DialogActions className={classes.action2}>
            <button
              className={classes.button} 
              onClick={this.props.onSubmit}
            >
              Thanks
            </button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(UploadingCompanyInfoDialog);
