import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";

import {
  WithStyles,
  createStyles,
  withStyles
} from "@material-ui/core/styles";

export interface Props extends WithStyles<any> {
  open: boolean;

  onApplyChange(): void;
  onSaveChange(): void;
  onClose(): void;
}

const styles = () =>
  createStyles({
    dialog: {},

    title: {
      position: "relative",
      padding: "44px 44px 0"
    },

    heading: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "34px",
      letterSpacing: "-0.005em",
      textAlign: "center"
    },

    content: {
      marginTop: 16,
      padding: "0 44px"
    },

    closeButton: {
      position: "absolute",
      right: 32,
      top: 44
    },

    topContent: {},

    description: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: "24px",
      textAlign: "center",
      color: "#475569",
      marginTop: 16
    },

    action: {
      marginTop: 40,
      padding: "0 44px 44px"
    },

    applyButton: {
      padding: "10px 16px",
      borderRadius: 8,
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "19.2px",
      width: "100%",
      border: "1px solid #CBD5E1",
      cursor: "pointer",
      backgroundColor: "#FFFFFF"
    },

    saveButton: {
      padding: "10px 16px",
      borderRadius: 8,
      backgroundColor: "#000000",
      color: "#FFFFFF",
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "19.2px",
      width: "100%",
      border: "none",
      cursor: "pointer"
    }
  });

export class ParameterChangeDialog extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { open, classes } = this.props;

    return (
      <>
        <Dialog
          className={classes.dialog}
          open={open}
          scroll="paper"
          PaperProps={{ style: { borderRadius: 20, maxWidth: 670 } }}
        >
          <DialogTitle className={classes.title}>
            <IconButton
              className={classes.closeButton}
              onClick={this.props.onClose}
            >
              <Close />
            </IconButton>
          </DialogTitle>

          <DialogContent className={classes.content}>
            <Box className={classes.topContent}>
              <Typography className={classes.heading}>
                Are you sure you want to <br /> change the current workflow?
              </Typography>
            </Box>

            <Typography className={classes.description}>
              Note that the whole campaign workflow will be changed.
              <br />
              You can just apply the changes, if you are not planning to use
              them in
              <br />
              other campaigns.
            </Typography>
          </DialogContent>

          <DialogActions className={classes.action}>
            <button
              className={classes.applyButton}
              onClick={this.props.onApplyChange}
            >
              Apply changes
            </button>
            <button
              className={classes.saveButton}
              onClick={this.props.onSaveChange}
            >
              Save changes
            </button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(ParameterChangeDialog);
