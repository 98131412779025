import { Box, Typography } from "@material-ui/core";
import React from "react";
import clsx from "clsx";

import {
  WithStyles,
  createStyles,
  withStyles
} from "@material-ui/core/styles";
import { Height, InfoOutlined } from "@material-ui/icons";
import { arrow } from "./assets";

export interface Props extends WithStyles<any> {
  highlight: boolean;

  onSubmit(): void;
  onClick(): void;
}

const styles = () =>
  createStyles({
    container: {
      position: "absolute",
      top: -120,
      left: -206
    },

    overlay: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      position: "fixed",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 2
    },

    content: {
      backgroundColor: "transparent",
      boxShadow: "none",
      width: 252
    },

    descriptionContainer: {
      position: "relative"
    },

    description: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "19.2px",
      color: "#FFFFFF"
    },

    button: {
      padding: "10px 16px",
      borderRadius: 8,
      backgroundColor: "transparent",
      color: "#FFFFFF",
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "19.2px",
      marginTop: 12,
      border: "1px solid #CBD5E1",
      cursor: "pointer"
    },

    arrow: {
      position: "absolute",
      right: 16
    },

    arrowInner: {
      position: "relative"
    },

    highlight: {
      position: "relative",
      top: 20,
      left: 142
    },

    hightLightInner1: {
      position: "absolute",
      width: 174,
      height: 174,
      border: "1px solid rgba(255, 255, 255, 0.1)",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },

    hightLightInner2: {
      position: "absolute",
      width: 124,
      height: 124,
      border: "1px solid rgba(255, 255, 255, 0.3)",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },

    hightLightInner3: {
      position: "absolute",
      width: 86,
      height: 86,
      border: "1px solid rgba(255, 255, 255, 0.6)",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },

    hightLightInner4: {
      position: "relative",
      width: 52,
      height: 52,
      borderRadius: "50%",
      background:
        "linear-gradient(144.88deg, rgba(255, 255, 255, 0.52) -7.94%, rgba(255, 255, 255, 0.79) 102.73%)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 3
    },

    highlightIcon: {
      fontSize: 28,
      color: "#3A84C9",
      cursor: "pointer",
      zIndex: 3,
      // border: '6px solid #FFFFFF',
      // borderRadius: '50%',
      // '& path': {
      //   width: '16px',
      //   Height: '16px',
      // }
    },

    clearInner: {
      border: "none",
      background: "none"
    },

    pointerEventsNone: {
      pointerEvents: "none",
      cursor: "initial"
    },

    displayNone: {
      display: "none"
    }
  });

export class BulkUploadingCompanyInfoHighlightIcon extends React.Component<
  Props
> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, highlight } = this.props;

    return (
      <>
        <Box
          className={clsx(classes.overlay, {
            [classes.displayNone]: !highlight
          })}
        />

        <Box
          className={clsx(classes.hightLightInner4, {
            [classes.clearInner]: !highlight
          })}
        >
          <Box className={classes.container}>
            <Box className={classes.content}>
              <Box className={classes.descriptionContainer}>
                <Typography
                  component="span"
                  className={clsx(classes.description, {
                    [classes.displayNone]: !highlight
                  })}
                >
                  Click here to find out additional information
                </Typography>

                <Box className={classes.arrowContainer}>
                  <Box className={classes.arrowInner}>
                    <img
                      src={arrow}
                      className={clsx(classes.arrow, {
                        [classes.displayNone]: !highlight
                      })}
                    />
                  </Box>
                </Box>
              </Box>

              <button
                data-test-id="submit-btn"
                className={clsx(classes.button, {
                  [classes.displayNone]: !highlight
                })}
                onClick={this.props.onSubmit}
              >
                OK, I got it
              </button>
            </Box>
          </Box>
          <Box
            className={clsx(classes.hightLightInner3, {
              [classes.clearInner]: !highlight
            })}
          />

          <Box
            className={clsx(classes.hightLightInner2, {
              [classes.clearInner]: !highlight
            })}
          />

          <Box
            className={clsx(classes.hightLightInner1, {
              [classes.clearInner]: !highlight
            })}
          />

          <InfoOutlined
            data-test-id="highlight-icon"
            className={clsx(classes.highlightIcon, {
              [classes.pointerEventsNone]: highlight
            })}
            onClick={this.props.onClick}
          />
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(BulkUploadingCompanyInfoHighlightIcon);
