import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { clearStorages, getUrlAndParams, getVisibleElementsByClassName, removeUrlParams } from '../../helpers/other';
import { Backdrop, Box, Button, CircularProgress, IconButton, Typography } from '@material-ui/core';
import SectionAccordion from './components/SectionAccordion';
import { Form, Formik } from 'formik';
import { saveCampaignEmail, getCampaignById, getCampaignParameters, getCampaignTemplate, saveCampaignParameters, saveCampaignTemplate, triggerEmailGenerate, updateCampaign } from '../../redux/services/tactical-outreach';
import TooltipsModal from './components/TooltipModal';
import { enqueueSnackbar } from 'notistack';
import Header from './components/Header';
import { ReactComponent as PromptLoaderIcon } from '../../assets/icons/prompt_loading.svg';
import { ReactComponent as PromptReadyIcon } from '../../assets/icons/prompt_ready.svg';
import PromptPreparationVideo from '../../assets/videos/prompt_preparation_video.webm';
import ConfirmationModal from '../../components/main/ConfirmationModal';
import { Prompt } from 'react-router-dom';
import { generateInitialValue, generateValidationRules, isFieldNotEmpty } from './helpers';

import { useStyles } from './styles';
import { convertMarkdownToHtml } from '../TacticalOutreachEmail/helpers';
import { observableService } from '../../services/observable';

interface Props {
  navigation: any;
  history: any;
}

const TacticalOutreachBuilder: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [promptReady, setPromptReady] = useState<boolean>(false);
  const [template, setTemplate] = useState<any>(null);
  const [validationSchema, setValidationSchema] = useState<any>({});
  const [tooltipMessage, setTooltipMessage] = useState<string | null>(null);
  const [templateMetadata, setTemplateMetadata] = useState<any>({
    name: {
      active: true,
      value: ''
    },
    description: {
      active: false,
      value: ''
    },
  });
  const [resetFormModal, setResetFormModal] = useState<boolean>(false);
  const [submitFormModal, setSubmitFormModal] = useState<boolean>(false);
  const [leavingModal, setLeavingModal] = useState<{ active: boolean, nextLocation: string | null }>({
    active: false,
    nextLocation: null
  });
  const [bloackNavigation, setBloackNavigation] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const classes = useStyles();
  const formRef = useRef<any>(null);

  useEffect(() => {
    setLoading(true);

    const { params } = getUrlAndParams();
    const template_id = params.get('template_id');
    const campaign_id = params.get('campaign_id');
    const target_email_id = params.get('target_email_id');

    if (campaign_id) {
      handleGetCampaignById(campaign_id, target_email_id);
    } else {
      handleGetCampaignDataHandler(template_id, null);
    }

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };

  const handleGetCampaignById = async (campaign_id, target_email_id) => {
    setIsEdit(true);
    setBloackNavigation(true);

    const { data: metadata, error: metadataError } = await getCampaignById(campaign_id);
    const { data: parameters, error: parametersError } = await getCampaignParameters(campaign_id);

    if (parameters && !parametersError) {
      await handleGetCampaignDataHandler(parameters.campaignTypeId, parameters.uiParameters);
      setLoading(true);

      if (metadata && !metadataError) {
        setTemplateMetadata({
          name: {
            active: false,
            value: metadata.name
          },
          description: {
            active: false,
            value: metadata.description
          },
        })
      }

      const intervalId = setInterval(() => {
        if (formRef?.current) {
          clearInterval(intervalId);

          if (parameters.uiParameters) {
            formRef.current.setValues(parameters.uiParameters);

            setLoading(false);
          } else {
            enqueueSnackbar(
              'Parameters not found',
              { variant: 'error' }
            )

            setBloackNavigation(false);

            setTimeout(() => {
              props.history.push('/tactical-outreach');
            }, 500);
          }
        }
      }, 1000);
    } else {
      enqueueSnackbar(
        'Campaign not found',
        { variant: 'error' }
      )

      setBloackNavigation(false);

      setTimeout(() => {
        props.history.push('/tactical-outreach');
      }, 500);
    }
  }

  const handleGetCampaignDataHandler = async (template_id, parameters) => {
    if (template_id) {
      setBloackNavigation(true);

      const { data, error } = await getCampaignTemplate(template_id);

      if (!error && data && Object.keys(data).length) {
        setTemplate({
          ...data,
          id: template_id
        });

        generateYupSchema(data, parameters);
      } else {
        enqueueSnackbar(
          'Template not found',
          { variant: 'error' }
        )

        setBloackNavigation(false);

        setTimeout(() => {
          props.history.push('/tactical-outreach');
        }, 500);
      }

      setLoading(false);
    } else {
      enqueueSnackbar(
        'Template not found',
        { variant: 'error' }
      )

      setBloackNavigation(false);

      setTimeout(() => {
        props.history.push('/tactical-outreach');
      }, 500);
    }
  }

  const generateYupSchema = (template, parameters) => {
    const shape = {};

    template.sections.forEach(section => {
      section.subSections.forEach(subSection => {
        subSection.elements.forEach(element => {
          const parentName = `${section.id}-${subSection.id}`;
          const targetName = `${parentName}-${element.id}`;
          const maxElementsAttribute = subSection.attributes.find(item => item.type === 'MAX_ELEMENTS_ATTRIBUTE');
          const hideUnderConditionAttribute = subSection.attributes.find(item => item.type === 'HIDE_UNDER_CONDITION_ATTRIBUTE');

          if (targetName) {
            if (parameters) {
              if (maxElementsAttribute?.value === 1) {
                const restElementName = Object.keys(parameters).find(item => item.startsWith(parentName) && item !== targetName);
  
                shape[targetName] = generateValidationRules(targetName, element, subSection, isFieldNotEmpty(parameters[restElementName]));
              } else if (hideUnderConditionAttribute) {
                const restElementName = Object.values(hideUnderConditionAttribute.elementReference).join('-');

                shape[targetName] = generateValidationRules(targetName, element, subSection, parameters[restElementName] === hideUnderConditionAttribute.option);
              } else {
                shape[targetName] = generateValidationRules(targetName, element, subSection)
              }
            } else {
              shape[targetName] = generateValidationRules(targetName, element, subSection)
            }
          }
        });
      });
    });

    setValidationSchema(shape);
  };

  const getInitialValues = () => {
    const initialValues = {};

    Object.entries(Yup.object().shape(validationSchema).describe().fields).forEach(([key, value]) => {
      initialValues[key] = generateInitialValue({ ...value, nullable: !!validationSchema[key]?._nullable })
    });

    return initialValues;
  };

  const getCompletedFields = (startName, values) => {
    const newValues = Object.fromEntries(Object.keys(validationSchema).map(key => [key, values[key] || null]));
    const fieldsBySection = Object.fromEntries(Object.entries(newValues).filter(([key, value]) => key.startsWith(startName)));
    let fields = Object.fromEntries(Object.keys(fieldsBySection).map(key => [key, false]));
    let required = {};

    // check and set all not empty fields
    Object.entries(fieldsBySection).forEach(([key, value]: any) => {
      if (
        isFieldNotEmpty(value) &&
        !Object.keys(fieldsBySection).some(item => item.startsWith(key) && validationSchema[item]?._exclusive?.required && item !== key)
      ) {
        fields[key] = true
      } else {
        fields[key] = false
      }
    })

    // get only required fields from all non empty fields
    for (const key in fields) {
      if (
        validationSchema[key] &&
        validationSchema[key]._exclusive &&
        validationSchema[key]._exclusive.required &&
        !Object.keys(fields).some(item => item.startsWith(key) && validationSchema[item]?._exclusive?.required && item !== key)
      ) {
        required[key] = fields[key];
      }
    }

    // the result of sorting according to the principle: the key with the name 1-1-1-2 is higher in the list by 1-1-1
    fields = Object.fromEntries(Object.entries(fields).sort(([a], [b]) => a.split('-').map(Number).reduce((acc, n, i, arr) => acc || n - (b.split('-').map(Number)[i] || 0), 0)));
    required = Object.fromEntries(Object.entries(required).sort(([a], [b]) => a.split('-').map(Number).reduce((acc, n, i, arr) => acc || n - (b.split('-').map(Number)[i] || 0), 0)));

    return {
      all: {
        fields: fields || {},
        amount: Object.values(fields).filter(item => item === true).length || 0
      },
      required: {
        fields: required || {},
        amount: Object.values(required).filter(item => item === true).length || 0,
      }
    }
  }

  const handleSaveCampaignTemplate = async (values: any, redirect?: string) => {
    if (templateMetadata.name.value.trim().length < 50 && templateMetadata.description.value.trim().length < 300) {
      setBloackNavigation(false);
      setPromptReady(false);
      setSubmitting(!redirect);

      const { params } = getUrlAndParams();
      const campaign_id = params.get('campaign_id');

      const { data, error } = isEdit
        ? await (async function () {
          try {
            await updateCampaign({
              body: {
                name: templateMetadata.name.value.trim(),
                description: templateMetadata.description.value.trim(),
              },
              id: campaign_id,
            })

            return {
              data: { id: campaign_id },
              error: undefined
            }
          } catch (error) {
            return {
              data: undefined,
              error: error
            }
          }
        })()
        : await saveCampaignTemplate({
          name: templateMetadata.name.value.trim(),
          description: templateMetadata.description.value.trim(),
          typeId: +template.id
        })

      if (data && !error) {
        const body = {
          campaignId: data.id,
          uiParameters: values,
          parameters: generateParameters(
            Object.fromEntries(Object.entries(values).sort(([keyA], [keyB]) => keyA.localeCompare(keyB))) // sort
          )
        };

        const { data: answer, error: answerError } = await saveCampaignParameters({ id: body.campaignId, body });

        if (redirect) {
          enqueueSnackbar(
            isEdit
              ? 'Campaign updated successfully'
              : 'Campaign saved as draft successfully',
            { variant: 'success' }
          )

          props.history.push(redirect);
        } else {
          if (answer && !answerError) {
            const { data: email, error: emailError } = await triggerEmailGenerate({ campaignId: body.campaignId });

            if (email && !emailError) {
              observableService.sendEvent('Decrease usage amount');

              await saveCampaignEmail({ 
                email: { 
                  ...email, 
                  content: convertMarkdownToHtml(email.content),
                  campaignId: body.campaignId
                } 
              });

              setTimeout(() => {
                setSubmitting(true);
                setPromptReady(true);
              }, 2000);

              setTimeout(() => {
                props.history.push(`/tactical-outreach/email?campaign_id=${body.campaignId}`);
              }, 4000);
            } else {
              if (emailError.status === 402) {
                observableService.sendEvent('Show top up modal for TACTICAL_OUTREACH');
              } else {
                enqueueSnackbar(
                  'Failed to request AI',
                  { variant: 'error' }
                )
              }

              observableService.sendEvent('Increase usage amount');

              setSubmitting(false);
              setPromptReady(false);
              setIsEdit(true);
  
              props.history.replace(`/tactical-outreach/builder?campaign_id=${body.campaignId}`)
            }
          } else {
            enqueueSnackbar(
              `Failed to ${isEdit ? 'update' : 'save'} save parameters`,
              { variant: 'error' }
            )

            setSubmitting(false);
            setPromptReady(false);
          }
        }
      } else {
        enqueueSnackbar(
          `Failed to ${isEdit ? 'update' : 'create'} template`,
          { variant: 'error' }
        )

        setSubmitting(false);
        setPromptReady(false);
      }

      setBloackNavigation(true);
    } else {
      handleScrollToError();
    }
  }

  const generateParameters = (values) => {
    const result = {
      campaignTypeId: +template.id,
      sectionAnswerList: []
    };

    const getSection = (sections, id) => {
      const section = sections.find(section => section.id === id);
      if (section) return section;

      const newSection = { id, subSectionAnswerList: [] };
      return newSection;
    };

    const getSubSection = (subSections, id) => {
      const subSection = subSections.find(sub => sub.id === id);
      if (subSection) return subSection;

      const newSubSection = { id, elementAnswerList: [] };
      return newSubSection;
    };

    const getElement = (elements, id) => {
      const element = elements.find(el => el.id === id);
      if (element) return element;

      const newElement = { id, answers: [] };
      return newElement;
    };

    const addAnswer = (keys, value, parentOption = null) => {
      const [sectionId, subSectionId, elementId, ...rest] = keys.map(Number);

      let section = getSection(result.sectionAnswerList, sectionId);
      result.sectionAnswerList = result.sectionAnswerList.some(sec => sec.id === sectionId)
        ? result.sectionAnswerList
        : [...result.sectionAnswerList, section];

      let subSection = getSubSection(section.subSectionAnswerList, subSectionId);
      section.subSectionAnswerList = section.subSectionAnswerList.some(sub => sub.id === subSectionId)
        ? section.subSectionAnswerList
        : [...section.subSectionAnswerList, subSection];

      let element = getElement(subSection.elementAnswerList, elementId);
      subSection.elementAnswerList = subSection.elementAnswerList.some(el => el.id === elementId)
        ? subSection.elementAnswerList
        : [...subSection.elementAnswerList, element];
      
      if (rest.length) {
        const [nestedElementId, ...nestedKeys] = rest;
        let nestedElement = getElement(subSection.elementAnswerList, nestedElementId);

        // Pass the current element's first answer as the parentOption for nested elements
        const parentAnswer = element.answers.length ? element.answers[0] : parentOption;

        // Recursive call for further nested elements
        addAnswer([sectionId, subSectionId, nestedElementId, ...nestedKeys], value, parentAnswer);
      } else if (isFieldNotEmpty(value)) {
        let updatedElement = { ...element };
        if (typeof value === 'object' && value !== null) {
          if (Array.isArray(value)) {
            value.forEach(item => {
              updatedElement.answers.push({ OPTION: item });
            });
          } else {
            updatedElement.answers.push(value);
          }
        } else {
          if (parentOption) {
            updatedElement.answers.push({
              PARENT: parentOption.OPTION || parentOption,
              OPTION: value
            });
          } else {
            updatedElement.answers.push(value);
          }
        }

        // Update the element list
        subSection.elementAnswerList = subSection.elementAnswerList.map(el => el.id === element.id ? updatedElement : el);
      }
    };

    const traverseValues = (values, prefix = '') => {
      Object.entries(values).forEach(([key, value]) => {
        const newKey = prefix ? `${prefix}-${key}` : key;
        const keys = newKey.split('-');
        addAnswer(keys, value);
      });
    };

    // Filter to keep only elements that have answers (non-empty)
    const removeEmptyAnswers = (sectionAnswerList) => {
      return sectionAnswerList
        .map(section => {
          const updatedSubSections = section.subSectionAnswerList
            .map(subSection => {
              const updatedElements = subSection.elementAnswerList
                .filter(element => element.answers.length > 0);
              return updatedElements.length > 0 ? { ...subSection, elementAnswerList: updatedElements } : null;
            })
            .filter(subSection => subSection !== null);

          return updatedSubSections.length > 0 ? { ...section, subSectionAnswerList: updatedSubSections } : null;
        })
        .filter(section => section !== null);
    };

    traverseValues(values);

    return {
      ...result,
      sectionAnswerList: removeEmptyAnswers(result.sectionAnswerList)
    };
  };

  const handleBlockedNavigation = (nextLocation) => {
    setLeavingModal({
      active: true,
      nextLocation
    })

    return false;
  };

  const handleScrollToError = () => {
    setTimeout(() => {
      const elements = getVisibleElementsByClassName('Mui-error');

      if (elements.length) {
        elements[0].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
      }
    }, 500);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <>
      {!submitting && (
        <Prompt
          when={bloackNavigation && !submitting}
          message={(location) => handleBlockedNavigation(location)}
        />
      )}

      <Backdrop
        style={{ color: '#fff', zIndex: 999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {submitting && (
        <Box className={classes.promptSavingWrapper}>
          {promptReady
            ? <>
                <PromptReadyIcon />
                <Typography>
                  Your content is ready!
                </Typography>
              </>
            // : <video 
            //     autoPlay 
            //     muted 
            //     loop 
            //   >
            //     <source src={PromptPreparationVideo} type="video/webm" />
            //   </video>
            : <>
                <PromptLoaderIcon />
                <Typography>
                  Just a moment
                </Typography>
              </>
          }
        </Box>
      )}

      <Box
        className={classes.container}
      // style={{
      //   opacity: loading ? '0.5' : '1'
      // }}
      >
        {template && Object.keys(validationSchema).length && (
          <Formik
            innerRef={formRef}
            initialValues={getInitialValues()}
            validationSchema={Yup.object().shape(validationSchema)}
            validateOnChange={true}
            validateOnBlur={false}
            isInitialValid={false}
            onSubmit={(values: any) => handleSaveCampaignTemplate(values)}
          >
            {({
              values,
              errors,
              touched,
              submitForm,
              validateField,
              validateForm,
              resetForm,
              setFieldValue,
              setFieldError,
              setFieldTouched
            }) => (
              <>
                {formRef?.current && (
                  <Header
                    values={values}
                    template={template}
                    errors={errors}
                    templateMetadata={templateMetadata}
                    validationSchema={validationSchema}
                    setTemplateMetadata={setTemplateMetadata}
                    getCompletedFields={getCompletedFields}
                  />
                )}

                <Form
                  translate={undefined}
                  onKeyDown={handleKeyDown}
                >
                  <Box className={classes.content}>
                    {template.sections.map((item, index) => (
                      <Box key={index}>
                        <SectionAccordion
                          section={item}
                          values={values}
                          errors={errors}
                          touched={touched}
                          validationSchema={validationSchema}
                          template={template}
                          validateForm={validateForm}
                          setFieldValue={setFieldValue}
                          setFieldError={setFieldError}
                          setFieldTouched={setFieldTouched}
                          setValidationSchema={setValidationSchema}
                          setTooltipMessage={setTooltipMessage}
                          getCompletedFields={getCompletedFields}
                        />
                      </Box>
                    ))}

                    <Box className={classes.footer}>
                      <Box className={classes.footerButtons}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="secondary"
                          onClick={() => setResetFormModal(true)}
                        >
                          Reset
                        </Button>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          type="submit"
                          onClick={async () => {
                            let result = {};

                            template.sections.forEach(item => {
                              const { required } = getCompletedFields(item.id, values);

                              result = {
                                ...result,
                                ...required.fields
                              }
                            });

                            if (Object.values(result).some(item => !item)) {
                              setSubmitFormModal(true);
                            } else {
                              handleScrollToError();
                            }
                          }}
                        >
                          Generate Content
                        </Button>
                      </Box>
                      <Typography style={{ width: '100%', textAlign: 'center', fontSize: '12px', fontWeight: '500', lineHeight: '14px', color: '#475569' }}>
                        In order to continue you have to fill in all mandatory choices.
                      </Typography>
                    </Box>
                  </Box>
                </Form>
              </>
            )}
          </Formik>
        )}
      </Box>

      <TooltipsModal
        open={!!tooltipMessage?.length}
        message={tooltipMessage}
        onClose={() => setTooltipMessage(null)}
      />

      <ConfirmationModal
        open={resetFormModal}
        title={"Are you sure you want to reset the form?"}
        description={"All entered data will be permanently lost"}
        onClose={() => setResetFormModal(false)}
        rejectBtnText={"No"}
        confirmBtnText={"Yes"}
        onReject={() => setResetFormModal(false)}
        onConfirm={() => {
          formRef.current.resetForm()
          setResetFormModal(false)
        }}
      />

      <ConfirmationModal
        open={submitFormModal}
        title={"Please, fill in all required fields!"}
        onClose={() => setSubmitFormModal(false)}
        rejectBtnText={"Ok"}
        onReject={() => setSubmitFormModal(false)}
      />

      <ConfirmationModal
        open={leavingModal.active}
        title={isEdit
          ? 'Save last changes?'
          : 'Save changes and create a draft campaign?'
        }
        onClose={() => setLeavingModal({ active: false, nextLocation: null })}
        rejectBtnText={"Yes"}
        confirmBtnText={"No"}
        reversedButtons
        onReject={() => {
          setLoading(true);
          handleSaveCampaignTemplate(formRef.current.values, leavingModal.nextLocation);
          setLeavingModal({ active: false, nextLocation: null });
        }}
        onConfirm={async () => {
          setBloackNavigation(false);
          setTimeout(() => {
            props.history.push(leavingModal.nextLocation);
          }, 500);
        }}
      />
    </>
  );
};

export default TacticalOutreachBuilder;

