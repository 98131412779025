import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  fileUploadWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    marginTop: '10px',
    '& .MuiButtonBase-root': {
      boxShadow: 'none'
    }
  },
  uploadedFileWrapper: {
    maxWidth: '100%',
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    background: '#F1F5F9',
    borderRadius: '8px',
    columnGap: '8px',
    '& .MuiIconButton-root': {
      width: '16px',
      height: '16px',
      marginLeft: '12px',
      '& .MuiIconButton-label': {
        width: '16px',
        height: '16px',
      }
    }
  }
}));