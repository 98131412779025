import React, { createContext, Component } from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";
// import { getToken, getRefreshToken, getUser } from "../helpers/auth";

const AppContext = createContext();

class ContextProvider extends Component {
  state = {
    token: null,
    refresh_token: null,
    user: null,
    loading: true
  };

  async componentDidMount() {
    // const revolut = await loadRevolut();
    // const token = await getToken();
    // const refresh_token = await getRefreshToken();
    // const user = await getUser();

    this.setState({
      loading: true
    }, () => {
      this.setState({
        // token: token?.trim()?.length ? token : null,
        // refresh_token: refresh_token?.trim()?.length ? refresh_token : null,
        // user: user ? JSON.parse(user) : null
      }, () => {
        this.setState({
          loading: false
        })
      })
    })
  }

  render() {
    const { loading, ...contextValue } = this.state;

    return loading ? (
      <Backdrop
        style={{ color: '#fff', zIndex: 9999 }}
        open
      >
        <CircularProgress style={{ color: '#fff' }} />
      </Backdrop>
    ) : (
      <AppContext.Provider value={contextValue}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export { ContextProvider, AppContext };
