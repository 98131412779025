import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '28px',
  },
  avatarBlock: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
  },
  inputsBlock: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
  },
  companyIndustry: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    gap: '12px'
  },
  buttonsBlock: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '12px'
  },
  logOut: {
    borderColor: '#DC2626 !important',
    '& .MuiButton-label': {
      color: '#DC2626 !important',
    }
  }
}));