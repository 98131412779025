import React from "react";
import {
  Link,
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import "./EmailAccountLoginBlock.scss";
import { Formik, Form } from "formik";
import { createTheme, withStyles } from "@material-ui/core/styles";
import CarouselDisplay from "../../../blocks/carouseldisplay/main/CarouselDisplay.web";
import EmailLoginController from "./EmailLoginController";
import { styles } from "../utils/styles";
import { googleLogo } from "./assets";
import * as Yup from "yup";
import Toast from "../../utilities/main/Toast";

//Icons
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

type Values = {
  email: string;
  password: string;
  termAndCondition: boolean;
};

const initialValues: Values = {
  email: "",
  password: "",
  termAndCondition: false,
};

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email.")
    .required("Email should not be blank."),
  password: Yup.string().required("Password should not be blank."),
});

export class EmailLoginBlock extends EmailLoginController {
  render() {
    const { classes } = this.props;

    return (
      <>
        <Backdrop
          style={{ color: '#fff', zIndex: 9999 }}
          open={this.state.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Box className={classes.layoutContainer}>
          <Box className={classes.emailContainer}>
            <Typography className={classes.sigupText}>
              Welcome back
            </Typography>
            <Typography className={classes.subTitle}>
              Don’t have an account?
              <span
                data-test-id="loginBtn"
                className={classes.loginText}
                onClick={this.redirectToSignUpPage}
              >
                {`Sign up`}
              </span>
            </Typography>
            <Formik
              data-testid="signupEmailFormik"
              initialValues={initialValues}
              validationSchema={LoginSchema}
              onSubmit={(values) => {
                this.doLogIn(values.email, values.password);
              }}
            >
              {({ 
                values, 
                errors, 
                touched, 
                handleChange, 
                handleSubmit 
              }) => (
                <Form translate={undefined}>
                  <Box className={classes.inputFeildOuterWrapper}>
                    <Typography
                      className={classes.label}
                      data-testid="emailField"
                    >
                      Email
                    </Typography>
                    <TextField
                      data-test-id="txtInputEmail"
                      id="email"
                      type="text"
                      variant="outlined"
                      fullWidth
                      name="email"
                      placeholder="Enter the email address"
                      onChange={e => {
                        handleChange(e);
                        this.setState({ errors:{email:""}});
                      }}
                      className={classes.input}
                      error={
                        !!this.state.errors?.email ||
                        !!(errors.email && touched.email)
                      }
                    />
                    {!!touched.email && !!errors.email && (
                      <Typography className={classes.validateError}>
                        {errors.email}
                      </Typography>
                    )}
                    {this.state.errors.email && (
                      <Typography className={classes.validateError}>
                        {this.state.errors.email}
                      </Typography>
                    )}
                  </Box>
                  <Box>
                    <Typography
                      variant="h4"
                      component="h2"
                      className={classes.label}
                    >
                      Password
                    </Typography>
                    <TextField
                      data-test-id="txtInputPassword"
                      name="password"
                      type={
                        this.state.enablePasswordField ? "password" : "text"
                      }
                      onChange={e => {
                        handleChange(e);
                        this.setState({ errors:{password:""}});
                      }}
                      placeholder="Enter the password"
                      fullWidth={true}
                      variant="outlined"
                      className={classes.input}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            className={classes.eyeIcon}
                          >
                            {this.state.enablePasswordField ? (
                              <VisibilityOffOutlinedIcon className={classes.pwdIconColor} />
                            ) : (
                              <VisibilityOutlinedIcon className={classes.pwdIconColor} />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      error={
                        !!this.state.errors?.password ||
                        !!(errors.password && touched.password)
                      }
                    />
                    {!!touched.password && !!errors.password && (
                      <Typography className={classes.validateError}>
                        {errors.password}
                      </Typography>
                    )}
                    {this.state.errors.password && (
                      <Typography className={classes.validateError}>
                        {this.state.errors.password}
                      </Typography>
                    )}
                    <Box className={classes.forgotPwdLabelWrapper}>
                      <Link
                        className={classes.forgotPwdLink}
                        onClick={this.handleForgotPasswordClick}
                        data-test-id="btnForgotPassword"
                      >
                        Forgot password?
                      </Link>
                    </Box>
                  </Box>
                  <Button
                    variant="contained"
                    fullWidth
                    type="submit"
                    className={classes.button}
                  >
                   Log in
                  </Button>
                  {/* <Box className={classes.divider}>
                    <div className={classes.hr} />
                    <Box className={classes.dividerTextWrapper}>
                      <Typography className={classes.dividerText}>
                        or
                      </Typography>
                    </Box>
                    <div className={classes.hr} />
                  </Box>
                  <Button variant="contained" className={classes.googlebtn}>
                    <img src={googleLogo} alt="googlelogo" />
                    <Typography
                      className={classes.googleTxt}
                      data-test-id="btnSocialLogin"
                      onClick={() => this.initiateGoogleLogin()}
                    >
                       Log in with Google
                    </Typography>
                  </Button> */}
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(EmailLoginBlock);
