import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Backdrop, Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { ReactComponent as CheckmarkRedIcon } from '../../../../../../../assets/icons/red_checkmark.svg';
import { useHistory } from 'react-router-dom';
import ConfirmationModal from '../../../../../ConfirmationModal';
import { addPaymentMethod, cancelSubscription, getCurrentSubscription, getSubscriptionById, renewSubscription } from '../../../../../../../redux/services/subscriptions';
import { getCurrencySybmol } from '../../../../../../../helpers/curency';
import { ReactComponent as SubscriptionExpiredIcon } from '../../../../../../../assets/icons/subscription_expired.svg';
import { enqueueSnackbar } from 'notistack';
import { getCurrentSubscriptionStatus } from '../../../../../../../helpers/subscription';

import { useStyles } from './styles';
import { observableService } from '../../../../../../../services/observable';

interface Props {
  setTab: (tab: string) => void;
  setOrder: (order: any) => void;
}

const CurrentPlan: React.FC<Props> = ({
  setTab,
  setOrder
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [currentSubscription, setCurrentSubscription] = useState<any>(null);
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState<boolean>(false);

  const history = useHistory();
  const classes = useStyles();

  const userTimezone = moment.tz.guess();

  useEffect(() => {
    handleGetCurrentSubscription();
  }, []);

  const handleGetCurrentSubscription = async () => {
    const { data, error } = await getCurrentSubscription();
    
    if (data && !error) {
      const { data: targetSubscription } = await getSubscriptionById(data.subscriptionId);

      setCurrentSubscription({
        ...data,
        metadata: targetSubscription || {}
      });
    } else {
      setCurrentSubscription({})
    }
  }

  const handleCancelCurrentSubscription = async () => {
    setLoading(true);

    const { error } = await cancelSubscription();

    if (!error) {
      enqueueSnackbar(
        `Your ${currentSubscription.metadata.name} has been canceled`,
        { variant: 'success' }
      )

      await handleGetCurrentSubscription();

      observableService.sendEvent('Check if subscription is active');
    } else {
      enqueueSnackbar(
        'Failed to cancel subsription',
        { variant: 'error' }
      )
    }

    setLoading(false);
  }

  const handleChangePaymentMethod = async () => {
    setLoading(true);

    const { data, error } = await addPaymentMethod();

    if (data && !error) {
      setOrder({
        ...data,
        // token: '127865fb-b1f8-472f-bb30-e06703f18199'
      });
      setTab('paymentMethod');
    } else {
      enqueueSnackbar(
        'Something went wrong. Try again later or contact our support',
        { variant: 'error' }
      )
    }

    setLoading(false);
  }

  const handleRenewSubscription = async () => {
    setLoading(true);

    const { error } = await renewSubscription();

    if (!error) {
      enqueueSnackbar(
        'Your subscription has been renewed',
        { variant: 'success' }
      )

      await handleGetCurrentSubscription();

      observableService.sendEvent('Check if subscription is active');
    } else {
      enqueueSnackbar(
        'Something went wrong. Try again later',
        { variant: 'error' }
      )
    }

    setLoading(false);
  }

  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: 9999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Typography style={{ marginBottom: '12px', fontSize: '30px', fontWeight: '700', lineHeight: '32px', textAlign: 'center' }}>
        Plan and Billing
      </Typography>
      <Typography style={{ fontSize: '20px', fontWeight: '700', lineHeight: '24px' }}>
        Your subscription
      </Typography>
      {currentSubscription === null
        ? <Box 
            className={classes.currentPlan}
            style={{ justifyContent: 'center' }}
          >
            <CircularProgress 
              color="inherit" 
              style={{ margin: '0 auto' }}
            />
          </Box>
        : typeof currentSubscription === 'object' && !Array.isArray(currentSubscription) && currentSubscription !== null && !Object.keys(currentSubscription).length
          ? <Box className={classes.currentPlan} />
          : <Box className={classes.currentPlan}>
              <Typography style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '16px', fontWeight: '600', lineHeight: '19px', color: getCurrentSubscriptionStatus(currentSubscription) === 'EXPIRED' ? '#64748B' : '#D93855' }}>
                {getCurrentSubscriptionStatus(currentSubscription) === 'CANCELED' 
                  ? <>&#x2715;&nbsp;</> 
                  : getCurrentSubscriptionStatus(currentSubscription) === 'EXPIRED'
                    ? <>
                        <SubscriptionExpiredIcon style={{ position: 'absolute' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </>
                    : ''
                }
                {currentSubscription.metadata.name} {getCurrentSubscriptionStatus(currentSubscription) === 'CANCELED' ? '(cancelled)' : getCurrentSubscriptionStatus(currentSubscription) === 'EXPIRED' ? '(expired)' : ''}
                {(getCurrentSubscriptionStatus(currentSubscription) === 'CANCELED' || getCurrentSubscriptionStatus(currentSubscription) === 'EXPIRED') && (
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => {
                      getCurrentSubscriptionStatus(currentSubscription) === 'CANCELED'
                        ? handleRenewSubscription()
                        : history.push('/subscriptions')
                    }}
                  >
                    {getCurrentSubscriptionStatus(currentSubscription) === 'CANCELED'
                      ? 'Renew'
                      : 'Activate'
                    }
                  </Button>
                )}
              </Typography>
              <Typography style={{ display: 'flex', alignItems: 'center', gap: '8px', fontSize: '16px', fontWeight: '500', lineHeight: '19px' }}>
                {getCurrencySybmol(currentSubscription.metadata.currency)}{currentSubscription.metadata.price}/mth
                {getCurrentSubscriptionStatus(currentSubscription) !== 'EXPIRED' && (
                  <Typography style={{ fontSize: '14px', fontWeight: '500', lineHeight: '21px', color: '#64748B' }}>
                    |&nbsp;&nbsp;{getCurrentSubscriptionStatus(currentSubscription) === 'ACTIVE' ? 'Next payment' : 'Active by'} {moment.utc(currentSubscription.validTill).tz(userTimezone).format('DD-MMM-YYYY')}
                  </Typography>
                )}
              </Typography>
              <Box className={classes.currentPlanBenefits}>
                <Box className={classes.benefitsLeftSidebar}>
                  {currentSubscription.metadata.properties.map((item, index) => (
                    <Typography 
                      key={index}
                      style={{ display: 'flex', alignItems: 'center', gap: '6px', fontSize: '12px', fontWeight: '500', lineHeight: '17px', color: '#475569' }}
                    >
                      <CheckmarkRedIcon />
                      {item.name}
                    </Typography>
                  ))}
                </Box>
                {getCurrentSubscriptionStatus(currentSubscription) === 'ACTIVE' && (
                  <Typography
                    style={{ fontSize: '14px', fontWeight: '600', lineHeight: '21px', textDecoration: 'underline', color: '#3A84C9', cursor: 'pointer' }}
                    onClick={() => setShowUnsubscribeModal(true)}
                  >
                    Cancel subscription
                  </Typography>
                )}
              </Box>
            </Box>
      }
      <Box className={classes.navigationButtons}>
        <Button
          fullWidth
          color='secondary'
          variant='outlined'
          onClick={() => setTab('history')}
        >
          View payments history
        </Button>
        {getCurrentSubscriptionStatus(currentSubscription) === 'ACTIVE' && (
          <Button
            fullWidth
            color='secondary'
            variant='outlined'
            onClick={() => handleChangePaymentMethod()}
          >
            Change payment method
          </Button>
        )}
      </Box>
      <Box className={classes.footer}>
        <Box className={classes.footerOtherOptions}>
          <Typography style={{ fontSize: '20px', fontWeight: '700', lineHeight: '24px' }}>
            Other options
          </Typography>
          <Button
            color='secondary'
            variant='outlined'
            onClick={() => history.push('/subscriptions')}
          >
            View details
          </Button>
        </Box>
        <Box>
          <Typography style={{ fontSize: '16px', fontWeight: '500', lineHeight: '19px', color: '#475569' }}>
            Wanna know more?
          </Typography>
          <Typography style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '16px', fontWeight: '500', lineHeight: '19px', color: '#475569' }}>
            Check our
            <Typography 
              style={{ fontWeight: '600', color: '#3A84C9', textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => window.open('/privacy-policy', '_blank')}
            >
              Privacy Policy
            </Typography>
            and
            <Typography 
              style={{ fontWeight: '600', color: '#3A84C9', textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => window.open('/terms-and-conditions', '_blank')}
            >
              Terms & Conditions
            </Typography>
          </Typography>
        </Box>
      </Box>

      <ConfirmationModal
        open={showUnsubscribeModal}
        title={'Are you sure you want to unsubscribe from your GTM Professional plan?'}
        description={'Please be aware that you will no longer have access to any features at the end of the billing month.'}
        rejectBtnText={'No, I want to stay'}
        confirmBtnText={'Continue'}
        onClose={() => setShowUnsubscribeModal(false)}
        onConfirm={() => {
          handleCancelCurrentSubscription();
          setShowUnsubscribeModal(false);
        }}
        onReject={() => setShowUnsubscribeModal(false)}
      />
    </>
  );
};

export default CurrentPlan;
