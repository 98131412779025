import { IBlock } from "../../../framework/main/IBlock";
import { BlockComponent } from "../../../framework/main/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/main/Messages/MessageEnum";
import { runEngine } from "../../../framework/main/RunEngine";



export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  title: string;
  serviceArray: any;
  inputList: any;
  handleChange1?: (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => void;
  handleChange?: (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => void;
  handleChange2?: (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => void;
  handleChange3?: (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => void;
  handleChange4?: (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => void;
  processText?: boolean;
  goodtoHave?: boolean;
  classes?: any;
  name?: any;
  formikHandelChange?: any;
  formikValue?: any;
  formikError?: any;
  tootipData?: any;

  showListHeader?: boolean;
}

interface S {
}

interface SS {
  id: any;
}

export default class ModularProductsController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    //
  }

  // web events

  styleCondition(event: any, styleCondition1: any, styleSondition2: any) {
    if (event) {
      return styleCondition1;
    } else {
      return styleSondition2;
    }
  }
}
