import { Box, Paper, Typography, IconButton } from "@material-ui/core";
import { WithStyles, createStyles, withStyles } from "@material-ui/core/styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import React from "react";
import LandingBlockWeb from "./LandingBlock.web";
import { robotLight } from "./assets";

export interface Props extends WithStyles<any> {
  onClick: () => void;
}

const styles = () =>
  createStyles({
    pebble: {
      position: "relative" as "relative",
      padding: "25px 20px",
      borderRadius: "20px",
      boxSizing: "border-box"
    },

    pebbleTitle: {
      fontSize: 16,
      lineHeight: "19.2px",
      fontWeight: 600
    },

    pebbleContent: {
      marginTop: 60
    },

    pebbleImage: {
      position: "absolute" as "absolute",
      top: 0,
      right: 0,
      width: 200,
      height: "auto",

      "@media (max-width: 767.98px)": {
        width: 156,
        top: -20,
        right: -20
      }
    },

    pebbleDescription: {
      marginTop: 8,
      fontWeigth: 500,
      fontSize: 12,
      lineHeight: "14.4px",
      color: "#475569"
    },

    arrowIcon: {
      transform: "rotate(-45deg)",
      cursor: 'pointer'
    }
  });

export class LandingOnDemandIntelligence extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <LandingBlockWeb
        title="ON DEMAND INTELLIGENCE"
        content="Ask your personal copilot to generate precise GTM intelligence and content."
      >
        <Paper className={classes.pebble}>
          <ArrowForwardIcon
            fontSize="small"
            className={classes.arrowIcon}
            onClick={() => this.props.onClick()}
          />
          <Box className={classes.pebbleContent}>
            <Typography className={classes.pebbleTitle}>
              Pebbles Copilot
            </Typography>

            <Typography className={classes.pebbleDescription}>
              Your very own go to market companion.
            </Typography>
          </Box>
          <img className={classes.pebbleImage} src={robotLight} />
        </Paper>
      </LandingBlockWeb>
    );
  }
}

export default withStyles(styles)(LandingOnDemandIntelligence);
