import React from "react";


import {
  Box,
  Button,
  Typography,
  Container,
  IconButton,
  Grid,
  Tooltip,
  Divider,
  TextField,
  InputAdornment,
  Modal,
  Backdrop,
  CircularProgress,
  Snackbar
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { rightBar, replyIcon, copy, sendIcon, addCircle, disableAdd, disableCopy, textStyle, replyDisableIcon, thinkigRobo, answerReadyRobo } from "./assets";
import SwapCallsIcon from '@material-ui/icons/SwapCalls';
import EditIcon from '@material-ui/icons/Edit';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CustomToaster from "../../../components/main/ChatHistoryToaster.web";
import DeleteCampaign from "../../../components/main/DeleteCampaign.web";
import ShareFileModal from "./ShareFileModal.web";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Alert from '@material-ui/lab/Alert';
import {
  MessageLeft,
  MessageRight
} from "../../../components/main/Message.web";
import clsx from "clsx";

const DeleteTooltip = withStyles({
  tooltip: {
    fontSize: "0.75rem",
    color: "#fff",
    backgroundColor: "#D93855",
    fontWeight: 500,
    fontFamily: 'Montserrat',
    padding: '10px'
  },
  arrow: {
    color: '#D93855',
    fontSize: '15px'
  }
})(Tooltip);

const CommonTooltip = withStyles({
  tooltip: {
    fontSize: "0.75rem",
    color: "#fff",
    backgroundColor: "#0B67BC",
    fontWeight: 500,
    fontFamily: 'Montserrat',
    padding: '10px'
  },
  arrow: {
    color: '#0B67BC',
    fontSize: '15px'
  }
})(Tooltip);


import EmailCreationController, {
  Props,
  configJSON,
} from "./EmailCreationController";
import ParameterModal from "./ParameterModal.web";
import ParameterChangeDialog from "./ParameterChangeDialog.web";
import Toast from "../../utilities/main/Toast";

export class EmailCreation extends EmailCreationController {
  constructor(props: Props) {
    super(props);
  }

  renderCampaignDetails(classes: any) {
    return (
      <Box className={classes.firstBox}>
        {/* {!this.state.rightMenu &&
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            data-testid="right-menu"
            onClick={this.handleRightMenu}
            className={classes.rightBar}
          >
            <img
              src={rightBar}
              alt="right-bar"
            />
          </IconButton>
        } */}
        <Grid container alignItems="center">
          {this.renderCampaignInfo(classes)}
          <Grid item xs={12} sm={6} md={4} className={classes.marketingDisplay}>
            <Typography className={clsx(
              classes.marketingText,
              {
                [classes.saleText]: this.state.campaignTypeId === 2,
                [classes.feedbackText]: this.state.campaignTypeId === 3
              }
            )}>{this.state.campaignType ? this.state.campaignType : configJSON.customMarketing}</Typography>
          </Grid>
          {this.renderCampaignActions(classes)}
        </Grid>
        <Box className={classes.displaySecondDiv}>
          <Button
            variant="contained"
            endIcon={<SwapCallsIcon className={classes.iconColor} />}
            className={classes.swapButton}
            onClick={this.handleOpenParameterDialog}
          >
            {configJSON.openParameter}
          </Button>
        </Box>
      </Box>
    );
  }
  renderCampaignInfo(classes: any) {
    return (
      <Grid item xs={12} sm={6} md={4}>
        <Typography className={classes.title} data-testid="campaign-name">{this.state.campDetails?.attributes?.campaign?.name}</Typography>
        <Typography className={classes.description}>{this.state.campDetails?.attributes?.campaign?.description}</Typography>
      </Grid>
    );
  }
  renderCampaignActions(classes: any) {
    return (
      <Grid item xs={12} sm={6} md={4} className={classes.iconCOntainer}>
        <Box className={classes.iconDisplay}>
          {this.renderEditIcon(classes)}
          <Box className={classes.divider}></Box>
          {this.renderBookmarkIcon(classes)}
          {this.renderShareIcon(classes)}
          <Box className={classes.divider}></Box>
          {this.renderDeleteIcon(classes)}
        </Box>
      </Grid>
    );
  }

  renderErrorSubject = () => {
    const { classes }: any = this.props;
    if (!this.state.subjectError) return null;
    return <Typography className={classes.subjectError}>{this.state.subjectError}</Typography>
  }
  renderEditIcon(classes: any) {
    return (
      <CommonTooltip title={configJSON.editCamp} arrow>
        <EditIcon className={classes.iconColor} onClick={this.handleOpenParameterDialog} data-testid="edit-icon" />
      </CommonTooltip>
    );
  }
  renderBookmarkIcon(classes: any) {
    const { campDetails } = this.state;
    return (
      <CommonTooltip title={configJSON.saveCamp} data-testid="save-button-tooltip" arrow onClick={() => this.handleSave(!campDetails?.attributes?.campaign?.favourited)}>
        {campDetails?.attributes?.campaign?.favourited ? <BookmarkIcon className={classes.iconColor} /> : <BookmarkBorderIcon className={classes.iconColor} />}
      </CommonTooltip>
    );
  }

  renderReady = () => {
    return (
      <Box style={{ alignItems: "end", display: "flex", marginLeft: "20px" }}>
        <img src={answerReadyRobo} style={{ width: "46.26px", height: "37px" }} />
        <Typography
          style={{
            fontWeight: 500,
            color: "rgba(100, 116, 139, 1)",
            fontFamily: "Montserrat",
            fontSize: "14px",
          }}
        >
          {'Your answer is ready!'}
        </Typography>
      </Box>
    )
  }

  renderThinking = () => {
    return (
      <Box>
        <img src={thinkigRobo} />
        <Typography
          style={{
            fontStyle: "italic",
            fontSize: "0.875rem",
            color: "rgba(100, 116, 139, 1)",
            fontWeight: 500,
            fontFamily: "Montserrat",
          }}
        >
          {'Thinking...'}
        </Typography>
      </Box>
    )
  }

  renderChatList = () => {
    const { classes }: any = this.props;
    return (
      <Container className={classes.chatContainer}>
        <Box
          style={{
            padding: "25px 25px 0px 25px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
          }}
        >
          {this.state.chatList?.map((data: any, index: any) => (
            <>
              {data.role !== "user" ? (
                <>
                  {index == this.state.chatList?.length - 1 && this.renderReady()}
                  <MessageLeft
                    timestamp={data?.created_at}
                    data-test-id="MessageLeftDataTestId"
                    message={data?.content}
                    hideAction
                    isSaved={data.is_saved}
                    setParameter={(obj: any) => {
                      this.setState(obj);
                    }}
                    isLiked={data.is_liked}
                  />
                </>
              ) : (
                <MessageRight
                  timestamp={data.created_at}
                  data-test-id="MessageRightDataTestId"
                  message={data.content}
                  hideAction
                  setParameter={(obj: any) => {
                    this.setState(obj);
                  }}
                  isSaved={data.is_saved}
                />
              )}
            </>
          ))}
          {this.state.isAiThinking && this.renderThinking()}
        </Box>
      </Container>
    )
  }

  renderButton = () => {
    const { classes }: any = this.props;
    return (
      <Box className={classes.generatebuttonDisplay}>
        <Button
          variant="contained"
          data-testid="generate-button"
          className={classes.generateButton}
          onClick={this.handleGenerate}
          disabled={this.state.mail === '' || this.state.generatedMail.length > 6}
          endIcon={(this.state.mail === '' || this.state.generatedMail.length > 6) ? <img src={disableAdd} /> : <img src={addCircle} />}
        >{configJSON.generateMail}</Button>
        <Box className={classes.buttonEditDisplay}>
          {this.state.generatedMailBookmark ?
            <Button
              variant="outlined"
              className={classes.copyButton}
              data-testid="save-button"
              onClick={() => this.handleSaveLibrary(false)}
              disabled={this.state.mail === ''}
              endIcon={this.state.mail === '' ? <BookmarkIcon style={{ color: '#BDC1BD' }} /> : <BookmarkIcon className={classes.iconColor} />}
            >{configJSON.saveLibrary}</Button> :
            <Button
              variant="outlined"
              className={classes.copyButton}
              data-testid="save-button"
              onClick={() => this.handleSaveLibrary(true)}
              disabled={this.state.mail === ''}
              endIcon={this.state.mail === '' ? <BookmarkBorderIcon style={{ color: '#BDC1BD' }} /> : <BookmarkBorderIcon className={classes.iconColor} />}
            >{configJSON.saveLibrary}</Button>
          }
          <Button
            variant="outlined"
            onClick={this.handleCopyClick}
            data-testid="copy-button"
            className={classes.copyButton}
            disabled={this.state.mail === ''}
            endIcon={this.state.mail === '' ? <img src={disableCopy} /> : <img src={copy} />}
          >{configJSON.copy}</Button>
          <Button
            variant="outlined"
            className={classes.copyButton}
            onClick={this.handleShare}
            disabled={this.state.mail === ''}
            endIcon={this.state.mail === '' ? <img src={replyDisableIcon} /> : <img src={replyIcon} />}
          >{configJSON.share}</Button>
        </Box>
      </Box>
    )
  }

  renderShareIcon(classes: any) {
    return (
      <CommonTooltip title={configJSON.shareCamp} arrow>
        <img src={replyIcon} alt="reply" className={classes.iconHover} data-testid="share-icon" onClick={this.handleShare} />
      </CommonTooltip>
    );
  }
  renderDeleteIcon(classes: any) {
    return (
      <DeleteTooltip title={configJSON.deleteCamp} data-testid="delete-icon" arrow onClick={this.handleCloseDelteModal}>
        <DeleteOutlineRoundedIcon color="error" className={classes.iconHover} />
      </DeleteTooltip>
    );
  }

  render() {
    const { classes, ...restProps }: any = this.props;
    return (
      <>
        <Container className={classes.mainContainer} data-testid="email-creation">
          <CustomToaster data-testid="CustomToaster" open={this.state.saveMessage} message={configJSON.saveMessage} onClose={this.handleCloseToast} />
          <DeleteCampaign data-testid="delete-modal" deleteModalopen={this.state.deletePopup} handleDelteModal={this.handleDelete} handleCloseDelteModal={this.handleCloseDelteModal} />
          <ShareFileModal data-testid="share-modal" open={this.state.shareModal} onClose={this.handleShare} generatedOutPutId={this.state.generatedOutPutId} shareType={'outreach'} {...restProps} />
          <Backdrop className={classes.backdrop} open={this.state.loader}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.copiedAlert}
            autoHideDuration={2000}
            data-testid="copy-snackbar"
            onClose={this.handleCopyClose}
            message={this.state.copiedMessage}
            className={classes.copySnackBar}
          />
          <Snackbar open={this.state.deleteSuccess} autoHideDuration={2000} onClose={this.handleCloseSuccessPopup}>
            <Alert onClose={this.handleCloseSuccessPopup} severity="success">
              {configJSON.deleteSuccess}
            </Alert>
          </Snackbar>
          {this.renderCampaignDetails(classes)}
          <Divider />
          <Box className={classes.SecondBox}>
            {this.state.generatedMail.length > 1 &&
              <Box className={classes.mailMainBox} data-testid="mail-display">
                {this.state.generatedMail.map((data, index) =>
                  <Box className={classes.mailArrayBox} key={index}>
                    <Box className={classes.dayBox}>
                      <CalendarTodayIcon className={classes.calenderIcon} />
                      <Typography className={classes.dayText}>{configJSON.day} {index + 1}</Typography>
                    </Box>
                    <Box className={this.state.mailActive === index ? classes.innerMailBoxActive : classes.innerMailBox} onClick={() => this.handleSelectMail(index)} data-testid={"mail-select-" + index}>
                      <MailOutlineIcon className={classes.mailIcon} />
                      <Typography className={classes.mailText} data-testid={"mail-" + index}>{configJSON.mail} {index + 1}</Typography>
                    </Box>
                  </Box>
                )}
                <Box>
                  {this.state.generatedMail.length > 6 ?
                    <Button
                      variant="contained"
                      data-testid="disabled-mailbutton"
                      disabled
                      className={classes.buttonDisabled}
                      endIcon={<AddCircleOutlineIcon />}
                    >{configJSON.addMail}</Button> :
                    <Button
                      variant="contained"
                      className={classes.buttonEnabled}
                      onClick={this.handleGenerate}
                      data-testid="add-mail"
                      endIcon={<AddCircleOutlineIcon />}
                    >{configJSON.addMail}</Button>}
                </Box>
              </Box>
            }
            <Box className={classes.subjectBox}>
              <Typography className={classes.subjectLineLabel}>{configJSON.subjectLine}</Typography>
              <TextField
                variant="outlined"
                name="subject"
                data-testid="subject-field"
                value={this.state.subject}
                onChange={this.handleChangeSubject}
                fullWidth
                size="small"
                InputProps={{
                  className: classes.inputProps
                }}
              />
              {this.renderErrorSubject()}
            </Box>
            <img src={textStyle} className={classes.textStyle} />
            <TextField
              variant="outlined"
              name="mail"
              fullWidth
              data-testid="mail-textfield"
              placeholder={configJSON.mailPlaceholder}
              value={this.state.mail}
              multiline
              rows={18}
              onChange={this.handleMailChange}
              InputProps={{
                className: classes.mailBoxInputProps
              }}
            />
            {this.renderButton()}
          </Box>
          {this.renderChatList()}
          <Box className={classes.ThirdBox}>
            <TextField
              label=""
              multiline
              id="input-with-icon-textfield"
              variant="outlined"
              data-testID='promptInput'
              placeholder={configJSON.editMailPlaceholder}
              minRows={4}
              fullWidth
              value={this.state.questionText}
              onChange={this.changeQuestText}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" data-testID='sendButton' onClick={this.submitChat} className={classes.inputAdornment}>
                    <img src={sendIcon} />
                  </InputAdornment>
                ),
                className: classes.mailEditorInput
              }}
            />
            <Typography className={classes.pebbelsCopilot}>{configJSON.pebbelsCopilot}</Typography>
          </Box>
          <Modal
            className={classes.modal}
            data-testid="ope-para-modal"
            open={this.state.parameterDialog}
            onClose={this.handleCloseParameterDialog}
          >
            <ParameterModal
              formValueData={this.state.formValueData}
              formData={this.state.formData}
              onCloseModal={this.handleCloseParameterDialog}
              id="ParameterModal"
              data-testid="parameter-modal"
              navigation={this.props.navigation}
              onApply={this.handleCloseParameterDialog}
              onSave={this.handleOpenParameterDialogChange}
            />
          </Modal>
          <ParameterChangeDialog
            open={this.state.parameterChangeDialog}
            data-testid="parameter-change-dialog"
            onApplyChange={this.handleCloseParameterDialogChange}
            onSaveChange={this.handleCloseParameterDialogChange}
            onClose={this.handleCloseParameterDialogChange}
          />
          <CustomToaster
            data-test-id="CustomToaster2"
            open={this.state.displayToaster}
            message={this.state.toasterMsg}
            onClose={() => {
              this.setState({ displayToaster: false });
            }}
          />
        </Container>
      </>
    );
  }
}

const webStyle: {} = () => ({
  rightBar: {
    position: "absolute" as "absolute",
    right: "0",
    zIndex: "9999",
    height: 'max-content',
    top: '0'
  },
  mainContainer: {
    position: 'relative',
    padding: '0'
  },
  iconDisplay: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    flexWrap: 'wrap'
  },
  marketingText: {
    border: '1px solid #57BE99',
    borderRadius: '26px',
    padding: '4px 12px',
    color: '#57BE99',
    fontSize: '0.75rem',
    fontWeight: 500,
    width: 'max-content',
    textAlign: 'center'
  },
  saleText: {
    border: '1px solid #D93855',
    color: "#D93855"
  },
  feedbackText: {
    border: '1px solid #3A84C9',
    color: "#3A84C9"
  },
  description: {
    fontSize: '0.75rem',
    fontWeight: 600,
    color: '#475569'
  },
  title: {
    fontWeight: 600
  },
  iconColor: {
    color: "#3A84C9",
    cursor: 'pointer'
  },
  divider: {
    width: "0.1rem",
    height: '1.5rem',
    background: '#94A3B8'
  },
  iconHover: {
    cursor: 'pointer'
  },
  marketingDisplay: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  parameter: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  displaySecondDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: 'start',
    marginTop: '10px'
  },
  applyParameterText: {
    fontSize: '0.875rem',
    fontWeight: 500
  },
  showParaDiv: {
    display: "flex",
    alignItems: "center",
    flexWrap: 'wrap'
  },
  showParamText: {
    color: "#3A84C9"
  },
  arrowDown: {
    height: "1rem",
    width: "1rem",
    color: "#3A84C9",
  },
  SecondBox: {
    padding: '20px 10px',
  },
  firstBox: {
    padding: '20px',
    background: '#F1F5F9'
  },
  mailMainBox: {
    display: 'flex',
    alignItems: 'end',
    gap: '10px',
    flexWrap: 'wrap'
  },
  mailArrayBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  },
  dayBox: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    paddingLeft: '14px',
    flexWrap: 'wrap'
  },
  calenderIcon: {
    fontSize: '1.2rem',
    color: '#64748B'
  },
  dayText: {
    color: '#64748B',
    fontWeight: 500
  },
  innerMailBox: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    background: '#F1F5F9',
    width: 'maxContent',
    padding: '10px 30px 10px 10px',
    borderRadius: '8px',
    cursor: 'pointer'
  },
  innerMailBoxActive: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    background: '#D1FAE5',
    width: 'maxContent',
    padding: '10px 30px 10px 10px',
    borderRadius: '8px',
    cursor: 'pointer'
  },
  mailIcon: {
    color: '#475569'
  },
  mailText: {
    color: '#334155'
  },
  buttonDisabled: {
    background: '#CBD5E1',
    borderRadius: '8px',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    color: '#64748B',
    padding: '10px',
    textTransform: 'inherit',

  },
  buttonEnabled: {
    background: '#000',
    borderRadius: '8px',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    color: '#fff',
    padding: '10px',
    textTransform: 'inherit'
  },
  subjectLineLabel: {
    fontWeight: 600,
    color: '#000'
  },
  subjectBox: {
    margin: '20px 0 10px'
  },
  copyButton: {
    border: '1px solid #CBD5E1',
    borderRadius: '8px',
    textTransform: 'inherit',
    fontFamily: 'Montserrat',
    color: '#000',
    fontWeight: 600
  },
  buttonDisplay: {
    display: 'flex',
    gap: '10px',
    marginTop: '5px'
  },
  buttonEditDisplay: {
    display: 'flex',
    gap: '10px',
  },
  ThirdBox: {
    background: '#F8FAFC',
    padding: '10px'
  },
  inputAdornment: {
    alignItems: 'start',
    cursor: 'pointer',
  },
  inputProps: {
    borderRadius: '8px'
  },
  subjectError: {
    marginTop: '2px',
    fontSize: '12px',
    fontWeight: 500,
    color: "#DC2626",
  },
  mailEditorInput: {
    fontSize: '0.75rem',
    color: '#64748B',
    fontFamily: 'Montserrat',
    fontStyle: 'italic',
    borderRadius: '8px',
    'input': {
      '& .MuiOutlinedInput-inputAdornedEnd-523': {
        // padding: '10px 0 38px 10px',
      }
    },
    padding: '10px 12px 4px 12px',
    minHeight: '91px'
  },
  pebbelsCopilot: {
    fontSize: '0.75rem',
    fontWeight: 500,
    color: '#475569',
    padding: '5px'
  },
  generateButton: {
    background: '#000',
    color: '#fff',
    fontWeight: 600,
    borderRadius: '8px',
    textTransform: 'inherit'
  },
  generatebuttonDisplay: {
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #3A84C9',
    borderWidth: '0 1px 1px 1px',
    borderRadius: '0 0 8px 8px',
    padding: '5px',
    background: '#F1F5F9'
  },
  iconCOntainer: {
    zIndex: '9999'
  },
  swapButton: {
    border: '1px solid #CBD5E1',
    borderRadius: '8px',
    background: '#fff',
    color: '#000',
    fontSize: '0.875rem',
    fontWeight: 500,
    boxShadow: 'none',
    textTransform: 'inherit',
  },
  textStyle: {
    width: '100%',
    marginBottom: '5px'
  },
  mailBoxInputProps: {
    border: '1px solid #3A84C9',
    borderRadius: '8px 8px 0 0',
    fontSize: '0.875rem',
    fontWeight: 500,
    color: '#090A14'
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%"
  },
  backdrop: {
    zIndex: 99999,
    color: '#fff',
  },
  copySnackBar: {
    textAlign: 'center',
  }
});
export default withStyles(webStyle)(EmailCreation);

