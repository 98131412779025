import React, { useEffect, useRef, useState } from 'react';
import RevolutCheckout from '@revolut/checkout'
import { Form, Formik } from 'formik';
import { Box, Button, CircularProgress, Typography, TextField as Input } from '@material-ui/core';
import * as Yup from "yup";
import TextField from '../../../../../CustomFields/TextField';
import { getProfile } from '../../../../../../../redux/services/profile';
import { Autocomplete } from '@material-ui/lab'
import SuccessPayment from '../SuccessPayment';
import ErrorPayment from '../ErrorPayment';
import { COUNTRIES } from '../../../../../../../utils/countries';
import { useHistory } from 'react-router-dom';
import { formatCurrency, getCurrencySybmol } from '../../../../../../../helpers/curency';

import { useStyles } from './styles';

interface Props {
  order: any;
  redirect?: boolean;
  onClose: () => void;
}

const PaymentMethod: React.FC<Props> = ({
  order = null,
  redirect = true,
  onClose = () => {},
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [revolut, setRevolut] = useState<any>(null);
  const [card, setCard] = useState<any>(null);
  const [profile, setProfile] = useState<any>(null);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const history = useHistory();
  const classes = useStyles();
  const fields = useRef<any>(null);
  const formRef = useRef<any>(null);

  useEffect(() => {
    setLoading(true);

    handleGetProfile();

    if (fields && order) {
      handleCreateRevolut();
    }
  }, [fields, order]);

  useEffect(() => {
    if (revolut) {
      handleCreateCardFields();
    }
  }, [revolut]);

  useEffect(() => {
    if (profile && formRef.current) {
      const { location } = JSON.parse(profile?.attributes?.account?.description || JSON.stringify({ location: profile?.attributes?.country_name }));

      formRef.current.setValues({
        email: profile?.attributes?.user_email,
        cardholderName: profile?.attributes?.account?.full_name,
        country: location ? { value: Object.keys(COUNTRIES).find(key => COUNTRIES[key] === location), label: location } : null,
        zip: null
      });
    }
  }, [profile, formRef]);

  const handleGetProfile = async () => {
    const { data, error } = await getProfile();

    if (data && !error) {
      setProfile(data);
    }
  }

  const handleCreateRevolut = async () => {
    const revolut = await RevolutCheckout(
      order?.token,
      process.env.REACT_APP_ENVIRONMENT === 'development' ? 'sandbox' : 'prod',
    );

    setRevolut(revolut);
  }

  const handleCreateCardFields = () => {
    const card = revolut.createCardField({
      target: fields.current,
      onSuccess() {
        setShowSuccessModal(true)
      },
      onError(message) {
        setShowErrorModal(true)
      },
      styles: {
        default: {
          // fontSize: '14px',
          // fontWeight: '500',
          // lineHeight: '21px',
          color: 'black',
          '::placeholder': {
            color: '#8792A2',
          },
        },
        invalid: {
          color: '#fa755a',
          '::placeholder': {
            color: '#fa755a',
          },
        },
      },
    });

    setCard(card);
    setLoading(false);
  }

  const handleSubmit = async (values) => {
    setLoading(true);

    card.validate();
    card.submit({
      name: values.cardholderName.trim(),
      email: values.email.trim(),
      // phone?: string;
      savePaymentMethodFor: 'merchant',
      cardholderName: values.cardholderName.trim(),
      billingAddress: {
        countryCode: values.country.value,
        postcode: values.zip,
        // region?: string;
        // city?: string;
        // streetLine1?: string;
        // streetLine2?: string;
      },
      shippingAddress: {
        countryCode: values.country.value,
        postcode: values.zip,
        // region?: string;
        // city?: string;
        // streetLine1?: string;
        // streetLine2?: string;
      },
    });

    setLoading(false);
  }

  const handleRedirect = () => {
    setLoading(true);

    setShowSuccessModal(false);
    setShowErrorModal(false);

    if (redirect) {
      const url = window.location.pathname;

      if (url.includes('/choose-your-plan')) {
        history.push('/choose-your-path');
      } else {
        history.push('/home');
      }
    }

    setLoading(false);
    onClose();
  }

  return (
    <>
      {loading && (
        <Box className={classes.loader}>
          <CircularProgress color="primary" />
        </Box>
      )}

      <Formik
        innerRef={formRef}
        initialValues={{}}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Invalid email address')
            .required('This field is required'),
          cardholderName: Yup.string()
            .matches(/^[a-zA-Z\s\-']+$/, 'Cardholder name can only contain letters, spaces, hyphens, and apostrophes')
            .test(
              'min-words',
              'Enter correct cardholder name',
              value => value && value.split(' ').filter(word => word.length >= 3).length >= 2
            )
            .required('Cardholder name is required'),
          country: Yup.object().shape({
            value: Yup.string().required('Country value is required'),
            label: Yup.string().required('Country label is required')
          }).required('Country is required'),
          zip: Yup.string()
            .required('Zip code is required')
            .matches(/^[a-zA-Z0-9\s-]{3,10}$/, 'Invalid zip code format')
            .nullable(),
        })}
        validateOnChange={true}
        validateOnBlur={false}
        isInitialValid={false}
        onSubmit={(values: any) => handleSubmit(values)}
      >
        {({
          values,
          errors,
          touched,
          submitForm,
          validateField,
          validateForm,
          resetForm,
          setFieldValue,
          setFieldError,
          setFieldTouched
        }) => (
          <Form
            translate={undefined}
            style={{ width: '100%' }}
          >
            <Box className={classes.container}>
              <TextField
                name={'email'}
                value={values['email']}
                placeholder={'Provide an email address'}
                label={'Email'}
                error={(touched['email'] && errors['email']) as string}
                onChange={(event) => setFieldValue('email', event.target.value)}
              />
              <Box className={classes.cardWrapper}>
                <Typography style={{ fontSize: '16px', fontWeight: '600', lineHeight: '24px' }}>
                  Enter card number and info
                </Typography>
                <div ref={fields} className={classes.cardFields} />
              </Box>
              <TextField
                name={'cardholderName'}
                value={values['cardholderName']}
                placeholder={'Provide the name of card'}
                label={'Cardholder name'}
                error={(touched['cardholderName'] && errors['cardholderName']) as string}
                onChange={(event) => setFieldValue('cardholderName', event.target.value)}
              />
              <Box>
                <Box>
                  <Typography style={{ marginBottom: '2px', fontSize: '16px', fontWeight: '600', lineHeight: '24px' }}>
                    Country and zip code
                  </Typography>
                  <Autocomplete
                    value={values['country'] || ''}  // Ensure value is always defined
                    options={Object.entries(COUNTRIES).map(([key, value]) => ({ value: key, label: value }))}
                    getOptionLabel={(option: any) => option?.label}
                    onChange={(event: any, value: any) => setFieldValue('country', value)}
                    disableClearable
                    autoHighlight
                    className={classes.contryAutocomplete}
                    renderInput={(params: any) => (
                      <Input
                        {...params}
                        name={'country'}
                        placeholder={'Choose country...'}
                      />
                    )}
                  />
                </Box>
                <TextField
                  name={'zip'}
                  value={values['zip']}
                  placeholder={'ZIP'}
                  // type={'number'}
                  className={classes.zipField}
                  error={
                    ((touched['country'] && errors['country'])
                      ? errors['country']
                      : (touched['zip'] && errors['zip'])
                        ? errors['zip']
                        : '') as string
                  }
                  onChange={(event) => setFieldValue('zip', event.target.value)}
                />
              </Box>
              {order?.amount !== 0
                ? <Button
                    fullWidth
                    color='primary'
                    variant='contained'
                    type='submit'
                    onClick={() => card.validate()}
                  >
                    Pay {getCurrencySybmol(order?.currency)} {formatCurrency(order?.amount)}
                  </Button>
                : <Box className={classes.footer}>
                    <Button
                      fullWidth
                      color='secondary'
                      variant='outlined'
                      onClick={() => onClose()}
                    >
                      Cancel
                    </Button>
                    <Button
                      fullWidth
                      color='primary'
                      variant='contained'
                      type='submit'
                      onClick={() => {
                        validateForm();
                        card.validate();
                      }}
                    >
                      Save
                    </Button>
                  </Box>
              }
            </Box>
          </Form>
        )}
      </Formik>

      <SuccessPayment
        open={showSuccessModal}
        title={
          order.amount !== 0
            ? 'Payment successful!'
            : 'Payment method has been changed!'
        }
        onClose={() => handleRedirect()}
      />

      <ErrorPayment
        open={showErrorModal}
        title={
          order.amount !== 0
            ? 'Your order has been declined'
            : 'Unable to change payment method'
        }
        onClose={() => setShowErrorModal(false)}
        onTryAgain={() => handleSubmit(formRef.current.values)}
        onPayLater={() => handleRedirect()}
      />
    </>
  );
};

export default PaymentMethod;
