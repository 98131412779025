import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  message: {
    width: '100%',
    padding: '2px 0px', 
    fontSize: '14px', 
    fontWeight: 500, 
    lineHeight: '21px',
    overflowX: 'hidden',
    overflowY: 'auto',
    whiteSpace: 'break-spaces',
    border: 'none',
  },
  markdown: {
    '& *': {
      margin: 0,
    },
  },
  errorMeesage: {
    width: 'fit-content',
    color: '#D93855',
    padding: '6px 14px',
    background: 'transparent',
    borderRadius: '6px',
    border: '1px solid #D93855',
  },
  progressBar: {
    width: '100%',
    height: '14px',
    borderRadius: '2px',
    overflow: 'hidden',
    backgroundColor: 'transparent',
    position: 'relative',
    margin: '8px 0px',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: 'linear-gradient(to right, #FFE4A933, #CAB354CC, #FFE4A933)',
      animation: '$progressAnimation 2s linear infinite',
    },
  },
  actionsBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginTop: '8px'
  },
  verticalSeparator: {
    width: '1px',
    height: '25px',
    background: '#CBD5E1'
  },
  '@keyframes progressAnimation': {
    '0%': {
      transform: 'translateX(-100%)',
    },
    '100%': {
      transform: 'translateX(100%)',
    },
  },
}));
