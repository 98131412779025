import { IBlock } from "../../../framework/main/IBlock";
import { Message } from "../../../framework/main/Message";
import { BlockComponent } from "../../../framework/main/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/main/Messages/MessageEnum";
import { runEngine } from "../../../framework/main/RunEngine";
export const configJSON = require("./config");

import {
  slider1,
  slider2,
  slider3,
  slider4,
  slider5,
  slider6,
  slider7,
  slider1Web,
  slider2Web,
  slider3Web,
  slider4Web,
  slider5Web,
  slider6Web,
  slider7Web
} from "./assets";

const welcomeSliderList = [
  {
    id: 1,
    img: slider1,
    imgWeb: slider1Web,
    title: "Create Ripples",
    subTitle: "With your personal high impact\nGTMSai co-pilot."
  },
  {
    id: 1,
    img: slider2,
    imgWeb: slider2Web,
    title: "Supercharge productivity",
    subTitle: "By automating go to market\nworkflows."
  }
];
const B2BSlider = [
  {
    id: 1,
    img: slider1,
    imgWeb: slider1Web,
    title: "Seamless AIaaS Integrations",
    subTitle: `Leverage our GTM intelligence to retain\n those high-value contracts.`
  },
  {
    id: 2,
    img: slider2,
    imgWeb: slider2Web,
    title: "Fully-branded White Labels",
    subTitle: `Present advanced GTM solutions as\nyour own and watch your agency soar.`
  },
  {
    id: 3,
    img: slider3,
    imgWeb: slider3Web,
    title: "Intelligent APIs",
    subTitle: `Convert your freemium sign-\nups into paying customers.`
  }
];

const B2BPlan = [
  {
    id: 2,
    img: slider2,
    imgWeb: slider2Web,
    title: "Create Ripples",
    subTitle: "With your personal high impact\nGTMSai co-pilot."
  },
  {
    id: 3,
    img: slider3,
    imgWeb: slider3Web,
    title: "Supercharge productivity",
    subTitle: "By automating go to market\nworkflows."
  }
];

const D2CSlider = [
  {
    id: 1,
    img: slider4,
    imgWeb: slider4Web,
    title: "Streamlined GTM Workflows",
    subTitle: `Eliminate go-to-market inefficiencies\nand unlock your business's growth potential.`
  },
  {
    id: 2,
    img: slider5,
    imgWeb: slider5Web,
    title: "Effortless High-impact Marketing",
    subTitle: `Save yourself from tedious tasks and craft\ncampaigns that truly captivate.`
  },
  {
    id: 3,
    img: slider6,
    imgWeb: slider6Web,
    title: "Stronger Sales Momentum",
    subTitle: `Reduce sales admin to free up more time\nand energy for impactful deal-making.`
  }
];

const PassSlider = [
  {
    id: 1,
    img: slider4,
    imgWeb: slider4Web,
    title: "Founders & C-Suite",
    subTitle: `Become a legendary leader by transforming your business into a profitable powerhouse with our AI-native, end-to-end GTM platform.`
  },
  {
    id: 2,
    img: slider3,
    imgWeb: slider3Web,
    title: "Marketing Professionals",
    subTitle: `Grow into a marketing mastermind by supercharging your marketing efforts with our intelligence and content generator.`
  },
  {
    id: 3,
    img: slider6,
    imgWeb: slider6Web,
    title: "Sales Professionals",
    subTitle: `Turn yourself into a sales superstar by converting each lead into a closed-won deal with our clever sales enablement tools.`
  }
];

const LoginSlider = [
  {
    id: 1,
    img: slider2,
    imgWeb: slider2Web,
    title: "Create Ripples",
    subTitle: `With your personal high impact\nGTMSai co-pilot`
  },
  {
    id: 2,
    img: slider7,
    imgWeb: slider7Web,
    title: "Supercharge productivity",
    subTitle: `By automating go to market\nworkflows.`
  }
];

export interface Props {
  navigation?: any;
  id?: string;
  type?: "company" | "individual" | "password" | "login" | "welcome" | "b2b-plan";
}

interface S {
  activeIdx: number;
  imageList: { 
    id: number; 
    img: any; 
    imgWeb: any;
    title: string; 
    subTitle: string;
  }[];
}

interface SS {
  id: any;
}

export default class CarouselDisplayController extends BlockComponent<
  Props,
  S,
  SS
> {
  swiper: any;
  interval: number | undefined = undefined;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    const slider = {
      company: B2BSlider,
      individual: D2CSlider,
      password: PassSlider,
      login: LoginSlider,
      welcome: welcomeSliderList,
      "b2b-plan": B2BPlan
    };

    this.state = {
      activeIdx: 0,
      imageList: slider[this.props.type || "company"]
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount(): Promise<void> {
    this.interval = (setInterval(() => {
      this.setState((pre) => ({
        ...pre,
        activeIdx:
          pre.activeIdx < pre.imageList.length - 1 ? pre.activeIdx + 1 : 0
      }));
    }, 4000) as unknown) as number;
    return Promise.resolve();
  }

  onClickPrevious = () => {
    this.updateInterval();
    this.setState((pre) => ({
      ...pre,
      activeIdx:
        pre.activeIdx > 0 ? pre.activeIdx - 1 : pre.imageList.length - 1
    }));
  };

  onClickNext = () => {
    this.updateInterval();
    this.setState((pre) => ({
      ...pre,
      activeIdx:
        pre.activeIdx < pre.imageList.length - 1 ? pre.activeIdx + 1 : 0
    }));
  };

  updateInterval = () => {
    !!this.interval && clearInterval(this.interval);
    this.interval = (setInterval(() => {
      this.setState((pre) => ({
        ...pre,
        activeIdx:
          pre.activeIdx < pre.imageList.length - 1 ? pre.activeIdx + 1 : 0
      }));
    }, 4000) as unknown) as number;
  };

}
