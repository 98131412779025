import { BlockComponent } from '../../../framework/main/BlockComponent';
import { IBlock } from "../../../framework/main/IBlock";
import { runEngine } from "../../../framework/main/RunEngine";
import { Message } from "../../../framework/main/Message";
import MessageEnum, { getName } from '../Messages/MessageEnum';
import { routeMap } from '../../../helpers/routes';

interface Props {}
interface S {}
interface SS {}

class NavigationBlock extends BlockComponent<Props, S, SS> {
  constructor(props: Props = { navigation: null }) {
    super(props);
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPropsMessage)
    ]);
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.NavigationMessage)) {
      // debugger;
      const raiseMessage: Message = message.getData(
        getName(MessageEnum.NavigationRaiseMessage)
      );
      if (raiseMessage !== undefined) {
        const self = this;
        setTimeout(function() {
          self.send(raiseMessage);
        }, 0);
      }
      const screenTitle = message.getData(
        getName(MessageEnum.NavigationScreenNameMessage)
      );
      const props = message.getData(
        getName(MessageEnum.NavigationPropsMessage)
      );
      const targetPage = message.getData(
        getName(MessageEnum.NavigationTargetMessage)
      );

      if (props && props.navigation) {
        props?.navigation?.set;
        props?.navigation?.navigate(
          targetPage,
          { navigationBarTitleText: screenTitle }
        );
      } else if (targetPage) {
        // use redirect with refresh in case of absent navigation in props
        window.location.href = routeMap[targetPage].path;
      }
    }
  }
}

export default NavigationBlock;
