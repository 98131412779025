import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  example: [],
};

export const exampleSlice = createSlice({
  name: 'example',
  initialState,
  reducers: {
    addExample: (state, action) => {
      state.example.push(action.payload);
    },
    updateExample: (state, action) => {
      state.example = state.example.map((item) => {
        item.id === action.payload.id
          ? {
              ...item,
              newField: "some new value"
            }
          : item
      });
    },
    removeExample: (state, action) => {
      state.example = state.example.filter((item) => item.id !== action.payload.id);
    },
    clearExample: (state) => {
      state.example = [];
    },
  },
});

export const { 
  addExample,
  updateExample,
  removeExample, 
  clearExample 
} = exampleSlice.actions;

export default exampleSlice.reducer;
