import React from "react";
import CustomTextArea from "../../../components/main/CustomTextArea.web";
import UniqueSellingController, {
  Props,
  configJSON,
} from "./UniqueSellingController";

export default class UniqueSelling extends UniqueSellingController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { 
      handleChange, 
      values, 
      errors, 
      name,
      requiredIndex,
      maxCharacters,
      maxWords,
    } = this.props;

    return (
      <CustomTextArea
        data-testid="unique-selling"
        title={configJSON.uniqueSelling}
        maxCharacters={maxCharacters}
        maxWords={maxWords}
        onChange={(e: any) => handleChange(e)}
        placeholder={configJSON.uniquePlaceholder}
        minRows={3}
        modalContent={configJSON.uniqueSellInfo}
        errors={errors}
        // value={this.props.textValue}
        value={values}
        name={name}
        required={requiredIndex === 0}
      />
    );
  }
}

