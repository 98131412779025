export const sidebar = require("../assets/sidebar.png");
export const bookBlue = require("../assets/book-blue.png");
export const bookGreen = require("../assets/book-green.png");
export const bookRed = require("../assets/book-red.png");
export const bookYellow = require("../assets/book-yellow.png");
export const flowBlue = require("../assets/flow-blue.png");
export const flowGreen = require("../assets/flow-green.png");
export const flowRed = require("../assets/flow-red.png");
export const flowYellow = require("../assets/flow-yellow.png");
export const robotBlock = require("../assets/robot-block.png");
export const robotCoin = require("../assets/robot-coin.png");
export const robotLight = require("../assets/robot-light.png");
export const robotLove = require("../assets/robot-love.png");
export const landingBg = require("../assets/landing-bg.png");
export const landingInner = require("../assets/landing-inner.png");
export const rightBar = require("../assets/right_bar_icon.svg").default;
