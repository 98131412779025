import { IBlock } from "../../../framework/main/IBlock";
import { BlockComponent } from "../../../framework/main/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/main/Messages/MessageEnum";
import { runEngine } from "../../../framework/main/RunEngine";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  textValue?: any;
  handleChange?: any;
  touched: any;
  values: any;
  errors: any;
  name: any;
  requiredIndex?: number;
  maxCharacters?: number;
  maxWords?: number;
}

interface S {
  uniqueTextValue: string;
}

interface SS {
  id: any;
}

export default class UniqueSellingController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
    ];

    this.state = {
      uniqueTextValue: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    super.componentDidMount();
  }


  // web events

  // handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
  //   if(event.target.value.length <= 200){
  //   this.setState({uniqueTextValue:event.target.value})
  //   }
  // }

}
