import moment from 'moment';

export const lastActivityDateFormat = (date) => {
  if (date) {
    const momentDate = moment(date);
    const today = moment();
    const yesterday = moment().subtract(1, 'days');

    if (momentDate.isSame(today, 'day')) {
      return `today ${momentDate.format('HH:mm')}`;
    } else if (momentDate.isSame(yesterday, 'day')) {
      return `yesterday ${momentDate.format('HH:mm')}`;
    } else {
      const daysAgo = today.diff(momentDate, 'days');
      return `${daysAgo} days ago ${momentDate.format('HH:mm')}`;
    }
  } else {
    return 'never'
  }
};

export const isDateInPast = (date) => {
  const incomingDate = new Date(date);
  const now = new Date();

  // Compare the input date with the current date
  return incomingDate < now;
}