import React from 'react';
import { Box, Button, IconButton, Modal, Typography } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../../../../../assets/icons/close.svg';
import { ReactComponent as DeclinedPaymentIcon } from '../../../../../../../assets/icons/declined_payment.svg';

import { useStyles } from './styles';

interface Props {
  open: boolean;
  title: string;
  onClose: () => void;
  onTryAgain: () => void;
  onPayLater: () => void;
}

const ErrorPayment: React.FC<Props> = ({
  open,
  title,
  onClose,
  onTryAgain,
  onPayLater,
}) => {
  const classes = useStyles();

  const url = window.location.pathname;

  return (
    <Modal open={open} onClose={onClose} className='MuiDialog-root'>
      <Box className={classes.modalBox}>
        <IconButton onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
        <DeclinedPaymentIcon />
        <Typography className={classes.title}>
          {title}
        </Typography>
        <Box className={classes.buttonContainer}>
          {!url.includes('/choose-your-plan') && (
            <Button 
              fullWidth
              color='secondary'
              variant='outlined'
              onClick={() => {
                onClose();
                onPayLater();
              }}
            >
              Pay later
            </Button>
          )}
          <Button 
            fullWidth
            color='primary'
            variant='contained'
            onClick={() => {
              onClose();
              // onTryAgain();
            }}
          >
            Try again
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ErrorPayment;
