import { IBlock } from "../../../framework/main/IBlock";
import { BlockComponent } from "../../../framework/main/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/main/Messages/MessageEnum";
import { runEngine } from "../../../framework/main/RunEngine";
import { ParameterFormData } from "./ParameterModalController.web";

import StorageProvider from '../../../framework/main/StorageProvider';
import { Message } from "../../../framework/main/Message";
import moment from "moment";
import { enqueueSnackbar } from "notistack";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  token?: string;
}

interface S {
  rightMenu: boolean;
  applyParameter: boolean;
  mailArray: {
    day: string
    mail: string
  }[];
  subject: string;
  subjectError: string;
  mailActive: number;
  saveMessage: boolean;
  deletePopup: boolean;
  shareModal: boolean;
  mail: string;
  generateMail: boolean;
  campDetails: {
    id: string,
    type: string,
    attributes: {
      id: number,
      subject_line: string,
      campaign: {
        id: number,
        name: string,
        description: string,
        account_id: number,
        favourited: boolean,
        campaign_type_id: number,
        campaign_category_id: number
      },
      prompt: string
    }
  };
  isAiThinking: boolean;
  chatList: any[]
  generatedMail: {
    id: string,
    type: string,
    attributes: {
      id: number,
      context: string,
      subject_line: string,
      account_id: number,
      prompt: string,
      favourited: boolean,
      campaign: {
        id: number,
        name: string,
        description: string,
        account_id: number,
        favourited: boolean,
        campaign_type_id: number,
        campaign_category_id: number
      },
      params: {
        data: {
          id: string,
          type: string,
          attributes: {
            id: number,
            name: string,
            description: string,
            param_group: {
              id: number
            }
          }
        }
      }
    }
  }[];
  questionText: string;
  displayToaster: boolean;
  toasterMsg: string;
  formData: any[];
  formValueData: any[];
  parameterDialog: boolean
  parameterChangeDialog: boolean
  loader: boolean
  generatedOutPutId: string
  copiedAlert: boolean;
  copiedMessage: string;
  campaignID: number;
  campaignName: string;
  outReachId: number;
  campaignTypeId: number;
  campaignType: string;
  groupId: string;
  deleteSuccess: boolean;
  generatedMailBookmark: boolean;
  chatId: any;
}

interface SS {
  id: any;
}

export default class EmailCreationController extends BlockComponent<
  Props,
  S,
  SS
> {
  apicreateChatHistoryCallId: string = '';
  displayCampDetailsID: string = "";
  getMailDataID: string = "";
  getParamDataID: string = "";
  getQuestionMsgId: string = "";
  bookmarkId: string = "";
  deleteCampDetailsID: string = "";
  saveLibraryId: string = "";
  campaignDetailsId: string = "";
  updateSubjectID: string = "";
  token: string;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      rightMenu: false,
      chatList: [],
      campaignTypeId: 1,
      displayToaster: false,
      toasterMsg: '',
      isAiThinking: false,
      applyParameter: false,
      chatId: null,
      mailArray: [
        {
          day: 'day',
          mail: 'Mail'
        }
      ],
      subject: '',
      subjectError: "",
      mailActive: 0,
      saveMessage: false,
      deletePopup: false,
      shareModal: false,
      mail: '',
      generateMail: false,
      questionText: '',
      campDetails:
      {
        id: "",
        type: "",
        attributes: {
          id: 0,
          subject_line: "",
          campaign: {
            id: 0,
            name: "",
            description: "",
            account_id: 0,
            favourited: false,
            campaign_type_id: 0,
            campaign_category_id: 0
          },
          prompt: ""
        }
      },
      generatedMail: [],
      parameterDialog: false,
      parameterChangeDialog: false,
      formData: [],
      formValueData: [],
      loader: true,
      generatedOutPutId: '',
      copiedAlert: false,
      copiedMessage: '',
      campaignID: 0,
      campaignName: '',
      outReachId: 0,
      campaignType: '',
      groupId: '',
      deleteSuccess: false,
      generatedMailBookmark: false
    };
  }

  async componentDidMount() {
    super.componentDidMount();

    const token = await StorageProvider.getItem("user-token");
    this.token = token;

    this.setState({
      groupId: await StorageProvider.getItem("param_group_id")
    }, () => {
      if (this.state.groupId !== null) {
        this.getCampaignDetails()
        this.getFormData()
      }
    })
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      await this.handleCampaignResponse(requestCallId, message);
      await this.handleCampDetails(requestCallId, message);
      await this.handleMailCreated(requestCallId, message);
      await this.handleParamDetails(requestCallId, message);
      await this.handleFormQuestion(requestCallId, message);
      await this.handleBookmarkResponse(requestCallId, message);
      await this.handleDeleteResponse(requestCallId, message);
    }
    this.handelCreateChatHistoryCallIdAPIResponse(message)
  }

  handelCreateChatHistoryCallIdAPIResponse = (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (this.apicreateChatHistoryCallId !== apiRequestCallId) return;
    if (responseJson.errors) {
      return;
    }
    this.setState({
      chatList: responseJson?.data?.attributes,
      isAiThinking: false,
      chatId: responseJson?.data?.attributes[0]?.chat_session_id,
    })
  }
  async handleCampDetails(requestCallId: string, message: Message) {
    if (this.displayCampDetailsID === requestCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      this.setState({ loader: false })
      if (responseJson.data) {
        this.setState({
          generatedMail: responseJson.data,
          campDetails: responseJson.data[0],
          mail: responseJson.data[0]?.attributes?.context,
          subject: responseJson.data[0]?.attributes?.subject_line,
          generatedOutPutId: responseJson.data[0]?.id,
          generatedMailBookmark: responseJson.data[0]?.attributes?.favourited
        });

        const activeMailId = await StorageProvider.getItem("mail_active")

        StorageProvider.removeItem("mail_active")

        if (!activeMailId) return

        const index = this.state.generatedMail.findIndex((item) => item.id.toString() === activeMailId.toString())

        if (index < 0) return

        this.handleSelectMail(index)

      } else {
        const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorMessage);
        this.setState({
          loader: false
        })
      }
    }
  }
  async handleMailCreated(requestCallId: string, message: Message) {
    this.setState({
      loader: false
    })
    if (this.getMailDataID === requestCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.answer) {
        this.handleAddMail();
        this.getCampDetails();
        this.setState({
          generateMail: true
        });
      } else {
        const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorMessage);
        if (!errorMessage) {
          try {
            const error = responseJson.errors[0].message;

            enqueueSnackbar(
              error,
              { variant: 'error' }
            )
          } catch (error) { }
        }
      }
    }
  }
  async handleParamDetails(requestCallId: string, message: Message) {
    if (this.getParamDataID === requestCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.data) {
        const listAnswer = responseJson.data;
        const cateId = listAnswer[0].attributes.campaign.campaign_type_id
        this.setState({
          formValueData: listAnswer,
          campaignTypeId: cateId
        });
      } else {
        const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorMessage);
      }
    }
  }
  async handleFormQuestion(requestCallId: string, message: Message) {
    if (this.getQuestionMsgId === requestCallId) {
      this.getParamData();
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.data) {
        this.setState({
          formData: responseJson.data
        });
      } else {
        const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorMessage);
      }
    }
  }
  async handleBookmarkResponse(requestCallId: string, message: Message) {
    if (this.bookmarkId === requestCallId || this.saveLibraryId === requestCallId) {
      this.setState({ loader: false })
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.data) {
        this.setState({ saveMessage: responseJson.data.attributes.favourited, generatedMailBookmark: !this.state.generatedMailBookmark }, () =>
          this.getCampDetails()
        )
      } else {
        const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorMessage);
      }
    }
  }
  async handleCampaignResponse(requestCallId: string, message: Message) {
    if (this.campaignDetailsId === requestCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson[0]?.id) {
        this.setState({
          campaignID: responseJson[0].id,
          campaignType: responseJson[0].campaign_type_title
        }, () => this.getCampDetails())
      } else {
        const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorMessage);
      }
    }
  }
  async handleDeleteResponse(requestCallId: string, message: Message) {
    if (this.deleteCampDetailsID === requestCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message) {
        this.setState({ deletePopup: false, deleteSuccess: true }, () => this.handleDashboard())

      } else {
        const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorMessage);
      }
    }
  }
  handleRightMenu = () => {
    this.setState({ rightMenu: !this.state.rightMenu })
  }
  handleAddMail = () => {
    this.setState({ mailArray: [...this.state.mailArray, { day: 'day', mail: 'Mail' }] })
  }
  handleSelectMail = (id: number) => {
    this.state.generatedMail.filter((data, index) => index === id).forEach(data =>
      this.setState({ subject: data.attributes.subject_line, mail: data.attributes.context, generatedOutPutId: data.id, generatedMailBookmark: data.attributes.favourited })
    )
    this.setState({ mailActive: id })
  }

  changeQuestText = (e: any) => {
    this.setState({
      questionText: e.target.value
    })
  }

  submitChat = async () => {
    const _trimmedValue = this.state.questionText.trim();
    if (_trimmedValue !== '') {
      this.setState({
        isAiThinking: true,
        chatList: [...this.state.chatList,
        {
          "chat_session_id": this.state.chatId,
          "id": 'myId' + (this.state.chatList.length + 1),
          "updated_at": moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
          "role": "user",
          "content_type": "Question",
          "content": _trimmedValue,
          "created_at": moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        }
        ],
        questionText: "",
      })
      const header = {
        token: this.token,
        "Content-Type": configJSON.loginApiContentType,
      };
      let apiUrl = `${configJSON.createChatHistoryApiEndpoint}/?content=${_trimmedValue}`;
      if (this.state.chatId) {
        apiUrl = `${configJSON.createChatHistoryApiEndpoint}/?chat_session_id=${this.state.chatId}&content=${_trimmedValue}`;
      }

      const apiMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      apiMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        apiUrl
      );
      this.apicreateChatHistoryCallId = apiMessage.messageId;

      apiMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      apiMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postMethod
      );
      runEngine.sendMessage(apiMessage.id, apiMessage);
      return true;
    }
  }

  handleSave = (bookmark: boolean) => {
    this.setState({ loader: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };
    const body = {
      "favourited": bookmark
    }
    this.bookmarkId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointBookmarkCamp(this.state.campDetails.attributes.campaign.id)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDelete = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };
    const body = {
      "ids": this.state.campDetails.attributes.campaign.id
    }
    this.deleteCampDetailsID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointDeleteCamp
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCloseDelteModal = () => {
    this.setState({ deletePopup: !this.state.deletePopup })
  }

  handleShare = () => {
    this.setState({ shareModal: !this.state.shareModal })
  };

  handleMailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ mail: event.target.value })
  }

  handleGenerate = () => {
    this.getMail()
    this.setState({ loader: true })
  }

  getCampDetails = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };
    this.displayCampDetailsID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetCamp + this.state.campaignID
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getMail = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };
    const body = {
      "campaign_id": this.state.campDetails.attributes.campaign.id,
      "param_group_id": this.state.groupId
    }
    this.getMailDataID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointCreateMail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleOpenParameterDialog = async () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Cfdataintegrationviapromptengineering4");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      paramGroupId: this.state.groupId
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  handleCloseParameterDialog = () => {
    this.setState({
      parameterDialog: false
    })
  }

  handleOpenParameterDialogChange = () => {
    this.setState({
      parameterChangeDialog: true
    })
  }

  handleCloseParameterDialogChange = () => {
    this.setState({
      parameterChangeDialog: false
    })
  }

  getFormData = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };
    this.getQuestionMsgId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.formCreationEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getParamData = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };
    this.getParamDataID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointgetParam + this.state.groupId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(this.state.mail);
      this.setState({ copiedAlert: true, copiedMessage: 'Copied to clipboard!' });
    } catch (error) {
      this.setState({ copiedAlert: true, copiedMessage: 'Failed to copy!' });
    }
  };

  handleCopyClose = () => {
    this.setState({ copiedAlert: false });
  }
  handleCloseToast = () => {
    this.setState({ saveMessage: false })
  }
  handleCloseSuccessPopup = () => {
    this.setState({ deleteSuccess: false })
  }
  handleDashboard = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'LandingPage'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }
  handleChangeSubject = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ subject: event.target.value })
    const regex = /^[a-zA-Z0-9]/
    if (!regex.test(event.target.value)) {
      this.setState({ subjectError: "Email Subject can only contain alphanumeric characters" })
      return
    } else {
      this.setState({ subjectError: '' })
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };
    const body = {
      "subject_line": event.target.value
    }
    this.updateSubjectID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointupdateSubject(this.state.generatedOutPutId)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSaveLibrary = (favourited: boolean) => {
    this.setState({ loader: true })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };
    this.saveLibraryId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointsaveLibrary(Number(this.state.generatedOutPutId), favourited)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCampaignDetails = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };
    this.campaignDetailsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointCampDetails + "?param_group_id=" + this.state.groupId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
