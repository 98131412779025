import React, { useEffect, useState } from 'react';
import { Box, IconButton, Modal } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import Profile from './components/Profile';

import { useStyles } from './styles';
import PlanAndBilling from './components/PlanAndBilling';
import Wallet from './components/Wallet';
import ConfirmationModal from '../ConfirmationModal';
import Settings2 from '../../../blocks/settings2/main/Settings2';

interface Props {
  open: boolean;
  onClose: () => void;
  onReloadData: () => void;
}

const ProfileModal: React.FC<Props> = ({
  open,
  onClose,
  onReloadData,
}) => {
  const [activeTab, setActiveTab] = useState<number>(1);

  const classes = useStyles();

  useEffect(() => {
    return () => {
      setActiveTab(1)
    }
  }, [open]);

  return (
    <>
      <Modal open={open} onClose={onClose} className='MuiDialog-root'>
        <Box className={classes.container}>
          <Box className={classes.header}>
            <Box className={classes.tabs}>
              <Box
                className={`${classes.tabItem} ${activeTab === 1 ? classes.tabItemActive : ''}`}
                onClick={() => setActiveTab(1)}
              >
                Profile
              </Box>
              {/* <Box 
                className={`${classes.tabItem} ${activeTab === 2 ? classes.tabItemActive : ''}`}
                onClick={() => setActiveTab(2)}
              >
                Settings
              </Box> */}
              <Box
                className={`${classes.tabItem} ${activeTab === 3 ? classes.tabItemActive : ''}`}
                onClick={() => setActiveTab(3)}
              >
                Plan and Billing
              </Box>
              <Box
                className={`${classes.tabItem} ${activeTab === 4 ? classes.tabItemActive : ''}`}
                onClick={() => setActiveTab(4)}
              >
                Wallet
              </Box>
            </Box>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box className={classes.content}>
            {activeTab === 1 && (
              <Profile
                onClose={onClose}
                onReloadData={onReloadData}
              />
            )}
            {/* {activeTab === 2 && (
              <Settings2
                reloadData={onReloadData}
                navigation={{}}
                handleToClose={onClose}
              />
            )} */}
            {activeTab === 3 && (
              <PlanAndBilling
                onReloadData={onReloadData}
              />
            )}
            {activeTab === 4 && (
              <Wallet
                onReloadData={onReloadData}
              />
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ProfileModal;
