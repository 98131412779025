import { createStyles } from "@material-ui/core/styles";

export const styles = () =>
  createStyles({
    layoutContainer: {
      display: "flex",
      flexDirection: "column",
      flex:1,
      '@media (min-width: 600px)' : {
        justifyContent: "center",
        alignItems: "center",
      },
    },
    emailContainer: {
      width: "80%",
      maxWidth: '460px',
      border: "1px solid #CBD5E1",
      display: "flex",
      flexDirection: "column",
      borderRadius: "28px",
      background: "linear-gradient(145deg, rgba(255, 255, 255, 0.52) -7.94%, rgba(255, 255, 255, 0.79) 102.73%)",
      padding:'20px',
      '@media (min-width: 1300px)' : {
        margin: "0 auto",
      },
    },
    sigupText: {
      textAlign: "center",
      fontSize: "30px",
      fontWeight: 700,
      lineHeight: "32px",
      marginBottom: "6px",
      '@media (min-width: 1300px)' : {
        marginBottom: "12px",
      },
      color: "black"
    },
    subTitle: {
      textAlign: "center",
      fontSize: "14px",
      lineHeight: 1.5,
      fontWeight: 500,
      color: "#475569",
      display: "inline-block"
    },
    loginText: {
      display: "inline-block",
      textAlign: "center",
      fontSize: "14px",
      lineHeight: 1.5,
      fontWeight: 700,
      color: "#3A84C9",
      marginLeft: "4px",
      cursor: "pointer",
      fontFamily: "Montserrat,sans-serif"
    },
    form: {
      margin: "0"
    },
    label: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
      color: "black",
      marginTop: "16px",
    },
    input: {
      height: "44px",
      marginTop: "4px",
      borderRadius: "8px",
      border: "1px solid rgba(140, 140, 140, 0.20)",
      background: "#FFF",
      "& .MuiOutlinedInput-root": {
        borderRadius: "8px",
        "& fieldset": {
          borderColor: "transparent",
          height: "44px"
        },
        "&:hover fieldset": {
          borderColor: "transparent"
        },
        "&.Mui-focused fieldset": {
          borderColor: "transparent"
        },
        "& .MuiOutlinedInput-input": {
          padding: "0 12px",
          lineHeight: "21px",
          color: "#64748B",
          fontSize: "14px",
          fontWeight: 500
        },

        "& .MuiInputBase-input::placeholder": {
          opacity: 1
        },

        "& .MuiInputBase-input": {
          height: "44px"
        }
      }
    },
    button: {
      height: "44px",
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: 1.2,
      background: "#000",
      textTransform: "none",
      color: "white",
      margin: "16px 0",
      '@media (min-width: 1300px)' : {
        margin: "32px 0",
      },
      "&:hover": {
        background: "#000"
      }
    },
    divider: {
      width: "100%",
      display: "flex",
      height: 21,
      justifyContent: "center",
      position: "relative",
      alignItems: "center"
    },
    dividerTextWrapper: {
      padding: "0 37.5px",
      position: "relative"
    },
    dividerText: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: 1.5,
      color: "#475569"
    },
    hr: {
      background: "#CBD5E1",
      height: "1px",
      flex: 1
    },
    googlebtn: {
      height: "42px",
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: 1.2,
      marginTop: "16px",
      '@media (min-width: 1300px)' : {
        marginTop: "32px",
      },
      textTransform: "none",
      border: "1px solid rgba(140, 140, 140, 0.20)",
      background: "#FFF",
      boxShadow: "none",
      width: "100%",
      "&:hover": {
        background: "#FFF"
      }
    },
    googleTxt: {
      color: "#000",
      marginLeft: "8px",
      fontWeight: 600,
      fontStyle: "normal",
      fontSize: "16px"
    },
    terms: {
      display: "flex",
      alignItems: "center",
      marginTop: "30px",
      marginBottom: "3%"
    },
    checkbox: {
      width: "20px",
      height: "20px",
      "& .MuiCheckbox-root": {
        color: "#64748B"
      }
    },
    agreement: {
      color: "#0F172A",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "22px",
      fontStyle: "normal",
      fontFamily: "Inter"
    },
    link: {
      color: "#3A84C9",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      textDecoration: "underline",
      paddingBottom: "3px",
      cursor: "pointer"
    },
    errorText: {
      color: "rgb(179, 23, 23)",
      fontSize: "13px",
      marginTop: "2px"
    },
    validateError: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 1.2,
      color: "#DC2626",
      marginTop: "2px"
    },
    forgotPwdLink: {
      cursor: "pointer",
      color: "#3A84C9",
      fontWeight:600,
      fontSize:"12px",
      fontFamily:"Montserrat"
    },
    forgotPwdLabelWrapper:{
      display:"flex",
      justifyContent:"end",
      alignItems:"center",
      marginTop:"5px"
    },
    pwdIconColor:{
      color:"#94A3B8",
      cursor:"pointer",
    },
    inputFeildOuterWrapper:{
      marginBottom:"2rem"
    }
  });
