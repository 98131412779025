export const getFileExtension = (filename) => {
  if (typeof filename === 'string') {
    const lastDotIndex = filename.lastIndexOf('.');
  
    if (lastDotIndex === -1 || lastDotIndex === filename.length - 1) {
      return 'unknown';
    }
  
    return filename.slice(lastDotIndex + 1);
  } else {
    return 'unknown';
  }
}

export const convertFileTypeToExtension = (filetype) => {
  if (typeof filetype === 'string') {
    const mimeToExtensionMap = {
      'text/plain': 'txt',
      'text/html': 'html',
      'text/css': 'css',
      'text/javascript': 'js',
      'application/json': 'json',
      'application/pdf': 'pdf',
      'application/zip': 'zip',
      'application/x-rar-compressed': 'rar',
      'application/msword': 'doc',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
      'application/vnd.ms-excel': 'xls',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
      'application/vnd.ms-powerpoint': 'ppt',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
      'image/jpeg': 'jpg',
      'image/png': 'png',
      'image/gif': 'gif',
      'image/bmp': 'bmp',
      'image/webp': 'webp',
      'image/svg+xml': 'svg',
      'audio/mpeg': 'mp3',
      'audio/wav': 'wav',
      'video/mp4': 'mp4',
      'video/x-msvideo': 'avi',
    };

    return mimeToExtensionMap[filetype] || 'unknown'
  } else {
    return 'unknown';
  }
}

export const convertFromBytes = (bytes, toFixed = 0) => {
  if (typeof bytes === 'number' && bytes > 0) {
    
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const index = Math.floor(Math.log(bytes) / Math.log(1024));
    const size = bytes / Math.pow(1024, index);

    return `${size.toFixed(toFixed)} ${units[index]}`;
  } else {
    return 'unknown';
  }
}

export const convertToBytes = (size, unit) => {
  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const unitMap = units.reduce((acc, unit, index) => {
    acc[unit.toUpperCase()] = index;
    return acc;
  }, {});

  const unitUpper = unit.toUpperCase();

  if (typeof size === 'number' && size > 0 && unitMap[unitUpper] !== undefined) {
    const bytes = size * Math.pow(1024, unitMap[unitUpper]);
    return bytes;
  }

  return 'unknown';
};